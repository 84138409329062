import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeleteScheduledJobData = {
  scheduleId: number;
};

export default function useDeleteScheduledJob(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ scheduleId }: DeleteScheduledJobData) => axios.delete(
    `/scheduler/api/schedule/${scheduleId}`,
  ).then(({ data }) => data), options);
}
