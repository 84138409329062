import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
  title: string;
  body: any;
  htmlDoc?: HTMLDocument;
  htmlBackgroundElement?: HTMLElement | null;
  show: boolean;
  onClose: () => void;
  onOk: () => void;
  onCancel: () => void;
};

const hideElement = (el: HTMLElement) => {
  el.setAttribute('aria-hidden', 'true');
  el.setAttribute('style', 'display:none;');
};

const showElement = (el: HTMLElement) => {
  el.setAttribute('style', `${el.getAttribute('style')} z-index: 1050; display:block;`);
  el.removeAttribute('aria-hidden');
};

const ModalConfirm: React.FC<ModalProps> = ({
  show,
  htmlBackgroundElement,
  htmlDoc,
  onOk,
  onCancel,
  title,
  body,
}) => {
  let doc = htmlDoc;
  if (!doc) doc = document;
  const [modalRoot] = useState(doc.getElementById('modal'));
  const [backdrop] = useState<HTMLDivElement>(doc.createElement('div'));
  const [element] = useState<HTMLDivElement>(doc.createElement('div'));
  element.className = 'modal-confirm"';
  element.style.position = 'absolute';
  element.style.width = '500px';
  element.style.height = '200px';
  element.style.top = '50%';
  element.style.left = '50%';
  element.style.transform = 'translate(-50%, -50%)';
  element.style.border = '1px solid #ccc';
  element.style.background = '#fff';
  element.style.borderRadius = '4px';
  element.style.outline = 'none';
  element.style.border = '2px solid #ccc';

  useEffect(() => {
    modalRoot?.appendChild(backdrop);
    modalRoot?.appendChild(element);
    return () => {
      modalRoot?.removeChild(backdrop);
      modalRoot?.removeChild(element);
    };
  }, []);

  const getModalContent = () => (
    <>
      <div className="modal-header">
        <div className="modal-title h4">{title}</div>
      </div>
      <div className="modal-body">
        {body}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" onClick={() => onCancel()}>No</button>
        <button type="button" className="btn btn-Primary" onClick={() => onOk()}>Yes</button>
      </div>
    </>
  );

  if (show) {
    if (htmlBackgroundElement) {
      showElement(element);
      backdrop.setAttribute('class', 'modal-backdrop show');
    }
    return createPortal(getModalContent(), element);
  }
  if (htmlBackgroundElement) {
    showElement(htmlBackgroundElement);
    backdrop.setAttribute('class', '');
    hideElement(element);
  }
  return null;
};

export default ModalConfirm;
