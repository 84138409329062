import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import { Sim } from '../interfaces';

type AddSimRequestData = {
  sim: Sim
};

export default function useAddSim(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ sim }: AddSimRequestData) => axios.post(
    '/sim-manager/api/sims',
    sim,
  ).then(({ data }) => data), options);
}
