import _ from 'lodash';

import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

import useUser from 'hooks/useUser';

export default function useGetUserSettings() {
  const axios = useAxiosWithToken();

  const user = useUser();

  return useQuery(['userSettings', user?.uidNumber], () => axios.get(
    `users/api/users/${user?.uidNumber}/settings`,
  ).then(({ data }) => data), { enabled: !_.isNil(user?.uidNumber), staleTime: 1000 * 60 * 5 });
}
