import React, { useReducer } from 'react';
import GherkinContext from './gherkinContext';
import GherkinReducer from './GherkinReducer';
import {
  ON_ERROR,
  CLEAR_ERRORS,
  ADD_FF,
  REMOVE_FF,
  UPDATE_FF,
  ADD_FF_TAG,
  REMOVE_FF_TAG,
  ADD_SCENARIO,
  REMOVE_SCENARIO,
  UPDATE_SCENARIO,
  ADD_SCENARIO_TAG,
  REMOVE_SCENARIO_TAG,
  ADD_STEP,
  REMOVE_STEP,
  UPDATE_STEP_LIST,
  ADD_AV_STEP,
  REMOVE_AV_STEP,
  UPDATE_AV_STEP,
  ADD_AV_TAG,
  REMOVE_AV_TAG,
  UPDATE_AV_TAG,
  UPDATE_DISPLAYED_STEPS,
  SET_FF_SELECTED,
  SET_SC_SELECTED,
  SET_STEP_SELECTED,
} from './types';
import {
  IScenario, IStep, IFeatureFile, ITag, IStepDefinitionList,
} from './interfaces';
import { StepTextToObject } from '../../components/gherkinStepGenerator/editor/Step';
// TODO replace with backend data - this is a temporary data
import { stepDefinitions } from '../../components/gherkinStepGenerator/data';

const GherkinState = (props: any) => {
  // TODO replace with backend data - this is a temporary data
  const initState = {
    selected: { ffId: null, scId: null, stepId: null },
    tags: ['hi', 'hola', 'ciao'],
    steps: [...stepDefinitions],
    displayedSteps: [...stepDefinitions],
    featureFiles: [{
      id: 0,
      order: 0,
      name: 'VoiceMail',
      description: 'Voicemail test',
      scenarios:
        [{
          id: 0,
          order: 0,
          name: 'make record in the Voicemail',
          tags: [{ text: 'PartyB', id: '1' }],
          isSelected: false,
          steps: [
            { ...StepTextToObject('{party} calls {party}') },
            { ...StepTextToObject('{party} calls {string} for {int}mins') },
            { ...StepTextToObject('{party} answers') },
          ],
        }],
      tags: [{ text: 'PartyA', id: '1' }],
      isSelected: false,
    },
    {
      id: 1,
      order: 1,
      name: 'VoiceMail',
      description: 'Voicemail test',
      scenarios: [{
        id: 0,
        name: 'make something in the Voicemail',
        order: 0,
        tags: [{ text: 'PartyB', id: '1' }],
        isSelected: false,
        steps: [],
      }, {
        id: 1,
        name: 'delete all messages',
        order: 1,
        tags: [{ text: 'PartyB', id: '1' }, { text: 'PartyA', id: '2' }],
        isSelected: false,
        steps: [
          { ...StepTextToObject('{party} calls {string}') },
          { ...StepTextToObject('wake up {party}') },
        ],
      }],
      tags: [{ text: 'PartyA', id: '1' }],
      isSelected: false,
    }],
  };

  const [state, dispatch] = useReducer(GherkinReducer, initState);
  const { children } = props;

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  // ===== Step functions
  const createStep = (ffId: number, scId: number, step: IStep) => {
    dispatch(
      {
        type: ADD_STEP,
        payload: { ffId, scId, step },
      },
    );
  };

  const updateStepList = (ffId: number, scId: number, steps: IStep[]) => {
    dispatch(
      {
        type: UPDATE_STEP_LIST,
        payload: { ffId, scId, steps },
      },
    );
  };

  const deleteStep = (ffId: number, scId: number, stepId: string) => {
    dispatch(
      {
        type: REMOVE_STEP,
        payload: { ffId, scId, stepId },
      },
    );
  };

  // ===== Scenario functions
  const createScenario = (ffId: number, scenario: IScenario) => {
    dispatch(
      {
        type: ADD_SCENARIO,
        payload: { ffId, scenario },
      },
    );
  };

  const updateScenario = (ffId: number, scenario: IScenario) => {
    dispatch(
      {
        type: UPDATE_SCENARIO,
        payload: { ffId, scenario },
      },
    );
  };

  const deleteScenario = (ffId: number, scId: number) => {
    dispatch(
      {
        type: REMOVE_SCENARIO,
        payload: { ffId, scId },
      },
    );
  };

  const addScenarioTag = (ffId: number, scId: number, tag: ITag) => {
    dispatch(
      {
        type: ADD_SCENARIO_TAG,
        payload: { ffId, scId, tag },
      },
    );
  };

  const deleteScenarioTag = (ffId: number, scId: number, tagId: string) => {
    dispatch(
      {
        type: REMOVE_SCENARIO_TAG,
        payload: { ffId, scId, tagId },
      },
    );
  };

  // ===== Feature File functions
  const createFeatureFile = (featureFile: IFeatureFile) => {
    dispatch(
      {
        type: ADD_FF,
        payload: { ...featureFile },
      },
    );
  };

  const updateFeatureFile = (featureFile: IFeatureFile) => {
    dispatch(
      {
        type: UPDATE_FF,
        payload: featureFile,
      },
    );
  };

  const deleteFeatureFile = (ffId: number) => {
    dispatch(
      {
        type: REMOVE_FF,
        payload: ffId,
      },
    );
  };

  const addFeatureFileTag = (ffId: number, tag: ITag) => {
    dispatch(
      {
        type: ADD_FF_TAG,
        payload: { ffId, tag },
      },
    );
  };

  const deleteFeatureFileTag = (ffId: number, tagId: string) => {
    dispatch(
      {
        type: REMOVE_FF_TAG,
        payload: { ffId, tagId },
      },
    );
  };

  // ===== Available steps functions
  const addAvailableStep = (step: IStep) => {
    dispatch(
      {
        type: ADD_AV_STEP,
        payload: step,
      },
    );
  };

  const removeAvailableStep = (id: number) => {
    dispatch(
      {
        type: REMOVE_AV_STEP,
        payload: id,
      },
    );
  };

  const updateAvailableStep = (step: IStep) => {
    dispatch(
      {
        type: UPDATE_AV_STEP,
        payload: step,
      },
    );
  };

  // ===== Available tags functions
  const addAvailableTag = (tag: ITag) => {
    dispatch(
      {
        type: ADD_AV_TAG,
        payload: tag,
      },
    );
  };

  const removeAvailableTag = (id: number) => {
    dispatch(
      {
        type: REMOVE_AV_TAG,
        payload: id,
      },
    );
  };

  const updateAvailableTag = (tag: ITag) => {
    if (tag.editable) {
      dispatch(
        {
          type: UPDATE_AV_TAG,
          payload: tag,
        },
      );
    } else {
      dispatch(
        {
          type: ON_ERROR,
          payload: `Tag ID ${tag.id} is not editable, action skipped!`,
        },
      );
    }
  };

  const updateDisplayedSteps = (steps: IStepDefinitionList[]) => {
    dispatch(
      {
        type: UPDATE_DISPLAYED_STEPS,
        payload: steps,
      },
    );
  };

  // ===== Selected functions
  const setFeatureFileSelected = (ffId: number) => {
    dispatch(
      {
        type: SET_FF_SELECTED,
        payload: { ffId },
      },
    );
  };

  const setScenarioSelected = (ffId: number, scId: number) => {
    dispatch(
      {
        type: SET_SC_SELECTED,
        payload: { ffId, scId },
      },
    );
  };

  const setStepSelected = (ffId: number, scId: number, stepId: string) => {
    dispatch(
      {
        type: SET_STEP_SELECTED,
        payload: { ffId, scId, stepId },
      },
    );
  };

  return (
    <GherkinContext.Provider
      value={{
        tags: state.tags,
        availableSteps: state.steps,
        displayedSteps: state.displayedSteps,
        featureFiles: state.featureFiles,
        error: state.error,
        clearErrors,
        selected: state.selected,
        setFeatureFileSelected,
        setScenarioSelected,
        setStepSelected,
        addAvailableTag,
        removeAvailableTag,
        updateAvailableTag,
        addAvailableStep,
        removeAvailableStep,
        updateAvailableStep,
        createStep,
        updateStepList,
        deleteStep,
        createScenario,
        updateScenario,
        deleteScenario,
        addScenarioTag,
        deleteScenarioTag,
        createFeatureFile,
        updateFeatureFile,
        deleteFeatureFile,
        addFeatureFileTag,
        deleteFeatureFileTag,
        updateDisplayedSteps,
      }}
    >
      {children}
    </GherkinContext.Provider>
  );
};

export default GherkinState;
