import React from 'react';

import { Card, CardDeck } from 'react-bootstrap';
import PageTitle from '../../../components/PageTitle';
import useGetArrays from '../../../hooks/services/simManagement/array/useGetArrays';
import useGetSimCards from '../../../hooks/services/simManagement/sim/useGetSimCards';
import useGetProbes from '../../../hooks/services/probe/useGetProbes';
import useGetGroups from '../../../hooks/services/user/group/useGetGroups';
import useGetUsers from '../../../hooks/services/user/useGetUsers';

const Overview = () => {
  const { data: simArrays } = useGetArrays();
  const { data: simCards } = useGetSimCards();
  const { data: probes } = useGetProbes();
  const { data: users } = useGetUsers();
  const { data: groups } = useGetGroups();
  return (
    <div>
      <PageTitle>Overview</PageTitle>
      <CardDeck>
        <Card>
          <Card.Body>
            <Card.Title>SIM Cards</Card.Title>
            <Card.Text>
              <span className="display-4">{simCards?.length}</span>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>SIM Arrays</Card.Title>
            <Card.Text>
              <span className="display-4">{ simArrays?.length }</span>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Probes</Card.Title>
            <Card.Text>
              <span className="display-4">{ probes?.length }</span>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Users</Card.Title>
            <Card.Text>
              <span className="display-4">{ users?.length }</span>
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Groups</Card.Title>
            <Card.Text>
              <span className="display-4">{ groups?.length }</span>
            </Card.Text>
          </Card.Body>
        </Card>
      </CardDeck>
    </div>
  );
};

export default Overview;
