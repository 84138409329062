import _ from 'lodash';

import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetChannelValues(probeName?: string) {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_ATTENUATORS });

  return useQuery(['attenuatorProbeOutputs', probeName], () => axios.get(
    `/attenuator/api/probes/${probeName}/outputs`
  ).then(({ data }) => data), { enabled: !_.isNil(probeName), staleTime: 1000 * 60 * 5 });
}
