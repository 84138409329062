import React, { useEffect, useState } from 'react';
import { Col, Row, ProgressBar } from 'react-bootstrap';

import moment from 'moment';
import useConfirm from 'hooks/useConfirm';
import ResourcesModal from './ResourcesModal';

import useKillStatus from '../../hooks/services/scheduler/useKillStatus';

const stfUrl = 'http://stf.its:9298/multi_view/';
const rpUrl = 'https://reporting.its-telekom.eu';

interface IOverviewProps {
  statuses: any,
  getStatuses: any,
  users: any,
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
}

const ApprovedJobs = ({
  statuses,
  getStatuses,
  users,
  setMessage,
  setErrMessage
}: IOverviewProps) => {
  const [showResourcesModal, setShowResourcesModal] = useState(false);
  const [jobUuid, setJobUuid] = useState<string>('');

  const regex = /(\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/;

  const parseFinishedOn = (dateString: any) => {
    const match = regex.exec(dateString);
    if (match) {
      const date = new Date();
      const dateUtc = new Date(match[0]);
      const offset = date.getTimezoneOffset();
      const localDate = new Date(dateUtc.getTime() - offset * 60000);
      return moment(localDate).utc(true).format('YYYY-MM-DD, HH:mm:ss');
    }
    return 'no match';
  };

  const confirm = useConfirm();

  const [killStatus] = useKillStatus(
    {
      onSuccess: () => {
        setMessage('Job aborted');
        getStatuses();
      },
      onError: () => setErrMessage('Failed to abort job'),
    },
  );

  const handleKillStatus = (id: any) => {
    confirm({
      body: 'Do you want to abort job?',
      onOk: () => killStatus({ id }),
      title: 'Abort job',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  const progress = (status: number, total: number) => {
    if ((status / total) > 0.1) {
      return Math.floor((status / total) * 100);
    } return 10;
  };

  const getVariant = (status: string) => {
    if (status === 'working') {
      return 'magenta';
    } if (status === 'completed') {
      return 'success';
    } if (status === 'killed' || status === 'failed') {
      return 'danger';
    }
    return 'info';
  };

  useEffect(() => {
    getStatuses();
  }, []);

  return (
    <>
      {statuses.length < 1 ? <span>Currently there are no jobs running for your user</span> : (
        statuses.map((status: any) => (
          <Row className="border rounded p-3 mb-2">
            <Col>
              <Row>
                <strong>Details:</strong>
              </Row>
              <Row>
                Job ID:   {status.uuid}
              </Row>
              <Row>
                Job type:   {status.options.job_type}
              </Row>
              <Row>
                Started on:   {moment.unix(status.time).format('YYYY-MM-DD, H:mm:ss')}
              </Row>
              {status.status === 'completed' && (
              <Row>
                Finished on: {parseFinishedOn(status.message)}
              </Row>
              )}
              {status.status === 'killed' && (
              <Row>
                Killed on: {parseFinishedOn(status.message)}
              </Row>
              )}
              <Row>
                Created by:   {users.filter(
                (item: any) => item.uid.toString() === status.options.user_id
              )[0].username}
              </Row>
              <Row>
                Schedule ID:   {status.options.schedule_id || 'N/A'}
              </Row>
              <Row>
                Campaign Name:   {status.options.schedule_id && status.options.schedule_name
                ? status.options.schedule_name : 'N/A'}
              </Row>
              <Row>
                Selenium Grid url:   {status.options.selenium_grid_url}
              </Row>
            </Col>
            <Col>
              <Row>
                <strong>Show:</strong>
              </Row>
              <Row>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    setShowResourcesModal(true);
                    setJobUuid(status.uuid);
                  }}
                >
                  Resources
                </button>
                <a
                  href={`https://grafana01.its-telekom.eu/d/_p3ZeeUZk/appium-environment-logs?orgId=1&var-env_id=${(status?.uuid)}`}
                  target="_blank"
                  rel="noreferrer"
                ><button type="button" className="btn btn-outline-primary btn-sm">Logs</button>
                </a>
              </Row>
              <Row>
                <a
                  href={`${stfUrl}${(status?.options.probes.reduce((p:any, c:any) => `${p}%7C${c.identifier}`, ''))}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    disabled={status.status !== 'working'}
                  >
                    Devices
                  </button>
                </a>
              </Row>
            </Col>
            <Col>
              <strong>Job Execution Status: {(status.status)
                .charAt(0).toUpperCase() + (status.status).slice(1)}
              </strong>
              <ProgressBar
                now={status.status !== 'completed' ? progress(status.num, status.total) : 100}
                label={`${status.status !== 'completed' ? progress(status.num, status.total) : 100} %`}
                style={{ margin: '0.4rem 0', background: '#ededed' }}
                variant={getVariant(status.status)}
                className="square border"
              />
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                disabled
              >
                Retry
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn-sm"
                disabled={status.status === 'completed' || status.status === 'failed' || status.status === 'killed'}
                onClick={() => {
                  handleKillStatus(status.uuid);
                }}
              >
                Abort
              </button>
            </Col>
            <Col>
              <div className="bu-py-1 bu-mb-1 text-right">
                <a
                  href={`${rpUrl}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    disabled={status.status === 'failed' || status.status === 'killed'}
                  >
                    Results
                  </button>
                </a>
              </div>
            </Col>
          </Row>
        )))}
      {showResourcesModal
                && (
                  <ResourcesModal
                    onHide={() => setShowResourcesModal(false)}
                    show={showResourcesModal}
                    queuedStatuses={null}
                    statuses={statuses}
                    jobUuid={jobUuid}
                  />
                )}
    </>
  );
};

export default ApprovedJobs;
