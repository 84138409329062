import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetAttenuatorDetails(attName: string) {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_ATTENUATORS });

  return useQuery(['attenuatorDetails', attName], () => axios.get(
    `/attenuator/api/attenuators/${attName}`
  ).then(({ data }) => data), { staleTime: 1000 * 60 * 5 });
}
