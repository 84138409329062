import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useUser from 'hooks/useUser';
import { PollingProvider } from 'hooks/apiPolling/apiPollingContext';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import useGetReservations from 'hooks/services/reservation/useGetReservations';
import useGetProbeLocations from 'hooks/services/probe/location/useGetProbeLocations';
import Iphone from 'components/iPhone/Iphone';

const ManualTestingProbe = () => {
  const { id } = useParams<{ id: any }>();
  const { data: locations, isLoading: isLoadingProbeLocations } = useGetProbeLocations();
  const { data: probes, isLoading: isLoadingProbes } = useGetProbes();
  const { data: reservations, isLoading: isLoadingReservations } = useGetReservations();
  const [probe, setProbe] = useState<any>(undefined);
  const [reservation, setReservation] = useState<any>(undefined);

  const { isAdmin, uidNumber: userId } = useUser();

  window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
    event.preventDefault();
    localStorage.removeItem(id);
  });

  useEffect(() => {
    if (!isLoadingProbes && !isLoadingProbeLocations && !probe) {
      const foundProbe = probes?.find((p:any) => p.probe_alias === id);
      const loc = locations.find((val: any) => val.country_iso === foundProbe?.country_iso);
      setProbe({
        ...foundProbe,
        country_iso: loc?.country_iso || 'UNK',
        country_name: loc?.country_name || 'Unknown location',
        location: `${loc?.country_name || 'Unknown location'}`,
      });
    }
    if (!isLoadingReservations && probe) {
      const activeRes = reservations.filter((r:any) => moment(r.until).isAfter(moment())
          && (String(r.user_id) === String(userId) || isAdmin));
      const res = activeRes?.find((r:any) => r.probe_name === probe.probe_name);
      setReservation(res);
    }
  }, [isLoadingProbes, isLoadingReservations, probe]);

  const renderProbe = (key :any) => {
    switch (key) {
      case 'iOS':
        return <Iphone probe={probe} reservation={reservation} />;
        break;
      case 'Android':
        return null;
        break;
      case 'Analogue_Modem':
        return null;
        break;
      case 'IP_Phone':
        return null;
        break;
      default:
        return null;
        break;
    }
  };

  return (
    <>
      {probe && reservation && renderProbe(probe?.devices[0]?.type)}
    </>
  );
};

const Wrapper = () => (
  <>
    <PollingProvider>
      <ManualTestingProbe />
    </PollingProvider>
  </>
);

export default Wrapper;
