import React from 'react';

import { Col, Pagination, Row } from 'react-bootstrap';

interface IJobsPagination {
  setPerPage: any,
  setPageNumber: any,
  perPage: any,
  pageNumber: any,
  pageCount: any,
  pageSizes: any
}

const JobsPagination = ({
  setPerPage, setPageNumber, perPage, pageNumber, pageCount, pageSizes,
}: IJobsPagination) => (
  <Row className="bu-mt-1">
    <Col xs="auto">
      <div className="d-flex align-items-baseline">
        <Pagination>
          {pageSizes.map((value: any) => (
            <Pagination.Item
              active={+perPage === value}
              onClick={() => {
                setPerPage(value);
                setPageNumber(1);
              }}
              key={value}
            >
              {value}
            </Pagination.Item>
          ))}
        </Pagination>
        <span className="bu-ml-1">per page</span>
      </div>
    </Col>
    <Col />
    <Col xs="auto">
      <Pagination>
        <Pagination.Item
          onClick={() => setPageNumber(pageCount()[0])}
          disabled={pageNumber === 1}
        >
          First
        </Pagination.Item>
        <Pagination.Item
          onClick={() => setPageNumber(pageNumber - 1)}
          disabled={pageNumber === 1}
        >
          Previous
        </Pagination.Item>
        {Array(pageCount().length)
          .fill(0)
          .map(
            (item, index) => index > (pageNumber - 1) - 5
                  && index < (pageNumber - 1) + 3 && (
                    <Pagination.Item
                      active={(pageNumber - 1) === index}
                      key={`pagination-item-${index + 1}`}
                      onClick={() => setPageNumber(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
            ),
          )}
        <Pagination.Item
          onClick={() => setPageNumber(pageNumber + 1)}
          disabled={pageNumber === pageCount().length}
        >
          Next
        </Pagination.Item>
        <Pagination.Item
          onClick={() => setPageNumber(pageCount()[pageCount().length - 1])}
          disabled={pageNumber === pageCount().length}
        >
          Last
        </Pagination.Item>
      </Pagination>
    </Col>
  </Row>
);

export default JobsPagination;
