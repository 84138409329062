import React, {
  FunctionComponent, useEffect, useState, useCallback, createContext, useContext,
} from 'react';
import { IViewContext, ViewStartMsg } from './interface';
import { WindowsManagerContext } from './windowsManager';
import { WsContext } from './ws';

interface ViewProps {
  channel: string,
  winId: string,
}

type ScreenSize = {
  height: number, width: number
};

const ViewContext = createContext({} as IViewContext);

// provide device screens
const ViewProvider: FunctionComponent<ViewProps> = (
  {
    children,
    channel,
    winId,
  }: React.PropsWithChildren<ViewProps>,
) => {
  const [viewWs, setViewWs] = useState<WebSocket>();
  const [deviceChannel, setDeviceChannel] = useState<string>('');
  const [screenSize, setScreenSize] = useState<ScreenSize | undefined>();
  const [screenInfo, setScreenInfo] = useState<ViewStartMsg>();
  const [image, setImage] = useState<string | null>(null);
  const reconnectTime = 5000;

  const { devices } = useContext(WsContext);
  const device = devices.find((dev) => dev.channel === channel);

  const {
    finishDestroyWindow, getState, getSize,
  } = useContext(WindowsManagerContext);

  const connect = useCallback(() => {
    if (device?.display.url) {
      if (viewWs) {
        console.log('closing old view WS');
        viewWs.close();
      }
      console.log(`connecting view WS at ${device.display.url}`);
      const wssProcotol = window.location.origin.indexOf('https') < 0 ? 'ws' : 'wss';
      if (device) setViewWs(new WebSocket(`${wssProcotol}://${window.location.host}/stfDevice/d/${device.display.url.split('/d/')[1]}`));
    }
  }, [device?.display.url, viewWs]);

  // send request for changing phone screen image resolution to STF server
  const changeRes = useCallback((size: ScreenSize) => {
    try {
      if (viewWs && viewWs.readyState === 1) viewWs!.send(`size ${size.width}x${size.height}`);
    } catch (e) {
      console.error(e);
    }
  }, [viewWs]);

  // process incoming phone screen image Blob data and save it
  const processImage = useCallback((imageData: Blob) => {
    new Response(imageData)
      .arrayBuffer()
      .then((imgData) => {
        // get base64 image data from array buffer
        const base64String = btoa(
          new Uint8Array(imgData)
            .reduce((data, byte) => data + String.fromCharCode(byte), ''),
        );
        // save image
        setImage(`data:image/png;base64, ${base64String}`);
      });
  }, []);

  useEffect(() => {
    setDeviceChannel(channel);
  }, [channel]);

  useEffect(() => {
    if (device?.display.url) connect();
  }, [device?.display.url]);

  useEffect(() => {
    // request new phone screen size when window size changed
    if (screenSize) changeRes(screenSize);
  }, [screenSize, changeRes]);

  // service the websocket
  useEffect(() => {
    if (viewWs) {
      viewWs.onopen = () => {
        viewWs.send('on');
        changeRes({
          height: 692,
          width: 692,
        });
      };
      viewWs.onmessage = (msg) => {
        try {
          // image received
          if (msg.data instanceof Blob) {
            processImage(msg.data);
          } else if (typeof msg.data === 'string') {
            // example message:
            // start {"version":1,"length":24,"pid":12468,"realWidth":1080,"realHeight":1920,
            // "virtualWidth":389,"virtualHeight":692,"orientation":0,"quirks":{"dumb":false,
            // "alwaysUpright":true,"tear":false}}
            const regexResult = msg.data.match('^(start.*)?');
            if (regexResult && regexResult[0] !== '') {
              const data = msg.data.slice('start '.length);
              const startInfo: ViewStartMsg = JSON.parse(data);
              setScreenInfo(startInfo);
            }
          }
        } catch (e:any) {
          console.log('view WS onmessage error: ', e.message);
        }
      };
      viewWs.onclose = (e) => {
        console.log(`Socket is closed. ${e.reason}`);
        // try to reconnect only if component not unmouting
        if (e.code !== 1000) {
          console.log(`Reconnect will be attempted in ${reconnectTime} ms.`);
          // clear timers
          setTimeout(() => {
            console.log('reconnecting');
            connect();
          }, reconnectTime);
        }
      };
      viewWs.onerror = (err: Event) => {
        setImage(null);
        console.error('Socket encountered error: ', err, 'Closing socket');
        if (viewWs) viewWs.close();
      };
    }
  }, [viewWs, connect, changeRes, processImage, screenSize, device?.display.url]);

  useEffect(() => {
    if (getState(winId) === 'CLOSING') {
      console.log(`closing ext win${viewWs?.OPEN}`);
      if (viewWs && viewWs.OPEN) {
        viewWs.close(1000, 'client disconnected');
      }
      finishDestroyWindow(winId);
    }
  }, [getState, winId, finishDestroyWindow, viewWs]);

  useEffect(() => {
    const size = getSize(winId);
    if (size) {
      const { width, height } = size;
      setScreenSize({ width: width - 648, height: height - 108 });
    }
  }, [getSize, winId]);

  return (
    <ViewContext.Provider value={{
      image, screenInfo, channel: deviceChannel, screenSize, setScreenSize,
    }}
    >
      {children}
    </ViewContext.Provider>
  );
};
const ViewConsumer = ViewContext.Consumer;
export { ViewProvider, ViewConsumer, ViewContext };
