/* eslint-disable max-len */
import { Container, Row, Col } from 'react-bootstrap';
import * as React from 'react';

const DataPrivacyInformation = () => (
  <div className="pt-4">
    <Container>
      <Row>
        <Col md={12}>
          <h1 className="display-4">
            Data privacy information
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <p>
            The protection of your personal data has a high priority for DEUTSCHE TELEKOM AG. It is important to us to inform you about what personal data are collected, how they are used and what options you have in this regard.
          </p>
          <ol>
            <li style={{ fontWeight: 'bold' }}>
              What data are collected, how are they used and how long are they stored?
            </li>
            <ul>
              <li>
                When using Test Automation in VTIhereinafter referred to as online service:
                <br />
                Log in data are being stored for the session duration.
                <br />
                (Art. 6 para. 1b GDPR, §25 para. 2 No. 2 TTDSG (Telecommunications Telemedia Data Protection Act)). When you use our online service, our servers temporarily record the domain name or IP address of your terminal device as well as other data, such as the content requested or the response code. The logged data are used exclusively for data security purposes, in particular to defend against attempted attacks on our web server (Art. 6 para. 1f DSGVO). They are neither used for the creation of individual application profiles nor passed on to third parties and are deleted after 7 days at the latest. We reserve the right to statistically evaluate anonymized data records.
              </li>
              <li>
                Many operating systems allow (provide the option) to dictate the text in addition to input via keyboard. When using this function, the speech is processed by a third party (e.g. Apple, Google, Microsoft) as the responsible party and the result is entered in the input field. For details of the functionality of how to enable or disable the use and the legal basis of the processing, please contact the respective operating system manufacturer.
              </li>
            </ul>
            <br />
            <li style={{ fontWeight: 'bold' }}>
              Where can I find the information important to me?
            </li>
            <span>
              This privacy policy provides an overview of the points that apply to Telekom&apos;s processing of your data in this online service. Further information, including on data privacy in general and in specific products, is available at <a href="https://www.telekom.com/en/company/data-privacy-and-security/governance-data-privacy">https://www.telekom.com/en/company/data-privacy-and-security/governance-data-privacy</a> and at <a href="http://www.telekom.de/datenschutzhinweise">http://www.telekom.de/datenschutzhinweise</a>.
            </span>
            <br />
            <br />
            <li style={{ fontWeight: 'bold' }}>
              Who is responsible for data processing? Who do I contact if I have questions about the data privacy policy at Telekom?
            </li>
            <span>
              DEUTSCHE TELEKOM AG Friedrich-Ebert-Allee 140, 53113 Bonn acts as the data controller. If you have any queries, please contact our Customer Services department or the Global Data Privacy Officer, Herrn Dr. Claus D. Ulmer, Friedrich-Ebert-Allee 140, 53113 Bonn, <a href="mailto:datenschutz@telekom.de">datenschutz@telekom.de</a>.
            </span>
            <br />
            <br />
            <li style={{ fontWeight: 'bold' }}>
              What rights do I have?
            </li>
            <span>
              You have the right
            </span>
            <ul style={{ listStyleType: 'lower-alpha' }}>
              <li>To request information on the categories of personal data concerned, the purposes of the processing, any recipients of the data, and the envisaged storage period (Art. 15 GDPR);</li>
              <li>to demand the correction or completion of incorrect or incomplete data (Art. 16 GDPR);</li>
              <li>to revoke given consent at any time with effect for the future (Art. 7 para. 3 GDPR);</li>
              <li>to object to data processing that is to be carried out on the basis of a legitimate interest for reasons arising from your particular situation (Art. 21 (1) GDPR);</li>
              <li>in certain cases, within the framework of Art. 17 GDPR, to demand the deletion of data - in particular, insofar as the data are no longer required for the intended purpose or is processed unlawfully, or you have revoked your consent in accordance with (c) above or declared an objection in accordance with (d) above;</li>
              <li>under certain conditions, to demand the restriction of data, insofar as deletion is not possible or the obligation to delete is disputed (Art. 18 GDPR);</li>
              <li>to data portability, i.e. you can receive your data that you have provided to us in a conventional machine-readable format, such as CSV, and transmit it to others if necessary (Art. 20 GDPR);</li>
              <li>to issue a complaint to the competent supervisory authority about the data processing (for telecommunication contracts: Federal Commissioner for Data Protection and Freedom of Information; otherwise: State Commissioner for Data Protection and Freedom of Information of North Rhine-Westphalia).</li>
            </ul>
            <br />
            <br />
            <li style={{ fontWeight: 'bold' }}>
              To whom does Telekom pass my data?
            </li>
            <p>
              <strong>Due to legal obligation:</strong> In certain cases, we are legally obligated to transmit certain data to the requesting government agency.
            </p>
            <br />
            <li style={{ fontWeight: 'bold' }}>
              Where is my data processed?
            </li>
            <span>Your data are processed in Germany and other European countries. If, in exceptional cases, processing of your data also takes place in countries outside the European Union (in so-called third countries), this will happen, </span>
            <ul>
              <li>if you have expressly consented to this (Art. 49 para. 1a GDPR). (In most countries outside the EU, the level of data protection does not meet EU standards. This applies in particular to comprehensive monitoring and control rights of state authorities, e.g. in the USA, which disproportionately interfere with the data protection of European citizens,</li>
              <li>or insofar as it is necessary for our provision of services to you (Art. 49 para. 1b DSGVO),</li>
              <li>or insofar as it is provided for by law (Art. 6 para. 1c GDPR).</li>
            </ul>
            <span>Furthermore, your data will only be processed in third countries if certain measures ensure that an adequate level of data protection exists (e.g. adequacy decision of the EU Commission or so-called suitable guarantees, Art. 44ff GDPR).</span>
          </ol>
        </Col>
      </Row>
    </Container>
  </div>
);

export default DataPrivacyInformation;
