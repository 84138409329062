export const ON_ERROR = 'ON_ERROR';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const ADD_AV_STEP = 'ADD_AVAILABLE_STEP';
export const REMOVE_AV_STEP = 'REMOVE_AVAILABLE_STEP';
export const UPDATE_AV_STEP = 'UPDATE_AV_STEP';
export const ADD_STEP = 'ADD_STEP';
export const REMOVE_STEP = 'REMOVE_STEP';
export const UPDATE_STEP_LIST = 'UPDATE_STEP_LIST';
export const ADD_SCENARIO = 'ADD_SCENARIO';
export const REMOVE_SCENARIO = 'REMOVE_SCENARIO';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const ADD_SCENARIO_TAG = 'ADD_SCENARIO_TAG';
export const REMOVE_SCENARIO_TAG = 'REMOVE_SCENARIO_TAG';
export const ADD_FF = 'ADD_FEATURE_FILE';
export const REMOVE_FF = 'REMOVE_FEATURE_FILE';
export const UPDATE_FF = 'UPDATE_FF';
export const ADD_FF_TAG = 'ADD_FF_TAG';
export const REMOVE_FF_TAG = 'REMOVE_FF_TAG';
export const ADD_AV_TAG = 'ADD_AV_TAG';
export const REMOVE_AV_TAG = 'REMOVE_AV_TAG';
export const UPDATE_AV_TAG = 'UPDATE_AV_TAG';
export const UPDATE_DISPLAYED_STEPS = 'UPDATE_DISPLAYED_STEPS';
export const SET_FF_SELECTED = 'SET_FF_SELECTED';
export const SET_SC_SELECTED = 'SET_SC_SELECTED';
export const SET_STEP_SELECTED = 'SET_STEP_SELECTED';
