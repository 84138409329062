import clsx from 'clsx';
import { useState } from 'react';
import { Container, Table, Modal, Button } from 'react-bootstrap';

export interface IStep {
  name: string,
  examples: string[],
  description: string,
  devices: string[],
  table: { header: string, values: string }[],
  codeLink: string,
  code: string[]
}

const Step = ({ step, category }: { step: IStep, category: string }) => {
  const [
    showCode,
    setShowCode
  ] = useState<{ show: boolean, content: string[], link?: string }>({ show: false, content: [], link: '' });

  const getParamsTable = (params: { header: string, values: string }[]) => {
    if (params) {
      return (
        <Table size="sm">
          <tbody>
            <tr>
              {params.map((p: any) => (
                <td style={{ fontWeight: 500, borderTop: 0 }}>{p.header}</td>
              ))}
            </tr>
            <tr>
              {params.map((p: any) => <td>{p.values}</td>)}
            </tr>
          </tbody>
        </Table>
      );
    }
    return null;
  };

  return (
    <>
      <Container fluid className="border rounded-bottom bu-px-1 bu-py-1 bu-mb-1">
        <Table size="sm" className="border rounded-bottom bu-px-1 bu-py-1 bu-mb-1">
          <tbody>
            <tr>
              <td style={{ borderTop: 0, width: '100px' }}>Step</td>
              <td style={{ borderTop: 0 }}><code>{step.name}</code></td>
            </tr>
            <tr>
              <td>Description</td>
              <td>{step.description}</td>
            </tr>
            <tr>
              <td>Category</td>
              <td>{category}</td>
            </tr>
            <tr>
              <td>Devices</td>
              <td>{step.devices.join(', ')}</td>
            </tr>
            <tr>
              <td>Parameters</td>
              <td>{getParamsTable(step.table)}</td>
            </tr>
            <tr>
              <td>Example</td>
              <td><pre style={{ margin: 0 }}>{step.examples.join('\n')}</pre>
              </td>
            </tr>
            <tr>
              <td>
                <button
                  className={clsx('text-button')}
                  key="show"
                  onClick={
                    () => setShowCode({ show: true, content: step.code, link: step.codeLink })
                  }
                  type="button"
                >
                  <u>Show code</u>
                </button>
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
        {/* <Row>
          <Col lg={1}>Step:</Col>
          <Col>
            <h3><code>{step.name}</code></h3>
          </Col>
        </Row>
        <Row>
          <Col>Description:</Col>
          <Col>{step.description}</Col>
        </Row>
        <Row>
          <Col lg={1}>Devices:</Col>
          <Col>{step.devices.join(', ')}</Col>
        </Row>
        <Row>
          <Col lg={1}>Parameters:</Col>
          <Col>{getParamsTable(step.table)}</Col>
        </Row>
        <Row>
          <Col lg={1}>Examples:</Col>
          <Col>
            {step.examples
              ? step.examples.map((example) => <div key={example}>{example}</div>)
              : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <button
              className={clsx('text-button')}
              key="show"
              onClick={() => setShowCode({ show: true, content: step.code, link: step.codeLink })}
              type="button"
            >
              <u>Show code</u>
            </button>
          </Col>
        </Row> */}
      </Container>
      <Modal
        show={showCode.show}
        onHide={() => setShowCode({ show: false, content: [] })}
        centered
        size="lg"
        backdrop="static"
      >
        <Modal.Body>
          <pre>{showCode.content.join('\n')}</pre>
        </Modal.Body>

        <Modal.Footer>
          <a className={clsx('text-button')} href={showCode.link} rel="noreferrer" target="_blank"><u>Show source code in Git</u></a>
          <Button variant="secondary" onClick={() => setShowCode({ show: false, content: [] })}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Step;

// https://gitlab01.its-telekom.eu/avengers/documber
// https://gitlab01.its-telekom.eu/avengers/actl
