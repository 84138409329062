import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type AddMultiplexerRequestData = {
  probeName: string;
  deviceId: any
  multiplexers: any;
};

export default function useAddMultiplexer(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(
    ({ probeName, deviceId, multiplexers }: AddMultiplexerRequestData) => axios.post(
      `/cms/api/probes/${probeName}/devices/${deviceId}/multiplexers?multiplexer_serial=${multiplexers.replace('#', '%23')}`,
    ).then(({ data }) => data), options,
  );
}
