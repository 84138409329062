/**
 * validate date formatted to DD/MM/YYYY hh:mm
 * @param date
 */
const validateDate = (date: string) => {
  const dateParts = date.split('/');
  const day = +(dateParts[0]);
  const month = +(dateParts[1]);
  const rest = dateParts[2].split(' ');
  const year = +(rest[0]);
  const time = rest[1].split(':');
  const hours = +(time[0]);
  const minutes = +(time[1]);

  if (
    (day && (day < 1 || day > 31)) || (month && (month < 1 || month > 12))
    || (year && (year < 2021))
    || ((hours && hours > 23) || (minutes && minutes > 59))
  ) {
    return 'Enter valid date and time.';
  }
  return false;
};

export const ShowError = ({ msg }: { msg: any }) => (msg ? (
  <span style={{ color: 'red' }}>{msg}</span>
)
  : (<span>&nbsp;</span>));

export default validateDate;
