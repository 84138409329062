export const LOAD_USER_DATA = 'LOAD_USER_DATA';
export const GET_USERS = 'GET_USERS';
export const GET_GROUPS = 'GET_GROUPS';
export const SET_LOADING = 'SET_LOADING';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
// todo remove after login via api when it is safe
export const DEV_LOGIN = 'DEV_LOGIN';
