/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import useSendTap from 'hooks/services/iPhone/useSendTap';
import useSendSwipe from 'hooks/services/iPhone/useSendSwipe';
import useSendKey from 'hooks/services/iPhone/useSendKey';
import DeviceButtons from './DeviceButtons';

type ScreenData = {
  probe: any;
  sessionId: string | undefined;
  probeStatus: any;
  getStatus: () => void;
  deviceStatus: string;
};

type Point = {
  x: number,
  y: number,
};

const Screen = ({
  probe,
  probeStatus,
  sessionId,
  getStatus,
  deviceStatus,
} : ScreenData) => {
  const [swiping, setSwiping] = useState(false);
  const [clickStartPosition, setClickStartPosition] = useState<Point | undefined>(undefined);
  const screenInfo = { width: 400, height: 820 };
  const [sendTap] = useSendTap({});
  const [sendSwipe] = useSendSwipe({});
  const [sendKey] = useSendKey({});

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    setSwiping(true);
    setClickStartPosition({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY });
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLImageElement>) => {
    if (swiping) {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      setSwiping(false);
      if (clickStartPosition?.x === x && clickStartPosition?.y === y) {
        sendTap({
          probeName: probe?.probe_name,
          sessionId: probeStatus?.sessionId,
          coordsData: { x, y }
        });
      } else {
        sendSwipe({
          probeName: probe?.probe_name,
          sessionId: probeStatus?.sessionId,
          coordsData: {
            duration: 0.1,
            fromX: clickStartPosition?.x,
            fromY: clickStartPosition?.y,
            toX: x,
            toY: y
          }
        });
      }
    }
  };

  const handleMouseOut = (e: React.MouseEvent<HTMLImageElement>) => {
    if (swiping) {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      setSwiping(false);
      sendSwipe({
        probeName: probe?.probe_name,
        sessionId: probeStatus?.sessionId,
        coordsData: {
          duration: 0.1,
          fromX: clickStartPosition?.x,
          fromY: clickStartPosition?.y,
          toX: x,
          toY: y
        }
      }).catch((event: any) => {
        console.log(event);
        getStatus();
      });
    }
  };

  const handleBlurr = () => {
    if (swiping) setSwiping(false);
  };

  const handleKeyDown = (key: any) => {
    switch (key) {
      case 'Enter':
      case 'Shift':
      case 'CapsLock':
      case 'Backspace':
      case 'Delete':
        break;
      default:
        sendKey({
          probeName: probe?.probe_name,
          sessionId: probeStatus?.sessionId,
          keyData: { value: [key], frequency: 10 }
        });
        break;
    }
  };

  return (
    <>
      {probe && (
        <div className="bu-px-1 bu-py-1 bu-mb-1 screen-container">
          <img
            className={`screen ${!sessionId ? 'op-50' : ''}`}
            // probeStatus?.sessionId is here just to refresh the image when new session Id
            // is fetched, can be also done with Date.now()
            src={`${process.env.REACT_APP_IOS}/d/${probe?.probe_alias}/?${sessionId || 'screen'}`}
            alt=""
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            width={`${screenInfo.width}px`}
            height={`${screenInfo.height}px`}
            draggable={false}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onKeyDown={(e) => handleKeyDown(e.key)}
            onKeyUp={() => null}
            onMouseOut={handleMouseOut}
            onBlur={handleBlurr}
          />
          <DeviceButtons probeName={probe?.probe_name} probeStatus={probeStatus} />
          {(!sessionId)
           && (
           <>
             <Spinner className="spinner-screen" animation="border" size="sm" />
             <span className="spinner-text">{`${deviceStatus === 'undefined' ? 'Loading' : 'Restarting'} ...`}</span>
           </>
           )}
        </div>
      )}
    </>
  );
};
export default Screen;
