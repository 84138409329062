import React from 'react';

import { Col, Row, Spinner } from 'react-bootstrap';

import useGetChannelValues from 'hooks/services/attenuator/useGetChannelValues';
import useGetProbeAttenuatorDetails from 'hooks/services/attenuator/useGetProbeAttenuatorDetails';
import { Probe } from 'hooks/services/probe/interfaces';

const Attenuation = ({
  probe,
} : {
  probe: Probe | null,
}) => {
  const { data: attDetails } = useGetProbeAttenuatorDetails(probe?.probe_name);
  const {
    data: channelValues,
    isLoading: isLoadingChannelValues
  } = useGetChannelValues(probe?.probe_name);

  const renderChannels = (channels: any[], start: any, end?: any) => (
    <Row className="bu-pl-1 text-center">
      {
        channels
          .sort((a: any, b: any) => ((a.name > b.name) ? 1 : -1))
          .slice(start, end)
          .map((probeChannel: any) => {
            const { name } = probeChannel;
            return (
              <div className="bu-mr-1">
                <div style={{ fontWeight: '500' }}>{name}</div>
                <div>{Number.isInteger(channelValues?.[name]) ? `${channelValues?.[name]} dB` : '-'}</div>
              </div>
            );
          })
      }
    </Row>
  );

  return (
    <>
      {attDetails?.probes.find((e: any) => e.name === probe?.probe_name) && (
      <>
        <Row className="bu-mb-1">
          <Col>
            <span className="font-weight-bold">Attenuation:</span>
          </Col>
        </Row>
        <Row>
          <Col>
            {isLoadingChannelValues ? (
              <div className="spinner-container">
                <Spinner animation="border" role="status" className="spinner" />
                <span>Loading...</span>
              </div>
            ) : (
              <>
                {renderChannels(attDetails?.channels, 0, 4)}
                {renderChannels(attDetails?.channels, 4)}
              </>
            )}
          </Col>
        </Row>
      </>
      )}
    </>
  );
};

export default Attenuation;
