import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import Tag from './Tag';
import useDidUpdateEffect from './use-did-update-effect';
import './style.css';

export type AutosuggestProps = {
  disabled?: boolean;
  suggestions?: string[];
  className?: string;
  toggleOpen?: () => void;
  selectSuggested?: () => void;
  tags: string[];
  separators: string[];
  onChangeInput: (tags: string[]) => void;
};

const AutosuggestTagInput = (props: AutosuggestProps) => {
  const { disabled, suggestions, className, tags, separators, onChangeInput } = props;
  const [localTags, setLocalTags] = useState<any>(tags || []);
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const wrapperRef = useRef<any>(null);
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[] | undefined>(
    suggestions || []
  );

  useEffect(() => {
    setFilteredSuggestions(
      suggestions?.filter((sug:any) => !tags.includes(sug))
    );
  }, [suggestions]);

  const onItemSelect = (name:any) => {
    if (!localTags.includes(name)) {
      onChangeInput([...localTags, name]);
      setInputValue('');
    }
  };

  useDidUpdateEffect(() => {
    onChangeInput(localTags);
  }, [localTags]);

  useDidUpdateEffect(() => {
    if (JSON.stringify(tags) !== JSON.stringify(localTags)) {
      setLocalTags(tags);
    }
  }, [tags]);

  const handleOnKeyDown = (e:any) => {
    e.stopPropagation();
    const text = e.target.value;

    if (
      !text
      && localTags.length
      && e.key === 'Backspace'
    ) {
      setInputValue(`${localTags.at(-1)}`);
      setLocalTags([...localTags.slice(0, -1)]);
    }

    if (text && (separators).includes(e.key)) {
      e.preventDefault();

      if (tags.includes(text)) {
        return;
      }
      setLocalTags([...tags, text]);
      setInputValue('');
      setFilteredSuggestions(suggestions);
    }
  };

  const onKeyUp = (e:any) => {
    e.stopPropagation();
    const text = e.target.value;
    setFilteredSuggestions(
      suggestions?.filter((sug:any) => sug.includes(text) && !tags.includes(sug))
    );
  };

  const onTagRemove = (text:any) => {
    setLocalTags(tags.filter((tag:any) => tag !== text));
    setIsOpen(false);
  };

  const handleClickOutside = (event:any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className="wrapper-container"
    >
      <div
        aria-labelledby="tagInput"
        className={clsx('rti--container', disabled && 'disabled')}
      >
        {tags.map((tag:any) => (
          <Tag
            key={tag}
            text={tag}
            remove={onTagRemove}
          />
        ))}
        <input
          className="rti--input"
          disabled={disabled}
          type="text"
          name="tagInput"
          placeholder="Enter tag"
          onKeyDown={handleOnKeyDown}
          onFocus={() => {
            setIsOpen(true);
          }}
          onKeyUp={onKeyUp}
          value={inputValue}
          onChange={(e:any) => setInputValue(e.target.value.replace(/\s/g, ''))}
        />
      </div>
      {isOpen && !_.isEmpty(filteredSuggestions) && (
        <div className={`autosuggest-container ${className}`}>
          {filteredSuggestions && filteredSuggestions?.map((item:any) => (
            <div
              key={item}
              className="autosuggest-item"
              onClick={() => {
                onItemSelect(item);
              }}
              onKeyDown={handleOnKeyDown}
              role="button"
              tabIndex={0}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AutosuggestTagInput;
