import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Table from 'components/Table';
import CheckboxColumnFilter from 'components/Table/filters/CheckboxColumnFilter';
import useGetProbeLocations from 'hooks/services/probe/location/useGetProbeLocations';

const EditBoardsTable = (props: any) => {
  const { probes, isSelected, selectedProbes, setSelectedProbes } = props;
  const { data: locations, isLoading } = useGetProbeLocations();

  const handleChangeProbes = (e: any) => {
    const { value } = e.target;
    setSelectedProbes((prevSelectedProbes: any) => {
      if (isSelected(prevSelectedProbes, value)) {
        return prevSelectedProbes.filter((val: any) => val !== value);
      }
      return [...prevSelectedProbes || [], value];
    });
  };

  const columns = useMemo(() => [
    {
      Header: 'Selected',
      accessor: 'checked',
      Filter: CheckboxColumnFilter,
      filter: 'multiValue',
      // eslint-disable-next-line @typescript-eslint/no-shadow
      Cell: (props: any) => {
        const { cell: { row } } = props;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { probe_name, checked } = row.original;
        return (
          <>
            <Form.Check
              defaultChecked={checked === 'Selected'}
              defaultValue={probe_name}
              id={`groups-probes.${probe_name}`}
              key={`groups-probes.${probe_name}`}
              inline
              aria-label="Select"
              onClick={handleChangeProbes}
            />
          </>
        );
      },
    },
    {
      Header: 'Name',
      accessor: 'probe_name',
      disableFilters: true,
      Cell: ({ value }: any) => (
        <>
          <span className="ml-2">{value}</span>
        </>
      ),
    },
    {
      Header: 'Alias',
      accessor: 'probe_alias',
      isSortable: true,
      disableFilters: true,
    },
    {
      Header: 'Location',
      accessor: 'location',
      isSortable: true,
      disableFilters: true,
    },
  ], [locations]);

  const tableData = probes?.map((probe: any) => ({
    checked: isSelected(selectedProbes, probe.probe_name) ? 'Selected' : 'Not selected',
    location: locations?.find((loc: any) => loc.country_iso === probe.country_iso).country_name,
    ...probe
  }));

  return (
    <>
      {!isLoading && (
      <Table
        actionsPosition="left"
        columns={columns}
        data={tableData ?? []}
        hideExportButton
        disablePagination
        skipReset={false}
        stickyActions
        stickyHeader
      />
      )}
    </>
  );
};

export default EditBoardsTable;
