import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetChannelValues(attName: string) {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_ATTENUATORS });

  return useQuery(['channelValues', attName], () => axios.get(
    `/attenuator/api/attenuators/${attName}/outputs`
  ).then(({ data }) => data), { staleTime: 1000 * 60 * 5 });
}
