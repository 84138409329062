import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type Pool = {
  name: string;
};

type AddPoolRequestData = {
  pool: Pool;
};

export default function useAddPool(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ pool }: AddPoolRequestData) => axios.post(
    '/sim-manager/api/pools',
    pool,
  ).then(({ data }) => data), options);
}
