import _ from 'lodash';
import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';

import FocusTrap from 'focus-trap-react/dist/focus-trap-react';

import clsx from 'clsx';

import {
  Button, Col, Overlay, Popover, Row,
} from 'react-bootstrap';

import CheckboxGroup from 'components/form/CheckboxGroup';

function CheckboxColumnFilter({
  column: {
    filterValue, id, setFilter, overlayContainer,
  },
  data,
  gotoPage
}: any) {
  const buttonRef = useRef(null);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState();

  const filterValueCheckboxGrouItems = useMemo(() => _.map(filterValue, (filterItem) => ({
    id: filterItem,
    label: filterItem,
    value: filterItem,
  })), [filterValue]);

  const filterCheckboxGroupItems = useMemo(
    () => _.chain(data)
      .map((row) => ({
        id: row[id],
        label: row[id],
        value: row[id],
      }))
      .concat(filterValueCheckboxGrouItems)
      .uniqBy('id')
      .value(),
    [data, filterValueCheckboxGrouItems, id],
  );

  const handleChange = useCallback((inputValue) => setValue(inputValue), [setValue]);

  const handleFilterButtonClick = () => {
    setShow(!show);
  };

  const handleOkButtonClick = () => {
    gotoPage(0);
    setFilter(value);
    setShow(false);
  };

  const handleResetButtonClick = () => {
    gotoPage(0);
    setFilter();
    setShow(false);
  };

  const initialValues = useMemo(
    () => _.reduce(
      filterValue,
      (acc, filter) => ({
        ...acc,
        [filter]: filter,
      }),
      {},
    ),
    [filterValue],
  );

  if (filterCheckboxGroupItems?.length < 2 && _.isEmpty(filterValue)) {
    return null;
  }

  return (

    <>
      <button
        className={clsx('filter-button text-button', { 'text-info': !_.isEmpty(filterValue) })}
        onClick={handleFilterButtonClick}
        ref={buttonRef}
        type="button"
        aria-label="checkbox-filter-button"
      >
        <span className={clsx('filter-icon', { active: filterValue })}>
          <i className="icon icon-filter" />
        </span>
      </button>
      <Overlay
        container={overlayContainer}
        onHide={() => setShow(false)}
        placement="bottom-end"
        rootClose
        rootCloseEvent="click"
        show={show}
        target={buttonRef.current}
      >
        {(props) => (
          <Popover id="popover-basic" {...props} placement="bottom">
            <Popover.Title as="h3">Filter</Popover.Title>
            <FocusTrap
              active={show}
              focusTrapOptions={{
                clickOutsideDeactivates: true,
                onDeactivate: () => setShow(false),
              }}
            >
              <Popover.Content>
                <CheckboxGroup
                  initialValues={initialValues}
                  items={filterCheckboxGroupItems}
                  onChange={handleChange}
                />
                <Row className="align-items-center flex-nowrap">
                  <Col>
                    <button
                      className="text-button"
                      onClick={handleResetButtonClick}
                      type="button"
                      aria-label="filter-checkbox-group-item"
                    >
                      Reset
                    </button>
                  </Col>
                  <Col xs="auto">
                    <Button className="ml-4 px-3" onClick={handleOkButtonClick} size="sm">
                      OK
                    </Button>
                  </Col>
                </Row>
              </Popover.Content>
            </FocusTrap>
          </Popover>
        )}
      </Overlay>
    </>

  );
}

export default CheckboxColumnFilter;
