import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxios from 'hooks/axios/useAxios';
import useSendSettings from './useSendSettings';

type SessionData = {
  probeName: string;
  sessionCaps: { capabilities: any }
};
// capabilities example
// {"capabilities": {}}

export default function useCreateSession(options: MutationConfig<any, any>) {
  const axios = useAxios({ baseURL: process.env.REACT_APP_IOS });

  const [sendSettings] = useSendSettings({});
  const desiredSettings = {
    settings:
      { mjpegServerScreenshotQuality: 5,
        mjpegServerFramerate: 15,
        mjpegScalingFactor: 40
      }
  };

  return useMutation(({ probeName, sessionCaps }: SessionData) => axios.post(
    `/c/${probeName}/session/`,
    sessionCaps
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, variables) => {
      sendSettings({ probeName: variables.probeName, sessionId: data?.sessionId, desiredSettings });
    }
  });
}
