import _ from 'lodash';
import React, { useState, useEffect } from 'react';

import { Form } from 'react-bootstrap';

type CheckboxGroupItem = {
  id: string;
  isCustom?: boolean;
  label?: string;
  value?: string | number;
};

type CheckboxGroupInitialValues = {
  [key: string]: string;
};

type CheckboxGroupInputValues = {
  [key: string]: string;
};

// todo: how to set default values for optional prop
/* eslint-disable react/require-default-props */
type CheckboxGroupProps = {
  initialValues?: CheckboxGroupInitialValues;
  items: CheckboxGroupItem[];
  onChange?: (selectedCheckboxes: string[]) => void;
};

const CheckboxGroup = ({
  initialValues,
  items,
  onChange,
}: CheckboxGroupProps) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<
  CheckboxGroupInitialValues
  >(initialValues || {});
  const [customInputValues, setCustomInputValues] = useState<
  CheckboxGroupInputValues
  >({});

  useEffect(() => {
    if (onChange) {
      const selectedCheckboxesArray = _.map(
        selectedCheckboxes,
        (value) => value,
      );
      onChange(selectedCheckboxesArray);
    }
  }, [onChange, selectedCheckboxes]);

  const handleCheckboxChange = (id: string, isCustom?: boolean) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const checked = event?.target?.checked;
    const value = event.target?.value;

    if (checked && !selectedCheckboxes[id]) {
      const customValue = customInputValues[id] || '';
      setSelectedCheckboxes({
        ...selectedCheckboxes,
        [id]: isCustom ? customValue : value,
      });
    }

    if (!checked && !_.isUndefined(selectedCheckboxes[id])) {
      const newValues = { ...selectedCheckboxes };
      _.unset(newValues, id);
      setSelectedCheckboxes(newValues);
    }
  };

  const handleCustomCheckboxValueChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.target?.value;
    const newCustomValues = { ...customInputValues };

    newCustomValues[id] = value;

    setCustomInputValues(newCustomValues);

    if (!_.isUndefined(selectedCheckboxes[id])) {
      const newValues = { ...selectedCheckboxes };
      newValues[id] = value;
      setSelectedCheckboxes(newValues);
    }
  };

  return (
    <Form.Group>
      {items.map(({
        id, isCustom, label, value: checkboxValue,
      }) => (
        <React.Fragment key={id}>
          {isCustom ? (
            <Form.Check
              checked={!_.isUndefined(selectedCheckboxes[id])}
              id={id}
              aria-label={`checkbox_${id}`}
              label={label || checkboxValue}
              onChange={handleCheckboxChange(id, isCustom)}
              type="checkbox"
            />
          ) : (
            <Form.Check
              checked={Boolean(selectedCheckboxes[id])}
              id={id}
              aria-label={`checkbox_${id}`}
              label={label || checkboxValue}
              onChange={handleCheckboxChange(id)}
              type="checkbox"
              value={checkboxValue}
            />
          )}
          {isCustom && (
            <Form.Control
              type="text"
              onChange={handleCustomCheckboxValueChange(id)}
              value={customInputValues[id] || checkboxValue || ''}
            />
          )}
        </React.Fragment>
      ))}
    </Form.Group>
  );
};

export default CheckboxGroup;
