import Markdown from 'react-markdown';
import { useEffect, useState } from 'react';
import useGetActlWiki from 'hooks/services/git/useGetActlWiki';

const GitlabHelpPage = () => {
  const [wikiContent, setWikiContent] = useState({ content: '' });
  const { data: actlWikiData, isLoading } = useGetActlWiki();
  useEffect(() => {
    if (!isLoading) {
      setWikiContent(actlWikiData);
    }
  }, [actlWikiData]);

  const lines = wikiContent.content.split('\n');
  const isHeader = (line: string) => line.match(/#{3,4} \d.\d.\d{1,2} .*/);
  // count number of # to know heading level
  const level = (line: string) => (line.match(/#/g) || []).length;
  const renderHeader = (line: string) => {
    const headerContent = line.replaceAll('#', '').trimStart();
    if (level(line) === 3) return <h3 key={headerContent}>{headerContent}</h3>;
    if (level(line) === 4) return <h4 key={headerContent}>{headerContent}</h4>;
    return null;
  };
  const renderLines = () => lines.map((line: string, index) => {
    if (isHeader(line)) {
      const id = `${line.toLowerCase()
        .replaceAll('#', '').trimStart()
        .replaceAll(' ', '-')
        .replace(/[{}()/:.,]/g, '')}`;
      return (
        <div id={id} key={`${id}_${+index}`} className="mb-4">
          {renderHeader(line)}
        </div>
      );
    }
    if (line.includes('Contents')) {
      return (
        <h2 id="contents" key="Contents">
          {line.replaceAll('#', '').trimStart()}
        </h2>
      );
    }
    return (
      <div key={`${line.toString()}_${+index}`}>
        <Markdown>
          {/* replace gitlab arrow with html code */}
          {line.replace(':arrow_heading_up:', '&#10548;')}
        </Markdown>
      </div>
    );
  });

  return <>{renderLines()}</>;
};

export default GitlabHelpPage;
