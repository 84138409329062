import SupportRequestForm from 'components/HelpPageComponents/SupportRequestForm';
import { Container, Row, Col } from 'react-bootstrap';

const SupportReq = () => (
  <Container fluid>
    <Row>
      <Col>
        <h1 className="mt-4 mb-3">
          Support Request
        </h1>
      </Col>
    </Row>
    <Row>
      <Col md={1} />
      <Col>
        <SupportRequestForm />
      </Col>
      <Col md={1} />
    </Row>
    <Row>
      <Col>
        <hr />
      </Col>
    </Row>
    <Row>
      <Col>
        <p style={{ fontStyle: 'italic', color: '#6B6B6B' }}>
          {'Another way how to contact Tessa Service Desk is sending mail to '}
          <a href="mailto:support@its-telekom.eu?subject=TESSA:%20Summarize%20your%20Problem">
            support@its-telekom.eu
          </a>.
        </p>
      </Col>
    </Row>
  </Container>
);

export default SupportReq;
