import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import { Device } from './interfaces';

type RemoveMultiplexerRequestData = {
  probeName: string;
  device: Device;
  multiplexers: any;
};

export default function useRemoveMultiplexer(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(
    ({ probeName, device, multiplexers }: RemoveMultiplexerRequestData) => axios.delete(
      `/cms/api/probes/${probeName}/devices/${device.device_id}/multiplexers?multiplexer_serial=${multiplexers.replace('#', '%23')}`,
    ).then(({ data }) => data), options,
  );
}
