import React from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';

const CampaignName = ({
  previousStep,
  nextStep,
  campaignName,
  setCampaignName
}: any) => (
  <Form>
    <strong>
      Please define a name for your new campaign:
    </strong>
    <br /><br />
    <Form.Label htmlFor="campaignName" className="col-form-label mr-4">Campaign name:</Form.Label>
    <Form.Control
      value={campaignName}
      id="campaignName"
      key="campaignName"
      type="text"
      aria-label="item"
      className="form-outline w-50"
      onChange={(e:any) => setCampaignName(e.target.value)}
    />
    <br /><br />
    <Container className="pt-4">
      <Row>
        <Col />
        <Col md={2}>
          <Button
            id="previousStep"
            onClick={previousStep}
            variant="primary"
            className="btn-block mr-1 mt-1 btn-lg"
            disabled
          >
            Back
          </Button>
        </Col>
        <Col md={2}>
          <Button
            id="nextStep"
            onClick={nextStep}
            variant="primary"
            className="btn-block mr-1 mt-1 btn-lg"
            type="submit"
            disabled={campaignName.length < 1}
          >
            Next
          </Button>
        </Col>
        <Col />
      </Row>
    </Container>
  </Form>
);

export default CampaignName;
