import * as React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { WindowsManagerGenericProvider } from 'components/WindowManager/WindowManager';
import keycloak from './keycloak';
import './scss/telekom-extended-scss/telekom-extended-styles.scss';

import ConfirmProvider from './context/Confirm/ConfirmProvider';
import 'iframe-resizer/js/iframeResizer.contentWindow';
import MainRouter from './routes/MainRouter';
import AlertsProvider from './context/alerts/AlertsState';
import ApiState from './context/backendData/ApiState';
import GherkinState from './context/gherkinData/GherkinState';

const App = () => {
  if (process.env.NODE_ENV === 'production') console.log = () => {};

  // Refresh page after login
  React.useEffect(() => {
    const handleMessageEvent = (event: any) => {
      if (event.data === 'loginSuccess') {
        if (window.top) {
          window.location.href = `${window.location.href}`;
        }
      }
    };

    window.addEventListener('message', handleMessageEvent, false);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{ onLoad: 'check-sso' }}
    >
      <AlertsProvider>
        <ApiState>
          <ConfirmProvider>
            <GherkinState>
              <WindowsManagerGenericProvider>
                <MainRouter />
              </WindowsManagerGenericProvider>
            </GherkinState>
          </ConfirmProvider>
        </ApiState>
      </AlertsProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
