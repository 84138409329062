import AccountRequestForm from 'components/HelpPageComponents/AccountRequestForm';
import { Container, Row, Col } from 'react-bootstrap';

const AccountReq = () => (
  <Container fluid>
    <Row>
      <Col>
        <h1 className="mt-4 mb-3">
          Account Request
        </h1>
      </Col>
    </Row>
    <Row>
      <Col md={1} />
      <Col>
        <AccountRequestForm />
      </Col>
      <Col md={1} />
    </Row>
  </Container>
);

export default AccountReq;
