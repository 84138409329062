import _ from 'lodash';

import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

import { Sim } from '../interfaces';

export default function useGetSubscriber(id: number | null) {
  const axios = useAxiosWithToken();

  return useQuery(['subscriber', id],
    () => axios.get(
      `/sim-manager/api/subscribers/${id}`,
    ).then(({ data }: { data: Sim }) => data),
    { enabled: _.isInteger(id), staleTime: 1000 * 60 * 5 });
}
