import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetSubscribers() {
  const axios = useAxiosWithToken();

  return useQuery('subscribers', () => axios.get(
    '/sim-manager/api/subscribers',
  ).then(({ data }) => data.reverse()), { staleTime: 1000 * 60 * 5 });
}
