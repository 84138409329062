import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeviceType = {
  desired_mapping?: {
    [iccid: string]: string
  };
  probe_alias: string;
};
export type CmdRequestDataType = {
  reservation_duration: number;
  user_id: string;
  filters: DeviceType[];
};

export default function useScheduleSleepJob(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation((cmd: CmdRequestDataType) => axios.post(
    '/schedule_sleep_job',
    cmd,
    { ...options, baseURL: process.env.REACT_APP_MINITESSA },
  ).then(({ data }) => data), options);
}
