import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxios from 'hooks/axios/useAxios';

type CommandData = {
  probeName: string;
  command: string;
};

export default function useSendCommand(options: MutationConfig<any, any>) {
  const axios = useAxios({ baseURL: process.env.REACT_APP_IOS });

  return useMutation(({ probeName, command }: CommandData) => axios.post(
    `/c/${probeName}/wda/${command}`
  ).then(({ data }) => data), options);
}
