import { Container, Row, Col } from 'react-bootstrap';
import * as React from 'react';

const Disclaimer = () => (
  <div className="pt-4">
    <Container>
      <Row>
        <Col md={12}>
          <h1 className="display-4">
            Disclaimer
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <p>
            Deutsche Telekom AG in no way guarantees that the information made available on this
            website is complete, accurate or up-to-date in all cases. This also applies to any links
            to other websites. Deutsche Telekom AG shall not be held responsible for the contents of
            a page accessed via such a link. Deutsche Telekom AG reserves the right to amend,
            supplement or delete the information supplied without prior notice.
            <br />
            <br />
            The information on this website does not constitute an offer to sell or the solicitation
            of an offer to buy any securities and should not be relied upon in connection with any
            investment decision. In no event shall Deutsche Telekom be liable for any damage
            whatsoever resulting from loss of use options or data loss in connection with the use
            of documents or information and/or from the performance of
            services available on this website.
            <br />
            <br />
            With the exception of historical information, the matters discussed in the materials
            and documents on this website are &quot;forward-looking statements&quot;.
            These forward-looking statements rely on a number of risks, uncertainties or
            other factors, many of which are outside Deutsche Telekom AG&apos;s control, which
            could cause actual results to differ
            materially from such statements. These risks, uncertainties and other factors are
            described in detail in Deutsche Telekom&apos;s financial reports which are available on
            Deutsche Telekom&apos;s website. Visitors of this website are cautioned not to put undue
            reliance on these forward-looking statements. Deutsche Telekom disclaims any
            intention or obligation to update or revise any forward-looking statements,
            whether as a result of new information, future events or otherwise.
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Disclaimer;
