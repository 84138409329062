import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Card, Form, FormControl, FormGroup,
} from 'react-bootstrap';
import useForm from '../../../../hooks/useForm';
import useConfirm from '../../../../hooks/useConfirm';
import useAddMultiplexer from '../../../../hooks/services/probe/useAddMultiplexer';
import useRemoveMultiplexer from '../../../../hooks/services/probe/useRemoveMultiplexer';

interface IModalProps {
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  onHide: () => void;
  probeName: string;
  deviceData: any;
  getProbes: any;
}

type FormErrors = {
  multiplexer?: string,
};

const EditMultiplexerTable = ({
  setErrMessage, setMessage, probeName, deviceData, getProbes, onHide,
}: IModalProps) => {
  const [showError, setShowError] = useState(true);

  const [selectedMultiplexers, setSelectedMultiplexers] = useState<string[]>();

  const isSelected = (arr: string[] | undefined, searchValue: any) => (
    arr?.includes(searchValue)
  );

  const handleChangeMultiplexer = (e: any) => {
    const { value } = e.target;
    if (isSelected(selectedMultiplexers, value)) {
      setSelectedMultiplexers(selectedMultiplexers?.filter((val: any) => val !== value));
    } else {
      setSelectedMultiplexers([...selectedMultiplexers || [], value]);
    }
  };

  const [addMultiplexer, { error }] = useAddMultiplexer({
    onSuccess: () => {
      setMessage('Multiplexer has been added!');
      getProbes();
      onHide();
    },
    onError: () => setErrMessage('Failed to update device'),
  });

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
    },
    onSubmit: () => {
      const multiplexerArray = formValues?.multiplexer.split(',');
      multiplexerArray.map((multiplexer: any) => (
        addMultiplexer({
          probeName,
          deviceId: deviceData.device_id.toString(),
          multiplexers: multiplexer.trim(),
        })
      ));
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.multiplexer || values?.multiplexer === 0) {
        errors.multiplexer = 'Please fill in multiplexer serial.';
      }
      return errors;
    },
  });

  const [removeMultiplexer] = useRemoveMultiplexer(
    {
      onSuccess: () => {
        setMessage('Multiplexer has been deleted');
        getProbes();
        onHide();
      },
      onError: () => setErrMessage(`failed to delete multiplexer ${deviceData?.name}`),
    },
  );

  const removeMultiplexer1 = ({ device }: any, { multiplexer }: any) => {
    const multiplexerArray1 = multiplexer.toString().split(',');
    multiplexerArray1.map((multiplexers: any) => (
      removeMultiplexer({ probeName, device, multiplexers })
    ));
  };
  const confirm = useConfirm();
  const handleRemoveMultiplexer = async ({ device }: any, { multiplexer }: any) => {
    confirm({
      body: 'Are you sure to remove multiplexer?',
      onOk: () => removeMultiplexer1({ device }, { multiplexer }),
      title: 'Remove multiplexer',
    });
  };

  // show error if it appears
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <>
      <div>
        <Card className="form-input">
          <Card.Header className="d-flex">
            <span className="align_left">
              Multiplexers
            </span>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              {error?.message && showError && (
                <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                  {error?.message}
                </Alert>
              )}
              <Form.Row>
                <FormGroup key="multiplexer">
                  <Form.Label htmlFor="multiplexer">
                    Multiplexer serial (Insert one or more comma separated values)
                  </Form.Label>
                  <FormControl
                    id="multiplexer"
                    isInvalid={submitted && Boolean(formErrors?.multiplexer)}
                    name="multiplexer"
                    onChange={handleChange('multiplexer')}
                    required
                    type="text"
                    value={formValues?.multiplexer}
                  />
                  {submitted && formErrors?.multiplexer && (
                    <FormControl.Feedback type="invalid">
                      {formErrors?.multiplexer}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
              </Form.Row>
              <div className="text-right">
                <Button className="btn btn-primary" type="submit">Add multiplexer</Button>
              </div>
              {deviceData.multiplexers?.length > 0
                            && (
                            <div>
                              <table className="table table-responsive-sm">
                                <tbody>
                                  {deviceData.multiplexers.map((item: any) => (
                                    <tr key={`multiplexer_${item}`}>
                                      <td>
                                        <Form.Check
                                          defaultValue={item}
                                          id={`multiplexer-serial.${item}`}
                                          key={`multiplexer-serial.${item}`}
                                          inline
                                          aria-label="Select"
                                          onClick={handleChangeMultiplexer}
                                        />
                                      </td>
                                      <td>{item}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                              <div className="text-right">
                                <Button
                                  onClick={() => (
                                    handleRemoveMultiplexer({ device: deviceData },
                                      { multiplexer: selectedMultiplexers })
                                  )}
                                >
                                  Delete selected
                                </Button>
                              </div>
                            </div>
                            )}
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default EditMultiplexerTable;
