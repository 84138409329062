import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetUsers() {
  const axios = useAxiosWithToken();

  return useQuery('users', () => axios.get(
    'users/api/users',
  ).then(({ data }) => data.reverse()), { staleTime: 1000 * 60 * 5 });
}
