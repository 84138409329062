import { useKeycloak } from '@react-keycloak/web';

import React, { useEffect, useState } from 'react';

import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';

import useUser from 'hooks/useUser';

import useSendMail from 'hooks/services/mail/useSendMail';
// import moment from 'moment';

const SupportRequestForm = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    issueType: '',
    description: '1. Description of the issue:\n\n2. Expected behavior:\n\n3. Actual behavior:\n\n4. Steps to reproduce the behavior:\n\n5. Report Portal Link If possible:\n\n',
    files: '',
    to: ''
  });
  // const [files, setFiles] = useState([]);
  const [formerrors, setFormErrors] = useState<any>({});

  const { keycloak } = useKeycloak();
  const isUserLoggedIn = keycloak?.authenticated;

  const { given_name: givenName, family_name: familyName, email } = useUser();

  const [sendMail, { isLoading, error }] = useSendMail(
    {
      onSuccess: (data) => {
        console.log(data);
        if (data && !data.error && data.success) setShowMessage(true);
        else console.log(`sending mail error: ${data.error}`);
      }
    }
  );

  // init form data
  useEffect(() => {
    console.log(`given_name: ${givenName}`);
    setValues((vals) => ({
      ...vals,
      firstName: givenName || '',
      lastName: familyName || '',
      email: email || '',
      to: 'support@its-telekom.eu'
    }));
  }, [givenName]);
  // given_name":"testadmin01","family_name":"testadmin01","email":"testadmin01@its-telekom.eu"}

  // this method handles the each form field changing and updates the relevant
  // state value for that input
  const handleChange = (event: any) => {
    console.log(
      `handleChange -> ${event.target.name} : ${event.target.value}`
    );
    setValues((vals) => ({
      ...vals,
      [event.target.name]: event.target.value,
    }));
  };

  const onFileUpload = (event: any) => {
    event.preventDefault();
    // Get the file Id
    const { id } = event.target;
    // Create an instance of FileReader API
    const fileReader = new FileReader();
    // Get the actual file itself
    const file = event.target.files[0];
    fileReader.onload = () => {
      // After uploading the file
      // appending the file to our state array
      // set the object keys and values accordingly
      console.log(`file uploaded ${id} ${fileReader.result} `);
      // setFiles([...files, { fileId: id, uploadedFile: fileReader.result }]);
    };
    // reading the actual uploaded file
    fileReader.readAsDataURL(file);
  };

  // this method will check each field in your form. You define
  // the rules for each field
  const validate = () => {
    console.log('Validate the form....');
    const errors: any = {};
    // name field
    if (!values.firstName) {
      errors.firstName = 'First name is required';
    }
    // name field
    if (!values.lastName) {
      errors.lastName = 'Last name is required';
    }
    // email field
    if (!values.email) {
      errors.email = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = 'Email address is invalid';
    }
    if (!values.subject) {
      errors.subject = 'Subject is required';
    }
    if (!values.description) {
      errors.description = 'Description is required';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      return true;
    }
    return false;
  };

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    if (validate()) {
      const formattedDescription = values.description.replace(/\n/g, '<br>');
      sendMail({ mail: { from: values.email, to: values.to, subject: `TESSA UI - Support Request Form: ${values.subject}`, text: values.description, html: `<div>${formattedDescription}</div>` } });
    } else {
      setShowMessage(false);
    }
  };

  return (
    <>
      <Alert show={showMessage} variant="success">
        <Alert.Heading>Success</Alert.Heading>
        <hr />
        <p>
          Thank you for reaching out to us!
        </p>
        <p style={{ color: 'green' }}>
          Your support request was succesfully send and we are working on it.
        </p>
        <p>
          We&apos;ll come back to you as soon as possible.
        </p>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              setShowMessage(false);
              // reset form
              setValues((vals) => ({
                ...vals,
                subject: '',
                description: '1. Description of the issue:\n\n2. Expected behavior:\n\n3. Actual behavior:\n\n4. Steps to reproduce the behavior:\n\n5. Report Portal Link If possible:\n\n',
              }));
            }}
            variant="outline-success"
          >
            Ok
          </Button>
        </div>
      </Alert>
      <Alert show={Boolean(error)} variant="danger">
        <Alert.Heading>Sending error</Alert.Heading>
        <hr />
        <p>
          {/* {error?.message} */}
          {JSON.stringify(error?.message)}
        </p>
      </Alert>
      <Alert show={isLoading} variant="info">
        <Alert.Heading>Sending your request...</Alert.Heading>
      </Alert>
      {(isLoading) && (
        <div className="spinner-container">
          <Spinner animation="border" className="spinner" role="status" />
        </div>
      )}

      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              placeholder="Enter your first name"
              type="text"
              name="firstName"
              value={values.lastName}
              disabled={isUserLoggedIn}
              onChange={handleChange}
            />
            {formerrors.firstName && (
              <p className="text-warning">{formerrors.firstName}</p>
            )}
          </Form.Group>

          <Form.Group as={Col} controlId="formGridLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Enter your last name"
              type="text"
              name="lastName"
              value={values.firstName}
              disabled={isUserLoggedIn}
              onChange={handleChange}
            />
            {formerrors.lastName && (
              <p className="text-warning">{formerrors.lastName}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              placeholder="Enter email"
              type="email"
              name="email"
              value={values.email}
              disabled={isUserLoggedIn}
              onChange={handleChange}
            />
            {formerrors.email && (
              <p className="text-warning">{formerrors.email}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridSubject">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              placeholder="Enter subject"
              type="text"
              name="subject"
              value={values.subject}
              onChange={handleChange}
            />
            {formerrors.subject && (
              <p className="text-warning">{formerrors.subject}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              style={{ height: '500px' }}
              type="text"
              name="description"
              value={values.description}
              onChange={handleChange}
            />
            {formerrors.description && (
              <p className="text-warning">{formerrors.description}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row hidden>
          <Form.Group controlId="formFile">
            <Form.Label>Attachments</Form.Label>
            <Form.Control
              type="file"
              name="files"
              multiple
              onChange={onFileUpload}
            />
          </Form.Group>
        </Form.Row>

        <Row>
          <Col />
          <Col md={2}>
            <Button
              type="submit"
              variant="primary"
              disabled={showMessage}
            >
              Send
            </Button>
          </Col>
          <Col />
        </Row>
      </Form>

    </>
  );
};

export default SupportRequestForm;
