const ServerStatusInfo = () => (
  <div className="pt-4" style={{ textAlign: 'center', height: '91vh' }}>
    <iframe
      style={{ display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        border: 'none',
        paddingBottom: '24px' }}
      title="server status page"
      src="https://status.its-telekom.eu/"
    />
  </div>

);

export default ServerStatusInfo;
