import React, { useState } from 'react';

import PageTitle from 'components/PageTitle';
import Tabs from 'components/Tabs';

import GroupsTable from './GroupsTable';
import UsersTable from './UsersTable';

const UserManagement = () => {
  const [activeTab, setActiveTab] = useState('groups');

  return (
    <>
      <PageTitle>User Management</PageTitle>
      <Tabs
        activeTab={activeTab}
        block
        onChange={(value) => setActiveTab(value)}
        tabs={[{
          content: 'Groups',
          value: 'groups',
        }, {
          content: 'Users',
          value: 'users',
        }]}
      />
      <div className="border border-top-0 rounded-bottom bu-px-3 bu-py-2">
        {activeTab === 'users' && <UsersTable />}
        {activeTab === 'groups' && <GroupsTable />}
      </div>
    </>
  );
};

export default UserManagement;
