import axios from 'axios';
import React from 'react';
import { Button } from 'react-bootstrap';

export interface IProps {
  filename: string;
  value: string;
  variant: string;
}

const Downloader = (props: IProps) => {
  const { filename, value, variant } = props;
  // todo check if we need cleanup like URL.revokeObjectURL(url);
  const downloadFile = () => {
    axios({
      url: `%PUBLIC_URL%/../../ex/${filename}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Button
      id="download-file"
      variant={variant}
      onClick={downloadFile}
    >
      {value}
    </Button>
  );
};

export default Downloader;
