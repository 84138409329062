import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetActlWiki() {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_GIT });

  return useQuery('wiki', () => axios.get(
    '/wiki',
  ).then(({ data }) => data), { staleTime: 1000 * 60 * 5 });
}
