import React, { useMemo, useState, useEffect } from 'react';

import { Form, Button, Alert } from 'react-bootstrap';

import Table from 'components/Table';

import useUserTableSettings from 'hooks/useUserTableSettings';

import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useAssignDevice from 'hooks/services/simManagement/subscriber/useAssignDevice';
import useUnassignDevice from 'hooks/services/simManagement/subscriber/useUnassignDevice';

import TableColumns from '../../SimColumns';

const hiddenColumns = [
  'msisdn',
  'imsi',
  'tariff',
  'wnw',
  'op_wnw',
  'psp',
  'wholesale_id',
  'itg_id',
  'secret',
  'name',
  'origin',
  'prepaid',
  'type',
  'sim_type',
  'modified',
  'position'
];

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const EditSubscriberTable = (props : any) => {
  const {
    onHide,
    setMessage,
    setErrMessage,
    selectedSims,
    setSelectedSims,
    isSelected,
    deviceData,
    getProbes
  } = props;
  const [showErrorAssign, setShowErrorAssign] = useState(true);
  const [showErrorUnassign, setShowErrorUnassign] = useState(true);
  const { data: sims } = useGetSubscribers();
  const userTableSettings = useUserTableSettings('edit-sim-cards', { hiddenColumns });

  const tableColumns = [
    ...TableColumns,
    {
      Header: 'Tags',
      accessor: 'tags',
      isSortable: false,
      Cell: ({ value }: any) => (
        <>
          {value?.join(', ')}
        </>
      )
    },
    {
      Header: 'Network',
      accessor: 'network',
      Cell: ({ value }: any) => (
        <>
          {value && value?.filter((val:any) => (['lab', 'live'].includes(val))).join(', ')}
        </>
      )
    },
  ];

  const tableData = sims?.filter((sim: any) => {
    switch (deviceData.type) {
      case 'Android':
      case 'iOS':
        return sim.type === 'static';
        break;
      case 'Analogue_Modem':
        return sim.type === 'fixed_line';
        break;
      case 'IP_Phone':
        return sim.type === 'sip';
        break;
      default:
        return false;
        break;
    }
  })
    .map((simCard: any) => ({ checked: isSelected(selectedSims, String(simCard.id)), ...simCard, network: [simCard?.lab && 'lab', simCard?.live && 'live'] }));

  const handleChangeSims = (e: any) => {
    const { value } = e.target;
    if (isSelected(selectedSims, value)) {
      setSelectedSims(selectedSims?.filter((val: any) => val !== value));
    } else {
      setSelectedSims([...selectedSims || [], value]);
    }
  };

  const [assignDevice, { error: errorAssign }] = useAssignDevice({
    onSuccess: () => {
      setMessage('Subscriber assigned successfully');
      onHide();
    },
    onError: () => {
      setErrMessage('Failed to assign subscriber');
    },
  });
  const [unassignDevice, { error: errorUnassign }] = useUnassignDevice({
    onSuccess: () => {
      setMessage('Subscriber unassigned successfully');
      onHide();
    },
    onError: () => {
      setErrMessage('Failed to unassign subscriber');
    }
  });

  const onSaveSelectedSubscribers = () => {
    selectedSims.forEach((sim: any) => {
      if (!isSelected(Object?.keys(deviceData?.subscribers || []), sim)) {
        assignDevice({
          id: sim,
          deviceId: deviceData?.device_id
        });
      }
    });
    tableData.forEach((sim: any) => {
      if (!isSelected(selectedSims, String(sim.id))
        && isSelected(Object?.keys(deviceData?.subscribers || []), String(sim.id))) {
        unassignDevice({
          id: sim.id,
          deviceId: deviceData?.device_id
        });
      }
    });
    // Delay the update of probes in order to get probes with subscribers assigned
    (async () => {
      await delay(1000);
      getProbes();
    })();
  };

  // show error if it appears
  useEffect(() => {
    setShowErrorAssign(Boolean(errorAssign));
    setShowErrorUnassign(Boolean(errorUnassign));
  }, [errorAssign, errorUnassign]);

  const columns = useMemo(() => [
    {
      Header: 'Selected',
      accessor: 'checked',
      disableFilters: true,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      Cell: (props: any) => {
        const { cell: { row } } = props;
        const { id, checked } = row.original;
        return (
          <>
            <Form.Check
              defaultChecked={checked}
              defaultValue={id}
              id={`groups-sims.${id}`}
              key={`groups-sims.${id}`}
              inline
              aria-label="Select"
              onClick={handleChangeSims}
            />
          </>
        );
      },
    },
    ...tableColumns.map((item) => ({ ...item, disableFilters: true })),
  ], [tableColumns]);

  return (
    <>
      {errorAssign?.message && showErrorAssign && (
      <Alert variant="warning" onClose={() => setShowErrorAssign(false)} dismissible>
        {errorAssign?.message}
      </Alert>
      )}
      {errorUnassign?.message && showErrorUnassign && (
      <Alert variant="warning" onClose={() => setShowErrorUnassign(false)} dismissible>
        {errorUnassign?.message}
      </Alert>
      )}
      {userTableSettings?.isFetched && (
        <Table
          columns={columns}
          data={tableData ?? []}
          hideExportButton
          initialState={userTableSettings?.initialState}
          onStateChange={userTableSettings.updateTableSettings}
          skipReset={false}
          showSettingsMenu
        />
      )}
      <div className="text-right">
        <Button className="btn btn-secondary" style={{ color: 'black' }} onClick={onHide}>Close</Button>
        <Button className="btn btn-primary" onClick={onSaveSelectedSubscribers}>Save</Button>
      </div>
    </>
  );
};

export default EditSubscriberTable;
