import React from 'react';
import { Link, NavLink, Route } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';
import AdministrationLayout from '../../components/layout/AdministrationLayout';
import Overview from './Overview';
import SimCards from './SimCards';
import Attenuators from './Attenuators';
import ProbeManagement from './ProbeManagement';
import UserManagement from './UserManagement';
import Maintenance from './Maintenance';
import SimArrays from './SimArrays';
import Pools from './Pools';

const menuItems = [
  { title: 'Overview', link: '/administration', page: Overview },
  { title: 'Subscribers', link: '/administration/sim-cards', page: SimCards },
  { title: 'Pools', link: '/administration/pools', page: Pools },
  { title: 'SIM Arrays', link: '/administration/sim-arrays', page: SimArrays },
  { title: 'Probes', link: '/administration/probes', page: ProbeManagement },
  { title: 'Attenuators', link: '/administration/attenuators', page: Attenuators },
  { title: 'Users & Groups', link: '/administration/user-management', page: UserManagement },
  { title: 'Maintenance', link: '/administration/maintenance', page: Maintenance },
];

const Administration = () => (
  <AdministrationLayout
    sidebar={(
      <ListGroup className="bu-mt-1_5" variant="flush">
        {menuItems.map((menuItem) => (
          <ListGroup.Item
            action
            as={NavLink}
            to={menuItem.link}
            key={menuItem.title}
            id={menuItem.title.toLowerCase().replace(' ', '-')}
            exact
          >
            {menuItem.title}
          </ListGroup.Item>
        ))}
      </ListGroup>
    )}
  >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><Link to="/administration">Administration</Link></li>
      {menuItems.map((menuItem) => (
        <Route
          key={menuItem.title}
          path={menuItem.link}
          exact
          render={() => <li className="breadcrumb-item">{menuItem.title}</li>}
        />
      ))}
    </ol>
    {menuItems.map((menuItem) => (
      <Route
        key={menuItem.title}
        path={menuItem.link}
        exact
        component={menuItem.page}
      />
    ))}
  </AdministrationLayout>
);

export default Administration;
