import React, { useState, useContext } from 'react';
// react bootstrap components
import { Button, Dropdown } from 'react-bootstrap';
// key generator
import shortid from 'shortid';
import uuid from 'uuid';
// icons
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GherkinContext from '../../../context/gherkinData/gherkinContext';
// data and types
import ElementType, { IStep, IStepElement, IGherkinContext } from '../../../context/gherkinData/interfaces';
import { types } from '../data';
// styles
import '../styles.scss';

// function to convert step text into object
export const StepTextToObject = (text: string): IStep => {
  // append {keyword} string
  // Keywords represents Gherkin step keywords (Given, When, Then, And, But
  // Then split step string into elements - find all variables between curly brackets and
  // find all text between variables
  // e.g.: "after {int} sec {party} hangs up" -->
  // [{keyword}, after, {int}, sec, {party}, hangs up]
  const stepElements = ['{keyword}'].concat(text.match(/\{[\w]+\}|[^{]*/g)!);
  // initialize object to hold parsed elements
  const elements: IStepElement[] = [];
  // go through all elements (text and variables) and objectize them
  stepElements.map((part) => {
    const stepElement: IStepElement = {
      content: null,
      selectedValueIndex: null,
      typeKey: '',
      type: ElementType.text,
      isActive: false,
      key: shortid.generate(),
    };
    // if variable, e.g.: {party}
    if (part.startsWith('{')) {
      const variableName = part.split(/{|}/g)[1];
      stepElement.content = variableName;
      switch (variableName) {
        // numeric up/down
        case 'int': {
          stepElement.type = ElementType.number;
          break;
        }
        // text input
        case 'string': {
          stepElement.type = ElementType.string;
          stepElement.content = '';
          break;
        }
        // other variable - enumeration - try to find if variable type is defined
        default: {
          let assigned = false;
          Object.entries(types).some(
            ([key]) => {
              if (key === variableName) {
                stepElement.type = ElementType.specialType;
                stepElement.typeKey = key;
                assigned = true;
              }
              return null;
            },
          );
          // if variable not match variable list, set it as unknown
          if (!assigned) {
            stepElement.type = ElementType.unknown;
            stepElement.typeKey = variableName;
          }
          break;
        }
      }
    } else {
      // otherwise it is plain (not editable) text
      stepElement.content = part;
      stepElement.type = ElementType.text;
    }
    elements.push(stepElement);
    return null;
  });
  return {
    id: uuid(),
    elements,
    isSelected: false,
    key: shortid.generate(),
  };
};

// todo: how to set default values for optional prop
/* eslint-disable react/require-default-props */
interface IStepProps extends IStep {
  dndHandleProps?: any;
}

// step component
const Step = (props: IStepProps) => {
  const {
    elements, dndHandleProps, id,
  } = props;
  const ghContext = useContext<IGherkinContext>(GherkinContext);
  const { deleteStep } = ghContext;
  // hold elemets state to be able to select from dropdowns and hold text/number inputs
  const [elems, setElems] = useState(elements);

  const onVariableChanged = (
    part: IStepElement,
    partIndex: number,
    event: any,
  ) => {
    const updatedElements = [...elems];

    updatedElements[partIndex].content = event.target.value;
    // get index from list of possible variable values (drop down list)
    if (updatedElements[partIndex].type === ElementType.specialType) {
      updatedElements[partIndex].selectedValueIndex = event.target.value;
    }
    // update selected and active variable
    updatedElements[partIndex].isActive = true;
    // update state
    setElems(updatedElements);
  };

  return (
    <div id="step" className="step">
      <div
        {...dndHandleProps}
        className="align-left"
        style={{ display: 'relative', float: 'left', margin: '5px' }}
      >
        <FontAwesomeIcon
          className="align-middle"
          icon={faBars}
          color="gray"
        />
      </div>
      {
        // foreach step element
        elems.map((part: IStepElement, partIndex: number) => {
          switch (part.type) {
            // if element is number variable, generate numeric input box
            case ElementType.number: {
              return (
                <input
                  className="step_input"
                  type="number"
                  name="number"
                  placeholder="Nr"
                  min="0"
                  step="1"
                  key={part.key}
                  value={part.content!}
                  onChange={(event) => onVariableChanged(part, partIndex, event)}
                />
              );
            }
            // if element is string variable, generate text input box
            case ElementType.string: {
              return (
                <input
                  className="step_input"
                  type="text"
                  name="string"
                  placeholder="text"
                  key={part.key}
                  value={part.content!}
                  onChange={(event) => onVariableChanged(part, partIndex, event)}
                />
              );
            }
            // if no variable, just print step text
            case ElementType.text: {
              return (
                <span key={shortid.generate()} className="step_row">{part.content}</span>
              );
            }
            // try to generate drop down box for variable
            default: {
              let found = false;
              let dropDownItems: Array<string> = [];
              // find variable
              Object.entries(types).forEach(
                ([key, value]) => {
                  if (key === part.typeKey) {
                    found = true;
                    dropDownItems = value;
                  }
                },
              );
              // if found, generate drop down box
              if (found) {
                return (
                  <Dropdown key={part.key} className="align_left">
                    <Dropdown.Toggle
                      size="sm"
                      variant={part.selectedValueIndex === null ? 'secondary' : 'primary'}
                      id="dropdown-basic"
                    >
                      {/* if nothing selected, write variable name
                          otherwise write selected item */}
                      {part.selectedValueIndex === null
                        ? part.typeKey
                        : dropDownItems[part.selectedValueIndex]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        // fill menu items
                        dropDownItems.map((val, typeIndex) => (
                          <Dropdown.Item
                            key={shortid.generate()}
                            onClick={() => onVariableChanged(
                              part,
                              partIndex,
                              { target: { value: typeIndex } },
                            )}
                          >
                            {val}
                          </Dropdown.Item>
                        ))
}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }
              // if variable not found, warn user
              return (
                <span key={shortid.generate()} className="warn step_row">
                  {`Unsupported type {${part.typeKey}}`}
                </span>
              );
            }
          }
        })
}
      {/*  remove step button */}
      <Button
        className="small_button align_right"
        variant="danger"
        onClick={(evt: any) => {
          evt.stopPropagation();
          const ids = id.split('+');
          const ffId = parseInt(ids[0], 10);
          const scId = parseInt(ids[1], 10);
          const stId = ids[2];
          deleteStep(ffId, scId, stId);
        }}
      >
        <FontAwesomeIcon
          className="align-middle"
          icon={faTimes}
          color="white"
        />
      </Button>
    </div>
  );
};
export default Step;
