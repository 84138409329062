import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetSims() {
  const axios = useAxiosWithToken();

  return useQuery('sims', () => axios.get(
    '/sim-manager/api/sims',
  ).then(({ data }) => data.reverse()), { staleTime: 1000 * 60 * 5 });
}
