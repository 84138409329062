import React, { useMemo } from 'react';

import { Button } from 'react-bootstrap';

import Table from 'components/Table';

import useUserTableSettings from 'hooks/useUserTableSettings';

import { useApiPolling } from 'hooks/apiPolling/apiPollingContext';
import { Probe } from 'hooks/services/probe/interfaces';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import { Sim } from 'hooks/services/simManagement/interfaces';

import SimColumns from 'pages/Administration/SimColumns';

const hiddenColumns = [
  'position',
  'modified',
  'sim_type',
  'type',
  'prepaid',
  'origin',
  'name',
  'secret',
  'itg_id',
  'wholesale_id',
  'psp',
  'op_wnw',
  'wnw',
  'tariff',
  'imsi',
  'msisdn'
];

const InsertSimCard = (
  { handleInsertSim, simCards }: { handleInsertSim: (sim: any) => void, simCards: Sim[] },
) => {
  const additionalColumns = [
    {
      Header: 'Tags',
      accessor: 'tags',
      isSortable: false,
      Cell: ({ value }: any) => (
        <>
          {value?.join(', ')}
        </>
      )
    },
    {
      Header: 'Network',
      accessor: 'network',
      Cell: ({ value }: any) => (
        <>
          {value && value?.filter((val: any) => ['lab', 'live'].includes(val)).join(', ')}
        </>
      )
    }
  ];

  const extendedSimColumns = SimColumns.reduce((acc: any, column: any) => {
    acc.push(column);
    if (column.Header === 'MSISDN') {
      acc.push(...additionalColumns);
    }
    return acc;
  }, []);

  const { mappings } = useApiPolling();
  const { data: probes } = useGetProbes();

  const userTableSettings = useUserTableSettings('insert-sim-cards', { hiddenColumns });

  const extendedSims = simCards
    ? simCards.map((sim) => {
      // Find probe sim card is mapped to
      const mapping = mappings
        ? mappings.find((m) => (m && (m.mapped && m.mapped.iccid === sim.iccid))
        || (m.using && m.using.iccid === sim.iccid))
        : null;
      const mappedInProbe = mapping ? probes.find((p:Probe) => p.probe_name === mapping.channel.path.split('.')[0])?.probe_alias : '';
      // set status active, mapped, using
      let mappingStatus = 'Active';
      mappings?.find((m) => {
        if (m && m.using && m.using.iccid === sim.iccid) {
          mappingStatus = 'Using';
          return (m && m.using && m.using.iccid === sim.iccid);
        }
        if (m && m.mapped && m.mapped.iccid === sim.iccid) {
          mappingStatus = 'Mapped';
          return (m && m.mapped && m.mapped.iccid === sim.iccid);
        }
        mappingStatus = 'Active';
        return null;
      });
      return ({
        ...sim,
        mapping: mappedInProbe,
        status: mappingStatus,
        network: [sim?.lab && 'lab', sim?.live && 'live']
      });
    })
    : null;

  const tableRowActions = useMemo(() => ({
    content: 'Actions',
    items: [
      {
        render: (sim: any) => {
          const { mapping } = sim;
          return (
            <Button
              // className={clsx('text-button', { 'bu-ml-1': false })}
              key="insert2"
              disabled={mapping !== ''}
              onClick={() => {
                handleInsertSim(sim);
              }}
              size="sm"
              type="button"
              variant="outline-primary"
            >
              Insert
            </Button>
          );
        }
      },
    ],
  }), [handleInsertSim]);

  const columns = useMemo(() => [
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: true,
      disableFilters: true,
      Cell: ({ value }: any) => {
        let status = '';
        switch (value) {
          case 'Active':
            status = 'text-success';
            break;
          case 'Mapped':
            status = 'text-warning';
            break;
          case 'Using':
            status = 'text-danger';
            break;
          default:
            status = '';
            break;
        }
        return (
          <>
            <i
              className={`icon icon-record icon-solid ${status}`}
            />
            <span className="sr-only">{value}</span>
          </>
        );
      },
    },
    {
      Header: 'Connected to',
      accessor: 'mapping',
      isSortable: true,
      disableFilters: true,
    },
    ...extendedSimColumns.map((col:any) => ({ ...col, disableFilters: true }))
  ], [extendedSimColumns]);

  const tableData = extendedSims?.filter((sim: any) => sim.position !== null && sim.type === 'mappable');

  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        actionsPosition="left"
        disablePagination
        rowActions={tableRowActions}
        hideExportButton
        columns={columns}
        data={tableData || []}
        initialState={userTableSettings?.initialState}
        maxHeight="500px"
        onStateChange={userTableSettings.updateTableSettings}
        skipReset={false}
        showSettingsMenu
        stickyActions
        stickyHeader
      />
      )}
    </>
  );
};

export default InsertSimCard;
