import _ from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';

const useModal = (showProp = false) => {
  const onHideModalRef = useRef();

  const [state, setState] = useState({
    data: null,
    show: showProp ?? false,
  });

  const hideModal = useCallback(() => {
    if (_.isFunction(onHideModalRef.current)) {
      onHideModalRef.current();
    }

    setState({ show: false, data: null });
  }, []);

  const showModal = useCallback((data, { onHideModal } = {}) => {
    if (onHideModalRef) {
      onHideModalRef.current = onHideModal;
    }

    setState({ show: true, data: data ?? null });
  }, []);

  const modalProps = useMemo(
    () => ({
      hideModal,
      data: state?.data,
      show: state?.show,
      showModal,
    }),
    [hideModal, state, showModal]
  );

  return modalProps;
};

export default useModal;
