import React, { useEffect, useMemo, useState } from 'react';

import Table from 'components/Table';

import { IGroup } from 'context/backendData/interfaces';

import { SimMapping } from 'hooks/apiPolling/apiPollingContext';

import useAlerts from 'hooks/useAlerts';
import useUser from 'hooks/useUser';
import useUserTableSettings from 'hooks/useUserTableSettings';

import useReloadArrays from 'hooks/services/simManagement/array/useReloadArrays';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useGetMappings from 'hooks/services/simManagement/mapping/useGetMappings';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import { Probe } from 'hooks/services/probe/interfaces';
import { Sim } from 'hooks/services/simManagement/interfaces';

import EditSimModal from './EditSimModal';

const hiddenColumns = [
  'position',
  'modified',
  'sim_type',
  'type',
  'prepaid',
  'origin',
  'name',
  'secret',
  'itg_id',
  'wholesale_id',
  'psp',
  'op_wnw',
  'wnw',
  'tariff',
  'imsi',
  'msisdn'
];

const SimCardsTable = (props : any) => {
  const { tableColumns } = props;

  const { showAlert } = useAlerts();
  const { isAdmin, preferred_username: username } = useUser();
  const userTableSettings = useUserTableSettings('sim-cards', { hiddenColumns });

  const { data: mappings, refetch: getMappings } = useGetMappings();
  const { data: simCards, refetch: getSimCards } = useGetSubscribers();
  const { data: probes } = useGetProbes();

  const [reloadSims] = useReloadArrays({
    onSuccess: () => {
      const message = 'SIMs reloaded';

      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
    },
    onError: () => {
      const errMessage = 'Failed reload SIMs';
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
    }
  });

  const timeout = 15;
  useEffect(() => {
    getMappings();
    getSimCards();
    const timerId = setInterval(() => {
      getSimCards();
      getMappings();
    }, timeout * 1000);
    return () => clearInterval(timerId);
  }, []);

  const extendedSims = simCards
    ? simCards.map((sim:Sim) => {
      // Find probe sim card is mapped to
      const mapping = mappings
        ? mappings.find((m:SimMapping) => (m && (m.mapped && m.mapped.iccid === sim.iccid))
          || (m.using && m.using.iccid === sim.iccid))
        : null;
      const mappedInProbe = mapping ? probes?.find((p:Probe) => p.probe_name === mapping.channel.path.split('.')[0])?.probe_alias : '';
      // set status active, mapped, using
      let mappingStatus = 'Available';
      mappings?.find((m:SimMapping) => {
        if (m && m.using && m.using.iccid === sim.iccid) {
          mappingStatus = 'In Use';
          return (m && m.using && m.using.iccid === sim.iccid);
        }
        if (m && m.mapped && m.mapped.iccid === sim.iccid) {
          mappingStatus = 'Inserting';
          return (m && m.mapped && m.mapped.iccid === sim.iccid);
        }
        mappingStatus = 'Available';
        return null;
      });
      return ({
        ...sim,
        mapping: mappedInProbe,
        status: mappingStatus,
        network: [sim?.lab && 'lab', sim?.live && 'live']
      });
    })
    : null;

  const [editSimShowModal, setEditSimShowModal] = useState(false);
  const [selectedSim, setSelectedSim] = useState<IGroup | undefined>();

  const columns = useMemo(() => [
    {
      Header: 'Status',
      accessor: 'status',
      disableFilters: true,
      Cell: ({ value }: any) => {
        let status = '';
        switch (value) {
          case 'Available':
            status = 'text-success';
            break;
          case 'Inserting':
            status = 'text-warning';
            break;
          case 'In Use':
            status = 'text-info';
            break;
          default:
            status = '';
            break;
        }
        return (
          <>
            <i className={`icon icon-record icon-solid ${status}`} />
            <span className="ml-2">{value}</span>
          </>
        );
      },
    },
    {
      Header: 'Assigned to',
      accessor: 'mapping',
      isSortable: true,
    },
    ...tableColumns,
  ], [tableColumns]);

  const openModal = (value: any) => {
    console.log('group data: ', value);
    setSelectedSim(value);
    setEditSimShowModal(true);
  };

  const tableRowActions = useMemo(() => ({
    content: 'Actions',
    items: [
      {
        content: 'Edit',
        key: 'edit',
        onClick: (row: any) => openModal(row),
        variant: 'outline-primary'
      },
    ],
  }), []);

  const tableData = extendedSims?.filter((sim: any) => sim.position && (sim.type === 'mappable' || sim.type === 'static'));

  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        actionsPosition="left"
        columns={columns}
        data={tableData ?? []}
        disablePagination
        hideRefreshButton={!isAdmin && username !== 'ivancic' && username !== 'loeffler'}
        initialState={userTableSettings?.initialState}
        onStateChange={userTableSettings.updateTableSettings}
        RefreshButtonProps={{ content: 'Reload SIMs', onClick: () => reloadSims() }}
        rowActions={tableRowActions}
        showSettingsMenu
        skipReset={false}
        stickyActions
        stickyHeader
      />
      )}
      {editSimShowModal
      && (
        <EditSimModal
          onHide={() => setEditSimShowModal(false)}
          show={editSimShowModal}
          simData={selectedSim}
          getSimCards={getSimCards}
        />
      )}
    </>
  );
};

export default SimCardsTable;
