import React, { useCallback, useReducer } from 'react';
import axios from 'axios';
// @ts-ignore
import jwt_decode from 'jwt-decode';
import ApiContext from './apiContext';
import ApiReducer from './ApiReducer';
import {
  SET_LOADING,
  LOAD_USER_DATA,
  GET_USERS,
  GET_GROUPS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  DEV_LOGIN,
  CLEAR_ERRORS,
  RESPONSE_ERROR,
} from './types';
import setAuthToken from '../utils/setAuthToken';
import { ILoginUser, IUser } from './interfaces';

const apiServerUrl = process.env.REACT_APP_BE_URL;
// state for development without API
const defaultAdminState = {
  sims: [
    {
      iccid: '89490200000815295444',
      msisdn: '+42777777777',
      imsi: null,
      tariff: null,
      wnw: null,
      op_wnw: null,
      psp: null,
      wholesale_id: null,
      itg_id: null,
      pin: 1234,
      name: null,
      origin: null,
      prepaid: null,
      type: null,
      position: 'PRAGUE',
      modified: null,
    },
    {
      iccid: '89490200000985373591',
      msisdn: '+49198765432',
      imsi: null,
      tariff: null,
      wnw: null,
      op_wnw: null,
      psp: null,
      wholesale_id: null,
      itg_id: null,
      pin: null,
      name: null,
      origin: null,
      prepaid: null,
      type: null,
      position: 'BONN',
      modified: null,
    },
    {
      iccid: '89490200001568468915',
      msisdn: '+38573425167',
      imsi: null,
      tariff: null,
      wnw: null,
      op_wnw: null,
      psp: null,
      wholesale_id: null,
      itg_id: null,
      pin: null,
      name: null,
      origin: null,
      prepaid: null,
      type: null,
      position: 'ZAGREB',
      modified: null,
    },
    {
      iccid: '89490200001568468907',
      msisdn: '+420999987654',
      imsi: null,
      tariff: null,
      wnw: null,
      op_wnw: null,
      psp: null,
      wholesale_id: null,
      itg_id: null,
      pin: null,
      name: null,
      origin: null,
      prepaid: null,
      type: null,
      position: null,
      modified: null,
    },
    {
      iccid: '89490200001568468873',
      msisdn: '+49188997766',
      imsi: null,
      tariff: null,
      wnw: null,
      op_wnw: null,
      psp: null,
      wholesale_id: null,
      itg_id: null,
      pin: null,
      name: null,
      origin: null,
      prepaid: null,
      type: null,
      position: null,
      modified: null,
    },
  ],
  simArrays: [
    {
      name: 'Test',
      enabled: 'true',
      available: 'false',
      internalIPAddress: '192.168.124.4',
      internalIPPort: '51503',
      externalIPAddress: '192.168.124.4',
      externalIPPort: '51503',
      maxNumberOfBoards: '1',
      description: 'The testarray located in Rack 1',
    },
    {
      name: 'testttt',
      enabled: 'true',
      available: 'false',
      internalIPAddress: '1.2.3.4',
      internalIPPort: '1234',
      externalIPAddress: '2.3.4.5',
      externalIPPort: '2345',
      maxNumberOfBoards: '6',
      description: '',
    },
    {
      name: 'test-array',
      enabled: 'true',
      available: 'false',
      internalIPAddress: '1.2.3.4',
      internalIPPort: '1234',
      externalIPAddress: '1.2.3.4',
      externalIPPort: '123',
      maxNumberOfBoards: '1',
      description: '',
    },
  ],
  groups: [
    { gid: 1, groupname: 'fakeGroup1' },
    { gid: 2, groupname: 'fakeGroup2' },
  ],
  claims: [
    {
      gid: 1,
      sims: ['4242', '1337'],
      boards: [],
      pools: [],
      probes: ['RAprobe26', 'RRprobeDEV01', 'RRprobeDEV02', 'RRprobeDEV03', 'RRprobeDEV10 ', 'test'],
    },
  ],
  users: [
    { uid: 1, username: 'John Doe', cn: 'nerds' },
    { uid: 2, username: 'Jane Doe', cn: 'nerds' },
    { uid: 3, username: 'Nikola Tesla', cn: 'superNerds' },
  ],
  user: { uid: 100, username: 'admin', cn: 'admins' },
};

const ApiState = (props: any) => {
  const initState = {
    sims: [],
    simArrays: [],
    // todo set to [] when BE ready
    probes: [{ id: 100 }, { id: 105 }, { id: 106 }, { id: 107 }],
    reservations: [],
    users: [],
    groups: [],
    claims: [],
    user: { username: '' },
    isAuthenticated: false,
    loading: false,
    error: null,
  };
  // set headers for JSON format
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const [state, dispatch] = useReducer(ApiReducer, initState);

  const handleErr = (err: any) => dispatch({
    type: RESPONSE_ERROR,
    payload: err.response ? err.response.data.message : err.message,
  });
  // set loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  // load current user's data
  const loadUserData = async (user: IUser) => {
    await dispatch({
      type: LOAD_USER_DATA,
      payload: user,
    });
  };

  // user login
  const login = useCallback(async ({ username, password }: ILoginUser) => {
    setLoading();
    // todo dev login to be removed when api login is safe
    if (username === 'admin') {
      dispatch({
        type: DEV_LOGIN,
        payload: defaultAdminState,
      });
      return;
    }

    try {
      const res = await axios.post(
        `${apiServerUrl}users/api/authenticate`,
        { username, password },
        config,
      );

      const token = res.headers && res.headers.authorization;
      localStorage.setItem('token', token);
      const decoded: any = jwt_decode(token);
      // todo cn will be refactored to decide access to different segments of Tessa
      const newUser = { username: decoded.aud, uid: decoded.uid, cn: decoded.groups[0] };
      await loadUserData(newUser);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: token,
      });
    } catch (err: any) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response ? err.response.data.message : err.message,
      });
    }
  }, [config]);

  // logout
  const logout = () => dispatch({ type: LOGOUT });

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  // get user list
  const getUsers = useCallback(async () => {
    // TODO remove when dev login is removed
    if (state.user.username === 'admin') {
      return;
    }
    setLoading();
    setAuthToken(localStorage.token);

    try {
      const res = await axios.get(
        `${apiServerUrl}users/api/users`,
      );

      dispatch({
        type: GET_USERS,
        payload: res && res.data,
      });
    } catch (err) {
      handleErr(err);
    }
  }, [state.user.username]);

  // get user list
  const loadGroups = useCallback(async () => {
    // TODO remove when dev login is removed
    if (state.user.username === 'admin') {
      return;
    }
    setLoading();
    setAuthToken(localStorage.token);

    try {
      const res = await axios.get(
        `${apiServerUrl}users/api/groups`,
      );

      dispatch({
        type: GET_GROUPS,
        payload: res && res.data,
      });
    } catch (err) {
      handleErr(err);
    }
  }, [state.user.username]);

  const { children } = props;

  return (
    <ApiContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        users: state.users,
        error: state.error,
        login,
        logout,
        getUsers,
        loadGroups,
        setLoading,
        clearErrors,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiState;
