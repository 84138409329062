import React, { ReactNode } from 'react';

import clsx from 'clsx';

// import { ReactComponent as IconMoveDown } from 'assets/icons/move-down_solid.svg';
// import { ReactComponent as IconMoveUp } from 'assets/icons/move-up_solid.svg';

// todo: how to set default values for optional prop
/* eslint-disable react/require-default-props */
type TableSortProps = {
  children: ReactNode;
  isSorted?: boolean;
  isSortedDesc?: boolean;
};

const TableSort = ({
  children,
  isSorted,
  isSortedDesc,
  ...sortProps
}: TableSortProps) => (
  <div className="flex-grow-1">
    <div
      className="text-button overflow-hidden position-relative bu-pl-1_5"
      role="button"
      tabIndex={0}
      aria-label="Sort Column"
      {...sortProps}
    >
      <div className="d-flex flex-column position-absolute" style={{ left: 0, top: 0 }}>
        <i className={clsx('icon icon-navigation-up icon-small icon-solid', {
          'text-light': isSorted && isSortedDesc,
        })}
        />
        <i className={clsx('icon icon-navigation-down icon-small icon-solid', {
          'text-light': isSorted && !isSortedDesc,
        })}
        />
      </div>
      {children}
    </div>
  </div>
);

export default TableSort;
