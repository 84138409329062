import React, { useState } from 'react';

import PageTitle from 'components/PageTitle';
import Tabs from 'components/Tabs';

import MaintenanceOverview from './MaintenanceOverview/MaintenanceOverview';
import Probes from './Probes';
import useGetProbes from '../../../hooks/services/probe/useGetProbes';

const Maintenance = () => {
  const [activeTab, setActiveTab] = useState('probes');
  const { data: probes, refetch: getProbes } = useGetProbes();

  return (
    <>
      <PageTitle>Maintenance</PageTitle>
      <Tabs
        activeTab={activeTab}
        block
        onChange={(value) => setActiveTab(value)}
        tabs={[{
          content: 'Planning',
          value: 'probes',
        }, {
          content: 'Scheduled Maintenance',
          value: 'scheduled',
        }]}
      />
      <div className="border border-top-0 rounded-bottom bu-px-3 bu-py-2">
        {activeTab === 'probes'
          && (
          <Probes
            probes={probes}
            getProbes={getProbes}
          />
          )}
        {activeTab === 'scheduled'
          && (
            <MaintenanceOverview probes={probes} />
          )}
      </div>
    </>
  );
};

export default Maintenance;
