import React, { useMemo } from 'react';

import Table from 'components/Table';

import useUserTableSettings from 'hooks/useUserTableSettings';

import ProbeStatus from '../../ProbeStatus';

interface IProps {
  probes: any[];
}
const MaintenanceOverview = (props: IProps) => {
  const { probes } = props;

  const userTableSettings = useUserTableSettings('maintainance');

  const columns = useMemo(() => [
    {
      Header: 'Probe name',
      accessor: 'probe_name',
      isSortable: true,
    },
    {
      Header: 'Probe alias',
      accessor: 'probe_alias',
      isSortable: true,
    },
    {
      Header: 'Status',
      accessor: 'status',
      isSortable: true,
    },
    {
      Header: 'Location',
      accessor: 'country_iso',
      isSortable: true,
    },
    {
      Header: 'OS',
      accessor: 'OS',
      isSortable: true,
    },
    // {
    //   Header: 'Devices',
    //   accessor: 'devices',
    //   disableFilters: true,
    //   Cell: ({ value }: any) => value?.length || 0,
    // },
  ], []);

  const scheduled = useMemo(() => (
    probes?.filter((probe: any) => probe.status === ProbeStatus.DEFECTIVE)
  ), [probes]);

  return (
    <>
      <Table
        columns={columns}
        data={scheduled}
        initialState={userTableSettings?.initialState}
        onStateChange={userTableSettings.updateTableSettings}
        skipReset={false}
      />
    </>
  );
};

export default MaintenanceOverview;
