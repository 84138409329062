import _ from 'lodash';

function multiValueFilter(rows: any, id: any, filterValues: any) {
  if (filterValues && filterValues.length > 0) {
    return _.filter(rows, (row) => filterValues.indexOf(row.values[id]) > -1);
  }
  return rows;
}

multiValueFilter.autoRemove = (val: any) => !val;

export default multiValueFilter;
