// temporary mockup data, will be replaced by DB connection in future

export const types = {
  keyword: ['Given', 'When', 'Then', 'And', 'But'],
  party: ['A-party', 'B-party', 'C-party', 'D-party', 'Festnetz', 'X-party'],
  network: ['5G', 'LTE', '3G', '2G'],
  outgoing_call_state: ['established', 'idle', 'ring', 'wait'],
  selection: ['enable', 'disable'],
  hold: ['hold', 'terminate'],
  on_off: ['on', 'off'],
  timeout_sec: ['1', '2', '3', '4', '5', '10', '15', '20', '30', '45', '60'],
};

export const basicCallSteps = [
  '{party} calls {party}',
  '{party} calls {string}',
  '{party} answers',
  'after {int} seconds {party} hangs up',
  '{party} takes a screenshot',
  'wake up {party}',
  '{party} gives his name',
  '{party} turns Mobile data {status}',
  '{party} insert MMI-Code {string}',
  '{party} TURN {status} Caller Waiting',
  '{party} TURN {status} Caller Id',
  '{party} sends message to {party}',
  '{party} change network {network}',
  '{party} verifies that the message arrived',
  '{party} testing the download upload',
];

// export const types = {
//   Kenntnis: ['unbekannten', 'bekannten'],
//   Hymenoptera: ['Biene', 'Vespe', 'Hornisse'],
//   kudy: ['Moravu', 'McDonald', 'přejezd'],
//   kdo: ['strašidlo', 'prase', 'Babiš'],
//   hold: ['hold', 'terminate'],
//   on_off: ['on', 'off'],
//   timeout_sec: ['1', '2', '3', '4', '5', '10', '15', '20', '30', '45', '60'],
// };
//
// export const basicCallSteps = [
//   'In einem {Kenntnis} Land',
//   'Vor gar nicht allzu langer Zeit',
//   'War eine {Hymenoptera} sehr bekannt',
//   'Von der sprach alles weit und breit',
//
//   'diese Biene, die ich meine, nennt sich Maja',
//   'Kleine, freche, schlaue Biene Maja',
//   'Maja fliegt durch ihre Welt',
//   'Zeigt uns das, was ihr gefällt',
//   'Wir treffen heute uns‘re Freundin Biene Maja',
//   'Diese kleine, freche Biene Maja',
//   'Maja, alle lieben Maja',
//   'Maja Maja',
//   'Maja Maja',
//   'Maja, erzähle uns von Dir',
// ];

// export const types = {
//   keyword: ['Given', 'When', 'Then', 'And', 'But'],
//   vehicle: ['Škodou 100', 'Oktávií', 'pěšky'],
//   kam: ['Oravu', 'Moravu', 'Kanáry'],
//   kudy: ['Moravu', 'McDonald', 'přejezd'],
//   kdo: ['strašidlo', 'prase', 'Babiš'],
//   hold: ['hold', 'terminate'],
//   on_off: ['on', 'off'],
//   timeout_sec: ['1', '2', '3', '4', '5', '10', '15', '20', '30', '45', '60'],
// };
//
// export const basicCallSteps = [
//   '{Jedu} takhle tábořit {vehicle} na {kam}',
//   'Spěchám, proto riskuji, projíždím přes {kudy}',
//   'Řádí tam to {kdo}, vystupuje z bažin,',
//   'žere hlavně Pražáky, jmenuje se Jožin!',
//   'Jožin z bažin močálem se plíží,',
//   'Jožin z bažin k vesnici se blíží,',
//   'Jožin z bažin už si zuby brousí,',
//   'Jožin z bažin kouše, saje, rdousí.',
//   'Na Jožina z bažin, koho by to napadlo,',
//   'platí jen a pouze práškovací letadlo.',
//   'Projížděl jsem dědinou cestou na Vizovice,',
//   'přivítal mě předseda, řek mi u Slivovice,',
//   'živého či mrtvého Jožina kdo přivede,',
//   'tomu já dám za ženu dcéru a půl JZD.',
//   'Jožin z bažin močálem se plíží,',
//   'Jožin z bažin k vesnici se blíží,',
//   'Jožin z bažin už si zuby brousí,',
//   'Jožin z bažin kouše, saje, rdousí.',
//   'Na Jožina z bažin,  koho by to napadlo,',
//   'platí jen a pouze práškovací letadlo',
//   'Říkám: Dej mi, předsedo, letadlo a prášek,',
//   'Jožina ti přivedu, nevidím v tom háček.',
//   'Předseda mi vyhověl, ráno jsem se vznesl,',
//   'na Jožina z letadla prášek pěkně klesl.',
//   'Jožin z bažin už je celý bílý,',
//   'Jožin z bažin z močálu ven pílí,',
//   'Jožin z bažin, dostal se na kámen,',
//   'Jožin z bažin, tady je s ním ámen!',
//   'Jožina jsem dohnal, už ho držím, johoho,',
//   'dobré každé love, prodám já ho do ZOO.',
//
// ];

export const stepDefinitions = [
  {
    name: 'Basic call steps',
    data: [
      '{party} calls {party}',
      '{party} calls {string}',
      '{party} answers',
      'after {int} seconds {party} hangs up',
      '{party} takes a screenshot',
      'wake up {party}',
      '{party} gives his name',
      '{party} turns Mobile data {status}',
      '{party} insert MMI-Code {string}',
      '{party} TURN {status} Caller Waiting',
      '{party} TURN {status} Caller Id',
      '{party} sends message to {party}',
      '{party} change network {network}',
      '{party} verifies that the message arrived',
      '{party} testing the download upload',
    ],
  },
  {
    name: 'Device steps',
    data: [
      'Given a {string} mobile device as {string}',
      'Given {int} mobile devices',
      'Given the following parties:',
      '{party} has all apps closed',
      'after {timeout}',
      '{party} turns airplane mode {status}',
      'wait for {timeout}',
      '{party} browse to {url}',
      'stop every tracing tasks upload to QC',
      '{party} opens active pdp context',
      '{party} get call information',
      '{party} opens youtube video {string}',
      '{party} check the VoLTE setting',
      '{party} dumps network traces',
    ],
  },
  {
    name: 'Verify steps',
    data: [
      '{party} verify call is (in ){string}',
      '{party} verify CLIR status {status} of {party}',
      '{party} verify Caller Id {status}',
      '{party} verify screentext {string}',
      '{party} verify MMI-Code {string}, {string}',
      '{party} verfiy call forwarding number MMI-Code {string}',
      '{party} verifies that it is camped on {string}',
      '{party} verifies that WIFI is connected',
      '{party} verify Caller Id number of {party}',
    ],
  },
  {
    name: 'Merge steps',
    data: [
      '{party} calls in national format {party}',
      '{party} merge calls to conference',
      '{party} cancel the call from {party}',
      '{party} cancel the call',
    ],
  },
  {
    name: 'Select steps',
    data: [
      '{party} switch RAT to {string}',
      '{party} set the wlan call {status}',
      '{party} set the VoLTE call {status}',
      '{party} connect to Wlan ssid {string} password {string}',
      '{party} set wlan {status}',
      '{party} verify the current call is in {string} network for {int} seconds long',
      'verify the current call from {party} is in {string} network from {party} in {string} network for {int}',
    ],
  },
  {
    name: 'Automation steps',
    data: [
      'Given {party} search in web for {string}',
      '{party} open the address book',
      '{party} open the phone settings',
      '{party} open the information settings of given app {string}',
      '{party} open the developer settings ',
      '{party} tap on text {string} ',
      'on {party} the text {string} should visible',
      'Given {party} open the network settings',
      '{party} open the location settings',
      '{party} open the apps settings',
      '{party} open the airplane mode settings',
      '{party} open the APN settings',
      'Given { party} open the WIFI setting ',
      '{party} record the screen ',
      '{party} find in the addressbook of given number {string}',
    ],
  },
  {
    name: 'Call Barring Forwarding steps',
    data: [
      '{party} open call Barring setting',
      '{party} expect to see following',
      '{party} displays following text',
      '{party}  {status} CF to {party} Option: {string} after {int} seconds',
      '{party}  {status} CF to {party} Option: {string}',
      '{party}  {string} CF to {int} Option: {string}',
    ],
  },
];
