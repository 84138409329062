import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

import useUser from 'hooks/useUser';

type TableSettings = {
  id: string;
  initialState: any;
  version: string;
};

type UserSettings = {
  tableSettings?: [TableSettings];
  expertTesting?: {
    showAllJobs: boolean;
  }
};

type UpdateUserSettingsRequestData = UserSettings;

export default function useUpdateUserSettings(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  const user = useUser();

  const queryCache = useQueryCache();

  return useMutation((settings: UpdateUserSettingsRequestData) => axios.post(
    `/users/api/users/${user?.uidNumber}/settings`,
    settings,
  ).then(({ data }: any) => data), {
    onSuccess: () => {
      queryCache.invalidateQueries('userSettings');
    },
    ...options });
}
