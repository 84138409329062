import {
  useCallback, useEffect, useState,
} from 'react';

import moment from 'moment';

const currentVisibleTime = () => {
  const visibleTimeStart = moment()
    .startOf('hour')
    .add(-1, 'hour')
    .valueOf();

  const visibleTimeEnd = visibleTimeStart + 23 * 60 * 60 * 1000;

  return [visibleTimeStart, visibleTimeEnd];
};

const useTimeline = () => {
  const [fullscreen, setFullscreen] = useState(false);
  const [visibleTime, setVisibleTime] = useState(currentVisibleTime());

  useEffect(() => {
    setVisibleTime(currentVisibleTime());
  }, [fullscreen]);

  const animateScroll = useCallback((invert: Boolean) => {
    setVisibleTime((prevState) => {
      let zoom = prevState[1] - prevState[0];

      if (invert) {
        zoom *= -1;
      }

      return [
        prevState[0] + zoom,
        prevState[1] + zoom,
      ];
    });
  }, []);

  const zoom = useCallback((out: Boolean) => {
    setVisibleTime((prevState) => {
      // get previous values
      const preStart = prevState[0];
      const preEnd = prevState[1];
      // count zoom level as a 1/4 of currently visible calendar width
      // ensure repeating zoom in/out will lead into same view - out 2, in 4
      let zoomLevel = Math.floor((preEnd - preStart) / (out ? 2 : 4));

      if (out) {
        // set zoom direction and avoid infinite zoom
        // 1year -> view max approx. 4years
        zoomLevel = zoomLevel >= 365 * 24 * 60 * 60 * 1000 ? 0 : -zoomLevel;
      } else {
        // set zoom direction and avoid infinite zoom
        // 15min -> view min approx. 45min
        zoomLevel = zoomLevel <= 15 * 60 * 1000 ? 0 : zoomLevel;
      }
      // set state with new values
      return [
        preStart + zoomLevel,
        preEnd - zoomLevel,
      ];
    });
  }, []);

  return ({
    animateScroll,
    fullscreen,
    setFullscreen,
    setVisibleTime,
    visibleTime,
    zoom,
  });
};

export default useTimeline;
