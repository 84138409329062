import React from 'react';

export const LoginRedirectClose = () => {
  window?.opener?.postMessage('loginSuccess', '*');
  window.close();

  return (
    <>
      <h1>Login Successful</h1>
      <p>Please close this tab to continue.</p>
    </>
  );
};

export default LoginRedirectClose;
