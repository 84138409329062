import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type AddLocationRequestData = {
  'country_name': string;
  'country_iso': string;
};

export default function useAddProbeLocation(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ country_iso, country_name }: AddLocationRequestData) => axios.post(
    '/cms/api/locations',
    { country_iso, country_name },
  ).then(({ data }) => data), options);
}
