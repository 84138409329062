import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type EditChannelValueData = {
  attName: string;
  name: string;
  level: number | number[] | string;
};

export default function useEditChannelValue(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken({ ...options, baseURL: process.env.REACT_APP_ATTENUATORS });

  return useMutation(({ attName, name, level }: EditChannelValueData) => axios.patch(
    `/attenuator/api/attenuators/${attName}/channels/${name}/set/${level}`
  ).then(({ data }) => data), options);
}
