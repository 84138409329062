import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type RebootComponentRequestData = {
  uuid: string;
};

export default function useRebootComponent(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ uuid }: RebootComponentRequestData) => axios.patch(
    `/pcs/api/components/${uuid}/reboot`
  ).then(({ data }) => data), options);
}
