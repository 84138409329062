import React, { useCallback } from 'react';
// import * as util from 'util';

import moment from 'moment';

import ReactCalendarTimeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from 'react-calendar-timeline';

import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';

import 'react-calendar-timeline/lib/Timeline.css';
import './TimelineCustom.scss';

import TimelineItem from './TimelineItem';

import {
  keys,
  headerLabelFormats,
  subHeaderLabelFormats,
  timeSteps,
} from './timelineKeys';

const Timeline = ({
  title,
  ...props
}: any) => {
  // todo solve this
  // eslint-disable-next-line no-shadow,@typescript-eslint/no-shadow
  const itemRenderer = useCallback((props: any) => <TimelineItem {...props} />, []);

  return (
    <>
      <ReactCalendarTimeline
        defaultTimeStart={moment().add(-2, 'day')} // {moment(props.visibleTimeStart)}
        defaultTimeEnd={moment().add(1, 'day')} // {moment(props.visibleTimeEnd)}
        lineHeight={47}
        itemRenderer={itemRenderer}
        keys={keys}
        headerLabelFormats={headerLabelFormats}
        minZoom={7 * 24 * 60 * 60 * 1000}
        resizeDetector={containerResizeDetector}
        subHeaderLabelFormats={subHeaderLabelFormats}
        timeSteps={timeSteps}
        {...props}
      >
        <TimelineHeaders>
          <SidebarHeader>
            {({ getRootProps }: any) => (
              <div className="d-flex align-items-center bu-mt-1 bu-mb-1 bu-pl-1 text-white" {...getRootProps()}>
                <div className="flex-grow-1" key={`sidebar-${title}`}>
                  {title}
                </div>
              </div>
            )}
          </SidebarHeader>
          <DateHeader unit="primaryHeader" />
          <DateHeader />
        </TimelineHeaders>
        <TodayMarker
          interval={2000}
        >
          {({ styles }: any): any => (
            <div
              style={{
                ...styles,
                backgroundColor: 'deeppink',
                width: '3px',
              }}
            />
          )}
        </TodayMarker>
      </ReactCalendarTimeline>
      <small>
        Use SHIFT + mousewheel to move timeline left and right
        <br />
        Use ALT + mousewheel to zoom in and out
      </small>
    </>
  );
};

export default Timeline;
