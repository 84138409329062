import React from 'react';
// import PropTypes from 'prop-types';

import { Form, InputGroup } from 'react-bootstrap';

const TableSearchFormField = (props) => (
  <InputGroup>
    <InputGroup.Prepend>
      <InputGroup.Text>Search</InputGroup.Text>
    </InputGroup.Prepend>
    <Form.Control
      {...props}
    />
  </InputGroup>

);

// todo: why it is reguired?
// TableSearchFormField.propTypes = {
//  setGlobalFilter: PropTypes.func.isRequired,
// };

export default TableSearchFormField;
