import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import moment from 'moment';

export default function useGetReservations() {
  const axios = useAxiosWithToken();

  return useQuery('reservations', () => axios.get(
    `/reservations/api/reservations?from=${moment().add(-1, 'day').toISOString()}&until=${moment().add(7, 'day').toISOString()}&all=true`,
    // reservations returns 404 when empty res. list, disabling validation could help
    // { validateStatus: () => false },
  ).then(({ data }) => data.slice().map(
    (res: any, index: number) => ({ ...res, index })
  )), { staleTime: 1000 * 60 * 5 });
}
