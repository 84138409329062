/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faCog, faBroadcastTower, faEraser, faSync, faTools } from '@fortawesome/free-solid-svg-icons';
import { Col, Container, Row } from 'react-bootstrap';
import { Probe } from 'hooks/services/probe/interfaces';
import { useApiPolling, SimMapping } from 'hooks/apiPolling/apiPollingContext';
import ModalConfirm from 'components/confirmModal/ModalConfirm';
import CountDown from 'components/CountDown';

const Buttons = ({
  deadline,
  onTimeOut,
  probe,
} : {
  deadline: number | null,
  onTimeOut?: () => any,
  probe: Probe | null,
}) => {
  const { mappings } = useApiPolling();
  const [mapping, setMapping] = useState<SimMapping | null>(null);
  const [confirmModal, setConfirmModal] = useState<any>(undefined);

  useEffect(() => {
    if (mappings && probe) {
      setMapping(mappings.find(
        (value: SimMapping) => value.channel.path.includes(probe.probe_name)
      ) || null);
    }
  }, [mappings]);

  return (
    <Container className="border rounded bu-mb-1 bu-pt-2 bu-pb-0">
      <Row>
        <Col md={2} className="bu-pl-0">
          <img src="/img/devices/iphone13.png" alt="img" height={100} width={100} />
        </Col>
        <Col>
          <Row>
            <span style={{ fontWeight: 'bold' }}>
              {`${probe?.location} - ${probe?.probe_alias}`}
            </span>
          </Row>
          <Row>
            <br />
          </Row>
          <Row>
            {(probe?.devices !== undefined && probe?.devices[0].name) ? probe?.devices[0].name : 'Unknown' }
          </Row>
          <Row>
            <span style={{ color: (probe?.devices !== undefined && probe?.devices[0].status && probe?.devices[0].stf_status) ? 'green' : 'red' }}>
              {(probe?.devices !== undefined && probe?.devices[0].status && probe?.devices[0].stf_status) ? 'Online' : 'Offline' }
            </span>
          </Row>
        </Col>
        <Col>
          <Row>
            <span style={{ fontWeight: 'bold', margin: 'auto' }}>
              Reservation Time Left:
            </span>
          </Row>
          <Row>
            <span style={{ margin: 'auto' }}>
              <CountDown deadline={deadline} onTimeOut={onTimeOut} formating />
            </span>
          </Row>
        </Col>
      </Row>
      <Row className="bu-mt-1" style={{ fontSize: '14px', textAlign: 'center' }}>
        {/* <Col style={{ display: 'flex', padding: 0 }}>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => setConfirmModal({
                title: 'Restart Device',
                onOk: () => {
                  console.log('restart');
                  setConfirmModal(undefined);
                },
                body: <>Do you want to restart the device now?</>
              })}
            >
              <FontAwesomeIcon
                icon={faSync}
              />
            </button>
            <span>Restart device</span>
          </div>
        </Col>
        <Col style={{ display: 'flex', justifyContent: 'end', padding: 0 }}>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => console.log('settings')}
            >
              <FontAwesomeIcon
                icon={faCog}
              />
            </button>
            <span>Settings</span>
          </div>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => console.log('radio settings')}
            >
              <FontAwesomeIcon
                icon={faBroadcastTower}
              />
            </button>
            <span>Radio settings</span>
          </div>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => console.log('flight mode')}
            >
              <FontAwesomeIcon
                icon={faPlane}
              />
            </button>
            <span>Flight mode</span>
          </div>
        </Col> */}
      </Row>
      {/* <ModalConfirm
        title={confirmModal?.title}
        show={confirmModal}
        onClose={() => setConfirmModal(undefined)}
        onCancel={() => setConfirmModal(undefined)}
        onOk={() => confirmModal.onOk()}
        body={confirmModal?.body}
      /> */}
    </Container>
  );
};

export default Buttons;
