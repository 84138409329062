import React from 'react';

import PageTitle from 'components/PageTitle';
import ArrayTable from './ArrayTable';

const SimArrays = () => (
  <>
    <PageTitle>SIM Arrays</PageTitle>
    <div className="border rounded-bottom bu-px-3 bu-py-2">
      <ArrayTable />
    </div>
  </>
);

export default SimArrays;
