import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export type MailData = {
  to?: string,
  from: string,
  subject: string,
  text: string,
  html: string,
};

type SendMailRequestData = {
  mail: MailData
};

export default function useSendMail(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken({ baseURL: '' });

  return useMutation(({ mail }: SendMailRequestData) => axios.post(
    '/mail/mail',
    mail,
  ).then(({ data }) => data), options);
}
