import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeleteMappingRequestData = {
  probeName: string;
};

export default function useDeleteMapping(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ probeName }: DeleteMappingRequestData) => axios.delete(
    `/sim-manager/api/mappings/probe/${probeName}`,
  ).then(({ data }) => data), options);
}
