/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './styles.scss';
import { ProgressBar, Step } from 'react-step-progress-bar';
import useConfirm from 'hooks/useConfirm';
import _ from 'lodash';
import moment from 'moment';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useGetProjects from 'hooks/services/git/useGetProjects';
import useUser from 'hooks/useUser';
import { Sim } from 'hooks/services/simManagement/interfaces';
import PageTitle from '../../../components/PageTitle';
import CampaignName from './CampaignName';
import RepositorySelection, { Repository } from './RepositorySelection';
import TestParameters from './TestParameters';
import Schedule, { ScheduleProps, Frequency, Repetition, Run } from './Schedule';
import Finish from './Finish';

const steps = [
  'Campaign Name',
  'Test Set Creation',
  'Test Parameters',
  'Schedule',
  'Summary & Finish',
];

interface IActiveScreen {
  activeScreen: string;
  setActiveScreen(value: any): void;
  setActiveTab(value: any): void;
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
}

const TestCampaign = ({
  activeScreen, setActiveScreen, setActiveTab, setMessage, setErrMessage
}: IActiveScreen) => {
  const [campaignName, setCampaignName] = useState<string>('');
  const [selectedOsix, setSelectedOsix] = useState<boolean>(false);
  const [selectedNetwork, setSelectedNetwork] = useState<string>('Live');
  const [selectedSubscribers, setSelectedSubscribers] = useState<string[]>([]);
  const [selectedTcpDump, setSelectedTcpDump] = useState<boolean>(false);
  const [selectedRecordScreen, setSelectedRecordScreen] = useState<boolean>(false);
  const [selectedRepository, setSelectedRepository] = useState<Repository | undefined>(undefined);
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>(undefined);
  const [selectedTestCases, setSelectedTestCases] = useState<any[] | undefined>(undefined);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const [step, setStep] = useState(1);
  const { data: projectsAll, isLoading: isLoadingProjects } = useGetProjects(['tessa.test', 'localhost'].includes(window.location.hostname) ? 'gherkin_test' : 'gherkin');
  const [projects, setProjects] = useState<any[] | undefined>(undefined);
  const { isAdmin, preferred_username: username } = useUser();
  const [scheduleValues, setScheduleValues] = useState<ScheduleProps>({
    frequency: Frequency.Once,
    run: Run.RunNow,
    selectedRepetition: Repetition.Hourly,
    repeatEvery: 1,
    repeatDays: [],
    dayOfMonth: 1,
    minuteOfHour: 0,
    startTime: moment().format('YYYY-MM-DD HH:mm')
  });
  const confirm = useConfirm();
  const location = useLocation();

  useEffect(() => {
    if (!isLoadingProjects) {
      if (isAdmin) {
        setProjects(projectsAll);
      } else {
        const filteredRepos = projectsAll.filter((repo:any) => !_.isEmpty(
          repo.members
            .find((member:any) => member.username === username && member.accessValue >= 30)
        ));
        setProjects(filteredRepos);
      }
    }
  }, [isAdmin, isLoadingProjects]);

  const { data: subscribers } = useGetSubscribers();

  const subscriberTags = subscribers
    ?.filter((sub: Sim) => sub.position)
    ?.reduce((accumulator:any, currentValue:any) => accumulator.concat(currentValue.tags), []);

  const subscriberTagsUnique = _.sortBy(_.uniqWith(subscriberTags), (t:any) => t?.toLowerCase());

  const nextStep = () => {
    if (step < 5) {
      setStep(step + 1);
      setShouldResetForm(false);
    }
  };
  const previousStep = () => {
    if (step > 1) {
      setStep(step - 1);
      setShouldResetForm(false);
    }
  };

  const confirmCloseWizard = (closeWizard: any) => {
    confirm({
      // eslint-disable-next-line max-len
      body: <>Do you want to cancel the test campaign creation?</>,
      onOk: closeWizard,
      title: 'Cancel campaign creation?',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  useEffect(() => {
    setShouldResetForm(true);
    setSelectedTestCases(undefined);
  }, [selectedRepository, selectedBranch]);

  const renderSwitch = () => {
    switch (step) {
      case 1:
        return (
          <CampaignName
            previousStep={previousStep}
            nextStep={nextStep}
            campaignName={campaignName}
            setCampaignName={setCampaignName}
          />
        );
      case 2:
        return (
          ((!isLoadingProjects && projects) ? (
            <RepositorySelection
              previousStep={previousStep}
              nextStep={nextStep}
              shouldResetForm={shouldResetForm}
              setShouldResetForm={setShouldResetForm}
              projects={projects}
              selectedRepository={selectedRepository}
              setSelectedRepository={setSelectedRepository}
              selectedBranch={selectedBranch}
              setSelectedBranch={setSelectedBranch}
              setSelectedTestCases={setSelectedTestCases}
              selectedTestCases={selectedTestCases}
            />
          )
            : null
          )
        );
      case 3:
        return (
          <TestParameters
            previousStep={previousStep}
            nextStep={nextStep}
            subscriberTagsUnique={subscriberTagsUnique}
            selectedOsix={selectedOsix}
            setSelectedOsix={setSelectedOsix}
            selectedNetwork={selectedNetwork}
            subscribers={subscribers}
            setSelectedNetwork={setSelectedNetwork}
            selectedSubscribers={selectedSubscribers}
            setSelectedSubscribers={setSelectedSubscribers}
            selectedTcpDump={selectedTcpDump}
            setSelectedTcpDump={setSelectedTcpDump}
            selectedRecordScreen={selectedRecordScreen}
            setSelectedRecordScreen={setSelectedRecordScreen}
          />
        );
      case 4:
        return (
          <Schedule
            previousStep={previousStep}
            nextStep={nextStep}
            scheduleValues={scheduleValues}
            setScheduleValues={setScheduleValues}
          />
        );
      case 5:
        return (
          <Finish
            previousStep={previousStep}
            campaignName={campaignName}
            selectedSubscribers={selectedSubscribers}
            projectId={selectedRepository?.id}
            selectedBranch={selectedBranch}
            selectedTestCases={selectedTestCases}
            selectedNetwork={selectedNetwork}
            selectedOsix={selectedOsix}
            selectedTcpDump={selectedTcpDump}
            selectedRecordScreen={selectedRecordScreen}
            scheduling={scheduleValues}
            setMessage={setMessage}
            setErrMessage={setErrMessage}
            setActiveScreen={setActiveScreen}
            setActiveTab={setActiveTab}
          />
        );
      default:
        return (<div />);
    }
  };

  return (
    (!_.isEmpty(projects) || isLoadingProjects) ? (
      <div>
        <Container>
          <Row>
            <Col />
            <Col xs={7}>
              <div style={{ paddingLeft: '40px', paddingRight: '50px', paddingBottom: '30px' }}>
                <ProgressBar percent={(step - 1) * 25}>
                  {steps.map((label) => (
                    <Step key={label}>
                      {({ accomplished, index }) => (
                        <>
                          <div
                            className={`indexedStep ${
                              accomplished ? 'accomplished' : null
                            }`}
                          >
                            {index + 1}
                          </div>
                          <div className="test">{label}</div>
                        </>
                      )}
                    </Step>
                  ))}
                </ProgressBar>
              </div>
            </Col>
          </Row>
          <Row style={{ paddingTop: '10px' }}>
            <Col className="border rounded p-5">
              <button
                type="button"
                className="close"
                aria-label="Close"
                onClick={() => {
                  confirmCloseWizard(() => setActiveScreen('overview'));
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {isLoadingProjects ? (
                <div className="spinner-container">
                  <Spinner animation="border" role="status" className="spinner" />
                  <span>Loading...</span>
                </div>
              ) : renderSwitch()}
            </Col>
          </Row>
        </Container>
      </div>
    )
      : (
        <div>
          Could not find any automation project. Please contact the administrator for support.
        </div>
      )
  );
};

export default TestCampaign;
