import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Alert, Col, Container, Row,
} from 'react-bootstrap';

import useAlerts from 'hooks/useAlerts';

const Alerts = () => {
  const {
    alerts,
    dismissAlert,
    dismissNonPersistAlerts,
    dismissAllAlerts,
  } = useAlerts();

  const { pathname } = useLocation();

  useEffect(() => {
    dismissNonPersistAlerts();
  }, [dismissNonPersistAlerts, pathname]);

  return (
    <Container fluid>
      {alerts.map(({
        content, id, heading, variant,
      }) => (
        <Alert
          className="mt-4"
          dismissible
          key={id}
          variant={variant || 'info'}
          onClose={() => dismissAlert(id)}
        >
          {heading && <Alert.Heading>{heading}</Alert.Heading>}
          {content}
        </Alert>
      ))}
      {alerts?.length > 1 && (
        <Row style={{ marginTop: '-.75rem' }}>
          <Col />
          <Col xs="auto">
            <button
              className="text-button-link"
              onClick={() => dismissAllAlerts()}
              type="button"
            >
              <small>Dismiss all</small>
            </button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Alerts;
