import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';

import OutsideClickHandler from 'react-outside-click-handler';

import FocusTrap from 'focus-trap-react/dist/focus-trap-react';

import { Button, Container } from 'react-bootstrap';

import { useKeycloak } from '@react-keycloak/web';

import './navbar.css';
import useUser from 'hooks/useUser';

// const redirectUri =

const Navbar: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState('');
  const { initialized, keycloak } = useKeycloak();
  const { isAdmin, name, roles } = useUser();

  const history = useHistory();
  const location = useLocation();

  const rootHref = history.createHref({ pathname: '/' });
  const keycloakLogoutRedirectUri = `${window?.location?.origin}${rootHref}`;

  useEffect(() => {
    if (location) {
      setDropdownOpen('');
    }
  }, [location]);

  const toggleDropdownOpen = (newDropdown: string) => {
    if (dropdownOpen === newDropdown) {
      setDropdownOpen('');
    } else {
      setDropdownOpen(newDropdown);
    }
  };

  // Hide navbar from the probe manual testing page and iframe
  if (location.pathname.match(/\/manual_testing\/.+/)) {
    return null;
  }

  if (location.search.match(/iframe=true/)) {
    // in iframe, combine manual testing and reservations
    if ((location.pathname.match(/reservations/) || location.pathname.match(/manual_testing/)) && keycloak?.authenticated) {
      return (
        <header className="navbar-2-extended sticky">
          <Container fluid>
            <nav aria-label="navbar" id="navigation" className="navbar-navigation-container">
              <ul className="navbar-navigation">
                {(roles.includes('manual') || roles.includes('admin'))
                && (
                  <li className="navbar-navigation-item">
                    <NavLink className="btn btn-link" exact to="/reservations?iframe=true">Reservations</NavLink>
                  </li>
                )}
                {(roles.includes('manual') || roles.includes('admin'))
                && (
                  <li className="navbar-navigation-item">
                    <NavLink className="btn btn-link" exact to="/manual_testing?iframe=true">Manual Testing</NavLink>
                  </li>
                )}
              </ul>
            </nav>
          </Container>
        </header>
      );
    }
    return null;
  }

  return (
    <header className="navbar-2-extended sticky">
      <Container fluid>
        <h2 className="navbar-portal-name"><Link to="/">TESSA</Link></h2>
        <nav aria-label="navbar" id="navigation" className="navbar-navigation-container">
          <ul className="navbar-navigation">
            {/* <FocusTrap */}
            {/*  active={dropdownOpen === 'test-in-live'} */}
            {/*  focusTrapOptions={{ */}
            {/*    clickOutsideDeactivates: true, */}
            {/*    onDeactivate: () => setDropdownOpen(''), */}
            {/*  }} */}
            {/* > */}
            {/*  <li className="navbar-navigation-item"> */}
            {/*    <button */}
            {/*      type="button" */}
            {/*      onClick={() => toggleDropdownOpen('test-in-live')} */}
            {/* eslint-disable-next-line max-len */}
            {/*      className={`btn btn-link btn-profile ${dropdownOpen === 'test-in-live' ? 'active' : ''}`} */}
            {/*    > */}
            {/*      Products */}
            {/*    </button> */}
            {/*    <OutsideClickHandler */}
            {/*      display="contents" */}
            {/*      onOutsideClick={() => setDropdownOpen('')} */}
            {/*    > */}
            {/*      <ul */}
            {/* className={`navbar-dropdown ${dropdownOpen === 'test-in-live' ? 'active' : ''}`} */}
            {/*      > */}
            {/*        <li className="navbar-dropdown-item"> */}
            {/*          <span className="btn font-weight-bold"> */}
            {/*            Test in LIVE */}
            {/*          </span> */}
            {/*        </li> */}
            {/*        <li className="navbar-dropdown-item"> */}
            {/*  <Link className="btn btn-link" to="/product-mtlive">Manual Testing</Link> */}
            {/*        </li> */}
            {/*        <li className="navbar-dropdown-item"> */}
            {/*  <Link className="btn btn-link" to="/product-atlive">Automated Testing</Link> */}
            {/*        </li> */}
            {/*        <li className="navbar-dropdown-item"> */}
            {/*          <span className="btn font-weight-bold"> */}
            {/*            Test in LAB */}
            {/*          </span> */}
            {/*        </li> */}
            {/*        <li className="navbar-dropdown-item"> */}
            {/*     <Link className="btn btn-link" to="/product-mtlab">Manual Testing</Link> */}
            {/*        </li> */}
            {/*        <li className="navbar-dropdown-item"> */}
            {/*  <Link className="btn btn-link" to="/product-atlab">Automated Testing</Link> */}
            {/*        </li> */}
            {/*      </ul> */}
            {/*    </OutsideClickHandler> */}
            {/*  </li> */}
            {/* </FocusTrap> */}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/dashboard">Dashboard</NavLink> */}
            {/* </li> */}
            {(roles.includes('manual') || roles.includes('admin'))
              && (
                <li className="navbar-navigation-item">
                  <NavLink className="btn btn-link" exact to="/reservations">Reservations</NavLink>
                </li>
              )}
            {(roles.includes('manual') || roles.includes('admin'))
              && (
                <li className="navbar-navigation-item">
                  <NavLink className="btn btn-link" exact to="/manual_testing">Manual Testing</NavLink>
                </li>
              )}
            {(roles.includes('automation') || roles.includes('admin'))
              && (
                <li className="navbar-navigation-item">
                  <NavLink className="btn btn-link" exact to="/automated-testing">Automated Testing</NavLink>
                </li>
              )}
            {(roles.includes('expert-testing') || roles.includes('admin'))
              && (
                <FocusTrap
                  active={dropdownOpen === 'vat'}
                  focusTrapOptions={{
                    clickOutsideDeactivates: true,
                    onDeactivate: () => setDropdownOpen(''),
                  }}
                >
                  <li className="navbar-navigation-item">
                    <button
                      type="button"
                      onClick={() => toggleDropdownOpen('vat')}
                      className={`btn btn-link btn-profile ${dropdownOpen === 'vat' || location.pathname.match('semi-automated') || location.pathname.match('jobs-pending') || location.pathname.match('jobs-approved') ? 'active' : ''}`}
                    >
                      Expert Testing
                    </button>
                    <OutsideClickHandler
                      display="contents"
                      onOutsideClick={() => setDropdownOpen('')}
                    >
                      <ul
                        className={`navbar-dropdown ${dropdownOpen === 'vat' ? 'active' : ''}`}
                      >
                        <li className="navbar-dropdown-item">
                          <Link className="btn btn-link" to="/semi-automated">Semi-automated Reservation</Link>
                        </li>
                        <li className="navbar-dropdown-item">
                          <Link className="btn btn-link" to="/jobs-pending">Jobs Pending Approval</Link>
                        </li>
                        <li className="navbar-dropdown-item">
                          <Link className="btn btn-link" to="/jobs-approved">Approved Jobs</Link>
                        </li>
                      </ul>
                    </OutsideClickHandler>
                  </li>
                </FocusTrap>
              )}
            {/* {roles.includes('admin')
              && (
                <li className="navbar-navigation-item">
                  <NavLink className="btn btn-link" exact to="/attenuators">Attenuators</NavLink>
                </li>
              )} */}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/manual_testing">Testing</NavLink> */}
            {/* </li> */}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/test_generator"> */}
            {/*    Test Generator */}
            {/*  </NavLink> */}
            {/* </li> */}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/about">About</NavLink> */}
            {/* </li> */}
            {(roles.includes('manual') || roles.includes('automation') || roles.includes('admin'))
              && (
                <li className="navbar-navigation-item">
                  <NavLink className="btn btn-link" exact to="/sim-cards">Subscribers</NavLink>
                </li>
              )}
            {initialized && keycloak?.authenticated && (
            <li className="navbar-navigation-item">
              <button
                type="button"
                onClick={() => toggleDropdownOpen('help')}
                className={`btn btn-link btn-profile ${dropdownOpen === 'help' || location.pathname.match('help') ? 'active' : ''}`}
              >
                Help
              </button>
              <OutsideClickHandler
                display="contents"
                onOutsideClick={() => setDropdownOpen('')}
              >
                <ul
                  className={`navbar-dropdown ${dropdownOpen === 'help' ? 'active' : ''}`}
                >
                  <li className="navbar-dropdown-item">
                    <a className="btn btn-link" href="https://jira.telekom.de/plugins/servlet/desk/portal/2242/create/8181" target="_blank" rel="noreferrer">Report Incident</a>
                  </li>
                  <li className="navbar-dropdown-item">
                    <a className="btn btn-link" href="https://jira.telekom.de/plugins/servlet/desk/portal/2242/create/8172" target="_blank" rel="noreferrer">Request Access</a>
                  </li>
                  <li className="navbar-dropdown-item">
                    <a className="btn btn-link" href="https://jira.telekom.de/plugins/servlet/desk/portal/2242/create/8182" target="_blank" rel="noreferrer">Request Support</a>
                  </li>
                  <li className="navbar-dropdown-item">
                    <Link className="btn btn-link" to="/help/documentation/atomic_step_definitions">Documentation</Link>
                  </li>
                  <li className="navbar-dropdown-item">
                    <Link className="btn btn-link" to="/help/serverstatusinfo">Server Status Info</Link>
                  </li>
                </ul>
              </OutsideClickHandler>
            </li>
            )}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/sim_events">Sim Events</NavLink> */}
            {/* </li> */}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/jenkins">Jenkins</NavLink> */}
            {/* </li> */}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/simmap">SIM mapping</NavLink> */}
            {/* </li> */}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/simgroup">SIM groups</NavLink> */}
            {/* </li> */}
            {/* <li className="navbar-navigation-item"> */}
            {/*  <NavLink className="btn btn-link" exact to="/auto_tests"> */}
            {/*    Automated test */}
            {/*  </NavLink> */}
            {/* </li> */}
          </ul>

          {initialized && (
          <ul className="navbar-navigation">
            {
              isAdmin && (
                <li className="navbar-navigation-item">
                  <NavLink className="btn btn-link" exact to="/administration">Administration</NavLink>
                </li>
              )
            }
            {keycloak?.authenticated ? (
              <FocusTrap
                active={dropdownOpen === 'profile'}
                focusTrapOptions={{
                  clickOutsideDeactivates: true,
                  onDeactivate: () => setDropdownOpen(''),
                }}
              >
                <li className="navbar-navigation-item">
                  <button id="open-profile" type="button" onClick={() => toggleDropdownOpen('profile')} className={`btn btn-icon btn-link btn-profile ${dropdownOpen === 'profile' ? 'active' : ''}`}>
                    <span className="icon icon-my-profile" />
                    <span>
                      {name}
                    </span>
                  </button>
                  <OutsideClickHandler
                    display="contents"
                    onOutsideClick={() => setDropdownOpen('')}
                  >
                    <ul className={`navbar-dropdown navbar-dropdown-profile navbar-dropdown-right-aligned ${dropdownOpen === 'profile' ? 'active' : ''}`}>
                      <li
                        className="navbar-dropdown-item navbar-dropdown-title-item"
                      >
                        <Link to={{ pathname: 'https://auth.its-telekom.eu/realms/wwtc/account/' }} target="_blank" rel="noopener noreferrer">Edit Account </Link>
                      </li>
                      <li className="navbar-dropdown-item navbar-dropdown-button-item">
                        <button id="logout" type="button" className="btn btn-light" onClick={() => keycloak.logout({ redirectUri: keycloakLogoutRedirectUri })}>Logout</button>
                      </li>
                    </ul>
                  </OutsideClickHandler>
                </li>
              </FocusTrap>
            ) : (
              <li className="navbar-navigation-item">
                <Button id="open-profile" type="button" onClick={() => keycloak.login()} className={`btn btn-icon btn-link btn-profile ${dropdownOpen === 'profile' ? 'active' : ''}`}>
                  <span className="icon icon-my-profile" />
                  <span>
                    Login
                  </span>
                </Button>
              </li>
            )}
          </ul>
          )}
        </nav>
      </Container>
    </header>
  );
};

export default Navbar;
