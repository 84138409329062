import {
  ON_ERROR,
  CLEAR_ERRORS,
  ADD_FF,
  REMOVE_FF,
  UPDATE_FF,
  ADD_FF_TAG,
  REMOVE_FF_TAG,
  ADD_SCENARIO,
  REMOVE_SCENARIO,
  UPDATE_SCENARIO,
  ADD_SCENARIO_TAG,
  REMOVE_SCENARIO_TAG,
  ADD_STEP,
  REMOVE_STEP,
  UPDATE_STEP_LIST,
  ADD_AV_STEP,
  REMOVE_AV_STEP,
  UPDATE_AV_STEP,
  ADD_AV_TAG,
  REMOVE_AV_TAG,
  UPDATE_AV_TAG,
  UPDATE_DISPLAYED_STEPS,
  SET_FF_SELECTED,
  SET_SC_SELECTED,
  SET_STEP_SELECTED,
} from './types';
import {
  IFeatureFile,
  IStep,
  ITag,
  IScenario,
} from './interfaces';

export default (state: any, action: any) => {
  switch (action.type) {
    case ON_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_AV_TAG:
      return {
        ...state,
        tags: [action.payload, ...state.tags || []],
      };
    case UPDATE_AV_TAG:
      return {
        ...state,
        tags: state.tags.map(
          (tag: ITag) => (tag.id === action.payload.id ? action.payload : tag),
        ),
      };
    case REMOVE_AV_TAG:
      return {
        ...state,
        tags: state.tags.filter((tag: ITag) => tag.id !== action.payload.id),
      };
    case ADD_AV_STEP:
      return {
        ...state,
        steps: [action.payload, ...state.steps || []],
      };
    case UPDATE_AV_STEP:
      return {
        ...state,
        steps: state.steps.map(
          (step: IStep) => (step.id === action.payload.id ? action.payload : step),
        ),
      };
    case REMOVE_AV_STEP:
      return {
        ...state,
        steps: state.steps.filter((step: IStep) => step.id !== action.payload.id),
      };
    case ADD_STEP:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            scenarios: ff.scenarios.map(
              (sc) => (sc.id === action.payload.scId ? {
                ...sc,
                steps: [...sc.steps || [], action.payload.step],
              }
                : sc),
            ),
          }
            : ff),
        ),
      };
    case REMOVE_STEP:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            scenarios: ff.scenarios.map(
              (sc) => (sc.id === action.payload.scId ? {
                ...sc,
                steps: sc.steps.filter((step) => step.id !== action.payload.stepId),
              }
                : sc),
            ),
          }
            : ff),
        ),
      };
    case UPDATE_STEP_LIST:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            scenarios: ff.scenarios.map(
              (sc) => (sc.id === action.payload.scId ? {
                ...sc,
                steps: action.payload.steps,
              }
                : sc),
            ),
          }
            : ff),
        ),
      };
    case ADD_SCENARIO:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            scenarios: [...ff.scenarios || [], action.payload.scenario],
          }
            : ff),
        ),
      };
    case REMOVE_SCENARIO:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            scenarios: ff.scenarios.filter((sc) => sc.id !== action.payload.scId),
          }
            : ff),
        ),
      };
    case UPDATE_SCENARIO:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            scenarios: ff.scenarios.map(
              (sc) => (sc.id === action.payload.scenario.id ? action.payload.scenario : sc),
            ),
          }
            : ff),
        ),
      };
    case ADD_SCENARIO_TAG:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            scenarios: ff.scenarios.map(
              (sc: IScenario) => (sc.id === action.payload.scId ? {
                ...sc,
                tags: [...sc.tags, action.payload.tag],
              }
                : sc),
            ),
          }
            : ff),
        ),
      };
    case REMOVE_SCENARIO_TAG:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            scenarios: ff.scenarios.map(
              (sc: IScenario) => (sc.id === action.payload.scId ? {
                ...sc,
                tags: sc.tags.filter((tag: ITag) => tag.id !== action.payload.tagId),
              }
                : sc),
            ),
          }
            : ff),
        ),
      };
    case ADD_FF:
      return {
        ...state,
        featureFiles: [...state.featureFiles, action.payload],
      };
    case REMOVE_FF:
      return {
        ...state,
        featureFiles:
          state.featureFiles.filter((ff: IFeatureFile) => ff.id !== action.payload),
      };
    case UPDATE_FF:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.id ? action.payload : ff),
        ),
      };
    case ADD_FF_TAG:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            tags: [...ff.tags, action.payload.tag],
          }
            : ff),
        ),
      };
    case REMOVE_FF_TAG:
      return {
        ...state,
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            tags: ff.tags.filter((tag: ITag) => (tag.id !== action.payload.tagId)),
          }
            : ff),
        ),
      };
    case UPDATE_DISPLAYED_STEPS:
      return {
        ...state,
        displayedSteps: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case SET_FF_SELECTED:
      return {
        ...state,
        selected: { ffId: action.payload.ffId, scId: null, stepId: null },
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            isSelected: true,
          }
            : {
              ...ff,
              isSelected: false,
              scenarios: ff.scenarios.map(
                (sc: IScenario) => ({
                  ...sc,
                  isSelected: false,
                  steps: sc.steps.map(
                    (step: IStep) => ({
                      ...step,
                      isSelected: false,
                    }),
                  ),
                }
                ),
              ),
            }
          ),
        ),
      };
    case SET_SC_SELECTED:
      return {
        ...state,
        selected: { ffId: action.payload.ffId, scId: action.payload.scId, stepId: null },
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            isSelected: true,
            scenarios: ff.scenarios.map(
              (sc: IScenario) => (sc.id === action.payload.scId ? {
                ...sc,
                isSelected: true,
              }
                : {
                  ...sc,
                  isSelected: false,
                  steps: sc.steps.map(
                    (step: IStep) => ({
                      ...step,
                      isSelected: false,
                    }),
                  ),
                }
              ),
            ),
          }
            : {
              ...ff,
              isSelected: false,
              scenarios: ff.scenarios.map(
                (sc: IScenario) => ({
                  ...sc,
                  isSelected: false,
                  steps: sc.steps.map(
                    (step: IStep) => ({
                      ...step,
                      isSelected: false,
                    }),
                  ),
                }),
              ),
            }
          ),
        ),
      };
    case SET_STEP_SELECTED:
      return {
        ...state,
        selected: {
          ffId: action.payload.ffId,
          scId: action.payload.scId,
          stepId: action.payload.stepId,
        },
        featureFiles: state.featureFiles.map(
          (ff: IFeatureFile) => (ff.id === action.payload.ffId ? {
            ...ff,
            isSelected: true,
            scenarios: ff.scenarios.map(
              (sc: IScenario) => (sc.id === action.payload.scId ? {
                ...sc,
                isSelected: true,
                steps: sc.steps.map(
                  (step: IStep) => (step.id === action.payload.stepId ? {
                    ...step,
                    isSelected: true,
                  }
                    : {
                      ...step,
                      isSelected: false,
                    }
                  ),
                ),
              }
                : {
                  ...sc,
                  isSelected: false,
                  steps: sc.steps.map(
                    (step: IStep) => ({
                      ...step,
                      isSelected: false,
                    }),
                  ),
                }
              ),
            ),
          }
            : {
              ...ff,
              isSelected: false,
              scenarios: ff.scenarios.map(
                (sc: IScenario) => ({
                  ...sc,
                  isSelected: false,
                  steps: sc.steps.map(
                    (step: IStep) => ({
                      ...step,
                      isSelected: false,
                    }),
                  ),
                }),
              ),
            }
          ),
        ),
      };
    default:
      return state;
  }
};
