import React, { useState, useEffect } from 'react';
import { Card, Modal } from 'react-bootstrap';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeTree, renderTree, getFolders } from '../NewTestCampaign/Utils';

interface IModalProps {
  show: boolean
  onHide: () => void;
  campaignName: string;
  selectedTestCases: any;
}

const TestSetModal = ({
  onHide, show, campaignName, selectedTestCases,
}: IModalProps,) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  console.log(`selectedTestCases ${JSON.stringify(selectedTestCases)}`);
  console.log(`selectedTestCases ${typeof selectedTestCases}`);

  useEffect(() => {
    setExpanded(getFolders(selectedTestCases));
  }, []);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="lg"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header>
                Testcase list of campaign {campaignName}
                <button type="button" className="close" onClick={onHide} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Card.Header>
              <Card.Body>
                <div className="test-case-tree-view">
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expanded={expanded}
                    onNodeToggle={(event: React.SyntheticEvent, nodeIds: string[]) => {
                      setExpanded(nodeIds);
                    }}
                  >
                    {renderTree(makeTree(selectedTestCases))}
                  </TreeView>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default TestSetModal;
