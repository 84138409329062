import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { PollingProvider, useApiPolling } from 'hooks/apiPolling/apiPollingContext';
import { Device } from 'hooks/services/probe/interfaces';
import useUser from 'hooks/useUser';
import { WsContext, WsProvider } from 'components/stf/context/ws';
import { WindowsManagerProvider } from 'components/stf/context/windowsManager';
import DeviceReservation from './DeviceReservation';

// devices page
const Main = () => {
  const {
    reservations, probes,
  } = useApiPolling();

  const {
    devices
  } = useContext(WsContext);

  const { isAdmin, uidNumber: userId } = useUser();

  const [list, setList] = useState<any[]>([]);

  useEffect(() => {
    if (reservations && probes) {
      // active reservation
      const activeRes = reservations.filter((r) => moment(r.until).isAfter(moment())
          && (String(r.user_id) === String(userId) || isAdmin));

      setList(activeRes.sort((r1, r2) => (
        moment(r1.from).unix() <= moment(r2.from).unix() ? 1 : -1
      )).map((r) => {
        const probe = probes.find((p) => p.probe_name === r.probe_name);
        if (!probe) return null;

        const stfDevice = devices.find((d: any) => (
          probe.devices?.find((stfd: Device) => stfd.serial === d.serial)
        ));

        return (
          <DeviceReservation
            key={r.id}
            probe={probe}
            reservation={r}
            stfDevice={stfDevice}
          />
        );
      }));
    }
  }, [reservations, probes, devices]);

  const showLoadingOrErrorOrDeviceList = () => {
    if (!devices) return 'loading...';
    // todo: move error messages into some shared error/notification manager
    // if (error) return error;
    if (list.length === 0) return 'There are no available devices for you. You don\'t have active reservation, or device is not accessible...';
    return list;
  };

  return (
    <div className="pt-4">
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="display-4">Your devices with active reservation</h1>
          </Col>
        </Row>
        <>
          {
            // todo: another way to avoid nested ternary?
            showLoadingOrErrorOrDeviceList()
          }
        </>
      </Container>
    </div>
  );
};

const Wrapper = () => (
  <>
    {/* context rulez, bro... */}
    <PollingProvider>
      <WsProvider>
        <WindowsManagerProvider>
          <Main />
        </WindowsManagerProvider>
      </WsProvider>
    </PollingProvider>
  </>
);

export default Wrapper;
