import { useQuery } from 'react-query';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetList() {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_ATTENUATORS });

  return useQuery(['attenuators'], () => axios.get(
    '/attenuator/api/attenuators'
  ).then(({ data }) => data.slice()), { staleTime: 1000 * 60 * 5 });
}
