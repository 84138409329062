import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Card, Form, FormControl, FormGroup, Modal,
} from 'react-bootstrap';
import useForm from '../../../../hooks/useForm';
import useAddProbeAndEditLocation from '../../../../hooks/services/probe/useAddProbeAndEditLocation';
import { Probe } from '../../../../hooks/services/probe/interfaces';

interface IModalProps {
  show: boolean
  onHide: () => void;
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  location: string;
}

type FormErrors = {
  probe_alias?: string,
  probe_name?: string,
  platform?: string,
  OS?: string,
  IP?: string,
};

const AddProbeModal = (
  {
    onHide, show, setErrMessage, setMessage, location,
  }: IModalProps,
) => {
  const [showError, setShowError] = useState(true);

  const [addProbe, { error }] = useAddProbeAndEditLocation({
    onSuccess: () => {
      setMessage('New probe has been added!');
      onHide();
    },
    onError: () => setErrMessage('Failed to create probe!'),
  });

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    /* TODO update form with all values
     example
      "probe_name": "RAprobe42", *
      "probe_alias": "San Diego", *
      "platform": "Upboard", *
      "OS": "Linux", *
      "IP": "1.3.4.5", *
      "ras_protocol": "VNC", // RDP, VNC
      "ras_port": "5901",
      "ras_username": "probe_admin",
      "ras_password": "A secret to everybody",
      "location_latitude": "32.715736",
      "location_longitude": "-117.161087",
       status on creation is ignored but needs to be sent
     */
    initialValues: {
      probe_alias: '',
      probe_name: '',
      platform: '',
      OS: '',
      IP: '',
      VPN: true,
      poe: false,
      location_latitude: '',
      location_longitude: '',
      ras_password: '',
      ras_port: '',
      ras_protocol: '',
      ras_username: '',
    },
    onSubmit: () => {
      const probe: Probe = {
        probe_name: formValues.probe_name,
        probe_alias: formValues.probe_alias,
        platform: formValues.platform,
        OS: formValues.OS,
        IP: formValues.IP,
        VPN: formValues.VPN,
        poe: formValues.poe,
        location_latitude: formValues.location_latitude,
        location_longitude: formValues.location_longitude,
        ras_password: formValues.ras_password,
        ras_port: formValues.ras_port,
        ras_protocol: formValues.ras_protocol,
        ras_username: formValues.ras_username,
        status: 'offline',
      };
      addProbe({ countryIso: location, probe });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.probe_alias || values?.probe_alias === '') {
        errors.probe_alias = 'Please fill in probe alias.';
      }
      if (/[_ ]/.test(values?.probe_alias)) {
        errors.probe_alias = '"_" or " " not allowed';
      }
      if (!values?.probe_name || values?.probe_name === 0) {
        errors.probe_name = 'Please fill in probe name.';
      }
      if (/[_ ]/.test(values?.probe_name)) {
        errors.probe_name = '"_" or " " not allowed';
      }
      if (!values?.platform || values?.platform === '') {
        errors.platform = 'Please fill in platform.';
      }
      if (!values?.OS || values?.OS === '') {
        errors.OS = 'Please select OS.';
      }
      if (!values?.IP || values?.IP === '') {
        errors.IP = 'Please fill in IP.';
      }
      return errors;
    },
  });

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="xl"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header>
                Add new probe
                <button type="button" className="close" onClick={onHide} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  {error?.message && showError && (
                    <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                      {error?.message}
                    </Alert>
                  )}
                  <Form.Row>
                    <FormGroup key="location">
                      <Form.Label htmlFor="location">Location*</Form.Label>
                      <FormControl
                        autoFocus
                        id="location"
                        name="location"
                        required
                        type="text"
                        value={location}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup key="probe_name" className="ml-2">
                      <Form.Label htmlFor="probe_name">
                        Probe name*
                      </Form.Label>
                      <FormControl
                        id="probe_name"
                        isInvalid={submitted && Boolean(formErrors?.probe_name)}
                        name="probe_name"
                        onChange={handleChange('probe_name')}
                        required
                        type="text"
                        value={formValues?.probe_name}
                      />
                      {submitted && formErrors?.probe_name && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.probe_name}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="probe_alias">
                      <Form.Label htmlFor="probe_alias">
                        Probe alias*
                      </Form.Label>
                      <FormControl
                        id="probe_alias"
                        isInvalid={submitted && Boolean(formErrors?.probe_alias)}
                        name="probe_alias"
                        onChange={handleChange('probe_alias')}
                        required
                        type="text"
                        value={formValues?.probe_alias}
                      />
                      {submitted && formErrors?.probe_alias && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.probe_alias}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup key="IP" className="ml-2">
                      <Form.Label htmlFor="IP">
                        IP*
                      </Form.Label>
                      <FormControl
                        id="IP"
                        isInvalid={submitted && Boolean(formErrors?.IP)}
                        name="IP"
                        onChange={handleChange('IP')}
                        required
                        type="text"
                        value={formValues?.IP}
                      />
                      {submitted && formErrors?.IP && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.IP}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="platform">
                      <Form.Label htmlFor="platform">
                        Platform*
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue=""
                        id="platform"
                        isInvalid={submitted && Boolean(formErrors?.platform)}
                        name="platform"
                        onChange={handleChange('platform')}
                      >
                        <option value="" disabled hidden>platform</option>
                        <option value="Upboard">Upboard</option>
                        <option value="Raspberry">Raspberry</option>
                        <option value="Virtual">Virtual</option>
                      </Form.Control>
                    </FormGroup>
                    <FormGroup key="OS" className="ml-2">
                      <Form.Label htmlFor="OS">
                        OS*
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue=""
                        id="OS"
                        isInvalid={submitted && Boolean(formErrors?.OS)}
                        name="OS"
                        onChange={handleChange('OS')}
                      >
                        <option value="" disabled hidden>OS</option>
                        <option value="Linux">Linux</option>
                        <option value="MacOS">MacOS</option>
                        <option value="Windows">Windows</option>
                      </Form.Control>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="location_latitude">
                      <Form.Label htmlFor="location_latitude">
                        Latitude
                      </Form.Label>
                      <FormControl
                        id="location_latitude"
                        name="location_latitude"
                        onChange={handleChange('location_latitude')}
                        type="text"
                        value={formValues?.location_latitude}
                      />
                    </FormGroup>
                    <FormGroup key="location_longitude" className="ml-2">
                      <Form.Label htmlFor="location_longitude">
                        Longitude
                      </Form.Label>
                      <FormControl
                        id="location_longitude"
                        name="location_longitude"
                        onChange={handleChange('location_longitude')}
                        type="text"
                        value={formValues?.location_longitude}
                      />
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="ras_username">
                      <Form.Label htmlFor="ras_username">
                        RAS Username
                      </Form.Label>
                      <FormControl
                        id="ras_username"
                        name="ras_username"
                        onChange={handleChange('ras_username')}
                        type="text"
                        value={formValues?.ras_username}
                      />
                    </FormGroup>
                    <FormGroup key="ras_password" className="ml-2">
                      <Form.Label htmlFor="ras_password">
                        RAS Password
                      </Form.Label>
                      <FormControl
                        id="ras_password"
                        name="ras_password"
                        onChange={handleChange('ras_password')}
                        type="text"
                        value={formValues?.ras_password}
                      />
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="ras_protocol">
                      <Form.Label htmlFor="ras_protocol">
                        RAS Protocol
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formValues?.ras_password}
                        id="ras_protocol"
                        name="ras_protocol"
                        onChange={handleChange('ras_protocol')}
                      >
                        <option value="" disabled hidden>RAS Protocol</option>
                        <option value="RDP">RDP</option>
                        <option value="VNC">VNC</option>
                      </Form.Control>
                    </FormGroup>
                    <FormGroup key="ras_port" className="ml-2">
                      <Form.Label htmlFor="ras_port">
                        RAS Port
                      </Form.Label>
                      <FormControl
                        id="ras_port"
                        name="ras_port"
                        onChange={handleChange('ras_port')}
                        type="text"
                        value={formValues?.ras_port}
                      />
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group className="ml-2" controlId="vpnCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="VPN connection"
                        onChange={handleChange('VPN')}
                        value={formValues?.VPN}
                        checked={formValues?.VPN}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group className="ml-2" controlId="poeCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="PoE connected"
                        onChange={handleChange('poe')}
                        value={formValues?.poe}
                        checked={formValues?.poe}
                      />
                    </Form.Group>
                  </Form.Row>
                  <div className="text-right">
                    <Button
                      className="btn btn-secondary"
                      style={{ color: 'black' }}
                      onClick={onHide}
                    >
                      Close
                    </Button>
                    <Button className="btn btn-primary" type="submit">Save</Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddProbeModal;
