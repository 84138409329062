import React, { useState, useEffect } from 'react';

import { Form } from 'react-bootstrap';

type RadioGroupItem = {
  id: string;
  isCustom?: boolean;
  label?: string;
  value?: string | number;
};

type RadioGroupInitialValue = {
  id: string;
  value: string;
};

type RadioGroupInputValues = {
  [key: string]: string;
};

// todo: how to set default values for optional prop
/* eslint-disable react/require-default-props */
type RadioGroupProps = {
  initialValue?: RadioGroupInitialValue;
  items: RadioGroupItem[];
  name: string;
  onChange?: (selectedCheckboxes: string) => void;
};

const RadioGroup = ({
  name,
  initialValue,
  items,
  onChange,
}: RadioGroupProps) => {
  const [selectedRadio, setSelectedRadioValue] = useState<
  RadioGroupInitialValue
  >(initialValue || {} as RadioGroupInitialValue);
  const [customInputValues, setCustomInputValues] = useState<
  RadioGroupInputValues
  >({});

  useEffect(() => {
    if (onChange) {
      onChange(selectedRadio?.value);
    }
  }, [onChange, selectedRadio]);

  const handleRadioChange = (id: string, isCustom?: boolean) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    const customValue = customInputValues[id] || '';

    setSelectedRadioValue({
      id,
      value: isCustom ? customValue : value,
    });
  };

  const handleCustomCheckboxValueChange = (id: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    const newCustomValues = { ...customInputValues };

    newCustomValues[id] = value;

    setCustomInputValues(newCustomValues);

    if (selectedRadio?.id === id) {
      setSelectedRadioValue({ id, value });
    }
  };

  return (
    <Form.Group>
      {items.map(({
        id, isCustom, label, value: radioValue,
      }) => (
        <React.Fragment key={id}>
          {isCustom ? (
            <Form.Check
              checked={selectedRadio?.id === id}
              id={id}
              label={label || radioValue}
              name={name}
              onChange={handleRadioChange(id, isCustom)}
              type="radio"
            />
          ) : (
            <Form.Check
              checked={selectedRadio?.id === id}
              id={id}
              label={label || radioValue}
              name={name}
              onChange={handleRadioChange(id)}
              type="radio"
              value={radioValue}
            />
          )}
          {isCustom && (
            <Form.Control
              type="text"
              onChange={handleCustomCheckboxValueChange(id)}
              value={customInputValues[id] || radioValue || ''}
            />
          )}
        </React.Fragment>
      ))}
    </Form.Group>
  );
};

export default RadioGroup;
