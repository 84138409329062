import React from 'react';
import PropTypes from 'prop-types';

import { Col, Container, Row } from 'react-bootstrap';

type AdministrationLayoutProps = {
  children: React.ReactNode;
  sidebar: React.ReactNode;
};

const AdministrationLayout = ({ sidebar, children }: AdministrationLayoutProps) => (
  <Container className="d-flex flex-grow-1" fluid>
    <div className="d-flex flex-column flex-lg-row flex-grow-1">
      <div className="d-none flex-grow-1 d-lg-block border-right" style={{ maxWidth: '300px' }}>
        {sidebar}
      </div>
      <div className="d-lg-none border-bottom mb-4 pb-4">
        {sidebar}
      </div>
      <div className="d-flex  flex-grow-1 ">
        <Container className="d-flex flex-column">
          <Row className="flex-grow-1">
            <Col className="d-flex flex-column">
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Container>
);

AdministrationLayout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
};

export default AdministrationLayout;
