import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Card, Form, FormControl, FormGroup, InputGroup, Modal,
} from 'react-bootstrap';
import useForm from '../../../../hooks/useForm';
import useEditProbe from '../../../../hooks/services/probe/useEditProbe';
import { Probe } from '../../../../hooks/services/probe/interfaces';
import useConfirm from '../../../../hooks/useConfirm';
import useDeleteProbe from '../../../../hooks/services/probe/useDeleteProbe';
import useGetProbeDevices from '../../../../hooks/services/probe/useGetProbeDevices';
import useEditProbeLocation from '../../../../hooks/services/probe/useEditProbeLocation';

interface IModalProps {
  show: boolean
  onHide: () => void;
  onDeleteProbeCancel: () => void;
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  probeData: Probe | undefined;
  getProbes: any;
  locations: { country_iso: string, country_name: string }[];
}

type FormErrors = {
  probe_alias?: string,
  platform?: string,
  OS?: string,
  IP?: string,
};

const EditProbeModal = (
  {
    onHide, onDeleteProbeCancel, show, setErrMessage, setMessage, probeData, getProbes, locations,
  }: IModalProps,
) => {
  const [showError, setShowError] = useState(true);

  const [editProbe, { error }] = useEditProbe({
    onSuccess: () => {
      setMessage(`Probe ${probeData?.probe_alias} has been updated!`);
      getProbes();
      onHide();
    },
    onError: () => setErrMessage('Failed to update probe!'),
  });

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    /*
     example
      "probe_name": "RAprobe42", *
      "probe_alias": "San Diego", *
      "platform": "Upboard", *
      "OS": "Linux", *
      "IP": "1.3.4.5", *
      "ras_protocol": "VNC", // RDP, VNC
      "ras_port": "5901",
      "ras_username": "probe_admin",
      "ras_password": "A secret to everybody",
      "location_latitude": "32.715736",
      "location_longitude": "-117.161087",
     */
    initialValues: {
      probe_alias: probeData?.probe_alias,
      probe_name: probeData?.probe_name,
      platform: probeData?.platform,
      OS: probeData?.OS,
      IP: probeData?.IP,
      VPN: probeData?.VPN,
      poe: probeData?.poe,
      location_latitude: probeData?.location_latitude,
      location_longitude: probeData?.location_longitude,
      ras_password: probeData?.ras_password,
      ras_port: probeData?.ras_port,
      ras_protocol: probeData?.ras_protocol,
      ras_username: probeData?.ras_username,
      country_iso: probeData?.country_iso,
    },
    onSubmit: () => {
      const probe: Probe = {
        probe_name: formValues.probe_name,
        probe_alias: formValues.probe_alias,
        platform: formValues.platform,
        OS: formValues.OS,
        IP: formValues.IP,
        VPN: formValues.VPN,
        poe: formValues.poe,
        location_latitude: formValues.location_latitude || probeData?.location_latitude,
        location_longitude: formValues.location_longitude || probeData?.location_longitude,
        ras_password: formValues.ras_password,
        ras_port: formValues.ras_port,
        ras_protocol: formValues.ras_protocol,
        ras_username: formValues.ras_username,
        country_iso: probeData?.country_iso,
        status: probeData?.status,
      };
      editProbe({ probe, probeName: probe.probe_name });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.probe_alias || values?.probe_alias === '') {
        errors.probe_alias = 'Please fill in probe alias.';
      }
      if (/[_ ]/.test(values?.probe_alias)) {
        errors.probe_alias = '"_" or " " not allowed';
      }
      if (!values?.platform || values?.platform === '') {
        errors.platform = 'Please fill in platform.';
      }
      if (!values?.OS || values?.OS === '') {
        errors.OS = 'Please select OS.';
      }
      if (!values?.IP || values?.IP === '') {
        errors.IP = 'Please fill in IP.';
      }
      return errors;
    },
  });

  const [deleteProbe] = useDeleteProbe(
    {
      onSuccess: () => {
        setMessage(`probe ${probeData?.probe_alias} has been deleted`);
        getProbes();
        onHide();
      },
      onError: () => setErrMessage(`failed to delete probe ${probeData?.probe_alias}`),
    },
  );
  // move probe to another location
  const [locationEditable, setLocationEditable] = useState(false);
  const [assignProbeLocation] = useEditProbeLocation({
    onSuccess: () => {
      setMessage(`Moved probe ${probeData?.probe_alias} to ${formValues.country_iso}!`);
      getProbes();
      onHide();
    },
    onError: () => setErrMessage('Failed to move the probe!'),
  });

  // add confirm modal for delete probe
  const confirm = useConfirm();
  const { data: devices } = useGetProbeDevices(probeData!.probe_name);
  const handleDeleteProbe = async ({ probeName }: any) => {
    onHide();
    if (devices.length > 0) {
      setErrMessage('The probe has assigned devices, you cannot remove it!');
      onHide();
    } else {
      confirm({
        body: 'Are you sure to delete probe?',
        onOk: () => deleteProbe({ probeName }),
        onCancel: () => onDeleteProbeCancel(),
        title: 'Delete probe',
      });
    }
  };

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="xl"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header className="d-flex">
                <span className="align_left">
                  {`Probe ${probeData?.probe_alias} - (${probeData?.status})`}
                </span>
                <Button
                  variant="light"
                  className="text-button-link ml-auto pr-2 pl-2"
                  key={`${probeData?.probe_name}_delete`}
                  id={`delete-probe_${probeData?.probe_name}`}
                  onClick={() => (
                    handleDeleteProbe({ probeName: probeData!.probe_name })
                  )}
                  aria-label="delete_probe"
                >
                  delete probe
                </Button>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  {error?.message && showError && (
                    <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                      {error?.message}
                    </Alert>
                  )}
                  <Form.Row>
                    <FormGroup key="probe_name">
                      <Form.Label htmlFor="probe_name">
                        Probe name
                      </Form.Label>
                      <FormControl
                        id="probe_name"
                        name="probe_name"
                        disabled
                        type="text"
                        value={formValues?.probe_name}
                      />
                    </FormGroup>
                    <FormGroup key="country_iso" className={locationEditable ? 'ml-2' : ''}>
                      <Form.Label htmlFor="country_iso">Location</Form.Label>
                      { locationEditable && (
                        <div className="d-flex">
                          <Form.Control
                            as="select"
                            value={formValues.country_iso}
                            id="country_iso"
                            name="country_iso"
                            onChange={handleChange('country_iso')}
                          >
                            <option
                              value={formValues.country_iso || '__'}
                              hidden
                              disabled
                            >
                              {formValues.country_iso || 'select'}
                            </option>
                            { locations.map((loc) => (
                              <option key={loc.country_iso} value={loc.country_iso}>
                                {loc.country_iso}
                              </option>
                            ))}
                          </Form.Control>
                          <Button
                            variant="primary"
                            className="btn d-sm-block mt-0 px-xl-3"
                            key="country_iso"
                            id="country_iso"
                            aria-label="change_country_iso"
                            onClick={() => (
                              assignProbeLocation({
                                probeName: formValues.probe_name,
                                countryIso: formValues.country_iso,
                              })
                            )}
                          >
                            Confirm
                          </Button>
                        </div>
                      )}
                      { !locationEditable && (
                        <InputGroup className="mb-3">
                          <FormControl
                            id="country_iso"
                            aria-label="country_iso"
                            aria-describedby="country_iso"
                            defaultValue={formValues.country_iso}
                            readOnly
                            type="text"
                            name="country_iso"
                            style={{ borderColor: '#B2B2B2', margin: '2px 0 2px 0' }}
                          />
                          <InputGroup.Append style={{ padding: '1px' }}>
                            <Button
                              variant="outline-secondary"
                              style={{ padding: '6px', margin: '1px', borderColor: '#B2B2B2' }}
                              onClick={() => setLocationEditable(true)}
                            >
                              change location
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="probe_alias">
                      <Form.Label htmlFor="probe_alias">
                        Probe alias*
                      </Form.Label>
                      <FormControl
                        id="probe_alias"
                        isInvalid={submitted && Boolean(formErrors?.probe_alias)}
                        name="probe_alias"
                        onChange={handleChange('probe_alias')}
                        disabled={locationEditable}
                        required
                        type="text"
                        value={formValues.probe_alias}
                      />
                      {submitted && formErrors?.probe_alias && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.probe_alias}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup key="IP" className="ml-2">
                      <Form.Label htmlFor="IP">
                        IP*
                      </Form.Label>
                      <FormControl
                        id="IP"
                        isInvalid={submitted && Boolean(formErrors?.IP)}
                        name="IP"
                        onChange={handleChange('IP')}
                        disabled={locationEditable}
                        required
                        type="text"
                        value={formValues.IP}
                      />
                      {submitted && formErrors?.IP && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.IP}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="platform">
                      <Form.Label htmlFor="platform">
                        Platform*
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formValues.platform}
                        id="platform"
                        isInvalid={submitted && Boolean(formErrors?.platform)}
                        disabled={locationEditable}
                        name="platform"
                        onChange={handleChange('platform')}
                      >
                        <option value="" disabled hidden>platform</option>
                        <option value="Upboard">Upboard</option>
                        <option value="Raspberry">Raspberry</option>
                        <option value="Virtual">Virtual</option>
                      </Form.Control>
                    </FormGroup>
                    <FormGroup key="OS" className="ml-2">
                      <Form.Label htmlFor="OS">
                        OS*
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formValues.OS}
                        id="OS"
                        isInvalid={submitted && Boolean(formErrors?.OS)}
                        disabled={locationEditable}
                        name="OS"
                        onChange={handleChange('OS')}
                      >
                        <option value="" disabled hidden>OS</option>
                        <option value="Linux">Linux</option>
                        <option value="MacOS">MacOS</option>
                        <option value="Windows">Windows</option>
                      </Form.Control>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="location_latitude">
                      <Form.Label htmlFor="location_latitude">
                        Latitude
                      </Form.Label>
                      <FormControl
                        id="location_latitude"
                        name="location_latitude"
                        onChange={handleChange('location_latitude')}
                        disabled={locationEditable}
                        type="text"
                        value={formValues?.location_latitude}
                      />
                    </FormGroup>
                    <FormGroup key="location_longitude" className="ml-2">
                      <Form.Label htmlFor="location_longitude">
                        Longitude
                      </Form.Label>
                      <FormControl
                        id="location_longitude"
                        name="location_longitude"
                        onChange={handleChange('location_longitude')}
                        disabled={locationEditable}
                        type="text"
                        value={formValues?.location_longitude}
                      />
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="ras_username">
                      <Form.Label htmlFor="ras_username">
                        RAS Username
                      </Form.Label>
                      <FormControl
                        id="ras_username"
                        name="ras_username"
                        onChange={handleChange('ras_username')}
                        disabled={locationEditable}
                        type="text"
                        value={formValues?.ras_username}
                      />
                    </FormGroup>
                    <FormGroup key="ras_password" className="ml-2">
                      <Form.Label htmlFor="ras_password">
                        RAS Password
                      </Form.Label>
                      <FormControl
                        id="ras_password"
                        name="ras_password"
                        onChange={handleChange('ras_password')}
                        disabled={locationEditable}
                        type="text"
                        value={formValues?.ras_password}
                      />
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="ras_protocol">
                      <Form.Label htmlFor="ras_protocol">
                        RAS Protocol
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formValues?.ras_password}
                        disabled={locationEditable}
                        id="ras_protocol"
                        name="ras_protocol"
                        onChange={handleChange('ras_protocol')}
                      >
                        <option value="" disabled hidden>RAS Protocol</option>
                        <option value="RDP">RDP</option>
                        <option value="VNC">VNC</option>
                      </Form.Control>
                    </FormGroup>
                    <FormGroup key="ras_port" className="ml-2">
                      <Form.Label htmlFor="ras_port">
                        RAS Port
                      </Form.Label>
                      <FormControl
                        id="ras_port"
                        name="ras_port"
                        onChange={handleChange('ras_port')}
                        disabled={locationEditable}
                        type="text"
                        value={formValues?.ras_port}
                      />
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group className="ml-2" controlId="vpnCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="VPN connection"
                        onChange={handleChange('VPN')}
                        value={formValues?.VPN}
                        checked={formValues?.VPN}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group className="ml-2" controlId="poeCheckbox">
                      <Form.Check
                        type="checkbox"
                        label="PoE connected"
                        onChange={handleChange('poe')}
                        value={formValues?.poe}
                        checked={formValues?.poe}
                      />
                    </Form.Group>
                  </Form.Row>
                  <div className="text-right">
                    <Button
                      className="btn btn-secondary"
                      style={{ color: 'black' }}
                      onClick={onHide}
                    >
                      Cancel
                    </Button>
                    <Button className="btn btn-primary" type="submit" disabled={locationEditable}>
                      Save
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default EditProbeModal;
