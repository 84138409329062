import React, { useMemo } from 'react';
import moment from 'moment';

import { useForm } from 'react-form';

import {
  Button, Modal,
} from 'react-bootstrap';

import useUpdateReservation from 'hooks/services/reservation/useUpdateReservation';
import usePatchReservation from 'hooks/services/reservation/usePatchReservation';

import EditReservationFormFields from './EditReservationFormFields';

const CreateEditReservationModal = ({
  onHide, onSuccess, probe, selectedReservation, show,
}: any) => {
  const [updateReservation, {
    isLoading,
    error: updateError,
  }] = useUpdateReservation({ onSuccess });
  const [patchReservation, { error: patchError }] = usePatchReservation({ onSuccess });

  const defaultValues = useMemo(() => ({
    from: selectedReservation?.from ? moment(selectedReservation?.from).format('DD/MM/YYYY HH:mm') : undefined,
    until: selectedReservation?.until ? moment(selectedReservation?.until).format('DD/MM/YYYY HH:mm') : undefined,
  }), [selectedReservation]);

  const {
    Form,
    meta: { error: formError, isValid: fieldsAreValid }
  } = useForm({
    defaultValues,
    validate: (values: any) => {
      if (moment(values?.until, 'DD/MM/YYYY HH:mm').isBefore(moment(values?.from, 'DD/MM/YYYY HH:mm'))) {
        return 'The end time is before start time.';
      }
      if (values.probes && values.probes.length === 0) {
        return 'Select at least one probe.';
      }
      return false;
    },
    onSubmit: (values: any) => {
      if (!isLoading) {
        const from = moment(values?.from, 'DD/MM/YYYY HH:mm');
        if (from.isBefore(moment())) {
          patchReservation({
            reservationId: selectedReservation?.id,
            reservation: {
              until: moment(values?.until, 'DD/MM/YYYY HH:mm').toISOString(),
            },
          });
        } else {
          updateReservation({
            reservationId: selectedReservation?.id,
            reservation: {
              from: from.toISOString(),
              until: moment(values?.until, 'DD/MM/YYYY HH:mm').toISOString(),
            },
          });
        }
      }
    },
  });

  return (
    <Modal show={show} onHide={onHide} backdrop="static">
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>Edit reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditReservationFormFields probe={probe} defaultValues={defaultValues} />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <div style={{ color: 'red' }}>{formError || null}</div>
            <div style={{ color: 'red' }}>{updateError ? updateError.message : null}</div>
            <div style={{ color: 'red' }}>{patchError ? patchError.message : null}</div>
            <div style={{ color: 'red' }}>{!fieldsAreValid ? 'Correct the errors in the form.' : null}</div>
          </div>
          <Button disabled={isLoading || !fieldsAreValid} variant="primary" type="submit">
            Update
          </Button>
          <Button disabled={isLoading} variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default CreateEditReservationModal;
