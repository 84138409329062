import React from 'react';
import { Row, Dropdown, Form } from 'react-bootstrap';
import { useForm } from 'react-form';
import useConfirm from 'hooks/useConfirm';
import _ from 'lodash';
import TestCaseSelection from './TestCaseSelection';
import './styles.scss';

type Member = {
  username: string;
  accessValue: number;
};

export type Repository = {
  id: string;
  path: string;
  name: string;
  branches: string[];
  members: Member[];
};

const RepositorySelection = ({
  previousStep,
  nextStep,
  shouldResetForm,
  setShouldResetForm,
  projects,
  selectedRepository,
  setSelectedRepository,
  selectedBranch,
  setSelectedBranch,
  setSelectedTestCases,
  selectedTestCases
}: any) => {
  const {
    handleSubmit
  } = useForm({
    onSubmit: () => {
      nextStep();
    }
  });

  const confirm = useConfirm();

  const confirmClearSelectedTestCases = (clearTC: any) => {
    confirm({
      // eslint-disable-next-line max-len
      body: <><>Your test set will be deleted when you change the project/branch.</><br /><>Do you want to continue?</></>,
      onOk: clearTC,
      title: 'Change project/branch?',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <strong style={{ marginLeft: '-13px' }}>Select the project and branch from which you want to create a test set.</strong>
      <br /><br />
      <Row>
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            {selectedRepository ? selectedRepository.name : 'Project'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {projects && projects.map((repo:Repository) => (
              <Dropdown.Item
                onSelect={() => {
                  if (!_.isEmpty(selectedTestCases)) {
                    confirmClearSelectedTestCases(() => {
                      setSelectedRepository(repo);
                      setSelectedBranch(_.first(repo.branches.filter((item:any) => item !== 'playground')));
                      setShouldResetForm(true);
                    });
                  } else {
                    setSelectedRepository(repo);
                    setSelectedBranch(_.first(repo.branches.filter((item:any) => item !== 'playground')));
                    setShouldResetForm(true);
                  }
                }}
                key={repo.name}
              >
                {repo.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown hidden={!selectedRepository}>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            {selectedBranch}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {selectedRepository
              && projects?.find((repo:Repository) => repo.name === selectedRepository.name)
                .branches.filter((item:any) => item !== 'playground').map((branch:string) => (
                  <Dropdown.Item
                    onSelect={() => {
                      if (selectedBranch !== branch) {
                        if (!_.isEmpty(selectedTestCases)) {
                          confirmClearSelectedTestCases(() => {
                            setSelectedBranch(branch);
                            setShouldResetForm(true);
                          });
                        } else {
                          setSelectedBranch(branch);
                          setShouldResetForm(true);
                        }
                      }
                    }}
                    key={branch}
                  >
                    {branch}
                  </Dropdown.Item>
                ))}
          </Dropdown.Menu>
        </Dropdown>
      </Row>
      <TestCaseSelection
        shouldResetForm={shouldResetForm}
        repository={selectedRepository}
        branch={selectedBranch}
        setSelectedTestCases={setSelectedTestCases}
        selectedTestCases={selectedTestCases}
        previousStep={previousStep}
      />
    </Form>
  );
};

export default RepositorySelection;
