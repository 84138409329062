import React, { useEffect, useState } from 'react';
import { Alert, Container, Row, Col } from 'react-bootstrap';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import useGetProbeLocations from 'hooks/services/probe/location/useGetProbeLocations';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useGetReservations from 'hooks/services/reservation/useGetReservations';
import { Probe } from 'hooks/services/probe/interfaces';
import { Link } from 'react-router-dom';
import Overview from './Overview';
import ProbeSelection from './ProbeSelection';
import SimMapping from './SimMapping';

const SemiAutomatedReservation = () => {
  const [step, setStep] = useState(1);
  const nextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };
  const previousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };
  const { data: dirtyProbes } = useGetProbes();
  const { data: locations } = useGetProbeLocations();
  const { data: subscribers } = useGetSubscribers();
  const { data: reservations } = useGetReservations();
  const [probes, setProbes] = useState<any[]>([]);
  const [selectedProbes, setSelectedProbes] = useState([]);
  const [simMapping, setSimMapping] = useState([]);
  const [duration, setDuration] = useState(60);
  const [showAlert, setShowAlert] = useState(false);

  const resetForm = () => {
    setSelectedProbes([]);
    setSimMapping([]);
    setStep(1);
    setShowAlert(true);
  };

  const updateSelectedProbes = (selected:any) => {
    setSelectedProbes(selected);
    setSimMapping(
      simMapping.filter((mapping:any) => selected.includes(
        probes.find((probe:any) => probe.probe_alias === mapping.probe).probe_name
      ))
    );
  };

  useEffect(() => {
    if (locations && dirtyProbes && subscribers) {
      setProbes(dirtyProbes?.map((probe: Probe) => {
        const loc = locations.find((val: any) => val.country_iso === probe.country_iso);
        const subscriberId = (probe?.devices && probe?.devices[0] && probe?.devices[0].subscribers) ? Object.keys(probe.devices[0].subscribers) : 'no subscriber';
        const subscriber = subscribers.find((val: any) => val.id.toString() === subscriberId[0]);
        return {
          ...probe,
          country_iso: loc?.country_iso || 'UNK',
          country_name: loc?.country_name || 'Unknown location',
          location: `${loc?.country_name || 'Unknown location'}`,
          device: (probe?.devices && probe?.devices[0]) ? probe.devices[0].name : 'no device',
          tags: subscriber?.tags,
          live: subscriber?.live,
          lab: subscriber?.lab,
        };
      }));
    }
  }, [locations, dirtyProbes, subscribers]);

  const renderSwitch = () => {
    switch (step) {
      case 1:
        return (
          <ProbeSelection
            probes={probes}
            reservations={reservations}
            selectedProbes={selectedProbes}
            setSelectedProbes={updateSelectedProbes}
            previousStep={previousStep}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <SimMapping
            probes={probes}
            selectedProbes={selectedProbes}
            subscribers={subscribers}
            simMapping={simMapping}
            setSimMapping={setSimMapping}
            duration={duration}
            setDuration={setDuration}
            previousStep={previousStep}
            nextStep={nextStep}
          />
        );
      case 3:
        return (
          <Overview
            probes={probes}
            selectedProbes={selectedProbes}
            subscribers={subscribers}
            simMapping={simMapping}
            setSimMapping={setSimMapping}
            duration={duration}
            previousStep={previousStep}
            resetForm={resetForm}
          />
        );
      default:
        return (<div />);
    }
  };

  return (
    <div>
      <Alert show={showAlert} onClose={() => setShowAlert(false)} dismissible variant="success">
        <Alert.Heading>Reservation scheduled successfully</Alert.Heading>
        <hr />
        <p>
          You can monitor the status of reservation on the <Link to="/jobs-pending">list of pending jobs</Link>
        </p>
      </Alert>
      <Container>
        <Row>
          <Col md={12}>
            <h1 className="display-4">Semi-Automated Reservation</h1>
          </Col>
        </Row>
        <Row>
          <Col className="custom-margin">
            { renderSwitch() }
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SemiAutomatedReservation;
