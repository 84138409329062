import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import clsx from 'clsx';

import {
  Button, ButtonGroup, Col, Dropdown, Form, InputGroup, Row,
} from 'react-bootstrap';

const TimelineActions = ({
  animateScroll,
  gotoPage,
  fullscreen,
  hideNewButton,
  hideRefreshButton,
  hideSearchInput,
  NewButtonProps,
  RefreshButtonProps,
  SearchInputProps,
  setFullscreen,
  setGlobalFilter,
  setVisibleTime,
  zoom,
}: any) => {
  const { content: newButtonContent, ...newButtonProps } = NewButtonProps ?? {};

  const handleSearchChange = (event: any) => {
    const value = event?.target?.value || undefined;
    console.log('setting global filter');
    gotoPage(0);
    setGlobalFilter(value);
  };

  const handleGoToNow = () => {
    const visibleTimeStart = moment()
      .startOf('hour')
      .add(-1, 'hour')
      .valueOf();

    const visibleTimeEnd = visibleTimeStart + 23 * 60 * 60 * 1000;
    setVisibleTime([visibleTimeStart, visibleTimeEnd]);
  };

  return (
    <Row className="align-items-center bu-mb-2">
      {!hideSearchInput && (
        <Col lg={5} md="auto" xs={12}>
          <div className="sr-only">
            <Form.Label htmlFor="table-search">Search</Form.Label>
          </div>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Search</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              id="table-search"
              onChange={handleSearchChange}
              {...SearchInputProps}
            />
          </InputGroup>
        </Col>
      )}
      <Col />
      <Col md="auto" xs={12}>
        {!hideNewButton
          && !_.isEmpty(NewButtonProps) && (
            <Button variant="primary" {...newButtonProps}>
              {newButtonContent || 'New'}
            </Button>
        )}
        {!hideNewButton && NewButtonProps?.items && (
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              New
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {NewButtonProps?.items.map(() => <Dropdown.Item href="#/action-1">Action</Dropdown.Item>)}
            </Dropdown.Menu>
          </Dropdown>
        )}
        {!hideRefreshButton && RefreshButtonProps?.onClick && (
        <Button className="ml-4" variant="secondary" {...RefreshButtonProps}>
          {RefreshButtonProps?.content || 'Refresh'}
        </Button>
        )}
        <ButtonGroup className="ml-4">
          <Button onClick={() => animateScroll(true)} aria-label="Go Back In Time" variant="secondary"><i className="icon icon-navigation-left" /></Button>
          <Button onClick={handleGoToNow} variant="secondary" aria-label="Go To Now"><i className="icon icon-record" /></Button>
          <Button onClick={() => animateScroll(false)} variant="secondary" aria-label="Go Ahead In Time"><i className="icon icon-navigation-right" /></Button>
        </ButtonGroup>
        <ButtonGroup className="ml-4">
          <Button onClick={() => zoom(false)} variant="secondary" aria-label="Zoom In"><i className="icon icon-zoom-in" /></Button>
          <Button onClick={() => zoom(true)} variant="secondary" aria-label="Zoom Out"><i className="icon icon-zoom-out" /></Button>
        </ButtonGroup>
        <ButtonGroup className="ml-4">
          <Button onClick={() => setFullscreen(!fullscreen)} variant="secondary" aria-label="Fullscreen">
            <i className={clsx('icon', { 'icon-maximize': !fullscreen, 'icon-minimize': fullscreen })} />
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default TimelineActions;
