import React, {
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import TabsContext from '../TabsContext';

const Tab = ({
  active, children, className, disabled, value, ...props
}: any) => {
  const tabsContextValues = useContext<any>(TabsContext);

  if (!tabsContextValues) {
    throw new Error('Looks like <Tab> is not inside <Tabs>.');
  }

  const [activeTab, setActiveTab] = tabsContextValues;

  return (
    <button
      className={clsx('tab', {
        active: (activeTab && value && activeTab === value) || active,
        disabled,
      }, className)}
      disabled={disabled}
      onClick={() => !disabled && setActiveTab(value)}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};

Tab.defaultProps = {
  active: false,
  className: undefined,
  disabled: false,
  value: undefined,
};

Tab.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Tab;
