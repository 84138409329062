import Keycloak from 'keycloak-js';

// Keycloak configuration:
const keycloak = Keycloak({
  url: process.env.REACT_APP_KEYCLOAK,
  realm: 'wwtc',
  clientId: ['tessa.its', 'tessa.its-telekom.eu', 'tessa.automation.telekom.net'].includes(window.location.hostname) ? 'tessaprod' : 'tessadev',
});

export default keycloak;
