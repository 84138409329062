import React, { useEffect, useState } from 'react';
import { Container, Button, Spinner } from 'react-bootstrap';

import useGetUsers from 'hooks/services/user/useGetUsers';
import useUser from 'hooks/useUser';
import ScheduledCampaigns from './ScheduledCampaigns';
import RunningJobs from './RunningJobs';
// import NewDevelopmentEnvironment from './NewDevelopmentEnvironment';
import useGetScheduledJobs from '../../hooks/services/scheduler/useGetScheduledJobs';
import useGetStatuses from '../../hooks/services/scheduler/useGetStatuses';
import useGetQueuedStatuses from '../../hooks/services/scheduler/useGetQueuedStatuses';
import PageTitle from '../../components/PageTitle';
import Tabs from '../../components/Tabs';
import TestCampaign from './NewTestCampaign/TestCampaign';
import FinishedJobs from './FinishedJobs';
import useAlerts from '../../hooks/useAlerts';

const AutomatedTesting = () => {
  const {
    data: scheduledJobs,
    isLoading: isLoadingScheduledJobs,
    refetch: getScheduledJobs } = useGetScheduledJobs();
  const {
    data: queuedStatuses,
    isLoading: isLoadingQueuedJobs,
    refetch: getQueuedStatuses } = useGetQueuedStatuses();
  const { data: statuses, isLoading: isLoadingStatuses, refetch: getStatuses } = useGetStatuses();
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();
  const [activeScreen, setActiveScreen] = useState<string>('overview');
  const [activeTab, setActiveTab] = useState<string>('scheduled-campaigns');

  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();

  const { isAdmin } = useUser();

  const UPDATE_INTERVAL = 10000;

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  useEffect(() => {
    const interval = setInterval(() => {
      getScheduledJobs();
      getStatuses();
      getQueuedStatuses();
    }, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="pt-4">
      <Container>
        <PageTitle style={{ marginBottom: '0px' }}>Automated Testing</PageTitle>
        {
        (activeScreen === 'NewTestCampaign') && (
          <>
            <h3 style={{ marginBottom: '0px' }}>New Campaign</h3>
            <TestCampaign
              activeScreen={activeScreen}
              setActiveScreen={setActiveScreen}
              setActiveTab={setActiveTab}
              setMessage={setMessage}
              setErrMessage={setErrMessage}
            />
          </>
        )
        }
        {
        (activeScreen === 'overview') && (
        <>
          <h3>Overview</h3>
          <Button
            id="new-test-campaign"
            variant="primary"
            className="ml-auto p-1 mb-3"
            onClick={() => setActiveScreen('NewTestCampaign')}
          >
            <span className="ml-3 mr-3">New Campaign</span>
          </Button>
          <Button
            id="new-development-environment"
            variant="primary"
            className="ml-auto p-1 mb-3"
            hidden
          >
            <span className="ml-3 mr-3">New Development Environment</span>
          </Button>

          <Tabs
            activeTab={activeTab}
            block
            onChange={(value) => setActiveTab(value)}
            tabs={[{
              content: 'Scheduled Campaigns',
              value: 'scheduled-campaigns',
            }, {
              content: 'Running Campaigns',
              value: 'running-campaigns',
            }, {
              content: 'Finished Campaigns',
              value: 'finished-campaigns',
            }]}
          />
          {(isLoadingScheduledJobs || isLoadingQueuedJobs
            || isLoadingStatuses || isLoadingUsers) ? (
              <div className="spinner-container">
                <Spinner animation="border" role="status" className="spinner" />
                <span>Loading...</span>
              </div>
            ) : (
              <div className="border border-top-0 rounded-bottom bu-px-3 bu-py-2">
                {activeTab === 'scheduled-campaigns' && scheduledJobs && users
                && (
                <ScheduledCampaigns
                  scheduledJobs={scheduledJobs}
                  getScheduledJobs={getScheduledJobs}
                  setMessage={setMessage}
                  setErrMessage={setErrMessage}
                  users={users}
                />
                )}
                {activeTab === 'running-campaigns' && statuses && queuedStatuses && users
                && (
                <RunningJobs
                  scheduledJobs={scheduledJobs}
                  getScheduledJobs={getScheduledJobs}
                  getQueuedStatuses={getQueuedStatuses}
                  statuses={statuses}
                  getStatuses={getStatuses}
                  queuedStatuses={queuedStatuses}
                  users={users}
                  isAdmin={isAdmin}
                  setMessage={setMessage}
                  setErrMessage={setErrMessage}
                />
                )}
                {activeTab === 'finished-campaigns' && statuses && queuedStatuses && users
                && (
                <FinishedJobs
                  scheduledJobs={scheduledJobs}
                  getScheduledJobs={getScheduledJobs}
                  getQueuedStatuses={getQueuedStatuses}
                  statuses={statuses}
                  getStatuses={getStatuses}
                  queuedStatuses={queuedStatuses}
                  users={users}
                  isAdmin={isAdmin}
                  setMessage={setMessage}
                  setErrMessage={setErrMessage}
                />
                )}
              </div>
            )}
        </>
        )
}
      </Container>
    </div>
  );
};

export default AutomatedTesting;
