import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type KillStatusData = {
  id: number;
};

export default function useKillStatus(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ id }: KillStatusData) => axios.put(
    `/scheduler/api/status/${id}/kill`,
  ).then(({ data }) => data), options);
}
