import React, { useRef, useState } from 'react';

import FocusTrap from 'focus-trap-react/dist/focus-trap-react';

import clsx from 'clsx';

import {
  Button, Col, Form, Overlay, Popover, Row,
} from 'react-bootstrap';

function DefaultColumnFilter({
  column: {
    filterValue, overlayContainer, preFilteredRows, setFilter,
  },
  gotoPage,
}: any) {
  const count = preFilteredRows.length;
  const buttonRef = useRef(null);

  const [show, setShow] = useState(false);
  const [value, setValue] = useState(filterValue);

  const handleFilterButtonClick = (event: any) => {
    event.stopPropagation();
    setShow(!show);
  };

  const handleOkButtonClick = () => {
    gotoPage(0);
    setFilter(value);
    setShow(false);
  };

  const handleResetButtonClick = () => {
    gotoPage(0);
    setFilter();
    setShow(false);
  };

  return (

    <>
      <button
        className={clsx('filter-button text-button', { 'text-info': Boolean(filterValue) })}
        onClick={handleFilterButtonClick}
        ref={buttonRef}
        type="button"
        aria-label="Text Filter Button"
      >
        <span className={clsx('filter-icon', { active: filterValue })}>
          <i className="icon icon-search" />
        </span>
      </button>
      <Overlay
        container={overlayContainer}
        onHide={() => setShow(false)}
        placement="bottom-end"
        rootClose
        rootCloseEvent="click"
        show={show}
        target={buttonRef.current}
      >
        {(props) => (
          <Popover id="popover-basic" {...props} placement="bottom">
            <Popover.Title as="h3">Search</Popover.Title>
            <FocusTrap
              active={show}
              focusTrapOptions={{
                clickOutsideDeactivates: true,
                onDeactivate: () => setShow(false),
              }}
            >
              <Popover.Content>
                <Form.Group>
                  <Form.Control
                    value={value || ''}
                    onChange={(e) => {
                      // Set undefined to remove the filter entirely
                      setValue(e.target.value || undefined);
                    }}
                    placeholder={`Search ${count} records...`}
                  />
                </Form.Group>
                <Row className="align-items-center flex-nowrap">
                  <Col>
                    <button className="text-button" onClick={handleResetButtonClick} type="button">
                      Reset
                    </button>
                  </Col>
                  <Col xs="auto">
                    <Button className="ml-4 px-3" onClick={handleOkButtonClick} size="sm">
                      OK
                    </Button>
                  </Col>
                </Row>
              </Popover.Content>
            </FocusTrap>
          </Popover>
        )}
      </Overlay>
    </>
  );
}

export default DefaultColumnFilter;
