import React, {
  useEffect, useState,
} from 'react';
import {
  Button,
  Modal,
  Tabs,
  Tab,
  Card,
} from 'react-bootstrap';
import _ from 'lodash';
import useGetProbes from '../../../../hooks/services/probe/useGetProbes';
import useGetSubscribers from '../../../../hooks/services/simManagement/subscriber/useGetSubscribers';
import useAlerts from '../../../../hooks/useAlerts';
import EditDeviceTable from './EditDeviceTable';
import EditSubscriberTable from './EditSubscriberTable';
import EditMultiplexerTable from './EditMultiplexerTable';
import useConfirm from '../../../../hooks/useConfirm';
import useDeleteProbeDevice from '../../../../hooks/services/probe/useDeleteProbeDevice';

// e.g. array groupData.sims, iccid
const isSelected = (arr: string[] | undefined, searchValue: any) => (
  arr?.includes(searchValue)
);

interface IModalProps {
  show: boolean
  onHide: () => void;
  onDeleteDeviceCancel: () => void;
  probeName: string;
  deviceData: any;
  getProbes: any;
}

const EditDeviceModal = ({
  onHide, onDeleteDeviceCancel, show, probeName, deviceData, getProbes,
}: IModalProps) => {
  const [errorMessage, setErrMessage] = useState('');
  const [message, setMessage] = useState('');
  const { showAlert } = useAlerts();

  // success message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errorMessage) {
      showAlert({
        id: `new-message_${errorMessage}`,
        content: `${errorMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errorMessage, showAlert]);

  const { data: probes } = useGetProbes();
  const { data: sims } = useGetSubscribers();

  const [selectedSims, setSelectedSims] = useState(
    deviceData?.subscribers
      ? Object.keys(deviceData?.subscribers)
      : []
  );

  const [deleteDevice] = useDeleteProbeDevice(
    {
      onSuccess: () => {
        setMessage(`device ${deviceData?.name} has been deleted`);
        getProbes();
        onHide();
      },
      onError: () => setErrMessage(`failed to delete device ${deviceData?.name}`),
    },
  );

  const confirm = useConfirm();
  const handleDeleteDevice = async ({ device }: any) => {
    onHide();
    confirm({
      body: `Are you sure to delete the device ${deviceData?.name}?`,
      onOk: () => deleteDevice({ probeName, device }),
      onCancel: () => onDeleteDeviceCancel(),
      title: `Delete device ${deviceData?.name}`,
    });
  };

  useEffect(() => {}, [probes, sims, selectedSims]);
  useEffect(() => { getProbes(); }, []);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      id="device-edit-modal"
      backdrop="static"
    >
      <Card>
        <Card.Header>
          <span className="align_left">
            {`Edit device ${deviceData.name} (${deviceData.status})`}
          </span>
          <Button
            variant="light"
            className="text-button-link ml-auto pr-2 pl-2"
            key={`${deviceData?.name}_delete`}
            id={`delete-device_${deviceData?.name}`}
            onClick={() => (
              handleDeleteDevice({ device: deviceData })
            )}
            aria-label="delete_device"
          >
            delete device
          </Button>
          <button type="button" className="close" onClick={onHide} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey="device"
            id="group-edit-tabs"
            className="mb-3 border-bottom"
          >
            <Tab className="modalTab" eventKey="device" title="DEVICE">
              <EditDeviceTable
                onHide={onHide}
                setErrMessage={setErrMessage}
                setMessage={setMessage}
                probeName={probeName}
                deviceData={deviceData}
                getProbes={getProbes}
              />
            </Tab>
            {_.isEmpty(deviceData.multiplexers) && (
              <Tab className="modalTab" eventKey="subscribers" title="SUBSCRIBERS">
                <EditSubscriberTable
                  onHide={onHide}
                  setErrMessage={setErrMessage}
                  setMessage={setMessage}
                  selectedSims={selectedSims}
                  setSelectedSims={setSelectedSims}
                  isSelected={isSelected}
                  deviceData={deviceData}
                  getProbes={getProbes}
                />
              </Tab>
            )}
            {(deviceData?.type !== 'Analogue_Modem' && deviceData?.type !== 'IP_Phone')
              && (
                <Tab className="modalTab" eventKey="multiplexers" title="MULTIPLEXERS">
                  <EditMultiplexerTable
                    setErrMessage={setErrMessage}
                    setMessage={setMessage}
                    probeName={probeName}
                    deviceData={deviceData}
                    getProbes={getProbes}
                    onHide={onHide}
                  />
                </Tab>
              )}
          </Tabs>
        </Card.Body>
      </Card>
    </Modal>
  );
};

export default EditDeviceModal;
