import React, { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type ModalProps = {
  title: string;
  htmlDoc?: HTMLDocument;
  htmlBackgroundElement?: HTMLElement | null;
  show: boolean;
  onClose: () => void;
  footer?: ReactNode;
};

const hideElement = (el: HTMLElement) => {
  el.setAttribute('aria-hidden', 'true');
  el.setAttribute('style', 'display:none;');
//   el.style.overflow = 'hidden';
//   el.style.visibility = 'hidden';
//   el.style.display = 'none';
};

const showElement = (el: HTMLElement) => {
  el.removeAttribute('aria-hidden');
  // todo: why not working with setAttribute?
  // el.setAttribute('style', 'display:block;');
  // el.style.overflow = 'auto';
  // el.style.visibility = 'visible';
  // eslint-disable-next-line no-param-reassign
  el.style.display = 'block';
};

const ModalPortal: React.FC<ModalProps> = ({
  children,
  show,
  htmlBackgroundElement,
  htmlDoc,
  onClose,
  title,
  footer,
}) => {
  let doc = htmlDoc;
  if (!doc) doc = document;
  const [modalRoot] = useState(doc.getElementById('modal'));
  const [element] = useState<HTMLDivElement>(doc.createElement('div'));
  element.className = 'modalWin';
  element.style.position = 'absolute';
  element.style.top = '40px';
  element.style.left = '40px';
  element.style.right = '40px';
  element.style.bottom = '40px';
  element.style.border = '1px solid #ccc';
  element.style.background = '#fff';
  element.style.overflow = 'auto';
  element.style.borderRadius = '4px';
  element.style.outline = 'none';
  element.style.padding = '20px';
  element.style.border = '2px solid #ccc';

  useEffect(() => {
    modalRoot?.appendChild(element);
    return () => {
      modalRoot?.removeChild(element);
    };
  }, []);

  const getModalContent = () => (
    <>
      <div className="modal-header">
        <div className="modal-title h4">{title}</div>
        <button aria-label="close" type="button" className="close" onClick={() => onClose()}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
      </div>
      <div className="modal-body">
        {children}
      </div>
      {footer && (
        <div className="modal-footer">
          {footer}
        </div>
      )}
    </>
  );

  if (show) {
    if (htmlBackgroundElement) {
      hideElement(htmlBackgroundElement);
      showElement(element);
    }
    return createPortal(getModalContent(), element);
  }
  if (htmlBackgroundElement) {
    showElement(htmlBackgroundElement);
    hideElement(element);
  }
  return null;
};

export default ModalPortal;
