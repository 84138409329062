import _ from 'lodash';
import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import { Device } from './interfaces';

type EditProbeDeviceRequestData = {
  probeName: string;
  device: Device
};

export default function useEditProbeDevice(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  const queryCache = useQueryCache();

  return useMutation(({ probeName, device }: EditProbeDeviceRequestData) => axios.put(
    `/cms/api/probes/${probeName}/devices/${device.device_id}`,
    device,
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }
      queryCache.invalidateQueries('probeDevice');
      queryCache.invalidateQueries('probeDevices');
    }
  });
}
