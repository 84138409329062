import { useContext } from 'react';

import { ConfirmContext } from 'context/Confirm/ConfirmProvider';

const useConfirm = () => {
  const { setConfirmModal } = useContext(ConfirmContext);

  return (props: any) => {
    setConfirmModal({
      show: true,
      ...props,
    });
  };
};

export default useConfirm;
