import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import ImportSimCardsModal from './ImportSimCardsModal';
import Downloader from '../../../../components/Downloader';

const ImportSimCardsTab = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Row>
        <Col className="col-xl-9 col-lg-8 col-md-6 col-sm-12 mb-3">
          <p>
            New subscribers can be added only in form of excel sheet data.
            You can download the template .xlsx file here if you don&apos;t have one already.
          </p>
          <p>
            In the file there are two sheets visible: the &quot;Template&quot;
            {' '}
            itself and the other - &quot;Example&quot; - with example SIM cards data.
          </p>
        </Col>
        <Col className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div className="ml-auto mr-2 text-right">
            <Downloader
              value="Download Template"
              variant="secondary"
              filename="Recatt_SIM.xlsx"
            />
            <Button
              className="mt-2"
              id="import-sim-cards"
              variant="primary"
              onClick={() => setShowModal(true)}
            >
              Import Subscribers
            </Button>
          </div>
          {showModal && <ImportSimCardsModal onHide={() => setShowModal(false)} show={showModal} />}
        </Col>
      </Row>
    </>
  );
};

export default ImportSimCardsTab;
