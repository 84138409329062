import ActlHelpPage from 'components/HelpPageComponents/Actl/ActlHelpPage';
import GitlabHelpPage from 'components/HelpPageComponents/GitlabHelpPage';
import AdministrationLayout from 'components/layout/AdministrationLayout';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link, Route, NavLink } from 'react-router-dom';
// import General from './General';

const menuItems = [
  // { title: 'General', link: '/help/documentation', page: General },
  // eslint-disable-next-line max-len
  { title: 'Atomic Step Definitions', link: '/help/documentation/atomic_step_definitions', page: GitlabHelpPage },
  { title: 'Automation Common Test Library', link: '/help/documentation/actl', page: ActlHelpPage },
];

const Landing = () => (
  <AdministrationLayout
    sidebar={(
      <ListGroup className="bu-mt-1_5" variant="flush">
        {menuItems.map((menuItem) => (
          <ListGroup.Item
            action
            as={NavLink}
            to={menuItem.link}
            key={menuItem.title}
            id={menuItem.title.toLowerCase().replace(' ', '-')}
            exact
          >
            {menuItem.title}
          </ListGroup.Item>
        ))}
        <ListGroup.Item><a href="https://gitlab01.its-telekom.eu/firefly/tessa/frontend/-/wikis/home" target="_blank" rel="noreferrer">Wiki</a></ListGroup.Item>
      </ListGroup>
    )}
  >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><Link to="/help">Help</Link></li>
      <li className="breadcrumb-item"><Link to="/help/documentation/atomic_step_definitions">Documentation</Link></li>
      {menuItems.map((menuItem) => (
        <Route
          key={menuItem.title}
          path={menuItem.link}
          exact
          render={() => <li className="breadcrumb-item">{menuItem.title}</li>}
        />
      ))}
    </ol>
    {menuItems.map((menuItem) => (
      <Route
        key={menuItem.title}
        path={menuItem.link}
        exact
        component={menuItem.page}
      />
    ))}
  </AdministrationLayout>
);

export default Landing;
