import { Button, Container } from 'react-bootstrap';

export const Login = () => {
  const handleLogin = () => {
    window.open('/login-redirect', '_blank',);
  };

  return (
    <Container fluid className="text-center">
      <h1>TESSA Login</h1>
      <Button className="login-button" onClick={handleLogin} type="button">
        Login
      </Button>
      <p>Please log in to access TESSA</p>
    </Container>
  );
};

export default Login;
