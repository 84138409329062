import _ from 'lodash';
import React, { ReactNode, useMemo, useState } from 'react';

import { useDrag, useDrop } from 'react-dnd';

import { Parser as Json2csvParser } from 'json2csv';
import { CSVLink } from 'react-csv';

import {
  Button, ButtonGroup, Col, Dropdown, Form, Row,
} from 'react-bootstrap';

import TableSearchFormField from './TableSearchFormField';

interface Props {
  children?: ReactNode;
  onClick: (event: any) => void;
}
export type Ref = HTMLButtonElement;

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): any => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  );
  return [...columnOrder];
};

// eslint-disable-next-line
const CustomToggle = React.forwardRef<Ref, Props>(({ children, onClick }, ref) => (
  <Button
    className="ml-3 bu-pl-1 bu-pr-1"
    onClick={onClick}
    ref={ref}
    variant="outline-primary"
  >
    {children}
  </Button>
));

const SettingsMenuItem = ({
  column,
  columns,
  columnOrder,
  setColumnOrder,
  toggleHideAllColumns
}: {
  column: any,
  columns: any,
  columnOrder: any,
  setColumnOrder: any,
  toggleHideAllColumns: (value: boolean) => void
}) => {
  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: any) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder
      );
      setColumnOrder(newColumnOrder);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });

  //  console.log(columns, columnOrder);

  return (
    <div ref={dropRef}>
      <Dropdown.Item
        onClick={() => {
          if (_.filter(columns, (col: any) => col.isVisible).length === 0) {
            toggleHideAllColumns(false);
            _.map(columns, (colz: any) => (
              !colz?.isVisible ? colz?.toggleHidden() : null
            ));
          }
          if (_.filter(columns, (col: any) => col.isVisible).length === 1
        && column?.isVisible) {
            toggleHideAllColumns(true);
          } else {
            column?.toggleHidden();
          }
        }}
        ref={previewRef}
      >
        <div className="d-flex">
          <button
            className="text-button"
            onClick={(event) => { event.preventDefault(); event.stopPropagation(); }}
            ref={dragRef}
            style={{ fontSize: '13px', opacity: isDragging ? 0.5 : 1 }}
            type="button"
          >
            <span className="icon icon-export" />
            <span className="icon icon-import" />
          </button>
          <span className="flex-grow-1 ml-3">
            {column.Header}
          </span>
          {column?.isVisible
            ? <span className="icon icon-confirm ml-3" />
            : <span className="ml-3" style={{ display: 'inline-block', width: '16px' }} />}
        </div>
      </Dropdown.Item>
    </div>
  );
};

const SettingsMenu = ({
  columns,
  columnOrder,
  setColumnOrder,
  toggleHideAllColumns
}: {
  columns: any,
  columnOrder: any,
  setColumnOrder: any,
  toggleHideAllColumns: (value: boolean) => void
}) => {
  const [show, setShow] = useState(false);

  return (
    <Dropdown
      className="d-inline-block"
      onToggle={(isOpen, _event, metadata) => {
        if (metadata?.source !== 'select') {
          setShow(isOpen);
        }
      }}
      show={show}
    >
      <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
        Columns
      </Dropdown.Toggle>
      <Dropdown.Menu align="right">
        <Dropdown.Header style={{ textAlign: 'center' }}>Visible Columns</Dropdown.Header>
        <Dropdown.Item onClick={() => {
          if (_.filter(columns, (column: any) => !column.isVisible).length === 0) {
            toggleHideAllColumns(true);
          } else {
            toggleHideAllColumns(false);
          }
        }}
        >
          {_.filter(columns, (column: any) => !column.isVisible).length === 0 ? 'Hide' : 'Show'} all
        </Dropdown.Item>
        {_.chain(columns)
          .reject((column) => _.includes(['id', '__actions', '__selection'], column?.id))
          .map((column) => (
            <SettingsMenuItem
              column={column}
              columns={columns}
              columnOrder={columnOrder}
              key={column.id}
              setColumnOrder={setColumnOrder}
              toggleHideAllColumns={toggleHideAllColumns}
            />
          )).value()}
      </Dropdown.Menu>
    </Dropdown>
  );
};

type TableActionsProps = {
  columns: any,
  columnOrder: any,
  data: any,
  DeleteButtonProps?: any,
  ExportButtonProps?: any,
  globalFilter: string,
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void,
  hideDeleteButton?: boolean,
  hideExportButton?: boolean,
  hideNewButton?: boolean,
  hideRefreshButton?: boolean,
  hideSearchInput?: boolean,
  NewButtonProps?: any,
  RefreshButtonProps?: any,
  SearchInputProps?: any,
  setColumnOrder: any,
  setGlobalFilter: any,
  showSettingsMenu?: boolean;
  toggleHideAllColumns: (value: boolean) => void,
};

const TableActions = ({
  columns,
  columnOrder,
  data,
  DeleteButtonProps,
  ExportButtonProps,
  globalFilter,
  gotoPage,
  hideDeleteButton = false,
  hideExportButton = false,
  hideNewButton,
  hideRefreshButton = false,
  hideSearchInput,
  NewButtonProps,
  RefreshButtonProps,
  SearchInputProps,
  setColumnOrder,
  setGlobalFilter,
  showSettingsMenu,
  toggleHideAllColumns
}: TableActionsProps) => {
  const { content: deleteButtonContent, ...deleteButtonProps } = DeleteButtonProps ?? {};
  const { content: newButtonContent, ...newButtonProps } = NewButtonProps ?? {};

  const filename = ExportButtonProps?.filename
    ? `${ExportButtonProps?.filename}-${new Date()
      .toISOString()
      .substring(0, 10)}`
    : new Date().toISOString().substring(0, 10);

  const CSVData = useMemo(() => {
    if (!hideExportButton) {
      const fields = columns
        ?.filter((column: any) => column.export !== false)
        .map(({ accessor, Header, id }: any) => ({
          label: Header,
          value: id || accessor,
        }));

      const exportData = data?.map(({ values }: any) => {
        const temp: any = {};
        fields.forEach(({ value }: any) => {
          // extract 'enabled' value from <Toggle> JSX element in SIM arrays
          if (value === 'enabled' && React.isValidElement(values[value])) {
            const { props: { active } } = values[value];
            temp[value] = active;
          } else {
            temp[value] = values[value];
          }
        });
        return temp;
      });

      let parsedData = null;

      try {
        parsedData = `"SEP=,"\n${new Json2csvParser({
          fields,
          eol: '\n',
        }).parse(exportData)}`;
      } catch (error) {
        // eslint-disable-next-line
        console.log('error from csv', error, fields, exportData);
      }

      return parsedData;
    }
    return null;
  }, [data, columns]);

  const handleSearchChange = (event: any) => {
    const value = event?.target?.value || undefined;
    console.log('setting global filter');
    gotoPage(0);
    setGlobalFilter(value);
  };

  return (
    <Row className="align-items-center bu-mb-2">
      {!hideSearchInput && (
        <Col lg={5} md="auto" xs={12}>
          <span className="sr-only">
            <Form.Label htmlFor="table-search">Search</Form.Label>
          </span>
          <TableSearchFormField id="table-search" onChange={handleSearchChange} value={globalFilter} {...SearchInputProps} />
        </Col>
      )}
      <Col />
      <Col md="auto" xs={12}>
        {!hideRefreshButton && RefreshButtonProps?.onClick && (
          <Button variant="outline-primary" {...RefreshButtonProps}>
            {RefreshButtonProps?.content || 'Refresh'}
          </Button>
        )}
        {!hideNewButton && !_.isEmpty(NewButtonProps) && (
        <Button className="ml-3" variant="outline-primary" {...newButtonProps}>
          {newButtonContent || 'New'}
        </Button>
        )}
        {showSettingsMenu && (
        <SettingsMenu
          columns={columns}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          toggleHideAllColumns={toggleHideAllColumns}
        />
        )}
        <ButtonGroup className="ml-3">
          {!hideExportButton && CSVData && (
            <CSVLink
              className="btn btn-outline-primary"
              data={CSVData as string}
              filename={`${filename}.csv`}
            >
              Export
            </CSVLink>
          )}
          {/* {!hideExportButton && (
          <Dropdown
            content="Export"
            variant="secondary"
            items={[
              {
                as: CSVLink,
                content: 'CSV',
                data: CSVData,
                filename: `${filename}.csv`,
                key: 'csv',
                ...ExportButtonProps?.CSVButtonProps,
              },
              {
                content: 'PDF',
                key: 'pdf',
                onClick: handleExportPDFButtonClick,
                ...ExportButtonProps?.PDFButtonProps,
              },
            ]}
            placement="bottom-end"
            {...ExportButtonProps}
          >
            Export
          </Dropdown>
        )} */}
        </ButtonGroup>
        {!hideDeleteButton && !_.isEmpty(DeleteButtonProps) && (
          <Button className="ml-3" variant="outline-danger" {...deleteButtonProps}>
            {deleteButtonContent || 'Delete'}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default TableActions;
