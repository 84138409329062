import React, {
  createContext, FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';
import { WindowContainer, IWindowsManagerContext, WindowState } from './interface';
import Popout from '../window/Popout';

const WindowsManagerContext = createContext({} as IWindowsManagerContext);

interface WindowsManagerProps {
}

// manage opening separate windows with custom content, stores opened windows in state
// remove window from state on window close (destroy)
const WindowsManagerProvider: FunctionComponent<WindowsManagerProps> = (
  {
    children,
  }: React.PropsWithChildren<WindowsManagerProps>,
) => {
  // hold opened windows
  const [windows, setWindows] = useState<WindowContainer[]>([]);
  // reference used in unmounting - to have fresh state of windows, when unmounting occurs
  const latestWindows = useRef(windows);

  // open separate window
  const openWindow = (win: WindowContainer) => {
    setWindows((prevState) => ([...prevState, { ...win, state: 'OPEN' }]));
  };

  // remember the window object after opening it
  const setWin = useCallback((winId: string, extWin: Window) => {
    setWindows((prevState) => (
      prevState.map((win) => (win.id === winId ? { ...win, externalWin: extWin } : win))
    ));
  }, []);

  // start closing window
  const startDestroyWindow = useCallback((id: string) => {
    setWindows((prevState) => (
      prevState.map((win) => (win.id === id ? { ...win, state: 'CLOSING' } : win))
    ));
  }, []);

  // close window - notify about closing (to properly close websocket) and close window
  const closeWindow = useCallback((win: WindowContainer) => {
    // announce termination
    startDestroyWindow(win.id);
    // close window
    if (win.externalWin) win.externalWin.close();
  }, [startDestroyWindow]);

  // finish closing window
  const finishDestroyWindow = (id: string) => {
    const window = windows.find((win) => win.id === id);
    if (window) {
      window.externalWin?.close();
    }
    setWindows((prevState) => (
      prevState.filter((win) => win.id !== id)
    ));
  };

  // register unload events if any opened windows exist on page close/reload
  useEffect(() => {
    if (windows.length === 0) {
      return () => {
      };
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      // ask if the user really wants to leave (close, refresh browser)
      event.preventDefault();
      const text = 'Other open TESSA windows will also be closed.';
      // eslint-disable-next-line no-param-reassign
      event.returnValue = text;
      return text;
    };

    const handleUnload = async () => {
      if (windows) windows.forEach((win) => closeWindow(win));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [windows, closeWindow]);

  useEffect(() => {
    // update reference
    latestWindows.current = windows;
  }, [windows]);

  // on unmount
  useEffect(() => () => {
    if (latestWindows.current) {
      latestWindows.current.forEach((win) => closeWindow(win));
    }
  }, [closeWindow]);

  const focusWindow = (id: string) => {
    const window = windows.find((win) => win.id === id);
    // todo implemenet focusing window
    if (window) window.externalWin?.focus();
  };

  // check if window is in list
  const isOpen = (id: string): boolean => {
    const window = windows.find((win) => win.id === id);
    return !!(window && window.state === 'OPEN');
  };

  // returns window state - OPEN, CLOSE, CLOSING
  const getState = (id: string): WindowState => {
    const window = windows.find((win) => win.id === id);
    return (window && window.state);
  };

  // returns window's document
  const getDoc = (id: string): Document | undefined => {
    const window = windows.find((win) => win.id === id);
    return (window && window.externalWin?.document);
  };

  // returns window size
  const getSize = (id: string): { width: number, height: number } | undefined => {
    const window = windows.find((win) => win.id === id);
    return (window && window.size);
  };

  // handle window resize - save state
  const onWindowResize = useCallback((winId: string, width: number, height: number) => {
    setWindows((prevState) => (
      prevState.map((win) => (win.id === winId ? { ...win, size: { width, height } } : win))
    ));
  }, []);

  return (
    <WindowsManagerContext.Provider value={{
      windows,
      openWindow,
      startDestroyWindow,
      finishDestroyWindow,
      focusWindow,
      isOpen,
      getState,
      getSize,
      getDoc,
    }}
    >
      <>
        {children}
        {/* open separate windows */}
        {windows && windows.map((win) => (
          <Popout
            key={win.id}
            title={win.title}
            closeWindow={startDestroyWindow}
            state={win.state}
            onWindowResize={onWindowResize}
            setWin={setWin}
            winId={win.id}
          >
            <div className="d-flex align-items-center flex-column" style={{ height: '100%' }}>
              <div className="mb-auto p-2 align-self-stretch">
                {win.content}
              </div>
            </div>
          </Popout>
        ))}
      </>
    </WindowsManagerContext.Provider>
  );
};
const DevicesConsumer = WindowsManagerContext.Consumer;
export { WindowsManagerProvider, DevicesConsumer, WindowsManagerContext };
