import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Table from 'components/Table';

const EditBoardsTable = (props: any) => {
  const { sortedBoards, isSelected, selectedBoards, setSelectedBoards } = props;
  const arrays = Object.keys(sortedBoards) || [];

  const handleChangeBoards = (e: any) => {
    const { value } = e.target;
    setSelectedBoards((prevSelectedBoards: any) => {
      if (isSelected(prevSelectedBoards, value)) {
        return prevSelectedBoards.filter((val: any) => val !== value);
      }
      return [...prevSelectedBoards || [], value];
    });
  };

  const columns = useMemo(() => [
    {
      Header: 'Array',
      accessor: 'array',
      disableFilters: true,
      isSortable: false,
      disableSortBy: true,
      Cell: ({ value, row, data }: any) => {
        // Check if this row is the first occurrence of the array value in the filtered/sorted data
        const firstOccurrenceIndex = data.findIndex(
          (r: any) => r.array === value
        );
        // Show the value only if this row is the first occurrence
        if (row.index === firstOccurrenceIndex) {
          return <span className="ml-2">{value}</span>;
        }
        return null;
      },
    },
    {
      Header: 'Selected',
      accessor: 'checked',
      filter: 'multiValue',
      isSortable: false,
      disableFilters: true,
      disableSortBy: true,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      Cell: (props: any) => {
        const { cell: { row } } = props;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { board_path, checked } = row.original;
        return (
          <>
            <Form.Check
              defaultChecked={checked === 'Selected'}
              defaultValue={board_path}
              id={`groups-board.${board_path}`}
              key={`groups-board.${board_path}`}
              inline
              aria-label="Select"
              onClick={handleChangeBoards}
            />
          </>
        );
      },
    },
    {
      Header: 'Board path',
      accessor: 'board_path',
      disableFilters: true,
      isSortable: false,
      disableSortBy: true,
    },
  ], []);

  const tableData = useMemo(
    () => arrays?.flatMap((simArray) => sortedBoards[simArray].map((item: any) => ({
      checked: isSelected(selectedBoards, item.board_path) ? 'Selected' : 'Not selected',
      array: simArray,
      ...item,
    }))),
    [arrays, sortedBoards, selectedBoards, isSelected]
  );

  return (
    <Table
      actionsPosition="left"
      columns={columns}
      data={tableData ?? []}
      hideExportButton
      disablePagination
      skipReset={false}
      stickyActions
      stickyHeader
    />
  );
};

export default EditBoardsTable;
