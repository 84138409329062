import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from '../../axios/useAxiosWithToken';

type EditChannelValueData = {
  probe: string;
  channel: string;
  level: number | number[] | string;
};

export default function useEditProbeChannelValue(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken({ ...options, baseURL: process.env.REACT_APP_ATTENUATORS });

  return useMutation(({ probe, channel, level }: EditChannelValueData) => axios.patch(
    `/attenuator/api/probes/${probe}/channels/${channel}/set/${level}`
  ).then(({ data }) => data), options);
}
