import React, { useEffect, useState } from 'react';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import useGetReservations from 'hooks/services/reservation/useGetReservations';
import useGetMappings from 'hooks/services/simManagement/mapping/useGetMappings';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useGetProbeLocations from 'hooks/services/probe/location/useGetProbeLocations';
import { Probe } from 'hooks/services/probe/interfaces';

export type SimMapping = {
  channel: {
    path: string,
    ready: boolean,
    active: boolean,
  },
  mapped?: {
    iccid: string,
    path: string,
  },
  using?: {
    iccid: string,
    path: string,
  }
};

interface IPollingContext {
  reservations: any[],
  isLoadingReservations: boolean,
  getReservations: () => any,
  reservationsError: any,

  probes: any[],
  isLoadingProbes: boolean,
  getProbes: () => any,
  probesError: any,

  mappings: SimMapping[],
  isLoadingMappings: boolean,
  getMappings: () => any,
  mappingsError: any,

  sims: any,
  isLoadingSims: boolean,
  getSims: () => any,
  simsError: any,
}

const PollingContext = React.createContext<IPollingContext>({
  reservations: [],
  isLoadingReservations: false,
  getReservations: () => null,
  reservationsError: null,

  probes: [],
  isLoadingProbes: false,
  getProbes: () => null,
  probesError: null,

  mappings: [],
  isLoadingMappings: false,
  getMappings: () => null,
  mappingsError: null,

  sims: [],
  isLoadingSims: false,
  getSims: () => null,
  simsError: null,
});

const PollingProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [probes, setProbes] = useState<any[]>([]);
  const { data: locations } = useGetProbeLocations();
  const {
    data: dirtyProbes,
    isLoading: isLoadingProbes,
    refetch: getProbes,
    error: probesError
  } = useGetProbes();

  const {
    data: reservations,
    isLoading: isLoadingReservations,
    refetch: getReservations,
    error: reservationsError
  } = useGetReservations();

  const {
    data: mappings,
    isLoading: isLoadingMappings,
    refetch: getMappings,
    error: mappingsError,
  } = useGetMappings();

  const {
    data: sims,
    isLoading: isLoadingSims,
    refetch: getSims,
    error: simsError
  } = useGetSubscribers();

  const refreshPeriod = 5;

  useEffect(() => {
    getProbes();
    getReservations();
    getMappings();
    getSims();
    const timerId = setInterval(() => {
      getProbes();
      getReservations();
      getMappings();
      getSims();
    }, refreshPeriod * 1000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (locations && dirtyProbes) {
      setProbes(
        dirtyProbes?.map((probe: Probe) => {
          // loc:
          // country_iso: "FKT"
          // country_name: "Faktory"
          const loc = locations.find((val: any) => val.country_iso === probe.country_iso);
          return {
            ...probe,
            country_iso: loc?.country_iso || 'UNK',
            country_name: loc?.country_name || 'Unknown location',
            location: `${loc?.country_name || 'Unknown location'}`,
            device: (probe?.devices && probe?.devices[0]) ? probe.devices[0].name : 'no device',
          };
        })
      );
    }
  }, [locations, dirtyProbes]);

  return (
    <PollingContext.Provider value={{
      reservations,
      isLoadingReservations,
      getReservations,
      reservationsError,

      probes,
      isLoadingProbes,
      getProbes,
      probesError,

      mappings,
      isLoadingMappings,
      getMappings,
      mappingsError,

      sims,
      isLoadingSims,
      getSims,
      simsError,
    }}
    >
      {children}
    </PollingContext.Provider>
  );
};

const useApiPolling = () => React.useContext(PollingContext);

export { PollingProvider, useApiPolling, PollingContext };
