import React, { useEffect } from 'react';
import Channel from './Channel';
import useGetChannelValues from '../../../hooks/services/attenuator/useGetChannelValues';

interface IChannels {
  probe: string;
  max: number;
  min: number;
  channel: string;
  getAttDetails: any;
  channelDetails: any;
  setLoading: (message: boolean) => void;
}

const FetchApi = ({ probe,
  min,
  max,
  channel,
  getAttDetails,
  channelDetails,
  setLoading,
}: IChannels) => {
  const { data: channelValues, refetch: getChannelValues } = useGetChannelValues(probe);

  useEffect(() => {
    if (channelValues) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [channelValues, channelDetails]);

  const {
    rf_min: rfMin,
    rf_max: rfMax
  } = channelDetails?.find((e: any) => e.name === channel)?.unit || {};

  useEffect(() => {
    getChannelValues();
  }, [probe]);

  return (
    channelValues
      ? (
        <Channel
          key={channel}
          channel={channel}
          max={max}
          min={min}
          rf_max={rfMax}
          rf_min={rfMin}
          level={channelValues[channel]}
          probe={probe}
          getAttDetails={getAttDetails}
          getChannelValues={getChannelValues}
        />
      )
      : <></>
  );
};

export default FetchApi;
