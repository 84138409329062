import {
  RESPONSE_ERROR,
  CLEAR_ERRORS,
  GET_USERS,
  LOAD_USER_DATA,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_LOADING,
  DEV_LOGIN,
} from './types';

export default (state: any, action: any) => {
  switch (action.type) {
    // todo remove this when api login to BE is safe
    case DEV_LOGIN:
      return {
        ...state,
        users: action.payload.users,
        groups: action.payload.groups,
        claims: action.payload.claims,
        user: action.payload.user,
        sims: action.payload.sims,
        simArrays: action.payload.simArrays,
        isAuthenticated: true,
        loading: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload.length > 0,
        loading: false,
      };
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        loginUser: null,
      };
    case RESPONSE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case LOAD_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    //  todo update when api for the probes ready
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
