import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type HardRestartData = {
  probeName: string;
  parameters: any;
};

export default function useHardRestart(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ probeName, parameters }: HardRestartData) => axios.post(
    `/cms/api/probes/${probeName}/control/api/arduino/hardrestart`,
    parameters
  ).then(({ data }) => data), {
    ...options
  });
}
