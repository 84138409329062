import _ from 'lodash';
import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type RemoveQueuedStatusData = {
  id: number;
};

export default function useRemoveQueuedStatus(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();
  const queryCache = useQueryCache();

  return useMutation(({ id }: RemoveQueuedStatusData) => axios.put(
    `/scheduler/api/queue/${id}/release`,
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }
      queryCache.invalidateQueries('queue');
    }
  });
}
