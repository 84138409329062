import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import useAlerts from '../hooks/useAlerts';
import Attenuator from './components/Attenuator';
import Channels from './components/Channels';
import useGetList from './hooks/useGetList';

const AttenuatorList = () => {
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const [selectedAtt, setSelectedAtt] = useState<string>();

  const { data: attenuators, refetch: getAttenuators } = useGetList();

  useEffect(() => {
    getAttenuators();
  }, [
    selectedAtt, setSelectedAtt,
  ]);

  return (
    <Container fluid>
      <Row className="ml-5 mr-5">
        <Col xs={12} lg={12}>
          <h1>Attenuators</h1>
          {!selectedAtt && (
            <>
              <p>Select attenuator to manage:</p>
              { attenuators ? (
                attenuators.sort((a: any, b: any) => ((a.name > b.name) ? 1 : -1))
                  .map((att: any) => (
                    <Attenuator
                      key={att.name}
                      name={att.name}
                      setSelectedAtt={setSelectedAtt}
                    />
                  ))
              )
                : (
                  <div>Loading...</div>
                )}
            </>
          )}
          {selectedAtt && (
            <Channels
              attName={selectedAtt}
              setSelectedAtt={setSelectedAtt}
              setErrMessage={setErrMessage}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AttenuatorList;
