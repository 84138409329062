import { useKeycloak } from '@react-keycloak/web';
import { Redirect } from 'react-router-dom';

export const LoginRedirect = () => {
  const { keycloak, initialized } = useKeycloak();

  if (initialized && !keycloak?.authenticated) {
    keycloak.login();
  }

  if (keycloak?.authenticated) {
    return <Redirect to="/login-redirect-close" />;
  }

  return null;
};

export default LoginRedirect;
