import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxios from 'hooks/axios/useAxios';

type CommandData = {
  probeName: string;
  sessionId: string;
  keyData: any;
};
// key example
// {"value": ["a"], "frequency": 10}

export default function useSendKey(options: MutationConfig<any, any>) {
  const axios = useAxios({ baseURL: process.env.REACT_APP_IOS });

  return useMutation(({ probeName, sessionId, keyData }: CommandData) => axios.post(
    `/c/${probeName}/session/${sessionId}/wda/keys`,
    keyData
  ).then(({ data }) => data), options);
}
