import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetPools() {
  const axios = useAxiosWithToken();

  return useQuery('pools', () => axios.get(
    '/sim-manager/api/pools?format=long',
  ).then(({ data }) => data.slice().reverse()), { staleTime: 1000 * 60 * 5 });
}
