import { useContext } from 'react';

import { AlertsContext } from 'context/alerts/AlertsState';

const serverErrorAlert = {
  id: 'server-error',
  content: `We're Sorry. Unfortunately, this function is currently unavailable. Please
  try again later.`,
  variant: 'warning',
};

const useAlerts = () => {
  const alertContext = useContext(AlertsContext);

  return { ...alertContext, serverErrorAlert };
};

export default useAlerts;
