import React, { SyntheticEvent } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import useScheduleSleepJob, { CmdRequestDataType } from 'MiniTessa/hooks/useScheduleSleepJob';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useUser from '../../hooks/useUser';

const Overview = ({
  probes,
  selectedProbes,
  simMapping,
  duration,
  previousStep,
  resetForm,
}: any) => {
  const { uidNumber: userId } = useUser();
  const { data: subscribers } = useGetSubscribers();
  const [setCommand] = useScheduleSleepJob({
    onSuccess: () => {
      resetForm();
      console.log('Command(s) sent!');
    },
    onError: (error) => {
      console.log(`Failed to send command(s):  ${error}`);
    }
  });

  const scheduleJob = (e: SyntheticEvent) => {
    e.preventDefault();
    const command:CmdRequestDataType = {
      reservation_duration: duration,
      user_id: `${userId}`,
      filters: [
        ...probes.filter((probe:any) => simMapping.find(
          (mapping:any) => mapping.probe === probe.probe_alias
        ))
          .map((probe:any) => ({
            probe_alias: probe.probe_alias,
            desired_mapping: { iccid: simMapping.find((mapping:any) => mapping.probe === probe.probe_alias).sim.split(' - ')[0] }
          })),
        ...probes.filter((probe:any) => selectedProbes
          .includes(probe.probe_name) && !simMapping.find(
          (mapping:any) => mapping.probe === probe.probe_alias
        ))
          .map((other:any) => ({ probe_alias: other.probe_alias }))
      ]
    };
    setCommand(command);
  };

  return (
    <Form>
      <h2>
        Reservation Overview
      </h2>
      <Form.Row style={{ textAlign: 'center' }}>
        <table className="table table-striped mb-5 mt-3" id="probe-overview">
          <thead>
            <tr>
              <th>Probe Type</th>
              <th>Location</th>
              <th>Probe Name</th>
              <th>SIM Name</th>
              <th>ICCID</th>
            </tr>
          </thead>
          <tbody>
            {
              probes.filter((probe:any) => selectedProbes.includes(probe.probe_name) && ['Android', 'iOS'].includes(probe.devices[0]?.type))
                .map((probe: any, index:any) => {
                  const hasMapping = simMapping.find(
                    (mapping:any) => probe.probe_alias === mapping.probe
                  );
                  const extendedProbe = {
                    ...probe,
                    // eslint-disable-next-line no-nested-ternary
                    mapping: hasMapping
                      ? hasMapping.sim
                      : subscribers.find(
                        (sub:any) => probe?.devices[0]?.subscribers
                          && sub.msisdn === probe?.devices[0]?.subscribers[
                            Object.keys(probe?.devices[0]?.subscribers)[0]].msisdn
                      ) ? subscribers.find(
                          (sub:any) => probe?.devices[0]?.subscribers
                          && sub.msisdn === probe?.devices[0]?.subscribers[
                            Object.keys(probe?.devices[0]?.subscribers)[0]].msisdn
                        ).iccid
                        : ''
                  };
                  return (
                    <tr key={`${extendedProbe.probe_alias}_${+index}`}>
                      <td>
                        <p>
                          {extendedProbe.devices[0].type}
                        </p>
                      </td>
                      <td>
                        <p>
                          {extendedProbe.location}
                        </p>
                      </td>
                      <td>
                        <p>
                          {extendedProbe.probe_alias}
                        </p>
                      </td>
                      <td>
                        <p>
                          {extendedProbe.mapping?.split(' - ')[1]}
                        </p>
                      </td>
                      <td>
                        <p>
                          {extendedProbe.mapping?.split(' - ')[0]}
                        </p>
                      </td>
                    </tr>
                  );
                })
              }
            {
            probes.filter((probe:any) => selectedProbes.includes(probe.probe_name) && probe.devices[0]?.type !== 'Android' && probe.devices[0]?.type !== 'iOS').map((yealink: any, index:any) => (
              <tr key={`${yealink.probe_alias}_${+index}`}>
                <td>{yealink.devices[0].type}</td>
                <td>{yealink.location}</td>
                <td>{yealink.probe_alias}</td>
                <td />
                <td />
              </tr>
            ))
            }
          </tbody>
        </table>
      </Form.Row>
      <Row>
        <span style={{ margin: 'auto', marginBottom: '1.5rem', fontSize: '1.5rem' }}>Reservation duration: {duration} minutes</span>
      </Row>
      <Row>
        <Col />
        <Col md={2}>
          <Button
            id="previousStep"
            onClick={previousStep}
            className="btn-block mr-1 mt-1 btn-lg"
          >
            Back
          </Button>
        </Col>
        <Col md={2}>
          <Button
            id="nextStep"
            onClick={scheduleJob}
            className="btn-block mr-1 mt-1 btn-lg"
          >
            Reserve
          </Button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};

export default Overview;
