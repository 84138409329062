import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'react-bootstrap';

type ModalProps = {
  title: string;
  htmlDoc?: HTMLDocument;
  htmlBackgroundElement?: HTMLElement | null;
  show: boolean;
  onDownload: () => void;
  onClose: () => void;
  download: any;
};

const hideElement = (el: HTMLElement) => {
  el.setAttribute('aria-hidden', 'true');
  el.setAttribute('style', 'display:none;');
//   el.style.overflow = 'hidden';
//   el.style.visibility = 'hidden';
//   el.style.display = 'none';
};

const showElement = (el: HTMLElement) => {
  el.removeAttribute('aria-hidden');
  // todo: why not working with setAttribute?
  // el.setAttribute('style', 'display:block;');
  // el.style.overflow = 'auto';
  // el.style.visibility = 'visible';
  // eslint-disable-next-line no-param-reassign
  el.style.display = 'block';
};

const ModalPortal: React.FC<ModalProps> = ({
  show,
  htmlBackgroundElement,
  htmlDoc,
  onDownload,
  onClose,
  title,
  download,
}) => {
  let doc = htmlDoc;
  if (!doc) doc = document;
  const [modalRoot] = useState(doc.getElementById('modal'));
  const [element] = useState<HTMLDivElement>(doc.createElement('div'));
  element.className = 'modalWin';
  element.style.position = 'absolute';
  element.style.top = '260px';
  element.style.left = '260px';
  element.style.right = '260px';
  element.style.bottom = 'auto';
  element.style.border = '1px solid #ccc';
  element.style.background = '#fff';
  element.style.overflow = 'auto';
  element.style.borderRadius = '4px';
  element.style.outline = 'none';
  element.style.padding = '20px';
  element.style.border = '2px solid #ccc';

  useEffect(() => {
    modalRoot?.appendChild(element);
    return () => {
      modalRoot?.removeChild(element);
    };
  }, []);

  const getModalContent = () => (
    <>
      <div className="modal-header">
        <div className="modal-title h4">{title}</div>
      </div>
      <div className="modal-body" style={{ textAlign: 'center' }}>
        {download !== undefined && (
        <audio controls>
          <source src={URL.createObjectURL(new Blob([download], { type: 'audio/wav' }))} type="audio/wav" />
          <track src="" kind="captions" srcLang="en" label="english_captions" />
          Your browser does not support the audio element.
        </audio>
        )}
      </div>
      <div className="modal-footer">
        <Button
          type="button"
          variant="Secondary"
          key="close"
          onClick={() => onClose()}
        >
          Close
        </Button>
        <Button
          type="button"
          key="download"
          onClick={() => onDownload()}
          disabled={download === undefined}
        >
          Download
        </Button>
      </div>
    </>
  );

  if (show) {
    if (htmlBackgroundElement) {
      hideElement(htmlBackgroundElement);
      showElement(element);
    }
    return createPortal(getModalContent(), element);
  }
  if (htmlBackgroundElement) {
    showElement(htmlBackgroundElement);
    hideElement(element);
  }
  return null;
};

export default ModalPortal;
