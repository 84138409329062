/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import uuid from 'uuid';
import ModalPortal from '../../../ModalPortal';
import { WsContext } from '../../context/ws';
import { WindowsManagerContext } from '../../context/windowsManager';

// predefined shell actions - buttons
const shellActions = [
  // todo: to open "developer options" menu on the device fix this:
  // ADB shell returning: Security exception: Permission Denial: starting Intent
  // maybe it will be not possible due to security restrictions?
  // {
  //   name: 'Developer Options',
  //   cmd: 'am start -a android.intent.action.MAIN -n com.android.settings/.SubSettings
  //   -e :android:show_fragment com.android.settings.development.DevelopmentSettings
  //   --activity-clear-top',
  // },
  {
    name: 'Manage Apps',
    cmd: 'am start -a android.settings.APPLICATION_SETTINGS --activity-clear-top',
  },
  {
    name: 'Settings',
    cmd: 'am start -a android.intent.action.MAIN -n com.android.settings/.Settings --activity-clear-top',
  },
  {
    name: 'Wifi settings',
    cmd: 'am start -a android.settings.WIFI_SETTINGS --activity-clear-top',
  },
];

// adb shell command line
const DeviceShell = ({ channel, winId }: { channel: string, winId: string }) => {
  const { sendWithCallback } = useContext(WsContext);
  const [command, setCommand] = useState('');
  const [output, setOutput] = useState<string | undefined>(undefined);
  const [outputModalOpened, setOutputModalOpened] = useState(false);
  // compose message
  const getCmdMsg = (cmd: string): string => `{"command":"${cmd}", "timeout":10000}`;
  const {
    getDoc,
  } = useContext(WindowsManagerContext);

  // generic button for predefined shell commands
  const CmdButton = (name: string, cmd: string) => (
    <Button
      className="bu-mr-1"
      key={uuid()}
      onClick={() => {
        sendWithCallback('shell.command', channel, () => {
          // only log response
          // todo: process response
        }, getCmdMsg(cmd));
      }}
    >
      {name}
    </Button>
  );

  return (
    // todo: where to handle errors when executing shell commands?
    <>
      <div className="d-flex align-items-end bu-py-0_5">
        <div className="bu-mr-1">
          <div style={{ fontSize: '14px' }}>
            Send shell commands:{' '}
          </div>
          <FormControl
            className="bu-mr-1"
            size="sm"
            value={command}
            onChange={(event) => setCommand(event.target.value)}
            onKeyDown={
            (event: any) => {
              if (event.key === 'Enter') {
                sendWithCallback('shell.command', channel, (adbOutput:any) => { setOutput(adbOutput); setOutputModalOpened(true); }, getCmdMsg(command));
                setCommand('');
              }
            }
          }
          />
        </div>
        <Button
          variant={!command ? 'secondary' : 'outline-primary'}
          size="sm"
          disabled={!command}
          onClick={() => {
            sendWithCallback('shell.command', channel, (adbOutput:any) => { setOutput(adbOutput); setOutputModalOpened(true); }, getCmdMsg(command));
            setCommand('');
          }}
        >
          Send
        </Button>

        <Button
          variant={output === undefined ? 'secondary' : 'outline-primary'}
          size="sm"
          disabled={output === undefined}
          onClick={() => {
            setOutputModalOpened(true);
          }}
        >
          View Output
        </Button>
        <ModalPortal
          htmlDoc={getDoc(winId)}
          show={outputModalOpened}
          onClose={() => setOutputModalOpened(false)}
          title="ADB Output"
          htmlBackgroundElement={getDoc(winId)?.getElementById('portal')}
          footer={<button type="button" className="btn btn-secondary" onClick={() => setOutputModalOpened(false)}>Close</button>}
        >
          {output?.replaceAll('[', '').replaceAll(']', '').replaceAll('"', '').replaceAll(',', '')
            .split('\\n')
            .map((item:string) => (
              <div>
                {item}
              </div>
            ))}
        </ModalPortal>
      </div>
    </>
  );
};

export default DeviceShell;
