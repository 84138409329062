import React, { useMemo, useState } from 'react';

import Table from 'components/Table';

import { IPool } from 'context/backendData/interfaces';

import useUserTableSettings from 'hooks/useUserTableSettings';

import useGetPools from 'hooks/services/simManagement/pool/useGetPools';
import useDeletePool from 'hooks/services/simManagement/pool/useDeletePool';
import useConfirm from 'hooks/useConfirm';

import EditPoolModal from './EditPoolModal';
import AddPoolModal from './AddPoolModal';

const PoolTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPool, setSelectedPool] = useState<IPool | undefined>(undefined);
  const { data: pools, refetch: getPools } = useGetPools();

  const userTableSettings = useUserTableSettings('pool');

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      isSortable: true,
    },
    {
      Header: 'SIMS',
      accessor: 'displaySims',
      isSortable: true,
    },
  ], []);

  const [deletePool] = useDeletePool(
    { onSuccess: getPools },
  );

  const confirm = useConfirm();
  const handleDeletePool = (row: any) => {
    confirm({
      body: `Delete pool ${row?.name}?`,
      onOk: () => deletePool({ poolName: row?.name }),
      title: 'Delete pool',
    });
  };

  const openEditModal = (value: any) => {
    setSelectedPool(value);
    setShowEditModal(true);
  };

  const tableRowActions = useMemo(() => ({
    content: 'Actions',
    items: [
      {
        content: 'Edit',
        key: 'edit',
        onClick: (row: any) => openEditModal(row),
      },
      {
        content: 'Delete',
        key: 'delete',
        onClick: (row: any) => handleDeletePool(row),
      },
    ],
  }), []);
  const tableData = pools?.map((pool: any) => (
    {
      name: pool.name,
      sims: Array.isArray(pool.sims) ? pool.sims?.map((sim: any) => sim.iccid) : [],
    }
  ));

  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        columns={columns}
        data={tableData ? tableData?.map((row: any) => ({
          ...row,
          displaySims: row?.sims?.join(', '),
        })) : []}
        initialState={userTableSettings?.initialState}
        rowActions={tableRowActions}
        NewButtonProps={{
          id: 'add-pool',
          content: 'Add new pool',
          onClick: () => setShowModal(true),
        }}
        onStateChange={userTableSettings.updateTableSettings}
        skipReset={false}
      />
      )}
      {showModal
        && (
          <AddPoolModal
            getPools={getPools}
            onHide={() => setShowModal(false)}
            show={showModal}
          />
        )}
      {showEditModal
        && (
          <EditPoolModal
            onHide={() => setShowEditModal(false)}
            show={showEditModal}
            getPools={getPools}
            selectedPool={selectedPool}
          />
        )}
    </>
  );
};

export default PoolTable;
