import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  const location = useLocation();

  const getYear = () => {
    const now = new Date();
    return now.getFullYear();
  };

  // Hide footer from the probe manual testing page and iframe
  if (location.pathname.match(/\/manual_testing\/.+/) || location.search.match(/iframe=true/)) {
    return null;
  }

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src="/assets/images/deutsche-telekom-logo-simple.svg" alt="Telekom Logo" />
        </div>
        <div className="footer-copy">
          <span className="footer-copy-text">
            {`${getYear()} Deutsche Telekom AG`}
          </span>
        </div>
        <div className="footer-nav">
          <Link to="/imprint">
            <button type="button" className="btn footer-nav-btn">
              Imprint
            </button>
          </Link>
          <Link to="/data-privacy-information">
            <button type="button" className="btn footer-nav-btn">
              <FontAwesomeIcon
                className="align-middle"
                icon={faShieldAlt}
                color="white"
                style={{ scale: '70%', position: 'relative', bottom: '2px', right: '1px' }}
              />
              Data privacy information
            </button>
          </Link>
          <Link to="/disclaimer">
            <button type="button" className="btn footer-nav-btn">Disclaimer</button>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
