import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxios from 'hooks/axios/useAxios';

type SettingsData = {
  probeName: string;
  sessionId: string;
  desiredSettings: {
    settings: {
      mjpegServerScreenshotQuality: number,
      mjpegServerFramerate: number,
      mjpegScalingFactor: number
    }
  }
};
// desiredSettings example
// { settings:
//   { mjpegServerScreenshotQuality: 5,
//     mjpegServerFramerate: 10,
//     mjpegScalingFactor: 35
//   }
// }

export default function useSendSettings(options: MutationConfig<any, any>) {
  const axios = useAxios({ baseURL: process.env.REACT_APP_IOS });

  return useMutation(({ probeName, sessionId, desiredSettings }: SettingsData) => axios.post(
    `/c/${probeName}/session/${sessionId}/appium/settings`,
    desiredSettings
  ).then(({ data }) => data), {
    ...options
  });
}
