import _ from 'lodash';
import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type EditProbeStatusRequestData = {
  probeName: string;
  // defective, online, offline
  status: string
};
// todo check with backend
export default function useEditProbeStatus(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  const queryCache = useQueryCache();

  return useMutation(({ probeName, status }: EditProbeStatusRequestData) => axios.put(
    `/cms/api/probes/${probeName}/status?value=${status}`,
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }
      queryCache.invalidateQueries('probes');
    }
  });
}
