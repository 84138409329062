import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetMappings() {
  const axios = useAxiosWithToken();

  return useQuery('mappings', () => axios.get(
    '/sim-manager/api/mappings',
  ).then(({ data }) => data.slice()), { staleTime: 1000 * 60 * 5, enabled: false });
}
