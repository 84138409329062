import _ from 'lodash';
import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type EditProbeLocationRequestData = {
  probeName: string;
  countryIso: string
};
// todo check with backend
export default function useEditProbeLocation(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  const queryCache = useQueryCache();

  return useMutation(({ probeName, countryIso }: EditProbeLocationRequestData) => axios.put(
    `/cms/api/probes/${probeName}/location/assign/${countryIso}`,
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }

      queryCache.invalidateQueries('probes');
    }
  });
}
