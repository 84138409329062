import _ from 'lodash';
import { useRef } from 'react';

import useGetUserSettings from 'hooks/services/user/settings/useGetUserSettings';
import useUpdateUserSettings from 'hooks/services/user/settings/useUpdateUserSettings';

const addTableSettings = (userSettings: any, tableSettings: any) => {
  if (!userSettings) {
    return {
      tableSettings: [tableSettings]
    };
  }

  const newUserSettings = _.cloneDeep(userSettings);

  const oldTableSettings = _.find(userSettings.tableSettings, ['id', tableSettings?.id]);

  if (!_.isEqual(tableSettings, oldTableSettings)) {
    newUserSettings.tableSettings = _.chain(userSettings.tableSettings).reject(['id', tableSettings?.id]).concat(tableSettings).value();
  }

  return newUserSettings;
};

export default function useUserTableSettings(tableId: any, defaultInitialState?: any) {
  const latestUserSettingsRef = useRef();

  const getUserSettings = useGetUserSettings();
  const { data: userSettings } = getUserSettings;
  const [updateUserSettings] = useUpdateUserSettings();

  const tableSettings = _.find(userSettings?.tableSettings, ['id', tableId]);
  const tableInitialState = tableSettings?.initialState ?? defaultInitialState;

  const updateTableSettings = (state: any) => {
    const newUserSettings = addTableSettings(userSettings, {
      id: tableId,
      version: 1,
      initialState: state
    });

    if (!_.isEqual(userSettings, newUserSettings)
      && !_.isEqual(latestUserSettingsRef.current, newUserSettings)) {
      updateUserSettings(newUserSettings);

      latestUserSettingsRef.current = newUserSettings;
    }
  };

  return {
    initialState: tableInitialState,
    isFetched: getUserSettings?.isFetched,
    updateTableSettings
  };
}
