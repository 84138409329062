/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  createContext, FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';

export type WindowState = 'OPEN' | 'CLOSED' | 'CLOSING' | undefined;

export interface WindowContainer {
  id: string,
  title: string,
  url: string,
  state: WindowState,
  size: { width: number, height: number },
  window: Window | null,
}

export interface IWindowsManagerContext {
  windows: WindowContainer[],
  openWindow: (win: WindowContainer) => void,
  killWindow: (id: string) => void,
  startDestroyWindow: (id: string) => void,
  finishDestroyWindow: (id: string) => void,
  focusWindow: (id: string) => void,
  isOpen: (id: string) => boolean,
  getState: (id: string) => WindowState,
  setWindowState: (id: string, state: WindowState) => void,
}

interface WindowsManagerProps {
}

const WindowsManagerGenericContext = createContext({} as IWindowsManagerContext);

const WindowsManagerGenericProvider: FunctionComponent<WindowsManagerProps> = (
  {
    children,
  }: React.PropsWithChildren<WindowsManagerProps>,
) => {
  const [windows, setWindows] = useState<WindowContainer[]>([]);
  // const latestWindows = useRef(windows);

  const openWindow = (win: WindowContainer) => {
    const newWindow = window.open(
      `${win.url}`,
      win.id,
      `height=${win.size.height}, width=${win.size.width}`
    );
    setWindows((prevState) => ([...prevState, { ...win, state: 'OPEN', window: newWindow }]));
  };

  const startDestroyWindow = (id: string) => {
    setWindows((prevState) => (
      prevState.map((win) => (win.id === id ? { ...win, state: 'CLOSING' } : win))
    ));
  };

  const killWindow = (id: string) => {
    const selectedWindow = windows.find((prevState) => (prevState.id === id));
    if (selectedWindow?.window) {
      selectedWindow.window.close();
    }
    setWindows((prevState) => (
      prevState.filter((win) => win.id !== id)
    ));
  };

  const closeWindow = useCallback((win: WindowContainer) => {
    startDestroyWindow(win.id);
    if (win.window) win.window.close();
  }, [startDestroyWindow]);

  const finishDestroyWindow = (id: string) => {
    const window = windows.find((win) => win.id === id);
    if (window) {
      window.window?.close();
    }
    setWindows((prevState) => (
      prevState.filter((win) => win.id !== id)
    ));
  };

  useEffect(() => {
    if (windows.length === 0) {
      return () => {
      };
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const text = 'Other open TESSA windows will also be closed.';
      // eslint-disable-next-line no-param-reassign
      event.returnValue = text;
      return text;
    };

    const handleUnload = async () => {
      if (windows) windows.forEach((win) => closeWindow(win));
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [windows, closeWindow]);

  const focusWindow = (id: string) => {
    const window = windows.find((win) => win.id === id);
    if (window) window.window?.focus();
  };

  const isOpen = (id: string): boolean => {
    const window = windows.find((win) => win.id === id);
    return !!(window && window.state === 'OPEN');
  };

  const getState = (id: string): WindowState => {
    const window = windows.find((win) => win.id === id);
    return (window && window.state);
  };

  const setWindowState = (id: string, state: WindowState) => {
    setWindows((prevState) => (
      prevState.map((win) => (win.id === id ? { ...win, state: 'CLOSED' } : win))
    ));
  };

  return (
    <WindowsManagerGenericContext.Provider value={{
      windows,
      openWindow,
      killWindow,
      startDestroyWindow,
      finishDestroyWindow,
      focusWindow,
      isOpen,
      getState,
      setWindowState,
    }}
    >
      <>
        {children}
      </>
    </WindowsManagerGenericContext.Provider>
  );
};
const DevicesConsumer = WindowsManagerGenericContext.Consumer;
export { WindowsManagerGenericProvider, DevicesConsumer, WindowsManagerGenericContext };
