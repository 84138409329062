// Menu component with routing and login and user handling
// This is main entrance to TESSA application
import React from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Alerts from 'components/Alerts';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

import MainLayout from 'components/layout/MainLayout';
import RootLayout from 'components/layout/RootLayout';

import HomePage from 'components/homepage/HomePage';

import { Support, Documentation } from 'pages/Help/index';
import ServerStatusInfo from 'pages/Help/ServerStatusInfo';
import Reservation from 'pages/Reservation';
import Administration from 'pages/Administration';
import AutomatedTesting from 'pages/AutomatedTesting';
import SemiAutomatedReservation from 'pages/SemiAutomatedReservation';
import JobsPendingApproval from 'pages/JobsPendingApproval';
import ApprovedJobs from 'pages/ApprovedJobs';
import SimCards from 'pages/Administration/SimCards';
import ManualTesting from 'pages/ManualTesting';
import ManualTestingProbe from 'pages/ManualTesting/ManualTestingProbe';
import Imprint from 'pages/Footer/Imprint';
import DataPrivacyInformation from 'pages/Footer/DataPrivacyInformation';
import Disclaimer from 'pages/Footer/Disclaimer';
import LoginRedirectClose from 'pages/LoginRedirectClose';
import Login from 'pages/Login';
import LoginRedirect from 'pages/LoginRedirect';
import AutoLogout from 'components/AutoLogout';
import PrivateRoute from './PrivateRoute';

// render menu
const MainRouter = () => (
  <Router data-test="component-app-main">
    <RootLayout>
      <MainLayout>
        <Navbar />
        <Alerts />
        <AutoLogout />
        {/*  Routes definition */}
        <Switch>
          <PrivateRoute component={ManualTestingProbe} path="/manual_testing/:id" roles={['admin', 'manual']} />
          <PrivateRoute path="/reservations/" component={Reservation} roles={['admin', 'manual']} />
          <PrivateRoute path="/manual_testing/" component={ManualTesting} roles={['admin', 'manual']} />
          <PrivateRoute path="/automated-testing/" component={AutomatedTesting} roles={['admin', 'automation']} />
          <PrivateRoute path="/jobs-pending/" component={JobsPendingApproval} roles={['admin', 'expert-testing']} />
          <PrivateRoute path="/jobs-approved/" component={ApprovedJobs} roles={['admin', 'expert-testing']} />
          <PrivateRoute path="/semi-automated/" component={SemiAutomatedReservation} roles={['admin', 'expert-testing']} />
          <PrivateRoute path="/administration/" component={Administration} roles={['admin']} />
          <Route path="/login-redirect-close/" component={LoginRedirectClose} />
          <Route path="/login/" component={Login} />
          <Route path="/login-redirect/" component={LoginRedirect} />
          <Route path="/imprint/" component={Imprint} />
          <Route path="/data-privacy-information/" component={DataPrivacyInformation} />
          <Route path="/disclaimer/" component={Disclaimer} />
          <PrivateRoute
            path="/sim-cards/"
            roles={['admin', 'automation', 'manual']}
          >
            <Container fluid>
              <Row className="ml-5 mr-5">
                <Col>
                  <SimCards />
                </Col>
              </Row>
            </Container>
          </PrivateRoute>
          <PrivateRoute path="/help/support/" component={Support} />
          <PrivateRoute path="/help/documentation/" component={Documentation} />
          <PrivateRoute path="/help/serverstatusinfo/" component={ServerStatusInfo} />
          <Route component={HomePage} path="/" />
        </Switch>
      </MainLayout>
      <Footer />
    </RootLayout>
  </Router>
);

export default MainRouter;
