import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetProbeChannels(probeName: string) {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_ATTENUATORS });

  return useQuery(['attenuatorProbeChannels'], () => axios.get(
    `/attenuator/api/probes/${probeName}/channels`
  ).then(({ data }) => data), { staleTime: 1000 * 60 * 5 });
}
