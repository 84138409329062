import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { Form, Container, Row, Col, Button, Dropdown } from 'react-bootstrap';

export type ScheduleProps = {
  frequency: number,
  run: number,
  selectedRepetition: number,
  repeatEvery: number,
  repeatDays: string[] | never[],
  minuteOfHour:number
  dayOfMonth: number,
  startTime: string
};

export enum Frequency {
  Once,
  Periodically,
}

export enum Run {
  RunNow,
  RunAt,
}

export enum Repetition {
  Hourly,
  Daily,
  Weekly,
  Monthly,
}

const Days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const Schedule = ({
  previousStep,
  nextStep,
  scheduleValues,
  setScheduleValues,
}: any) => {
  useEffect(() => {
    if (scheduleValues.frequency === Frequency.Once) {
      setScheduleValues({ ...scheduleValues, startTime: moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm') });
    } else {
      setScheduleValues({ ...scheduleValues, startTime: moment().add(1, 'minutes').format('HH:mm') });
    }
  }, [scheduleValues.frequency]);

  console.log(`scheduleValues in scheduling ${JSON.stringify(scheduleValues)}`);
  console.log(`in future? ${moment(scheduleValues.startTime).isAfter(moment())}`);

  return (
    <Form>
      <strong>
        Select the frequency and start date/time of your campaign.
      </strong>
      <Row style={{ marginTop: '2rem', marginRight: '2rem', marginLeft: '2rem', marginBottom: '2rem' }}>
        <Col>
          <Row>
            Frequency:
          </Row>
          <Row>
            <Form.Check
              checked={scheduleValues.frequency === Frequency.Once}
              value="frequencyOnce"
              id="frequencyOnce"
              key="frequencyOnce"
              type="radio"
              inline
              aria-label="item"
              className="lg mr-0 ml-3"
              onChange={() => setScheduleValues({ ...scheduleValues, frequency: Frequency.Once })}
            />
            <Form.Label htmlFor="frequencyOnce" className="col-form-label mr-4">Once</Form.Label>
          </Row>
          <Row>
            <Form.Check
              checked={scheduleValues.frequency === Frequency.Periodically}
              value="frequencyPeriodically"
              id="frequencyPeriodically"
              key="frequencyPeriodically"
              type="radio"
              inline
              aria-label="item"
              className="lg mr-0 ml-3"
              onChange={() => setScheduleValues({
                ...scheduleValues, frequency: Frequency.Periodically
              })}
            />
            <Form.Label htmlFor="frequencyPeriodically" className="col-form-label mr-4">Periodically</Form.Label>
          </Row>
        </Col>
        <Col>
          {scheduleValues.frequency === Frequency.Once && (
          <>
            <Row>
              <Form.Check
                checked={scheduleValues.run === Run.RunNow}
                value="runNow"
                id="runNow"
                key="runNow"
                type="radio"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={() => setScheduleValues({ ...scheduleValues, run: Run.RunNow })}
              />
              <Form.Label htmlFor="runNow" className="col-form-label mr-4">Run now</Form.Label>
            </Row>
            <Row>
              <Form.Check
                checked={scheduleValues.run === Run.RunAt}
                value="runAt"
                id="runAt"
                key="runAt"
                type="radio"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={() => setScheduleValues({
                  ...scheduleValues, run: Run.RunAt
                })}
              />
              <Form.Label htmlFor="runAt" className="col-form-label mr-4">Run at:</Form.Label>
            </Row>
          </>
          )}

          <span style={((scheduleValues.frequency === Frequency.Once && scheduleValues.run === Run.RunNow) || (scheduleValues.frequency === Frequency.Periodically && scheduleValues.selectedRepetition === Repetition.Hourly)) ? { opacity: '0.5', pointerEvents: 'none' } : undefined}>
            Start time:
          </span>
          {(scheduleValues.frequency === Frequency.Once
          || (scheduleValues.frequency === Frequency.Periodically
            && scheduleValues.selectedRepetition !== Repetition.Hourly)) ? (
              <InputMask
                mask={scheduleValues.frequency === Frequency.Periodically ? '99:99' : '9999-99-99 99:99'}
                value={scheduleValues.startTime}
                onChange={(e:any) => setScheduleValues({
                  ...scheduleValues,
                  startTime: e.target.value
                })}
                style={((scheduleValues.frequency === Frequency.Once && scheduleValues.run === Run.RunNow) || (scheduleValues.frequency === Frequency.Periodically && scheduleValues.selectedRepetition === Repetition.Hourly)) ? { opacity: '0.5', pointerEvents: 'none' } : undefined}
              >
                {(inputProps: any) => (
                  <Form.Control {...inputProps} />
                )}
              </InputMask>
            ) : (
              <InputMask
                mask="a"
                placeholder=""
                style={{ opacity: '0.5', pointerEvents: 'none' }}
              >
                {(inputProps: any) => (
                  <Form.Control {...inputProps} />
                )}
              </InputMask>
            )}

          {scheduleValues.frequency === Frequency.Periodically && (
            <>
              <Dropdown style={{ display: 'inline-block', minWidth: '130px', marginTop: '10px' }}>
                <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ minWidth: '130px', marginRight: '5px' }}>
                  {Repetition[scheduleValues.selectedRepetition]}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[0, 1, 2, 3].map((rep:any) => (
                    <Dropdown.Item
                      onSelect={() => {
                        setScheduleValues({ ...scheduleValues, selectedRepetition: rep });
                      }}
                      key={rep}
                    >
                      {Repetition[rep]}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {(scheduleValues.selectedRepetition === Repetition.Hourly) && (
              <><span>every</span>
                <Form.Control
                  type="number"
                  placeholder="Repetition"
                  min={1}
                  max={23}
                  style={{ display: 'inline-block', maxWidth: '4rem', marginLeft: '5px', marginRight: '5px' }}
                  value={scheduleValues.repeatEvery}
                  onChange={(e: any) => {
                    if (!e.target.validity.badInput) {
                      setScheduleValues({ ...scheduleValues, repeatEvery: Number(e.target.value) });
                    }
                  }}
                />
                <span>hour{scheduleValues.repeatEvery > 1 ? 's' : ''}</span>
                <Row style={{ marginLeft: '0', display: 'inline-block' }}>
                  on minute
                  <Form.Control
                    type="number"
                    placeholder="minuteOfHour"
                    min={0}
                    max={59}
                    style={{ display: 'inline-block', maxWidth: '4rem', marginLeft: '5px', marginRight: '5px' }}
                    value={scheduleValues.minuteOfHour}
                    onChange={(e: any) => {
                      if (!e.target.validity.badInput) {
                        setScheduleValues({
                          ...scheduleValues,
                          minuteOfHour: Number(e.target.value)
                        });
                      }
                    }}
                  />
                </Row>
              </>
              )}
              {scheduleValues.selectedRepetition === Repetition.Weekly && (
                <Row style={{ marginLeft: '0', flexDirection: 'column' }}>
                  <span>Run on:</span>
                  <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', height: '10rem' }}>
                    {Days.map((day:any) => (
                      <div>
                        <Form.Check
                          checked={scheduleValues.repeatDays.includes(day)}
                          id={`dayRepetition${day}`}
                          key={`dayRepetition${day}`}
                          inline
                          aria-label="item"
                          className="lg mr-0 ml-3"
                          onChange={() => {
                            setScheduleValues({
                              ...scheduleValues,
                              repeatDays: scheduleValues.repeatDays.includes(day)
                                ? scheduleValues.repeatDays.filter((d:any) => d !== day)
                                : [...scheduleValues.repeatDays, day]
                            });
                          }}
                        />
                        <Form.Label htmlFor={`dayRepetition${day}`} className="col-form-label mr-4">{day}</Form.Label>
                      </div>
                    ))}
                  </div>
                </Row>
              )}
              {scheduleValues.selectedRepetition === Repetition.Monthly && (
              <Row style={{ marginLeft: '0', display: 'inline-block' }}>
                <span>At the:</span>
                <Form.Control
                  type="number"
                  placeholder="dayOfMonth"
                  min={1}
                  max={31}
                  style={{ display: 'inline-block', maxWidth: '4rem', marginLeft: '5px', marginRight: '5px' }}
                  value={scheduleValues.dayOfMonth}
                  onChange={(e: any) => {
                    if (!e.target.validity.badInput) {
                      setScheduleValues({ ...scheduleValues, dayOfMonth: Number(e.target.value) });
                    }
                  }}
                />
                Day
              </Row>
              )}
            </>
          )}
        </Col>
        <Col />
      </Row>
      <Row className="justify-content-center" style={{ marginTop: '100px' }}>
        Note: The job execution will only start at the scheduled time
        if all required resources are available!
      </Row>
      <Container className="pt-4">
        <Row>
          <Col />
          <Col md={2}>
            <Button
              id="previousStep"
              onClick={previousStep}
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
            >
              Back
            </Button>
          </Col>
          <Col md={2}>
            <Button
              id="nextStep"
              onClick={nextStep}
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
              disabled={
                (scheduleValues.selectedRepetition === Repetition.Weekly
                && scheduleValues.repeatDays.length === 0
                && scheduleValues.frequency === Frequency.Periodically)
                || (!moment(scheduleValues.startTime).isAfter(moment().utc())
                && scheduleValues.frequency === Frequency.Once
                && scheduleValues.run === Run.RunAt)
              }
              type="submit"
            >
              Next
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
    </Form>
  );
};

export default Schedule;
