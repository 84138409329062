import React, { useState } from 'react';

import { Alert, Container } from 'react-bootstrap';

import { Probe } from 'hooks/services/probe/interfaces';

import Buttons from './Buttons';
import DeviceShell from './Shell';
import DeviceSim from './Sim';
import DeviceClipboard from './DeviceClipboard';
import Browser from './Browser';
import {
  Device,
} from '../../context/interface';

const Info = ({
  device,
  deadline,
  onTimeOut,
  probe,
  winId,
} : {
  device: Device | undefined,
  deadline: number | null,
  onTimeOut?: () => any,
  probe: Probe | null,
  winId: string
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');

  const alertMessages = [
    {
      name: 'mappingInfo',
      title: 'Inserting in Progress',
      message: 'Please wait, SIM card is being inserted to the device',
      variant: 'info'
    },
    {
      name: 'mappingSuccessful',
      title: 'Insert Successful',
      message: 'SIM is inserted. Please wait until the device is connected to a network. You also might check manually on the device',
      variant: 'success'
    },
    {
      name: 'unmappingInfo',
      title: 'Removing in Progress',
      message: 'Please wait, SIM card is being removed from the device',
      variant: 'info'
    },
    {
      name: 'unmappingSuccessful',
      title: 'Remove Successful',
      message: 'SIM Card successfully removed',
      variant: 'success'
    },
    {
      name: 'fixedSim',
      title: 'Fixed SIM',
      message: 'This device has a fixed SIM inserted',
      variant: 'success'
    },
  ];

  return (
    device ? (
      <>
        <Alert
          show={showAlert}
          dismissible
          onClose={() => setShowAlert(false)}
          variant={alertMessages.filter((msg:any) => msg.name === alertType)[0]?.variant}
        >
          <p className="bu-my-0">
            {alertMessages.filter((msg:any) => msg.name === alertType)[0]?.message}
          </p>
        </Alert>
        <Container className="border rounded" style={{ minWidth: '600px' }}>
          <DeviceSim
            deadline={deadline}
            onTimeOut={onTimeOut}
            probe={probe}
            device={device}
            winId={winId}
            setShowAlert={setShowAlert}
            setAlertType={setAlertType}
          />
          <Buttons
            winId={winId}
            device={device}
            probe={probe}
          />
          <DeviceClipboard
            channel={device.channel}
          />
          <Browser channel={device.channel} />
          <DeviceShell channel={device.channel} winId={winId} />
        </Container>
      </>
    ) : <div>loading...</div>
  );
};

export default Info;
