import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Tab from './Tab';

import TabsContext from './TabsContext';

const Tabs = ({
  activeTab: activeTabProp,
  children,
  className,
  block,
  onChange,
  tabs,
  ...props
}: any) => {
  const [activeTab, setActiveTab] = useState(activeTabProp);

  useEffect(() => {
    setActiveTab(activeTabProp);
  }, [activeTabProp]);

  const handleTabClick = _.isFunction(onChange) ? onChange : setActiveTab;

  return (
    <TabsContext.Provider value={[activeTab, handleTabClick]}>
      <nav
        className={clsx('tabs', { 'tabs-block': block }, className)}
        {...props}
      >
        {Array.isArray(tabs)
          ? tabs.map(({ content, value, ...tabProps }) => (
            <Tab key={value} value={value} {...tabProps} id={`${value}_tab`}>
              {content}
            </Tab>
          ))
          : children}
      </nav>
    </TabsContext.Provider>
  );
};

Tabs.defaultProps = {
  activeTab: undefined,
  block: false,
  children: undefined,
  className: undefined,
  onChange: undefined,
  tabs: undefined,
};

Tabs.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  block: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      ...Tab.propTypes,
      content: PropTypes.node,
      children: PropTypes.node,
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
};

export default Tabs;
