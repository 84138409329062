import React, { useEffect, useState } from 'react';
import {
  Modal, Card
} from 'react-bootstrap';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import { Sim } from 'hooks/services/simManagement/interfaces';

interface IModalProps {
  show: boolean;
  onHide: () => void;
  queuedStatuses: any;
  statuses: any;
  jobUuid: string;
}

interface IResources {
  probe_alias: string;
  probe_type: string;
  msisdn: string;
  iccid: string;
}

const ResourcesModal = ({
  onHide, show, queuedStatuses, statuses, jobUuid
}: IModalProps,) => {
  const { data: subscribers } = useGetSubscribers();
  const { data: probes } = useGetProbes();
  const [queuedJob, setQueuedJob] = useState<IResources[] | undefined>(undefined);
  const [statusJob, setStatusJob] = useState<IResources[] | undefined>(undefined);

  useEffect(() => {
    if (subscribers && probes) {
      setQueuedJob(queuedStatuses?.find((status: any) => status
        .approval_id === jobUuid)?.arguments[0].filters
        .map((filters:any) => ({
          probe_alias: filters.probe_alias,
          probe_type: probes
            .find((probe:any) => probe.probe_alias === filters.probe_alias).devices[0].name,
          msisdn: subscribers
            .find((sub:Sim) => sub.iccid === filters?.desired_mapping?.iccid)?.msisdn,
          iccid: filters?.desired_mapping?.iccid
        })));

      setStatusJob(statuses?.find((status: any) => status.uuid === jobUuid)?.options?.probes
        .map((probe: any) => ({
          probe_alias: probe.probe_alias,
          probe_type: probe.device,
          msisdn: probe?.msisdn,
          iccid: probe?.filter_used?.desired_mapping?.iccid
        })));
    }
  }, [subscribers, probes]);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="lg"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header>
                Resources of Job ID: {jobUuid}
                <button type="button" className="close" onClick={onHide} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Card.Header>
              <table className="table">
                <thead>
                  <tr>
                    <td>
                      Probe Name
                    </td>
                    <td>
                      Probe Type
                    </td>
                    <td>
                      MSISDN
                    </td>
                    <td>
                      ICCID
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {queuedJob
                  && (queuedJob?.map((probe:any) => (
                    <tr>
                      <td>
                        {probe.probe_alias}
                      </td>
                      <td>
                        {probe.probe_type}
                      </td>
                      <td>
                        {probe.msisdn}
                      </td>
                      <td>
                        {probe.iccid}
                      </td>
                    </tr>
                  )))}
                  {statusJob
                  && (statusJob?.map((probe:any) => (
                    <tr>
                      <td>
                        {probe.probe_alias}
                      </td>
                      <td>
                        {probe.probe_type}
                      </td>
                      <td>
                        {probe.msisdn}
                      </td>
                      <td>
                        {probe.iccid}
                      </td>
                    </tr>
                  )))}
                </tbody>
              </table>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default ResourcesModal;
