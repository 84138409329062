import _ from 'lodash';

import { useKeycloak } from '@react-keycloak/web';
import { AxiosRequestConfig } from 'axios';

import useAxios from './useAxios';

// const keycloakToAuthorizationHeader = (keycloak: any) => `Bearer ${keycloak?.token}`;

export default function useGetAxiosWithToken(options?: AxiosRequestConfig) {
  const { keycloak } = useKeycloak();

  const axiosInstance = useAxios(options);

  axiosInstance.interceptors.request.use((config) => {
    const configWithAuthorizationToken = _.cloneDeep(config);

    // configWithAuthorizationToken.headers.Authorization = keycloakToAuthorizationHeader(keycloak);
    configWithAuthorizationToken.headers.Authorization = `Bearer ${keycloak?.token}`;

    return configWithAuthorizationToken;
  });

  return axiosInstance;
}
