const SimColumns = [
  {
    Header: 'ICCID',
    accessor: 'iccid',
    isSortable: true,
  },
  {
    Header: 'MSISDN',
    accessor: 'msisdn',
    isSortable: true,
  },
  {
    Header: 'IMSI',
    accessor: 'imsi',
    isSortable: true,
  },
  {
    Header: 'Tariff',
    accessor: 'tariff',
    isSortable: true,
  },
  {
    Header: 'WNW',
    accessor: 'wnw',
    isSortable: true,
  },
  {
    Header: 'OP_WNW',
    accessor: 'op_wnw',
    isSortable: true,
  },
  {
    Header: 'PSP',
    accessor: 'psp',
    isSortable: true,
  },
  {
    Header: 'WHOLESALE ID',
    accessor: 'wholesale_id',
    isSortable: true,
  },
  {
    Header: 'ITG ID',
    accessor: 'itg_id',
    isSortable: true,
  },
  {
    Header: 'PIN',
    accessor: 'secret',
    isSortable: true,
  },
  {
    Header: 'Name',
    accessor: 'name',
    isSortable: true,
  },
  {
    Header: 'Origin',
    accessor: 'origin',
    isSortable: true,
  },
  {
    Header: 'Prepaid',
    accessor: 'prepaid',
    isSortable: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    isSortable: true,
  },
  {
    Header: 'SIM Type',
    accessor: 'sim_type',
    isSortable: true,
  },
  {
    Header: 'Modified',
    accessor: 'modified',
    isSortable: true,
  },
  {
    Header: 'Position',
    accessor: 'position',
    isSortable: true,
  },
];

export default SimColumns;
