import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeletePoolRequestData = {
  poolName: string;
};

export default function useDeletePool(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ poolName }: DeletePoolRequestData) => axios.delete(
    `/sim-manager/api/pools/${poolName}`,
  ).then(({ data }) => data), options);
}
