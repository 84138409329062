import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  Button,
  Card,
  CardGroup,
  Col,
  Form,
} from 'react-bootstrap';
import _, { isArray } from 'lodash';
import useAlerts from '../../../../hooks/useAlerts';
import Toggle from '../../../../components/form/Toggle';
import { IProbeNew } from '../../../../context/backendData/interfaces';
import ProbeStatus from '../../ProbeStatus';
import useEditProbeStatus from '../../../../hooks/services/probe/useEditProbeStatus';
import useGetProbeLocations from '../../../../hooks/services/probe/location/useGetProbeLocations';

interface IProps {
  probes: IProbeNew[];
  getProbes: any;
}

const Probes = (props: IProps) => {
  const { probes, getProbes } = props;
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);
  const { showAlert } = useAlerts();

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const { data: locations } = useGetProbeLocations();

  // group clients by location
  const probesFixedLocation = probes?.map((probe: IProbeNew) => (
    !probe.country_iso ? { ...probe, country_iso: 'UNK' } : probe));
  const fixedLocations = [
    ...locations || [],
    { country_name: 'Unknown location', country_iso: 'UNK' },
  ];
  const [search, setSearch] = useState('');
  const filteredProbes = useMemo(() => _.chain(probesFixedLocation)
    .filter((p: any) => !search || (Object.values(p).filter((x: any) => {
      if (isArray(x) && x[0]) {
        return (Object.values(x[0]).filter((y: any) => String(y)
          .toLowerCase().includes(search.toLowerCase())).length !== 0);
      }
      return (String(x).toLowerCase().includes(search.toLowerCase()));
    }).length !== 0)).groupBy(
      (probe) => probe.country_iso
    ).value(),
  [probesFixedLocation, search]);

  const [editProbeStatus] = useEditProbeStatus(
    {
      onSuccess: () => {
        setMessage('Probe status updated!');
        getProbes();
      },
      onError: () => {
        setErrMessage('Failed to update probe status');
      },
    },
  );

  const inMaintenance = (probe: IProbeNew) => (probe.status === ProbeStatus.DEFECTIVE);

  const handleSearchProbes = (e: any) => {
    setSearch(e.target.value ?? '');
    setActiveTab(undefined);
  };

  return (
    <>
      <Col md={5}>
        <Form.Group className="ml-auto p-2 mb-3" controlId="searchProbes">
          <Form.Control
            type="text"
            placeholder="Search probes"
            onChange={(e: any) => {
              handleSearchProbes(e);
            }}
          />
        </Form.Group>
      </Col>
      {fixedLocations
          && fixedLocations
            .filter((loc: any) => (
              (search && filteredProbes[loc.country_iso]?.length) || !search))
            .sort((a: any, b: any) => a.country_name.localeCompare(b.country_name))
            .map((location: any) => (
              <Accordion key={`${location.country_iso}_acordion`} defaultActiveKey="-1" activeKey={search ? location.country_iso : activeTab || undefined}>
                <CardGroup key={`${location.country_name}_card`}>
                  <Card key={location.country_name} style={{ border: 0 }}>
                    <Card.Body style={{ padding: '5px' }}>
                      <Card.Header
                        style={{
                          fontSize: '1.2em',
                          fontWeight: 'inherit',
                          margin: '2px !important',
                          padding: '5px',
                          background: '#efefef',
                          border: '1px solid #B2B2B2',
                        }}
                        className="d-flex"
                      >
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={location.country_iso}
                          style={{ width: '100%' }}
                          aria-label={`open maintenance planning for ${location.country_name}`}
                        >
                          <span className="mr-3 align_left">{location.country_name}</span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={location.country_iso}>
                        <div className="mt-2">
                          {filteredProbes[location.country_iso]
                          && filteredProbes[location.country_iso].map(
                            ((probe: any, index) => (
                              <Card key={`${probe.probe_name}_${+index}_card`} style={{ width: '100%' }}>
                                <Card.Body style={{ padding: 12 }}>
                                  <div className="d-flex">
                                    <span className="align_left">
                                      {probe.probe_name} - {probe.probe_alias}
                                    </span>
                                    <span className="mr-3 ml-auto">
                                      <Toggle
                                        id="plan-maintenance-switch"
                                        active={inMaintenance(probe) || false}
                                        onChange={(value) => editProbeStatus({
                                          probeName: probe.probe_name,
                                          // translate back from "maintenance" to "defective"
                                          status: value ? 'defective' : ProbeStatus.ONLINE,
                                        })}
                                      />
                                    </span>
                                  </div>
                                </Card.Body>
                              </Card>
                            )),
                          )}
                        </div>
                      </Accordion.Collapse>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </Accordion>
            ))}

    </>
  );
};

export default Probes;
