import Toggle from 'components/form/Toggle';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import PageTitle from 'components/PageTitle';
import useAlerts from 'hooks/useAlerts';
import useGetUsers from 'hooks/services/user/useGetUsers';
import useUser from 'hooks/useUser';
import useGetQueuedStatuses from 'hooks/services/scheduler/useGetQueuedStatuses';
import useUpdateSettings from 'hooks/useUpdateSettings';
import QueuedJobs from './AutomatedTesting/QueuedJobs';
import JobsPagination from './JobsPagination';

const JobsPendingApproval = () => {
  const useSettings = useUpdateSettings('expertTesting');
  const [perPage, setPerPage] = useState('50');
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: queuedStatuses,
    isLoading: isLoadingQueuedJobs,
    refetch: getQueuedStatuses } = useGetQueuedStatuses();
  const { data: users, isLoading: isLoadingUsers } = useGetUsers();
  const { uidNumber: userId } = useUser();

  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();

  const UPDATE_INTERVAL = 10000;

  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  let queuedStatusesFinal;

  if (useSettings.initialState?.showAllJobs) {
    queuedStatusesFinal = queuedStatuses?.jobs;
  } else {
    queuedStatusesFinal = queuedStatuses?.jobs
      .filter((status:any) => (status.arguments[0].user_id === userId));
  }

  const pageCount = () => {
    const pageArray: any = [];
    for (let i = 1; i <= (Math.ceil(queuedStatusesFinal?.length / +perPage)); i += 1) {
      pageArray.push(i);
    }
    return pageArray;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getQueuedStatuses();
    }, UPDATE_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const pageSizes = [10, 20, 50, 100];

  return (
    <div className="pt-4">
      <Container>
        <Row>
          <Col md={10}>
            <PageTitle>Jobs pending approval</PageTitle>
          </Col>
          <Col md={2} className="d-flex justify-content-end" style={{ marginTop: '20px' }}>
            <Toggle
              id="see_all"
              active={useSettings.initialState?.showAllJobs}
              onChange={(value) => useSettings.updateTableSettings({ showAllJobs: value })}
              label="show all jobs"
            />
          </Col>
        </Row>
        {(queuedStatusesFinal?.length !== 0 && !isLoadingQueuedJobs && !isLoadingUsers)
           && (
           <JobsPagination
             setPerPage={setPerPage}
             setPageNumber={setPageNumber}
             perPage={perPage}
             pageNumber={pageNumber}
             pageCount={pageCount}
             pageSizes={pageSizes}
           />
           )}
        <Row className="mx-3">
          <Col xs={12} lg={12}>
            {(isLoadingQueuedJobs || isLoadingUsers) ? (
              <div className="spinner-container">
                <Spinner animation="border" role="status" className="spinner" />
                <span>Loading...</span>
              </div>
            ) : (
              <QueuedJobs
                getQueuedStatuses={getQueuedStatuses}
                queuedStatuses={queuedStatusesFinal
                  .slice(+perPage * (pageNumber - 1), (+perPage * (pageNumber - 1)) + +perPage)}
                users={users}
                setMessage={setMessage}
                setErrMessage={setErrMessage}
              />
            )}
          </Col>
        </Row>
        {(queuedStatusesFinal?.length !== 0 && !isLoadingQueuedJobs && !isLoadingUsers)
        && (
          <JobsPagination
            setPerPage={setPerPage}
            setPageNumber={setPageNumber}
            perPage={perPage}
            pageNumber={pageNumber}
            pageCount={pageCount}
            pageSizes={pageSizes}
          />
        )}
      </Container>
    </div>
  );
};

export default JobsPendingApproval;
