import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import PageTitle from 'components/PageTitle';
import Tabs from 'components/Tabs';

import OfflineSimCardsTable from './OfflineSimCardsTable';
import SimCardsTable from './SimCardsTable';
import SipAccountsTable from './SipAccountsTable';
import FixedNumbersTable from './FixedNumbersTable';
import useAlerts from '../../../hooks/useAlerts';
import ImportSimCardsTab from './ImportSimCardsTab';
import SimColumns from '../SimColumns';

const simColumns = [
  ...SimColumns,
  {
    Header: 'Tags',
    accessor: 'tags',
    isSortable: false,
    Cell: ({ value }: any) => (
      <>
        {value?.join(', ')}
      </>
    )
  },
  {
    Header: 'Network',
    accessor: 'network',
    Cell: ({ value }: any) => (
      <>
        {value && value?.filter((val:any) => (['lab', 'live'].includes(val))).join(', ')}
      </>
    )
  },
];

const SimCards = () => {
  const [activeTab, setActiveTab] = useState('sim-cards');

  const tableColumnsFixedNumbers = _.reject(simColumns, (column) => (
    column.accessor === 'iccid' || column.accessor === 'imsi' || column.accessor === 'sim_type'
  ));

  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  return (
    <>
      <PageTitle>Subscribers</PageTitle>
      <Tabs
        activeTab={activeTab}
        block
        onChange={(value) => setActiveTab(value)}
        tabs={[{
          content: 'SIM Cards',
          value: 'sim-cards',
        }, {
          content: 'Offline SIM Cards',
          value: 'offline-sim-cards',
        }, {
          content: 'Fixed Numbers',
          value: 'fixed-numbers',
        }, {
          content: 'SIP accounts',
          value: 'sip-accounts',
        }, {
          content: 'Import Subscribers',
          value: 'import-sim-cards',
        }]}
      />
      <div className="border border-top-0 rounded-bottom bu-px-3 bu-py-2">
        {activeTab === 'sim-cards' && <SimCardsTable tableColumns={simColumns} />}
        {activeTab === 'offline-sim-cards'
        && (
        <OfflineSimCardsTable
          tableColumns={simColumns}
          setMessage={setMessage}
          setErrMessage={setErrMessage}
        />
        )}
        {activeTab === 'fixed-numbers'
        && (
        <FixedNumbersTable
          tableColumns={tableColumnsFixedNumbers}
          setMessage={setMessage}
          setErrMessage={setErrMessage}
        />
        )}
        {activeTab === 'sip-accounts'
        && (
        <SipAccountsTable
          tableColumns={tableColumnsFixedNumbers}
          setMessage={setMessage}
          setErrMessage={setErrMessage}
        />
        )}
        {activeTab === 'import-sim-cards'
        && (
          <ImportSimCardsTab />
        )}
      </div>
    </>
  );
};

export default SimCards;
