import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxios from 'hooks/axios/useAxios';

type CommandData = {
  probeName: string;
  sessionId: string;
  coordsData: any;
};
// tap example
// {"x": 258, "y": 757}

export default function useSendTap(options: MutationConfig<any, any>) {
  const axios = useAxios({ baseURL: process.env.REACT_APP_IOS });

  return useMutation(({ probeName, sessionId, coordsData }: CommandData) => axios.post(
    `/c/${probeName}/session/${sessionId}/wda/tap`,
    coordsData
  ).then(({ data }) => data), options);
}
