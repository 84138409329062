import React from 'react';
import { Button } from 'react-bootstrap';
import clsx from 'clsx';
import useSendCommand from 'hooks/services/iPhone/useSendCommand';
import useSendSwipe from 'hooks/services/iPhone/useSendSwipe';

type DeviceButtonsData = {
  probeName: string;
  probeStatus: any;
};

// phone hadrware & additional buttons/keys
const DeviceButtons = ({ probeName, probeStatus } : DeviceButtonsData) => {
  const [sendCommand] = useSendCommand({});
  const [sendSwipe] = useSendSwipe({});

  return (
    <div className="basic-buttons">
      <Button
        className="customTooltip custom-basic-button"
        key="home"
        onMouseDown={() => sendCommand({ probeName, command: 'homescreen' })}
        style={{ width: '25%', height: '2.5rem' }}
      >
        <i className={clsx('icon', 'icon-home')} />
      </Button>
      <Button
        className="customTooltip custom-basic-button"
        key="app-switch"
        onMouseDown={() => sendSwipe({
          probeName,
          sessionId: probeStatus?.sessionId,
          coordsData: { duration: 0.1, fromX: 200, fromY: 840, toX: 200, toY: 410 }
        })}
        style={{ width: '25%', height: '2.5rem' }}
      >
        <i className={clsx('icon', 'icon-tile-view')} />
      </Button>
    </div>
  );
};
export default DeviceButtons;
