import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetPowerControllers(component_type: string) {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_ATTENUATORS });

  return useQuery(['probeDevice', component_type], () => axios.get(
    `/pcs/api/component_uuids?type=${component_type}`
  ).then(({ data }) => data), { staleTime: 1000 * 60 * 5 });
}
