import React, { useEffect, useState } from 'react';
import FetchApi from './FetchApi';
import useGetProbeAttenuatorDetails from '../../../hooks/services/attenuator/useGetProbeAttenuatorDetails';
import useGetProbeChannels from '../../../hooks/services/attenuator/useGetProbeChannels';

interface IChannels {
  probe: any;
}

const Attenuator = ({ probe }: IChannels) => {
  const { data: attDetails, refetch: getAttDetails } = useGetProbeAttenuatorDetails(probe);
  const { data: channelDetails } = useGetProbeChannels(probe);

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);

  let checkAttDetails: number;

  useEffect(() => {
    if (attDetails) {
      checkAttDetails = Object.entries(attDetails.channels).length;
      if (checkAttDetails === 0) {
        setErrorLoading(true);
      }
    }
  }, [attDetails]);

  useEffect(() => {
    getAttDetails();
  }, []);

  return (
    attDetails?.probes.find((e: any) => e.name === probe)) ? (
      <>
        <div className="d-flex mb-2">
          <h3>{attDetails?.name}</h3>
        </div>
        <div className="d-flex flex-wrap attenuator-container">
          {channelDetails && attDetails?.channels
            .sort((a: any, b: any) => ((a.name > b.name) ? 1 : -1))
            .map((channel: any) => {
              const { max, min, name } = channel;
              return (
                <FetchApi
                  key={name}
                  channel={name}
                  max={max}
                  min={min}
                  probe={probe}
                  getAttDetails={getAttDetails}
                  channelDetails={channelDetails}
                  setLoading={setLoading}
                />
              );
            })}
          {!errorLoading && loading && <div>Loading... </div>}
          {errorLoading && <div>Error loading channel details</div>}
        </div>
      </>
    )
    : (
  // eslint-disable-next-line max-len
      <> No attenuator configured for this device.</>
    );
};

export default Attenuator;
