import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetStfDevices() {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_STF_API });

  // get STF devices
  return useQuery(['stfDevices'], () => axios.get(
    '/devices',
  ).then(({ data }) => data), { staleTime: 1000 * 60 * 5 });
}
