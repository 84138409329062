import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { Overlay, Popover } from 'react-bootstrap';

const UpdatingPopover = React.forwardRef(({
  content, left, popper, show, title, ...props
}: any, ref) => {
  useEffect(() => {
    popper.scheduleUpdate();
  }, [left, popper, show]);

  return (
    <Popover ref={ref} {...props} placement="bottom">
      {title && <Popover.Title as="h3">{title}</Popover.Title>}
      {content && (
      <Popover.Content>
        {content}
      </Popover.Content>
      )}
    </Popover>
  );
});

const TimelineItem = ({
  item, itemContext, getItemProps, getResizeProps, timelineContext,
}: any) => {
  const [target, setTarget] = useState<any>(null);

  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();

  const {
    canResizeLeft, canResizeRight, dimensions, dragging, resizing, selected,
    title: itemTitle, useResizeHandle,
  } = itemContext;

  let itemVisible = false;

  if (selected) {
    const visibleTimeStart = moment(timelineContext.visibleTimeStart);
    const visibleTimeEnd = moment(timelineContext.visibleTimeEnd);

    const timeStart = moment(item.start);
    const timeEnd = moment(item.end);

    if (timeStart.isAfter(visibleTimeStart) && timeStart.isBefore(visibleTimeEnd)) {
      itemVisible = true;
    } else if (timeEnd.isAfter(visibleTimeStart) && timeEnd.isBefore(visibleTimeEnd)) {
      itemVisible = true;
    } else if (timeStart.isBefore(visibleTimeStart) && timeEnd.isAfter(visibleTimeEnd)) {
      itemVisible = true;
    }
  }

  return (
    <>
      <div
        {...getItemProps(item.itemProps)}
      >
        {useResizeHandle && selected && canResizeLeft && <div {...leftResizeProps} />}
        <div
          className="rct-item-content"
          ref={(el) => setTarget(el)}
          style={{
            maxHeight: `${dimensions.height}`,
          }}
        >
          { dimensions.width > 20 ? itemTitle : null }
        </div>
        {useResizeHandle && selected && canResizeRight && <div className="icon icon-navigation-right" {...rightResizeProps} />}
      </div>
      {item?.popover && (
      <Overlay
        placement="bottom-end"
        show={itemVisible && selected && !dragging && !resizing}
        target={target}
      >
        <UpdatingPopover
          content={item?.popover?.content}
          left={dimensions.left}
          title={item?.popover?.title}
        />
      </Overlay>
      )}
    </>
  );
};

export default TimelineItem;
