import React, { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Tabs from 'components/Tabs';
import { Probe } from 'hooks/services/probe/interfaces';
import { Device } from '../context/interface';
import Screen from './Screen';
import BasicButtons from './BasicButtons';
import Info from './DeviceTab/Info';
import { WsContext } from '../context/ws';
import Attenuator from '../Attenuator/Attenuator';
import useGetProbeAttenuatorDetails from '../../../hooks/services/attenuator/useGetProbeAttenuatorDetails';

// render content for separate window consisting of device screen, buttons, info
const DeviceWindowContent = (
  {
    device: initDevice,
    winId,
    deadline,
    onTimeOut,
    probe,
  }: {
    device: Device,
    winId: string,
    deadline: number,
    onTimeOut?: () => any,
    probe: Probe | null,
  },
) => {
  const [activeTab, setActiveTab] = useState('device');
  const { devices } = useContext(WsContext);
  const device = devices.find((dev) => dev.serial === initDevice.serial);
  const {
    data: attDetails,
  } = useGetProbeAttenuatorDetails(probe?.probe_name || '');

  if (device) {
    return (
      <div className="d-flex flex-row">
        <div className="bu-px-1 bu-py-1 bu-mb-1">
          {device.ready ? <Screen /> : (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              minHeight: 692,
              minWidth: 311,
              background: 'white' }}
            >
              <Spinner animation="border" role="status" style={{ margin: '0 auto' }} />
              <span style={{ margin: '10px auto' }}>Loading ...</span>
            </div>
          )}
          {device.ready ? <BasicButtons channel={device.channel} /> : null}
        </div>
        <div className="border rounded-bottom bu-px-1 bu-py-1 bu-mb-1">
          <Tabs
            activeTab={activeTab}
            block
            onChange={(value) => setActiveTab(value)}
            tabs={[
              {
                content: 'Device',
                value: 'device',
              },
              {
                content: 'Attenuator',
                value: 'attenuator',
                hidden: !!(attDetails === undefined),
              },
            ].filter((t:any) => !t.hidden)}
          />
          <div className="bu-pt-1">
            {activeTab === 'device' && <Info device={device} deadline={deadline} onTimeOut={onTimeOut} probe={probe} winId={winId} />}
            {activeTab === 'attenuator' && <Attenuator probe={probe?.probe_name} />}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="d-flex flex-row">Device lost</div>
  );
};

export default DeviceWindowContent;
