import { useQuery } from 'react-query';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetQueuedStatuses() {
  const axios = useAxiosWithToken();

  return useQuery(['queue'], () => axios.get(
    '/scheduler/api/queue?start=0&per_page=3000'
  ).then(({ data }) => data), { staleTime: 1000 * 60 * 5 });
}
