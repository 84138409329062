import React, { useState, useEffect } from 'react';
import Tabs from 'components/Tabs';
import moment from 'moment';
import useGetProbeAttenuatorDetails from 'hooks/services/attenuator/useGetProbeAttenuatorDetails';
import useGetStatus from 'hooks/services/iPhone/useGetStatus';
import useCreateSession from 'hooks/services/iPhone/useCreateSession';
import useSendCommand from 'hooks/services/iPhone/useSendCommand';
import Screen from 'components/iPhone/Screen';
import Attenuator from 'components/stf/Attenuator/Attenuator';
import Info from 'components/iPhone/Info';

type IphoneProps = {
  reservation: any | null;
  probe: any;
};

const Iphone = ({
  reservation,
  probe,
} : IphoneProps) => {
  const [activeTab, setActiveTab] = useState('device');
  const {
    data: attDetails,
  } = useGetProbeAttenuatorDetails(probe?.probe_name);
  const [createSession] = useCreateSession({});
  const { data: probeStatus, refetch: refetchStatus, error } = useGetStatus(probe?.probe_name);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [sessionId, setSessionId] = useState<string | undefined>(probeStatus?.sessionId);
  const [deviceStatus, setDeviceStatus] = useState<'available' | 'unavailable' | 'undefined'>('undefined');
  const [showSimAlert, setShowSimAlert] = useState(false);
  const [sendCommand] = useSendCommand({});

  useEffect(() => {
    if (probe?.probe_name && !probeStatus?.sessionId) {
      createSession({
        probeName: probe?.probe_name,
        sessionCaps: { capabilities: {} }
      });
    }
  }, [probe?.probe_name, probeStatus?.sessionId]);

  useEffect(() => {
    if ((probeStatus && !probeStatus?.sessionId) || (!probeStatus && refreshStatus)) {
      setRefreshStatus(true);
      setSessionId(undefined);
    }
  }, [probeStatus, refreshStatus]);

  useEffect(() => {
    if (error) {
      setSessionId(undefined);
    }
  }, [error]);

  useEffect(() => {
    if (!error) {
      if (refreshStatus) {
        setDeviceStatus('unavailable');
        setShowSimAlert(true);
        sendCommand({ probeName: probe?.probe_name, command: 'homescreen' });
      }
      setSessionId(probeStatus?.sessionId);
    }
  }, [probeStatus, error]);

  useEffect(() => {
    if (refreshStatus) {
      const timer = setInterval(() => {
        refetchStatus();
      }, 5000);
      return () => {
        setRefreshStatus(false);
        setDeviceStatus(probeStatus && probeStatus?.sessionId ? 'available' : 'unavailable');
        console.log(`setting device status ${probeStatus && probeStatus?.sessionId ? 'available' : 'unavailable'}`);
        clearInterval(timer);
      };
    }
    return () => {};
  }, [refreshStatus, probeStatus?.sessionId, probeStatus, refetchStatus]);

  return (
    <>
      <div className="d-flex flex-row">
        {<Screen
          probe={probe}
          sessionId={sessionId}
          probeStatus={probeStatus}
          getStatus={() => refetchStatus()}
          deviceStatus={deviceStatus}
        /> || 'Device is not ready'}
        <div className="border rounded-bottom bu-px-1 bu-py-1 bu-mb-1">
          <Tabs
            activeTab={activeTab}
            block
            onChange={(value) => setActiveTab(value)}
            tabs={[
              {
                content: 'Device',
                value: 'device',
              },
              {
                content: 'Attenuator',
                value: 'attenuator',
                hidden: !!(attDetails === undefined),
              },
            ].filter((t:any) => !t.hidden)}
          />
          <div className="bu-pt-1">
            {activeTab === 'device' && (
              <Info
                device={probe?.devices[0]}
                deadline={+moment(reservation.until)}
                probe={probe}
                getStatus={() => { setRefreshStatus(true); setSessionId(undefined); setDeviceStatus('unavailable'); }}
                deviceStatus={deviceStatus}
                showSimAlert={showSimAlert}
              />
            )}
            {activeTab === 'attenuator' && <Attenuator probe={probe?.probe_name} />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Iphone;
