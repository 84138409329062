import { useQuery } from 'react-query';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetOpenStatuses() {
  const axios = useAxiosWithToken();

  return useQuery(['scheduleddJobs'], () => axios.get(
    '/scheduler/api/schedule'
  ).then(({ data }) => data), { staleTime: 1000 * 60 * 5 });
}
