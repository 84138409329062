import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button, Card, Col, Form, FormControl, Modal,
} from 'react-bootstrap';
import useForm from '../../../../../hooks/useForm';
import useAlerts from '../../../../../hooks/useAlerts';
import useAddPool from '../../../../../hooks/services/simManagement/pool/useAddPool';

interface IModalProps {
  show: boolean
  onHide: () => void;
  getPools: any;
}

type FormErrors = {
  name?: string;
};

const AddPoolModal = ({ getPools, onHide, show }: IModalProps) => {
  const [showError, setShowError] = useState(true);
  const [message, setMessage] = useState('');
  const { showAlert } = useAlerts();

  // success message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
  }, [message, showAlert]);

  const [addPool, { error }] = useAddPool({
    onSuccess: () => {
      setMessage('Pool has been added!');
      getPools();
      onHide();
    },
  });

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      name: '',
    },
    onSubmit: () => {
      addPool({ pool: { name: formValues.name } });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.name || values?.name === '') {
        errors.name = 'Please fill in the name.';
      }
      return errors;
    },
  });

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="xl"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header>
                Add new pool
                <button type="button" className="close" onClick={onHide} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  {error?.message && showError && (
                    <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                      {error?.message}
                    </Alert>
                  )}
                  <Form.Row>
                    <Form.Group as={Col} md={3}>
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <FormControl
                        autoFocus
                        id="name"
                        isInvalid={submitted && Boolean(formErrors?.name)}
                        name="name"
                        onChange={handleChange('name')}
                        required
                        type="text"
                        value={formValues?.name}
                      />
                      {submitted && Boolean(formErrors?.name) && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.name}
                        </FormControl.Feedback>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <div className="text-right">
                    <Button className="btn btn-secondary" style={{ color: 'black' }} onClick={onHide}>Close</Button>
                    <Button className="btn btn-primary" type="submit">Save</Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddPoolModal;
