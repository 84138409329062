import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import { SimArray } from '../interfaces';

type AddPoolRequestData = {
  array: SimArray;
};

export default function useAddArray(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ array }: AddPoolRequestData) => axios.post(
    '/sim-manager/api/arrays',
    array,
  ).then(({ data }) => data), options);
}
