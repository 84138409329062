import React, { useEffect, useMemo } from 'react';

import { Form } from 'react-bootstrap';

import Table from 'components/Table';
import CheckboxColumnFilter from 'components/Table/filters/CheckboxColumnFilter';

import useUserTableSettings from 'hooks/useUserTableSettings';

import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import useGetMappings from 'hooks/services/simManagement/mapping/useGetMappings';

import { SimMapping } from 'hooks/apiPolling/apiPollingContext';

import SimColumns from 'pages/Administration/SimColumns';
import { Probe } from 'hooks/services/probe/interfaces';
import { Sim } from 'hooks/services/simManagement/interfaces';

const hiddenColumns = [
  'position',
  'modified',
  'sim_type',
  'type',
  'prepaid',
  'origin',
  'name',
  'secret',
  'itg_id',
  'wholesale_id',
  'psp',
  'op_wnw',
  'wnw',
  'tariff',
  'imsi',
  'msisdn'
];

const EditSimCardsTable = (props : any) => {
  const { selectedSims, setSelectedSims, isSelected } = props;
  const { data: sims, refetch: getSims } = useGetSubscribers();
  const { data: probes } = useGetProbes();
  const { data: mappings, refetch: getMappings } = useGetMappings();

  const userTableSettings = useUserTableSettings('edit-sim-cards', { hiddenColumns });

  const handleChangeSims = (e: any) => {
    const { value } = e.target;
    setSelectedSims((prevSelectedSims: any) => {
      if (isSelected(prevSelectedSims, value)) {
        return prevSelectedSims.filter((val: any) => val !== value);
      }
      return [...prevSelectedSims || [], value];
    });
  };

  const timeout = 15;
  useEffect(() => {
    getMappings();
    getSims();
    const timerId = setInterval(() => {
      getSims();
      getMappings();
    }, timeout * 1000);
    return () => clearInterval(timerId);
  }, []);

  const extendedSims = sims
    ? sims.map((sim:Sim) => {
      // Find probe sim card is mapped to
      const mapping = mappings
        ? mappings.find((m:SimMapping) => (m && (m.mapped && m.mapped.iccid === sim.iccid))
          || (m.using && m.using.iccid === sim.iccid))
        : null;
      const mappedInProbe = mapping ? probes?.find((p:Probe) => p.probe_name === mapping.channel.path.split('.')[0])?.probe_alias : '';
      // set status active, mapped, using
      let mappingStatus = 'Available';
      mappings?.find((m:SimMapping) => {
        if (m && m.using && m.using.iccid === sim.iccid) {
          mappingStatus = 'In Use';
          return (m && m.using && m.using.iccid === sim.iccid);
        }
        if (m && m.mapped && m.mapped.iccid === sim.iccid) {
          mappingStatus = 'Inserting';
          return (m && m.mapped && m.mapped.iccid === sim.iccid);
        }
        mappingStatus = 'Available';
        return null;
      });
      return ({
        ...sim,
        mapping: mappedInProbe,
        status: mappingStatus,
        network: [sim?.lab && 'lab', sim?.live && 'live']
      });
    })
    : null;

  const columns = useMemo(() => [
    {
      Header: 'Selected',
      accessor: 'checked',
      Filter: CheckboxColumnFilter,
      filter: 'multiValue',
      // disableFilters: true,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      Cell: (props: any) => {
        const { cell: { row } } = props;
        const { iccid, checked } = row.original;
        return (
          <>
            <Form.Check
              defaultChecked={checked === 'Selected'}
              defaultValue={iccid}
              id={`groups-sims.${iccid}`}
              key={`groups-sims.${iccid}`}
              inline
              aria-label="Select"
              onClick={handleChangeSims}
            />
          </>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableFilters: true,
      Cell: ({ value }: any) => {
        let status = '';
        switch (value) {
          case 'Available':
            status = 'text-success';
            break;
          case 'Inserting':
            status = 'text-warning';
            break;
          case 'In Use':
            status = 'text-info';
            break;
          default:
            status = '';
            break;
        }
        return (
          <>
            <i className={`icon icon-record icon-solid ${status}`} />
            <span className="ml-2">{value}</span>
          </>
        );
      },
    },
    {
      Header: 'Connected to',
      accessor: 'mapping',
      isSortable: true,
    },
    ...SimColumns.map((item) => ({ ...item, disableFilters: true })),
    {
      Header: 'Tags',
      accessor: 'tags',
      isSortable: false,
      Cell: ({ value }: any) => (
        <>
          {value?.join(', ')}
        </>
      )
    },
    {
      Header: 'Network',
      accessor: 'network',
      Cell: ({ value }: any) => (
        <>
          {value && value?.filter((val:any) => (['lab', 'live'].includes(val))).join(', ')}
        </>
      )
    },
  ], [SimColumns]);

  const tableData = extendedSims?.filter((sim: any) => sim.position !== null)
    .map((simCard: any) => ({ checked: isSelected(selectedSims, simCard.iccid) ? 'Selected' : 'Not selected', ...simCard }));

  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        actionsPosition="left"
        columns={columns}
        data={tableData ?? []}
        hideExportButton
        initialState={userTableSettings?.initialState}
        onStateChange={userTableSettings.updateTableSettings}
        showSettingsMenu
        skipReset={false}
        stickyActions
        stickyHeader
      />
      )}
    </>
  );
};

export default EditSimCardsTable;
