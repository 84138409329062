import React, { useMemo, useState } from 'react';

import Table from 'components/Table';

import { IClaim, IGroup } from 'context/backendData/interfaces';

import useUserTableSettings from 'hooks/useUserTableSettings';

import useGetClaims from 'hooks/services/user/claim/useGetClaims';
import useGetGroups from 'hooks/services/user/group/useGetGroups';

import EditGroupModal from './EditGroupModal';

const GroupsTable = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<IGroup | undefined>();
  const { data: groups } = useGetGroups();
  const { data: claims, refetch: getClaims } = useGetClaims();

  const userTableSettings = useUserTableSettings('user-groups');

  const columns = useMemo(() => [
    {
      Header: 'Group Name',
      accessor: 'groupname',
      isSortable: true,
    },
    {
      Header: 'Group ID',
      accessor: 'gid',
      isSortable: true,
    },
    {
      Header: 'SIMS',
      accessor: 'displaySims',
      isSortable: true,
    },
    {
      Header: 'Probes',
      accessor: 'displayProbes',
      isSortable: true,
    },
    {
      Header: 'Boards',
      accessor: 'displayBoards',
      isSortable: true,
    },
  ], []);

  const openModal = (value: any) => {
    console.log('group data: ', value);
    setSelectedGroup(value);
    setShowModal(true);
  };

  const tableRowActions = useMemo(() => ({
    content: 'Actions',
    items: [
      {
        content: 'Edit',
        key: 'edit',
        onClick: (row: any) => openModal(row),
      },
    ],
  }), []);

  const tableData = groups?.filter((group: IGroup) => group.gid !== null)
    .map((group: IGroup) => {
      const groupClaims = claims && claims.find((claim: IClaim) => claim.gid === group.gid);
      return {
        ...group,
        ...groupClaims,
      };
    });

  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        columns={columns}
        rowActions={tableRowActions}
        data={tableData ? tableData?.map((row: IGroup) => ({
          ...row,
          displaySims: row?.sims?.length,
          displayProbes: row?.probes?.join(', '),
          displayBoards: row?.boards?.join(', '),
        })) : []}
        initialState={userTableSettings?.initialState}
        onStateChange={userTableSettings.updateTableSettings}
        skipReset={false}
      />
      )}
      {showModal
      && (
      <EditGroupModal
        onHide={() => setShowModal(false)}
        show={showModal}
        groupData={selectedGroup}
        getClaims={getClaims}
      />
      )}
    </>
  );
};

export default GroupsTable;
