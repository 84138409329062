import React, { useEffect, useMemo, useState } from 'react';

import Table from 'components/Table';

import { ISimArray } from 'context/backendData/interfaces';

import useAlerts from 'hooks/useAlerts';
import useUserTableSettings from 'hooks/useUserTableSettings';

import useGetArrays from 'hooks/services/simManagement/array/useGetArrays';

import useUpdateArrayStatus
  from 'hooks/services/simManagement/array/useUpdateArrayStatus';

import Toggle from 'components/form/Toggle';

// import useDeleteArray from 'hooks/services/simManagement/array/useDeleteArray';
// import useConfirm from 'hooks/useConfirm';

import EditArrayModal from './EditArrayModal';
import AddSimArrayModal from './AddSimArrayModal';

const ArrayTable = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const { data: simArrays, refetch: getSimArrays } = useGetArrays();

  const userTableSettings = useUserTableSettings('sim-array');

  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const columns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      isSortable: true,
    },
    {
      Header: 'internal IPv4',
      accessor: 'internalIPAddress',
      isSortable: true,
    },
    {
      Header: 'internal Port',
      accessor: 'internalIPPort',
      isSortable: true,
      disableFilters: true,
    },
    {
      Header: 'external IPv4',
      accessor: 'externalIPAddress',
      isSortable: true,
    },
    {
      Header: 'external Port',
      accessor: 'externalIPPort',
      isSortable: true,
      disableFilters: true,
    },
    {
      Header: 'SIM Boards',
      accessor: 'maxNumberOfBoards',
      isSortable: true,
      disableFilters: true,
    },

    {
      Header: 'Enabled',
      accessor: 'enabled',
      isSortable: false,
      disableFilters: true,
    },
    {
      Header: 'Available',
      accessor: 'available',
      isSortable: true,
    },
  ], []);

  const [showEditModal, setShowEditModal] = useState(false);

  // const [selectedArray, setSelectedArray] = useState<ISimArray | undefined>(undefined);
  const [selectedArray] = useState<ISimArray | undefined>(undefined);
  /*
  Manipulating with SIM Array is currently not applied in TESSA GUI

const [deleteArray] = useDeleteArray(
  {
    onSuccess: () => {
      setMessage('SIM array deleted!');
      getSimArrays();
    },
  },
);
const confirm = useConfirm();

const handleDeleteSimArray = (row: any) => {
confirm({
  body: `Sure to delete SIM array ${row?.name}?`,
  onOk: () => deleteArray({ arrayName: row.name }),
  title: 'Delete SIM array',
});
};
const openEditModal = (value: any) => {
setSelectedArray(value);
setShowEditModal(true);
};

const tableRowActions = useMemo(() => ({
content: 'Actions',
items: [
  {
    content: 'Edit',
    key: 'edit',
    onClick: (array: any) => openEditModal(array),
  },
  {
    color: 'danger',
    content: 'Delete',
    key: 'delete',
    onClick: (array: any) => handleDeleteSimArray(array),
  },
],
}), []);

*/

  const [editArrayStatus] = useUpdateArrayStatus(
    {
      onSuccess: () => {
        setMessage('SIM array status updated!');
        getSimArrays();
      },
      onError: () => {
        setErrMessage('Failed to update SIM array');
        getSimArrays();
      }
    },
  );

  const tableData = simArrays ? simArrays.filter((array: ISimArray) => array.name !== 'test1')
    .map((row: ISimArray) => ({
      ...row,
      enabled: (
        <Toggle
          id="edit-array-status"
          active={row.enabled?.toString() === 'true' || false}
          onChange={(value) => editArrayStatus({
            status: { name: row.name, enabled: value },
          })}
        />
      ),
      available: row.available?.toString(),
    })) : [];

  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        columns={columns}
        data={tableData}
//        rowActions={tableRowActions}
        initialState={userTableSettings?.initialState}
        onStateChange={userTableSettings.updateTableSettings}
        skipReset={false}
      />
      )}
      {showAddModal && (
      <AddSimArrayModal
        onHide={() => setShowAddModal(false)}
        show={showAddModal}
        getSimArrays={getSimArrays}
        setMessage={setMessage}
        setErrMessage={setErrMessage}
      />
      )}
      {showEditModal
      && (
        <EditArrayModal
          onHide={() => setShowEditModal(false)}
          show={showEditModal}
          arrayData={selectedArray!}
          getSimArrays={getSimArrays}
          setMessage={setMessage}
          setErrMessage={setErrMessage}
        />
      )}
    </>
  );
};

export default ArrayTable;
