import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Card, Form, FormControl, FormGroup, Modal,
} from 'react-bootstrap';
import useForm from '../../../../hooks/useForm';
import useAddProbeDevice from '../../../../hooks/services/probe/useAddProbeDevice';

interface IModalProps {
  show: boolean
  onHide: () => void;
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  probeName: string;
  getProbes: any;
}

type FormErrors = {
  name?: string;
  serial?: string,
  type?: string,
  version?: string,
  IP?: string,
};

const AddDeviceModal = ({
  onHide, show, setErrMessage, setMessage, probeName, getProbes,
}: IModalProps) => {
  const [showError, setShowError] = useState(true);

  const [addProbeDevice, { error }] = useAddProbeDevice({
    onSuccess: () => {
      setMessage('New device has been added!');
      getProbes();
      onHide();
    },
    onError: () => setErrMessage('Failed to create device'),
  });

  const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      name: '',
      serial: '',
      type: '',
      version: '',
      adboe: false,
      status: 'online',
      exclusive: 'true',
      rooted: 'true',
      IP: '',
    },
    onSubmit: () => {
      if (formValues?.IP === '') {
        addProbeDevice({
          probeName,
          device: {
            name: formValues.name,
            serial: formValues.serial,
            type: formValues.type,
            adboe: formValues.adboe,
            version: formValues.version,
            status: formValues.status,
            exclusive: formValues.exclusive,
            rooted: formValues.rooted,
            multiplexers: [],
          },
        });
      } else {
        addProbeDevice({
          probeName,
          device: {
            name: formValues.name,
            serial: formValues.serial,
            type: formValues.type,
            adboe: formValues.adboe,
            version: formValues.version,
            status: formValues.status,
            exclusive: formValues.exclusive,
            rooted: formValues.rooted,
            IP: formValues.IP,
            multiplexers: [],
          },
        });
      }
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.name || values?.name === 0) {
        errors.name = 'Please fill in device name.';
      }
      if (!values?.serial || values?.serial === '') {
        errors.serial = 'Please fill in serial.';
      }
      if (!values?.type || values?.type === 0) {
        errors.type = 'Please select device type.';
      }
      if (!values?.version || values?.version === '') {
        errors.version = 'Please fill in version.';
      }
      if ((values?.type === 'IP_Phone' || values?.type === 'Analogue_Modem') && (!values?.IP || values?.IP === '' || !ipv4Regex.test(values?.IP))) {
        errors.IP = 'Please fill in IP.';
      }
      return errors;
    },
  });

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="xl"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header>
                Add new device
                <button type="button" className="close" onClick={onHide} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  {error?.message && showError && (
                    <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                      {error?.message}
                    </Alert>
                  )}
                  <Form.Row>
                    <FormGroup key="probe">
                      <Form.Label htmlFor="probe">Probe</Form.Label>
                      <FormControl
                        autoFocus
                        id="probe"
                        name="probe"
                        type="text"
                        value={probeName}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup key="name" className="ml-2">
                      <Form.Label htmlFor="name">Device name<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span></Form.Label>
                      <FormControl
                        autoFocus
                        id="name"
                        isInvalid={submitted && Boolean(formErrors?.name)}
                        name="name"
                        onChange={handleChange('name')}
                        required
                        type="text"
                        value={formValues?.name}
                      />
                      {submitted && Boolean(formErrors?.name) && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.name}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="serial">
                      <Form.Label htmlFor="serial">
                        Serial<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span>
                      </Form.Label>
                      <FormControl
                        id="serial"
                        isInvalid={submitted && Boolean(formErrors?.serial)}
                        name="serial"
                        onChange={handleChange('serial')}
                        required
                        type="text"
                        value={formValues?.serial}
                      />
                      {submitted && formErrors?.serial && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.serial}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup key="type" className="ml-2">
                      <Form.Label htmlFor="type">
                        Type<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formValues.type}
                        id="type"
                        isInvalid={submitted && Boolean(formErrors?.type)}
                        name="type"
                        onChange={handleChange('type')}
                      >
                        <option value="" disabled hidden>type</option>
                        <option value="iOS">iOS</option>
                        <option value="Android">Android</option>
                        <option value="IoT">IoT</option>
                        <option value="IP_Phone">IP Phone</option>
                        <option value="LTE_Modem">LTE Modem</option>
                        <option value="Analogue_Modem">Analogue Modem</option>
                      </Form.Control>
                      {submitted && formErrors?.type && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.type}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="adboe" className="ml-2">
                      <Form.Check
                        checked={formValues?.adboe}
                        value="adboe"
                        id="adboe"
                        key="adboe"
                        type="checkbox"
                        label="Connected via ADBoE"
                        inline
                        aria-label="item"
                        className="lg mr-0 ml-3"
                        onChange={handleChange('adboe')}
                      />
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="version">
                      <Form.Label htmlFor="version">
                        Version<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span>
                      </Form.Label>
                      <FormControl
                        id="version"
                        isInvalid={submitted && Boolean(formErrors?.version)}
                        name="version"
                        onChange={handleChange('version')}
                        required
                        type="text"
                        value={formValues?.version}
                      />
                      {submitted && formErrors?.version && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.version}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup key="status" className="ml-2">
                      <Form.Label htmlFor="status">
                        Status
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formValues.status}
                        id="status"
                        name="status"
                        onChange={handleChange('status')}
                      >
                        <option value="online">online</option>
                        <option value="offline">offline</option>
                      </Form.Control>
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="IP">
                      <Form.Label htmlFor="IP">
                        IP Address
                        {(formValues?.type === 'IP_Phone' || formValues?.type === 'Analogue_Modem')
                        && (<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span>)}
                      </Form.Label>
                      <FormControl
                        id="IP"
                        name="IP"
                        isInvalid={submitted && Boolean(formErrors?.IP)}
                        onChange={handleChange('IP')}
                        type="text"
                        value={formValues?.IP}
                      />
                      {submitted && formErrors?.IP && (
                      <FormControl.Feedback type="invalid">
                        {formErrors?.IP}
                      </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup key="exclusive" className="ml-2">
                      <Form.Label htmlFor="exclusive">
                        Exclusive
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formValues.exclusive}
                        id="exclusive"
                        name="exclusive"
                        onChange={handleChange('exclusive')}
                      >
                        <option value="true">yes</option>
                        <option value="false">no</option>
                      </Form.Control>
                    </FormGroup>
                    <FormGroup key="rooted" className="ml-2">
                      <Form.Label htmlFor="rooted">
                        Rooted
                      </Form.Label>
                      <Form.Control
                        as="select"
                        defaultValue={formValues.rooted}
                        id="rooted"
                        name="rooted"
                        onChange={handleChange('rooted')}
                      >
                        <option value="true">yes</option>
                        <option value="false">no</option>
                      </Form.Control>
                    </FormGroup>
                  </Form.Row>
                  <div className="text-right">
                    <Button className="btn btn-secondary" style={{ color: 'black' }} onClick={onHide}>Close</Button>
                    <Button
                      className="btn btn-primary"
                      type="submit"
                      disabled={(!formValues?.version || formValues?.version === '')
                      || (!formValues?.name || formValues?.name === 0)
                      || (!formValues?.serial || formValues?.serial === '')
                      || (!formValues?.type || formValues?.type === 0)
                      || ((formValues?.type === 'IP_Phone' || formValues?.type === 'Analogue_Modem') && (!formValues?.IP || formValues?.IP === '' || !ipv4Regex.test(formValues?.IP)))}
                    >Save
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddDeviceModal;
