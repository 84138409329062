import moment from 'moment';
import React, { useEffect, useState } from 'react';
import humanizeDuration from 'humanize-duration';

const CountDown = ({
  deadline,
  onTimeOut,
  text,
  formating,
}: {
  deadline: number | null,
  onTimeOut?: (() => any),
  text?: string,
  formating?: boolean,
}) => {
  const warnLimit = 5 * 60 * 1000;

  const [timeLeft, setTimeLeft] = useState(deadline ? deadline - +moment() : 0);

  useEffect(() => {
    setTimeout(() => {
      const rest = deadline ? deadline - +moment() : 0;
      setTimeLeft(rest);
      if (rest <= 0 && onTimeOut) onTimeOut();
    }, 1000);
  });

  if (deadline) {
    if (formating) {
      return (
        <>
          {
            timeLeft > 0
              ? (
                <span style={{ color: (warnLimit > (deadline - +moment()) ? 'red' : '') }}>
                  {text}
                  {humanizeDuration(deadline - +moment(), { units: ['y', 'mo', 'd', 'h', 'm', 's'], round: true, delimiter: '-' })
                    .replaceAll(' ', '')
                    .replaceAll('-', ' ')
                    .replace(/hour\w?/, 'h')
                    .replace(/minute\w?/, 'min')
                    .replace(/second\w?/, 'sec')}
                </span>
              )
              : <span style={{ color: 'red' }}>The reservation has ended</span>
          }
        </>
      );
    }
    return (
      <>
        {
          timeLeft > 0
            ? (
              <span style={{ color: (warnLimit > (deadline - +moment()) ? 'red' : '') }}>
                {'Reservation ends in '}
                {humanizeDuration(deadline - +moment(), { units: ['y', 'mo', 'd', 'h', 'm', 's'], round: true, delimiter: ' ' })}
              </span>
            )
            : <span style={{ color: 'red' }}>The reservation has ended</span>
        }
      </>
    );
  }
  return <span />;
};

export default CountDown;
