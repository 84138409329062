import React, { useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useField } from 'react-form';

import Table from 'components/Table';
import useUserTableSettings from 'hooks/useUserTableSettings';
import moment from 'moment';
import config from 'config';
import useGetUsers from '../../../hooks/services/user/useGetUsers';

interface IProbeTable {
  probes: any[],
  reservations: any[],
  selectedProbes: any[],
  semiAutomated:any,
}
const ProbeTable = ({
  probes, reservations, selectedProbes, semiAutomated,
}: IProbeTable) => {
  const userTableSettings = useUserTableSettings('probes');
  const validReservations = reservations?.filter((res:any) => moment(res.until).isAfter(moment()));
  const { data: users, refetch: getUsers } = useGetUsers();

  useEffect(() => {
    getUsers();
  }, []);

  const extendedColumns = semiAutomated ? [
    {
      accessor: 'location',
      Header: 'Location',
      disableFilters: true,
    },
    {
      accessor: 'probe_alias',
      Header: 'Name',
      disableFilters: true,
    },
    {
      Header: 'Tags',
      accessor: 'tags',
      isSortable: false,
      Cell: ({ value }: any) => (
        <>
          {value?.join(', ')}
        </>
      )
    },
    {
      Header: 'Network',
      accessor: 'network',
      Cell: ({ value }: any) => (
        <>
          {value && value?.filter((val:any) => (['lab', 'live'].includes(val))).join(', ')}
        </>
      )
    },
  ] : [
    {
      accessor: 'location',
      Header: 'Location',
      disableFilters: true,
    },
    {
      accessor: 'probe_alias',
      Header: 'Name',
      disableFilters: true,
    },
  ];

  const columns = useMemo(() => [
    ...extendedColumns,
    {
      accessor: 'device',
      Header: 'Device',
      disableFilters: true,
    },
    {
      accessor: 'res_status',
      Header: 'Reservation Status',
      disableFilters: true,
      Cell: (props:any) => {
        const { cell: { row } } = props;
        const foundRes = validReservations?.find(
          (res:any) => res.probe_name === row.original.probe_name
        );
        return (
          <div>
            {foundRes
              ? (
                <div>
                  <span style={{ color: '#6B6B6B', fontSize: '0.8em' }}>From: {moment(foundRes.from).format(config.ui.dateTimeFormat)}</span>
                  <br />
                  <span style={{ color: '#6B6B6B', fontSize: '0.8em' }}>Until: {moment(foundRes.until).format(config.ui.dateTimeFormat)}</span>
                  <br />
                  <span style={{ fontSize: '0.8em' }}>By:
                    <a href={`mailto:${users?.find((user:any) => user.uid === foundRes.user_id)?.mail}`}> {users?.find((user:any) => user.uid === foundRes.user_id)?.cn}</a>
                  </span>
                </div>
              )
              : (
                <div>
                  <i className="icon icon-record icon-solid text-success" style={{ margin: 'auto' }} />
                  {' Available'}
                </div>
              )}
          </div>
        );
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableFilters: true,
      Cell: (props: any) => {
        const { cell: { row } } = props;
        return (
          <div style={{ color: `${row.original.status === 'online' ? '##000000' : '#ff0000'}` }}>
            {row.original.status}
          </div>
        );
      }
    },
  ], [users, reservations]);

  const { setValue } = useField('probes', {
    validate: (value) => ((!value) ? 'This field is required' : undefined),
  });

  const onSelectedRowsChange = useCallback((rows: any) => {
    setValue((prevState: any) => {
      const newSelectedProbes = _.map(rows, 'probe_name');
      if (!_.isEqual(prevState, newSelectedProbes)) {
        return newSelectedProbes;
      }
      return prevState;
    });
  }, []);

  const probeData = probes ? probes.map((probe: any) => {
    if (probe?.devices[0]?.type === 'Android') {
      return ({
        ...probe,
        network: [probe?.lab && 'lab', probe?.live && 'live'],
        status: (probe?.devices[0]?.status === 'online' && probe?.devices[0]?.stf_status === 'online' && probe?.status === 'online') ? 'online' : 'offline'
      });
    }
    return ({
      ...probe,
      network: [probe?.lab && 'lab', probe?.live && 'live'],
      status: (probe?.devices[0]?.status === 'online' && probe?.status === 'online') ? 'online' : 'offline'
    });
  }) : [];

  if (!userTableSettings?.isFetched) {
    return null;
  }

  return (
    <Table
      columns={columns}
      data={probeData.map((pr) => ({ ...pr, isSelected: selectedProbes.includes(pr.probe_name) }))}
      hideExportButton
      initialState={userTableSettings?.initialState}
      onSelectedRowsChange={onSelectedRowsChange}
      onStateChange={userTableSettings.updateTableSettings}
      skipReset={false}
      selectable
    />
  );
};

export default ProbeTable;
