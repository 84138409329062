import _ from 'lodash';
import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import { Probe } from './interfaces';

type CreateProbeAndEditLocationRequestData = {
  countryIso: string;
  probe: Probe;
};

export default function useAddProbeAndEditLocation(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  const queryCache = useQueryCache();

  return useMutation(({
    countryIso,
    probe,
  }: CreateProbeAndEditLocationRequestData) => new Promise((resolve, reject) => {
    axios.post(
      '/cms/api/probes',
      probe,
    ).then(({ data: addProbeData }) => axios.put(
      `/cms/api/probes/${probe?.probe_name}/location/assign/${countryIso}`,
    ).then(() => resolve(addProbeData)).catch((error) => reject(error)))
      .catch((error) => reject(error));
  }), {
    ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }

      queryCache.invalidateQueries('probes');
    }
  });
}
