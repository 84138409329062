/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
// import './style.scss';

type ModalProps = {
  children: ReactNode;
  title: string;
  show: boolean;
  htmlDoc?: HTMLDocument;
  htmlBackgroundElement?: HTMLElement | null;
  onClose: () => void;
  footer?: ReactNode;
};

const hideElement = (el: HTMLElement) => {
  el.setAttribute('aria-hidden', 'true');
  el.setAttribute('style', 'display:none;');
};

const showElement = (el: HTMLElement) => {
  el.setAttribute('style', `${el.getAttribute('style')} z-index: 1050; display:block;`);
  el.removeAttribute('aria-hidden');
};

const Modal = ({
  children,
  show,
  onClose,
  title,
  footer,
}: ModalProps) => {
  const doc = document;
  const [modalRoot] = useState(doc.getElementById('modal'));
  const [backdrop] = useState<HTMLDivElement>(doc.createElement('div'));
  const [element] = useState<HTMLDivElement>(doc.createElement('div'));
  element.className = 'modal-confirm"';
  element.style.position = 'absolute';
  element.style.display = 'none';
  element.style.width = '90%';
  element.style.height = '90%';
  element.style.top = '50%';
  element.style.left = '50%';
  element.style.transform = 'translate(-50%, -50%)';
  element.style.border = '1px solid #ccc';
  element.style.background = '#fff';
  element.style.borderRadius = '4px';
  element.style.outline = 'none';
  element.style.border = '2px solid #ccc';

  useEffect(() => {
    modalRoot?.appendChild(backdrop);
    modalRoot?.appendChild(element);
    return () => {
      modalRoot?.removeChild(backdrop);
      modalRoot?.removeChild(element);
    };
  }, []);

  const getModalContent = () => (
    <>
      <div className="modalWin">
        <div className="modal-header">
          <div className="modal-title h4">{title}</div>
          <button aria-label="close" type="button" className="close" onClick={() => onClose()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        {footer && (
          <div className="modal-footer">
            {footer}
          </div>
        )}
      </div>
    </>
  );

  if (show) {
    showElement(element);
    backdrop.setAttribute('class', 'modal-backdrop show');
    return createPortal(getModalContent(), element);
  }
  backdrop.setAttribute('class', '');
  hideElement(element);
  return null;
};

export default Modal;
