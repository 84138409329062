import _ from 'lodash';
import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeleteProbeData = {
  probeName: string;
};

export default function useDeleteProbe(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  const queryCache = useQueryCache();

  return useMutation(({ probeName }: DeleteProbeData) => axios.delete(
    `/cms/api/probes/${probeName}`,
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }
      queryCache.invalidateQueries('probes');
    }
  });
}
