import React, { SyntheticEvent, useState } from 'react';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import parser from 'cron-parser';
import cronstrue from 'cronstrue';
import _ from 'lodash';
import TestSetModal from '../TestSetModal';
import useScheduleParallelJob, { ScheduleParallelJobData } from '../../../hooks/services/scheduler/useScheduleParallelJob';

const Finish = ({
  previousStep,
  campaignName,
  selectedSubscribers,
  projectId,
  selectedBranch,
  selectedTestCases,
  selectedNetwork,
  selectedOsix,
  selectedTcpDump,
  selectedRecordScreen,
  scheduling,
  setMessage,
  setErrMessage,
  setActiveScreen,
  setActiveTab,
}: any) => {
  const [showTestSetModal, setShowTestSetModal] = useState(false);

  const options = {
    currentDate: Date.now(),
    iterator: true
  };

  const nextRunAt = (cron:any) => {
    const interval = parser.parseExpression(cron, options);
    const intervalObj: any = interval.next();
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const originalDateOffset = new Date(intervalObj.value.toDate().getTime() - offset * 60000);
    return moment(originalDateOffset).utc().format('YYYY-MM-DD HH:mm');
  };

  const repeatDaysCron = scheduling.repeatDays.map((day: any) => day.slice(0, 3)).toString();
  const date = scheduling.startTime.split(' ');
  const testTags = selectedTestCases?.map((item:any) => item.tag);

  const [setCommand] = useScheduleParallelJob({
    onSuccess: () => {
      setMessage('Campaign started');
      setActiveScreen('overview');
      setActiveTab('scheduled-campaigns');
    },
    onError: (error) => {
      setErrMessage(`Failed to start campaign: ${error}`);
    }
  });

  const timeRegex = /^\d{1,2}:\d{2}$/;
  const localDate = new Date();

  const dateTimeGMT = (time:any) => {
    if (timeRegex.test(time)) {
      const [localHours, localMinutes] = scheduling.startTime.split(':');
      localDate.setHours(localHours, localMinutes, 0, 0);
      const utcHours = localDate.getUTCHours();
      const utcMinutes = localDate.getUTCMinutes();
      const utcTimeStr = `${utcHours}:${utcMinutes}`;
      return utcTimeStr;
    }
    return (moment(scheduling.startTime).utc().format('YYYY-MM-DD H:mm:ss UTC'));
  };

  const dateTimeGMTValue = dateTimeGMT(scheduling.startTime);

  const schedulingMapping = () => {
    if (!scheduling.frequency) {
      return scheduling.startTime;
    }
    switch (scheduling.selectedRepetition) {
      case 0:
        return `${scheduling.minuteOfHour} */${scheduling.repeatEvery} * * *`;
      case 1:
        return `${(dateTimeGMTValue).split(':')[1]} ${(dateTimeGMTValue).split(':')[0]} * * *`;
      case 2:
        return `${(dateTimeGMTValue).split(':')[1]} ${(dateTimeGMTValue).split(':')[0]} * * ${repeatDaysCron}`;
      case 3:
        return `${(dateTimeGMTValue).split(':')[1]} ${(dateTimeGMTValue).split(':')[0]} ${scheduling.dayOfMonth} * *`;
      default:
        return null;
    }
  };

  const schedulingMappingLocalTime = () => {
    switch (scheduling.selectedRepetition) {
      case 0:
        return `${scheduling.minuteOfHour} */${scheduling.repeatEvery} * * *`;
      case 1:
        return `${(scheduling.startTime).split(':')[1]} ${(scheduling.startTime).split(':')[0]} * * *`;
      case 2:
        return `${(scheduling.startTime).split(':')[1]} ${(scheduling.startTime).split(':')[0]} * * ${repeatDaysCron}`;
      default:
        return `${(scheduling.startTime).split(':')[1]} ${(scheduling.startTime).split(':')[0]} ${scheduling.dayOfMonth} * *`;
    }
  };

  const scheduleParallelJob = (e: SyntheticEvent) => {
    const localDatePlusMinute = moment(new Date(localDate.setMinutes(localDate.getMinutes() + 1))).format('YYYY-MM-DD H:mm');
    if ((scheduling.frequency || !scheduling.run) || (new Date(scheduling.startTime)
    > new Date(localDatePlusMinute))) {
      e.preventDefault();
      const command:ScheduleParallelJobData = {
        branch: selectedBranch,
        testcases: testTags,
        project_id: projectId,
        network: selectedNetwork,
        subscriber_tags: selectedSubscribers,
        traces: {
          osix: selectedOsix,
          tcpdump: selectedTcpDump,
          screencap: selectedRecordScreen,
        }
      };
      command.schedule = {
        type: scheduling.frequency ? 'cron' : 'at',
        value: scheduling.frequency ? schedulingMapping() : dateTimeGMTValue,
        name: campaignName
      };

      setCommand(command);
    } else {
      setErrMessage('Failed to start Campaign - please select start time in future');
    }
  };

  return (
    <Form>
      <strong>
        Your campaign summary:
      </strong>
      <Row style={{ margin: '2rem' }}>
        <Col md={3}>
          <Row>
            <strong>Campaign Name:</strong>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            {(campaignName !== '') ? campaignName : 'please fill campaign name'}
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <strong>Show:</strong>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                setShowTestSetModal(true);
              }}
            >
              Test Set
            </button>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <strong>Test Parameters: </strong>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            <Col md={4} style={{ padding: 0 }}>
              SIM Cards:
            </Col>
            <Col md={8}>
              {_.isEmpty(selectedSubscribers)
                ? (
                  <Row>
                    - All SIMs
                  </Row>
                )
                : selectedSubscribers.map((subTag:any) => (
                  <Row>
                    - {subTag}
                  </Row>
                ))}
            </Col>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            <Col md={4} style={{ padding: 0 }}>
              Trace Type:
            </Col>
            <Col md={8}>
              {(selectedOsix) && (
              <Row>
                - OSIX
              </Row>
              )}
              {(selectedTcpDump) && (
              <Row>
                - TCPdump
              </Row>
              )}
              {(selectedRecordScreen) && (
              <Row>
                - Record Device Screen
              </Row>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <strong>Scheduling:</strong>
          </Row>
          <Row style={{ marginTop: '2rem' }}>
            <Col>
              <Row>
                Repetition:
              </Row>
              {!scheduling.frequency && (
              <>
                <Row style={{ marginTop: '1rem' }}>
                  Start Date:
                </Row>
                <Row style={{ marginTop: '1rem' }}>
                  Start Time:
                </Row>
              </>
              )}
              <Row style={{ marginTop: '1rem' }}>
                Next run at:
              </Row>
            </Col>
            <Col>
              <Row style={{ marginBottom: '1rem' }}>
                {scheduling.frequency ? cronstrue.toString(schedulingMappingLocalTime(), { use24HourTimeFormat: true }) : 'Once'}
              </Row>
              {!scheduling.frequency && (
              <>
                <Row style={{ marginBottom: '1rem' }}>
                  {(!scheduling.frequency && !scheduling.run) ? 'Now' : date[0]}
                </Row>
                <Row style={{ marginBottom: '1rem' }}>
                  {(!scheduling.frequency && !scheduling.run) ? 'Now' : date[1]}
                </Row>
              </>
              )}
              <Row style={{ marginBottom: '1rem' }}>
                {scheduling.frequency ? moment(nextRunAt(schedulingMappingLocalTime())).utc(true).format('YYYY-MM-DD HH:mm') : '---'}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {showTestSetModal
      && (
        <TestSetModal
          onHide={() => setShowTestSetModal(false)}
          show={showTestSetModal}
          campaignName={campaignName}
          selectedTestCases={selectedTestCases}
        />
      )}
      <Container className="pt-4">
        <Row style={{ marginTop: '8rem' }}>
          <Col />
          <Col md={3} style={{ minWidth: '200px' }}>
            <Button
              id="previousStep"
              onClick={previousStep}
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
            >
              Back
            </Button>
          </Col>
          <Col md={3} style={{ minWidth: '200px' }}>
            <Button
              id="nextStep"
              onClick={scheduleParallelJob}
              className="btn-block mr-1 mt-1 btn-lg"
            >
              Start Campaign
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
    </Form>
  );
};

export default Finish;
