import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import { useApiPolling, SimMapping } from 'hooks/apiPolling/apiPollingContext';
import { Probe } from 'hooks/services/probe/interfaces';
import Modal from 'components/Modal';
import useCreateMapping from 'hooks/services/simManagement/mapping/useCreateMapping';
import useDeleteMapping from 'hooks/services/simManagement/mapping/useDeleteMapping';
import { Sim } from 'hooks/services/simManagement/interfaces';
import InsertSimCard from 'components/stf/window/DeviceTab/InsertSimCard';
import { Device } from 'components/stf/context/interface';
import './style.scss';

type ResultMsg = {
  title: string;
  msg: string;
};

type DeviceSimProps = {
  device: Device | undefined;
  probe: Probe | null;
  getStatus: () => void;
  deviceStatus: string;
  showSimAlert: boolean;
};

const DeviceSim = ({
  device,
  probe,
  getStatus,
  deviceStatus,
  showSimAlert
} : DeviceSimProps) => {
  const [mapping, setMapping] = useState<SimMapping | null>(null);

  const [simsForTable, setSimsForTable] = useState<Sim[]>([]);
  const [insertSimModalOpened, setInsertSimModalOpened] = useState(false);

  const [resultModalOpened, setResultModalOpened] = useState(false);
  const [resultMsg, setResultMsg] = useState<ResultMsg>({ title: '', msg: '' });

  const [mappedSim, setMappedSim] = useState<Sim | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    mappings,
    sims,
  } = useApiPolling();

  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');

  useEffect(() => {
    if (deviceStatus === 'available' && showSimAlert) {
      if (mappedSim) {
        setAlertType('mappingSuccessful');
        setShowAlert(true);
      } else {
        setAlertType('unmappingSuccessful');
        setShowAlert(true);
      }
    }
  }, [deviceStatus]);

  const alertMessages = [
    {
      name: 'mappingInfo',
      message: 'Please be patient, the device will be restarted to recognize the SIM card.',
      variant: 'info'
    },
    {
      name: 'mappingSuccessful',
      message: 'SIM is inserted. Please wait until the device is connected to a network. You also might check manually on the device.',
      variant: 'success'
    },
    {
      name: 'unmappingInfo',
      message: 'Please be patient, the device will be restarted to remove the SIM card.',
      variant: 'info'
    },
    {
      name: 'unmappingSuccessful',
      message: 'SIM successfully removed',
      variant: 'success'
    },
  ];

  useEffect(() => {
    setSimsForTable(sims);
  }, [sims]);

  const mappingError = (error: any) => {
    setAlertType('');
    setShowAlert(false);
    setResultMsg({ title: 'SIM mapping error', msg: `${error.message}` });
    setInsertSimModalOpened(true);
    // on SIM insertion error show inserting window again
    setResultModalOpened(true);
  };
  const unmappingError = (error: any) => {
    setAlertType('');
    setShowAlert(false);
    setResultMsg({ title: 'SIM unmapping error', msg: `${error.message}` });
    setResultModalOpened(true);
  };

  const [createMapping] = useCreateMapping({
    onError: mappingError,
    onSuccess: () => {
      setShowAlert(true);
      getStatus();
    }
  });
  const [deleteMapping] = useDeleteMapping({
    onError: unmappingError,
    onSuccess: () => {
      setShowAlert(true);
      getStatus();
    }
  });

  const insertSim = (sim: any) => {
    if (device && probe) {
      createMapping({ probeName: probe?.probe_name, iccid: sim.iccid });
      setAlertType('mappingInfo');
      setShowAlert(true);
      setShowTooltip(false);
    }
  };

  const removeSim = () => {
    if (device && probe) {
      deleteMapping({ probeName: probe.probe_name });
      setAlertType('unmappingInfo');
      setShowAlert(true);
      setShowTooltip(false);
      setMapping(null);
    }
  };

  useEffect(() => {
    if (mappings && device && probe) {
      setMapping(mappings.find(
        (value: SimMapping) => value.channel.path.includes(probe.probe_name)
      ) || null);
    }
  }, [mappings, device]);

  useEffect(() => {
    if (mappings && probe) {
      setMapping(mappings.find(
        (value: SimMapping) => value.channel.path.includes(probe.probe_name)
      ) || null);
    }
  }, []);

  useEffect(() => {
    if (mapping && sims) {
      if (mapping.mapped) {
        setMappedSim(sims.find(
          (value: Sim) => value.iccid === mapping.mapped?.iccid
        ));
      } else setMappedSim(null);
    }
    if (!mapping) {
      setMappedSim(null);
    }
  }, [mapping]);

  const renderSimInfoItems = (name:string, item:any) => {
    if (item && item !== '') {
      return (
        <>
          {name}: {item}
          <br />
        </>
      );
    }
    return (null);
  };

  return (
    <div>
      <Alert
        show={showAlert}
        dismissible
        onClose={() => setShowAlert(false)}
        variant={alertMessages.filter((msg:any) => msg.name === alertType)[0]?.variant}
      >
        <p className="bu-my-0">
          {alertMessages.filter((msg:any) => msg.name === alertType)[0]?.message}
        </p>
      </Alert>
      <Container className="border rounded bu-mb-1 bu-py-2">
        <Row className="bu-mb-1">
          <Col md="5">
            {mappedSim
            && (
              <Button
                style={{ background: 'transparent', border: 0 }}
                className="bu-p-0"
                onClick={() => setShowTooltip(!showTooltip)}
              >
                <img src="/img/icons/information_graphical.svg" alt="info" style={{ width: 32, height: 32 }} />
              </Button>
            )}
            { showTooltip && mappedSim
              ? (
                <div
                  role="tooltip"
                  x-placement="right"
                  className="fade show popover bs-popover-right"
                  id="popover-basic"
                  style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(60px, -20px)' }}
                  data-popper-reference-hidden="false"
                  data-popper-escaped="false"
                  data-popper-placement="right"
                >
                  <div
                    className="arrow"
                    style={{ margin: '0px', position: 'absolute', top: '0px', transform: 'translate(0px, 29px)' }}
                  />
                  <h3 className="popover-header">
                    SIM Information
                  </h3>
                  <div className="popover-body">
                    {renderSimInfoItems('ITG ID', mappedSim.itg_id)}
                    {renderSimInfoItems('Name', mappedSim.name)}
                    {renderSimInfoItems('OP WNW', mappedSim.op_wnw)}
                    {renderSimInfoItems('Origin', mappedSim.origin)}
                    {renderSimInfoItems('Position', mappedSim.position)}
                    {renderSimInfoItems('Prepaid', mappedSim.prepaid ? 'yes' : 'no')}
                    {renderSimInfoItems('PIN', mappedSim.secret)}
                    {renderSimInfoItems('PSP', mappedSim.psp)}
                    {renderSimInfoItems('SIM Type', mappedSim.sim_type)}
                    {renderSimInfoItems('Type', mappedSim.type)}
                    {renderSimInfoItems('Wholesale ID', mappedSim.wholesale_id)}
                    {renderSimInfoItems('WNW', mappedSim.wnw)}
                  </div>
                </div>
              )
              : null}
          </Col>
          <Col style={{ textAlign: 'center' }} md="2">
            <span
              style={{ margin: 'auto' }}
            >
              <img src="/img/icons/sim-card_graphical.svg" alt="info" className={`sim-icon-${mappedSim ? 'enabled' : 'disabled'}`} />
            </span>
          </Col>
        </Row>
        <Row
          style={{ textAlign: 'center' }}
          className="bu-mb-1"
        >
          <Col>
            ICCID
            <br />
            {mappedSim ? mappedSim.iccid : '-'}
          </Col>
          <Col>
            MSISDN
            <br />
            {mappedSim ? mappedSim.msisdn : '-'}
          </Col>
          <Col>
            IMSI
            <br />
            {mappedSim ? mappedSim.imsi : '-'}
          </Col>
          <Col>
            Tarif
            <br />
            {mappedSim ? mappedSim.tariff : '-'}
          </Col>
        </Row>
        <Row>
          <Button
            style={{ margin: 'auto' }}
            onClick={() => {
              if (mapping && mapping?.mapped) {
                removeSim();
              } else {
                setInsertSimModalOpened(true);
              }
            }}
            disabled={deviceStatus === 'unavailable'}
          >
            {mapping?.mapped ? 'Remove SIM' : 'Insert SIM'}
          </Button>
        </Row>
      </Container>
      <Modal
        show={insertSimModalOpened}
        onClose={() => setInsertSimModalOpened(false)}
        title="Insert SIM Card"
      >
        <InsertSimCard
          handleInsertSim={(sim) => {
            setInsertSimModalOpened(false);
            insertSim(sim);
          }}
          simCards={simsForTable}
        />
      </Modal>
      <Modal
        show={resultModalOpened}
        onClose={() => setResultModalOpened(false)}
        title={resultMsg.title}
      >
        <div>{resultMsg.msg}</div>x
      </Modal>
    </div>
  );
};

export default DeviceSim;
