import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetClaims() {
  const axios = useAxiosWithToken();

  return useQuery('claims', () => axios.get(
    'users/api/claims',
  ).then(({ data }) => data.reverse()), { staleTime: 1000 * 60 * 5 });
}
