import { useQuery } from 'react-query';
import useAxios from 'hooks/axios/useAxios';

export default function useGetStatus(probeName: string) {
  const axios = useAxios({ baseURL: process.env.REACT_APP_IOS });

  return useQuery(['status', probeName], () => axios.get(
    `/c/${probeName}/status`
  ).then(({ data }) => data), { staleTime: 0, retry: false });
}
