import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type Schedule = {
  type: string;
  value: string | null;
  name: string;
};
type Traces = {
  osix: boolean;
  tcpdump: boolean;
  screencap: boolean;
};
export type ScheduleParallelJobData = {
  branch: string;
  testcases: string[];
  project_id: number;
  network: string;
  subscriber_tags: string[]
  schedule?: Schedule;
  traces: Traces;
};

export default function useScheduleParallelJob(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation((parallelJob : ScheduleParallelJobData) => axios.post(
    'scheduler/api/schedule/parallel_job',
    parallelJob,
  ).then(({ data }) => data), options);
}
