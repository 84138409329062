import React, { useContext, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { WsContext } from '../../context/ws';

// device browser shortcut
const Browser = ({ channel }: { channel: string }) => {
  const { sendWithCallback } = useContext(WsContext);
  const [value, setValue] = useState('');

  const navigate = (url: string) => {
    sendWithCallback('browser.open', channel, (msg) => {
      if (!msg?.includes('success')) setValue('<browser error>');
    }, `{"url":"${url}", "browser":"com.android.chrome/com.google.android.apps.chrome.IntentDispatcher"}`);
  };

  return (
    <>
      <div className="d-flex align-items-end bu-py-0_5">
        <div className="bu-mr-1">
          <div style={{ fontSize: '14px' }}>
            Open URL in browser:{' '}
          </div>
          <FormControl
            className="bu-mr-1"
            size="sm"
            value={value}
            placeholder=""
            onSubmit={() => navigate(value)}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event: any) => (event.key === 'Enter' ? navigate(value) : null)}
          />
        </div>
        <Button
          variant={!value ? 'secondary' : 'outline-primary'}
          size="sm"
          disabled={!value}
          onClick={() => navigate(value)}
        >
          Open
        </Button>
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => {
            sendWithCallback('browser.clear', channel, () => null, `${''}{"browser":"com.android.chrome/com.google.android.apps.chrome.IntentDispatcher"}`);
          }}
        >
          Reset Browser
        </Button>
      </div>
    </>
  );
};

export default Browser;
