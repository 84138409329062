import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import { Sim } from '../interfaces';

type CreateSubscriberRequestData = {
  sim: Sim;
};

// eslint-disable-next-line max-len
type CreateSubscriberMutationConfig = MutationConfig<any, any, CreateSubscriberRequestData, unknown>;

export default function useAddSubscriber(options: CreateSubscriberMutationConfig) {
  const axios = useAxiosWithToken();

  return useMutation(({ sim }: CreateSubscriberRequestData) => axios.post(
    '/sim-manager/api/subscribers',
    sim,
  ).then(({ data }) => data), options);
}
