import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import XLSX from 'xlsx';

import { Button, Form, Modal } from 'react-bootstrap';

import Table from 'components/Table';

import useAlerts from 'hooks/useAlerts';
import useUserTableSettings from 'hooks/useUserTableSettings';

import useAddSim from 'hooks/services/simManagement/sim/useAddSim';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';

const ImportSimCardsModal = ({ onHide, show }: any) => {
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();

  const userTableSettings = useUserTableSettings('import-sim-cards');

  const { refetch: getSimCards } = useGetSubscribers();

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);
  const [simCardFile, setSimCardFile] = useState<any>();
  const [simCardData, setSimCardData] = useState<any>();

  useEffect(() => {
    if (simCardFile) {
      const reader = new FileReader();
      const isReadAsBinaryString = Boolean(reader.readAsBinaryString);

      reader.onload = (event) => {
        /* Parse data */
        const { result } = event.target!;
        const workbook = XLSX.read(result, {
          type: isReadAsBinaryString ? 'binary' : 'array',
        });

        /* Get first worksheet */
        const workSheetName = workbook.SheetNames[0];
        const workSheet = workbook.Sheets[workSheetName];
        if (!workSheet) {
          setErrMessage('Failed to save SIMs!');
          setSimCardFile(undefined);
          return;
        }
        // console.log('worksheet', workSheet);

        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(workSheet, { defval: '' });
        if (!data) {
          setErrMessage('Failed to save SIMs!');
          setSimCardFile(undefined);
          return;
        }
        // const columns = makeColumns(workSheet['!ref']);
        data = _.map(data,
          (row: any) => _.reduce(row, (accumulator: any, value: any, key: string) => ({
            ...accumulator,
            [key.toLowerCase()]: value,
          }), {}));
        // console.log(columns);

        setSimCardData(data);
      };

      if (simCardFile && isReadAsBinaryString) {
        reader.readAsBinaryString(simCardFile);
      } else {
        reader.readAsArrayBuffer(simCardFile);
      }
    }
  }, [simCardFile]);

  const handleChange = (event: any) => {
    const file = _.get(event, 'target.files[0]');
    if (file) {
      setSimCardFile(file);
    }
  };

  const [addSim] = useAddSim({
    onSuccess: () => {
      setMessage('SIMs have been saved!');
      getSimCards();
      onHide();
    },
    onError: (err: any) => setErrMessage(`Failed to save SIMs: ${err}`),
  });

  const handleAddSims = () => {
    console.log('data to be saved: ', simCardData);
    simCardData.forEach((sim: any) => {
      Object.keys(sim).forEach((property: any) => {
        // eslint-disable-next-line no-param-reassign
        sim[property] = String(sim[property]);
      });
    });
    simCardData.map((sim: any) => addSim({ sim }));
  };

  const tableColumns = useMemo(
    () => [{
      accessor: 'iccid',
      Header: 'ICCID',
      disableFilters: true,
    }, {
      accessor: 'msisdn',
      Header: 'MSISDN',
      disableFilters: true,
    }, {
      accessor: 'imsi',
      Header: 'IMSI',
      disableFilters: true,
    }, {
      accessor: 'tariff',
      Header: 'TARIFF',
      disableFilters: true,
    }, {
      accessor: 'wnw',
      Header: 'WNW',
      disableFilters: true,
    }, {
      accessor: 'op_wnw',
      Header: 'OP WNW',
      disableFilters: true,
    }, {
      accessor: 'psp',
      Header: 'PSP',
      disableFilters: true,
    }, {
      accessor: 'wholesale_id',
      Header: 'WHOLESALE_ID',
      disableFilters: true,
    }, {
      accessor: 'itg_id',
      Header: 'ITG ID',
      disableFilters: true,
    }, {
      accessor: 'secret',
      Header: 'SECRET',
      disableFilters: true,
    }, {
      accessor: 'name',
      Header: 'NAME',
      disableFilters: true,
    }, {
      accessor: 'origin',
      Header: 'ORIGIN',
      disableFilters: true,
    }, {
      accessor: 'prepaid',
      Header: 'PREPAID',
      disableFilters: true,
    }, {
      accessor: 'type',
      Header: 'TYPE',
      disableFilters: true,
    }, {
      accessor: 'sim_type',
      Header: 'SIM_TYPE',
      disableFilters: true,
    }],
    [],
  );

  useEffect(() => {
    console.log(simCardData);
  }, [simCardData]);

  return (
    <Modal size={simCardData ? 'lg' : undefined} show={show} onHide={onHide} backdrop="static">
      <Modal.Header>
        <Modal.Title>Import SIM Cards</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>SIM cards import file</Form.Label>
            <Form.File
              custom
              aria-label={simCardFile?.name ?? 'Please select file'}
              label={simCardFile?.name ?? 'Please select file'}
              onChange={handleChange}
            />
            <Form.Text>
              SIM Manager accepts SIM card data to be uploaded only in a
              specific form. Therefore you can download example .xlsx file.
            </Form.Text>
          </Form.Group>
        </Form>
        {tableColumns && simCardData && (
          <Table
            columns={tableColumns}
            data={simCardData}
            disableActions
            initialState={userTableSettings?.initialState}
            onStateChange={userTableSettings.updateTableSettings}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleAddSims}>
          Import
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportSimCardsModal;
