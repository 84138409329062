import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type Claims = {
  sims: string[]
  boards: string[]
  pools: string[]
  probes: string[]
};

type UpdateClaimsRequestData = {
  groupId: number;
  claims: Claims
};

export default function useUpdateClaims(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ groupId, claims }: UpdateClaimsRequestData) => axios.put(
    `/users/api/claims/${groupId}`,
    claims,
  ).then(({ data }) => data), options);
}
