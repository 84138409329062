import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type UpdatePoolRequestData = {
  pool: string;
  updatedPool: any;
};

export default function useUpdatePool(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ pool, updatedPool }: UpdatePoolRequestData) => axios.patch(
    `/sim-manager/api/pools/${pool}`,
    updatedPool,
  ).then(({ data }) => data), options);
}
