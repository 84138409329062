import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import './toggle.scss';

// todo: how to set default values for optional prop
/* eslint-disable react/require-default-props */
type ToggleProps = {
  active?: boolean;
  disabled?: boolean;
  disableFocusOutline?: boolean;
  id: string;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  onChange?: (value: boolean) => void
};

const Toggle = ({
  active: activeProp, disabled, disableFocusOutline, id, label, onChange, style, className,
}: ToggleProps) => {
  const [active, setActive] = useState(activeProp);

  const handleChange = (value: boolean) => {
    setActive(value);
    if (_.isFunction(onChange)) {
      onChange(value);
    }
  };

  useEffect(() => {
    setActive(activeProp);
  }, [activeProp]);

  return (
    <div className={`toggle-button ${disabled ? 'is-disabled' : ''}  ${className}`} style={style}>
      <button
        disabled={disabled}
        type="button"
        role="switch"
        aria-checked={active ? 'true' : 'false'}
        id={id}
        className={`toggle-button-switch ${active ? 'active' : 'inactive'} ${disableFocusOutline ? 'toggle-button-switch_witchout_focus' : ''}`}
        onClick={() => handleChange(!active)}
      >
        <span className={`toggle-button-circle ${active ? 'active' : ''}`}>
          <span className="sr-only">{active ? 'ON' : 'OFF'}</span>
        </span>
      </button>
      {label && (
        <label htmlFor={id} className="toggle-button-label">
          {label}
        </label>
      )}
    </div>
  );
};

export default Toggle;
