import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useReloadArrays(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(() => axios.post(
    '/sim-manager/api/refresh',
  ).then(({ data }) => data), options);
}
