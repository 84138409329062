import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { useApiPolling, SimMapping } from 'hooks/apiPolling/apiPollingContext';
import { Probe } from 'hooks/services/probe/interfaces';
import useCreateMapping from 'hooks/services/simManagement/mapping/useCreateMapping';
import useDeleteMapping from 'hooks/services/simManagement/mapping/useDeleteMapping';
import { Sim } from 'hooks/services/simManagement/interfaces';

import ModalPortal from '../../../ModalPortal';
import { WindowsManagerContext } from '../../context/windowsManager';
import { Device } from '../../context/interface';
import CountDown from '../../../CountDown';

import Attenuation from './Attenuation';
import InsertSimCard from './InsertSimCard';

import './style.scss';

type ResultMsg = {
  title: string;
  msg: string;
};

const DeviceSim = ({
  deadline,
  device,
  onTimeOut,
  winId,
  probe,
  setAlertType,
  setShowAlert,
} : {
  deadline: number | null,
  device: Device | undefined,
  onTimeOut?: () => any,
  winId: string,
  probe: Probe | null,
  setAlertType: (type: string) => any,
  setShowAlert: (show: boolean) => any,
}) => {
  const [mapping, setMapping] = useState<SimMapping | null>(null);

  const [simsForTable, setSimsForTable] = useState<Sim[]>([]);
  const [insertSimModalOpened, setInsertSimModalOpened] = useState(false);

  const [resultModalOpened, setResultModalOpened] = useState(false);
  const [resultMsg, setResultMsg] = useState<ResultMsg>({ title: '', msg: '' });

  const [mappedSim, setMappedSim] = useState<Sim | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const {
    mappings,
    sims,
  } = useApiPolling();

  const {
    getDoc,
  } = useContext(WindowsManagerContext);

  useEffect(() => {
    setSimsForTable(sims);
  }, [sims]);

  const mappingError = (error: any) => {
    setAlertType('');
    setShowAlert(false);
    setResultMsg({ title: 'SIM mapping error', msg: `${error.message}` });
    setInsertSimModalOpened(true);
    // on SIM insertion error show inserting window again
    setResultModalOpened(true);
  };
  const unmappingError = (error: any) => {
    setAlertType('');
    setShowAlert(false);
    setResultMsg({ title: 'SIM unmapping error', msg: `${error.message}` });
    setResultModalOpened(true);
  };

  const [createMapping] = useCreateMapping({
    onError: mappingError,
    onSuccess: () => {
      setAlertType('mappingSuccessful');
      setShowAlert(true);
    }
  });
  const [deleteMapping] = useDeleteMapping({
    onError: unmappingError,
    onSuccess: () => {
      setAlertType('unmappingSuccessful');
      setShowAlert(true);
    }
  });

  const insertSim = (sim: any) => {
    if (device && probe) {
      createMapping({ probeName: probe?.probe_name, iccid: sim.iccid });
      setAlertType('mappingInfo');
      setShowAlert(true);
      setShowTooltip(false);
    }
  };

  const removeSim = () => {
    if (device && probe) {
      deleteMapping({ probeName: probe.probe_name });
      setAlertType('unmappingInfo');
      setShowAlert(true);
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    if (mappings && device && probe) {
      setMapping(mappings.find(
        (value: SimMapping) => value.channel.path.includes(probe.probe_name)
      ) || null);
    }
  }, [mappings, device]);

  useEffect(() => {
    if (mapping && sims) {
      if (mapping.mapped) {
        setMappedSim(sims.find(
          (value: Sim) => value.iccid === mapping.mapped?.iccid
        ));
      } else setMappedSim(null);
    }
    if (!mapping) {
      setMappedSim(null);
    }
  }, [mapping]);

  const fixedSubscriber = _.chain(probe?.devices?.[0]?.subscribers)
    .values()
    .first()
    .value() ?? false;

  useEffect(() => {
    if (fixedSubscriber) {
      setAlertType('fixedSim');
      setShowAlert(true);
      setShowTooltip(false);
    }
  }, [fixedSubscriber]);

  // need hostname to add images to portal window
  let imgSrc = '';
  switch (window.location.hostname) {
    case 'tessa.its-telekom.eu':
      imgSrc = `https://${window.location.hostname}`;
      break;
    case 'tessa.test':
      imgSrc = `http://${window.location.hostname}`;
      break;
    case 'localhost':
      imgSrc = `http://${window.location.hostname}:5005`;
      break;
    default:
      break;
  }

  const renderSimInfoItems = (name:string, item:any) => {
    if (item && item !== '') {
      return (
        <>
          {name}: {item}
          <br />
        </>
      );
    }
    return (null);
  };

  return (
    <>
      <Row className="bu-py-0_5 border-bottom">
        <Col>
          <span style={{ fontWeight: 'bold' }}>
            Reservation Time Left:{' '}
          </span>
          <span>
            <CountDown deadline={deadline} onTimeOut={onTimeOut} formating />
          </span>
        </Col>
      </Row>
      <Row className="border-bottom">
        <Col md={7} className="bu-py-0_5">
          <Row>
            <Col md={3}>
              <span className="font-weight-bold">
                Location:{' '}
              </span>
            </Col>
            <Col>
              {probe?.location}
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <span className="font-weight-bold">
                Probe:{' '}
              </span>
            </Col>
            <Col>
              {probe?.probe_alias}
            </Col>
          </Row>
          <Row className="bu-mb-1">
            <Col md={3}>
              <span className="font-weight-bold">
                Device:{' '}
              </span>
            </Col>
            <Col>
              {(probe?.devices !== undefined && probe?.devices[0].name) ? probe?.devices[0].name : 'Unknown' }
            </Col>
          </Row>
          <Row className="bu-mb-1">
            <Col md={3}>
              <img alt="img" src={`${imgSrc}/img/devices/s7.png`} style={{ height: '100px', width: '100px', marginLeft: '-26px', marginTop: '13px' }} />
            </Col>
            <Col>
              <div className="d-flex">
                <div>
                  <div className="d-flex">
                    <div>
                      <div
                        className="bu-mr-1"
                        style={{ margin: 'auto', position: 'relative' }}
                      >
                        <img
                          alt="info"
                          className={`sim-icon-${(mappedSim || fixedSubscriber) ? 'enabled' : 'disabled'}`}
                          src={`${imgSrc}/img/icons/sim-card_graphical.svg`}
                          style={{ height: '48px', width: '48px', marginTop: '-6px', marginLeft: '-2px', transform: 'rotateZ(90deg)' }}
                        />
                        {(mappedSim || fixedSubscriber) && (
                          <button
                            style={{ position: 'absolute', right: '-20px', top: '-10px' }}
                            type="button"
                            className="btn icon-button"
                            onClick={() => setShowTooltip(!showTooltip)}
                          >
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                            />
                          </button>
                        )}
                        {showTooltip && (mappedSim || fixedSubscriber) && (
                        <div
                          role="tooltip"
                          x-placement="right"
                          className="fade show popover bs-popover-right"
                          id="popover-basic"
                          style={{
                            position: 'absolute',
                            inset: '0px auto auto 0px',
                            margin: '0px',
                            transform: 'translate(67px, -35px)',
                            display: 'flex',
                            flexDirection: 'column',
                            width: 'max-content'
                          }}
                          data-popper-reference-hidden="false"
                          data-popper-escaped="false"
                          data-popper-placement="right"
                        >
                          <div
                            className="arrow"
                            style={{ margin: '0px', position: 'absolute', top: '0px', transform: 'translate(0px, 29px)' }}
                          />
                          <h3 className="popover-header">
                            SIM Information
                          </h3>
                          {mappedSim && (
                            <div className="popover-body">
                              {renderSimInfoItems('ITG ID', mappedSim.itg_id)}
                              {renderSimInfoItems('Name', mappedSim.name)}
                              {renderSimInfoItems('OP WNW', mappedSim.op_wnw)}
                              {renderSimInfoItems('Origin', mappedSim.origin)}
                              {renderSimInfoItems('Position', mappedSim.position)}
                              {renderSimInfoItems('Prepaid', mappedSim.prepaid ? 'yes' : 'no')}
                              {renderSimInfoItems('PIN', mappedSim.secret)}
                              {renderSimInfoItems('PSP', mappedSim.psp)}
                              {renderSimInfoItems('SIM Type', mappedSim.sim_type)}
                              {renderSimInfoItems('Type', mappedSim.type)}
                              {renderSimInfoItems('Wholesale ID', mappedSim.wholesale_id)}
                              {renderSimInfoItems('WNW', mappedSim.wnw)}
                              {renderSimInfoItems('IMSI', mappedSim.imsi)}
                            </div>
                          )}
                          {fixedSubscriber && (
                            <div className="popover-body">
                              {renderSimInfoItems('ITG ID', fixedSubscriber.itg_id)}
                              {renderSimInfoItems('Name', fixedSubscriber.name)}
                              {renderSimInfoItems('OP WNW', fixedSubscriber.op_wnw)}
                              {renderSimInfoItems('Origin', fixedSubscriber.origin)}
                              {renderSimInfoItems('Position', fixedSubscriber.position)}
                              {renderSimInfoItems('Prepaid', fixedSubscriber.prepaid ? 'yes' : 'no')}
                              {renderSimInfoItems('PIN', fixedSubscriber.secret)}
                              {renderSimInfoItems('PSP', fixedSubscriber.psp)}
                              {renderSimInfoItems('SIM Type', fixedSubscriber.sim_type)}
                              {renderSimInfoItems('Type', fixedSubscriber.type)}
                              {renderSimInfoItems('Wholesale ID', fixedSubscriber.wholesale_id)}
                              {renderSimInfoItems('WNW', fixedSubscriber.wnw)}
                              {renderSimInfoItems('IMSI', fixedSubscriber.imsi)}
                            </div>
                          )}
                        </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <Button
                        disabled={fixedSubscriber}
                        onClick={() => {
                          if (mapping && mapping?.mapped) {
                            removeSim();
                          } else {
                            setInsertSimModalOpened(true);
                          }
                        }}
                        size="sm"
                      >
                        {mapping?.mapped ? 'Remove SIM' : 'Insert SIM'}
                      </Button>
                    </div>
                  </div>
                  <div>
                    {fixedSubscriber ? (
                      <ul className="list-unstyled">
                        <li>
                          <span style={{ fontWeight: '500' }}>
                            MSISDN:{' '}
                          </span>
                          {fixedSubscriber.msisdn}
                        </li>
                        <li>
                          <span style={{ fontWeight: '500' }}>
                            ICCID:{' '}
                          </span>
                          {fixedSubscriber.iccid}
                        </li>
                        <li>
                          <span style={{ fontWeight: '500' }}>
                            IMSI:{' '}
                          </span>
                          {fixedSubscriber.imsi}
                        </li>
                      </ul>
                    ) : (
                      <ul className="list-unstyled">
                        <li>
                          <span style={{ fontWeight: '500' }}>
                            MSISDN:{' '}
                          </span>
                          {mappedSim ? mappedSim.msisdn : 'N/A'}
                        </li>
                        <li>
                          <span style={{ fontWeight: '500' }}>
                            ICCID:{' '}
                          </span>
                          {mappedSim ? mappedSim.iccid : 'N/A'}
                        </li>
                        <li>
                          <span style={{ fontWeight: '500' }}>
                            IMSI:{' '}
                          </span>
                          {mappedSim ? mappedSim.imsi : 'N/A'}
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="bu-py-0_5 border-left">
          <Attenuation probe={probe} />
        </Col>
      </Row>
      <ModalPortal
        htmlDoc={getDoc(winId)}
        show={insertSimModalOpened}
        onClose={() => setInsertSimModalOpened(false)}
        title="Insert SIM Card"
        htmlBackgroundElement={getDoc(winId)?.getElementById('portal')}
      >
        <InsertSimCard
          handleInsertSim={(sim) => {
            setInsertSimModalOpened(false);
            insertSim(sim);
          }}
          simCards={simsForTable}
        />
      </ModalPortal>
      <ModalPortal
        htmlDoc={getDoc(winId)}
        show={resultModalOpened}
        onClose={() => setResultModalOpened(false)}
        title={resultMsg.title}
        htmlBackgroundElement={getDoc(winId)?.getElementById('portal')}
      >
        <div>{resultMsg.msg}</div>
      </ModalPortal>
    </>
  );
};

export default DeviceSim;
