import _ from 'lodash';
import React from 'react';

import clsx from 'clsx';
import { Button } from 'react-bootstrap';

const TableRowActions = ({ actions, row }: any) => {
  if (!_.isArray(actions)) {
    return null;
  }
  return (
    <div className="text-nowrap text-right">
      {actions.map(
        ({
          color, content, key, onClick, render, variant, ...actionProps
        }, index) => (
          _.isFunction(render) ? render(row?.original) : (
            <Button
              variant={variant || 'secondary'}
              size="sm"
              className={clsx({ 'bu-ml-1': index > 0, [`text-${color}`]: color })}
              key={key}
              onClick={onClick ? (event) => onClick(row?.original, event) : undefined}
              type="button"
              {...actionProps}
            >
              {content}
            </Button>
          )
        ),
      )}
    </div>
  );
};

export default TableRowActions;
