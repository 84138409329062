import React from 'react';
import {
  Modal, Card,
} from 'react-bootstrap';

interface IModalProps {
  show: boolean
  onHide: () => void;
  campaignName: string;
  scheduledJobs: any;
  scheduledJobsKeyArray: any;
  schedulingId: any;
}

const TestTagModal = ({
  onHide, show, campaignName, scheduledJobs, scheduledJobsKeyArray, schedulingId
}: IModalProps,) => {
  console.log(`scheduledJobs ${JSON.stringify(scheduledJobs)}`);
  console.log(`scheduledJobsKeyArray ${JSON.stringify(scheduledJobsKeyArray)}`);
  console.log(`schedulingId ${JSON.stringify(schedulingId)}`);

  const scheduledJobsKeyArrayFiltered = scheduledJobsKeyArray ? (scheduledJobsKeyArray
    .filter((key: any) => key === schedulingId)) : null;

  console.log(`scheduledJobsKeyArrayFiltered ${JSON.stringify(scheduledJobsKeyArrayFiltered)}`);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="xl"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header>
                Testcase list of campaign {campaignName}
                <button type="button" className="close" onClick={onHide} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Card.Header>
              <table className="table">
                <tbody>
                  {scheduledJobs[scheduledJobsKeyArrayFiltered][1]
                    .args.cucumber_args
                    && (
                      <tr>
                        <td>
                          {scheduledJobs[scheduledJobsKeyArrayFiltered][1]
                            .args.cucumber_args}
                        </td>
                      </tr>
                    )}
                  {scheduledJobs[scheduledJobsKeyArrayFiltered][1]
                    .args.testcases
                    && (
                      scheduledJobs[scheduledJobsKeyArrayFiltered][1]
                        .args.testcases.map((tag:any) => (
                          <tr>
                            <td>
                              {tag}
                            </td>
                          </tr>
                        ))
                    )}
                </tbody>
              </table>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default TestTagModal;
