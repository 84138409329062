import { onlyUnique } from 'MiniTessa/helpers';
import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Row, Col } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const SimMapping = ({
  probes,
  subscribers,
  selectedProbes,
  simMapping,
  setSimMapping,
  duration,
  setDuration,
  previousStep,
  nextStep
}: any) => {
  const [formError, setFormError] = useState('');
  const [nextDisabled, setNextDisabled] = useState(true);

  useEffect(() => {
    if ((simMapping.length === probes.filter(
      (probe:any) => selectedProbes.includes(probe.probe_name)
      && (probe.devices[0]?.type === 'Android' || probe.devices[0]?.type === 'iOS')
      && probe.devices[0].multiplexers.length !== 0
    ).length) && simMapping.map((mapping:any) => mapping.sim)
      .filter(onlyUnique).length === simMapping.length) {
      setNextDisabled(false);
    }
    if (simMapping.map((mapping:any) => mapping.sim)
      .filter(onlyUnique).length < simMapping.length) {
      setFormError('Please select unique SIM cards!');
      setNextDisabled(true);
    } else {
      setFormError('');
    }
  }, [simMapping]);

  const updateSimMappings = (probe:any, sim:any) => {
    if (simMapping.filter((mapping:any) => mapping.probe === probe)) {
      if (sim) {
        setSimMapping([
          ...simMapping.filter((mapping:any) => mapping.probe !== probe),
          {
            probe,
            sim
          }
        ]);
      } else {
        setSimMapping(simMapping.filter((mapping:any) => mapping.probe !== probe));
      }
    } else {
      setSimMapping([
        ...simMapping,
        {
          probe,
          sim
        }
      ]);
    }
  };

  const validateForm = (e:any) => {
    e.preventDefault();
    if (simMapping.length !== probes.filter(
      (probe:any) => selectedProbes.includes(probe.probe_name)
      && (probe.devices[0]?.type === 'Android' || probe.devices[0]?.type === 'iOS')
      && probe.devices[0]?.multiplexers.length !== 0
    ).length) {
      setFormError('Select SIM cards for all devices.');
    } else if (simMapping.map((mapping:any) => mapping.sim)
      .filter(onlyUnique).length < simMapping.length) {
      setFormError('Please select unique SIM cards!');
    } else {
      setFormError('');
      nextStep();
    }
  };

  return (
    <Form onSubmit={validateForm}>
      <h2>
        Select SIMs and Duration
      </h2>
      <Container className="pt-4">
        {probes.filter((probe:any) => (
          selectedProbes.includes(probe.probe_name) && (probe.devices[0]?.type === 'Android' || probe.devices[0]?.type === 'iOS')
        )).sort((a: any, b: any) => ((a.name > b.name) ? -1 : 1))
          .map((selectedProbe:any) => (
            <Row key={selectedProbe.probe_alias} style={{ marginBottom: '1rem', textAlign: 'center' }}>
              <Col md={1}>
                <p className="mb-3">{selectedProbe?.devices[0].type}</p>
              </Col>
              <Col md={2}>
                <p className="mb-3">{selectedProbe.location}</p>
              </Col>
              <Col md={2}>
                <p className="mb-3">{selectedProbe.probe_alias}</p>
              </Col>
              <Col md={7}>
                {selectedProbe?.devices[0].multiplexers.length !== 0
                  ? (
                    <Typeahead
                      id={selectedProbe.probe_alias}
                      placeholder="Select SIM card"
                      onChange={(e:any) => {
                        updateSimMappings(selectedProbe.probe_alias, e[0]);
                      }}
                      highlightOnlyResult
                      defaultSelected={[simMapping.filter(
                        (mapping:any) => mapping.probe === selectedProbe.probe_alias
                      )[0]?.sim || '']}
                      // flip
                      options={subscribers.filter((sub:any) => sub.type === 'mappable' && sub.position !== null)
                        .map((sim:any) => `${sim.iccid} - ${sim.name}`)}
                    />
                  )
                  : (
                    <p className="mb-3">
                      {
                        (selectedProbe?.devices[0]?.subscribers && selectedProbe?.devices[0]?.subscribers[Object.keys(selectedProbe.devices[0].subscribers)[0]].msisdn) || 'no multiplexer'
                      }
                    </p>
                  )}
              </Col>
            </Row>
          ))}
        <div style={{ color: 'red' }}>{formError}</div>
      </Container>
      <Container className="pt-4">
        {probes.filter((probe:any) => (
          selectedProbes.includes(probe.probe_name) && (probe.devices[0]?.type === 'IP_Phone' || probe.devices[0]?.type === 'Analogue_Modem')
        ))
          .map((selectedProbe:any) => (
            <Row key={selectedProbe.probe_alias} style={{ marginBottom: '1rem', textAlign: 'center' }}>
              <Col md={1}>
                <p className="mb-3">{selectedProbe?.devices[0].type}</p>
              </Col>
              <Col md={2}>
                <p className="mb-3">{selectedProbe.location}</p>
              </Col>
              <Col md={2}>
                <p className="mb-3">{selectedProbe.probe_alias}</p>
              </Col>
            </Row>
          ))}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Reservation duration in minutes</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter duration"
            min={1}
            value={duration}
            onChange={(e:any) => {
              if (!e.target.validity.badInput) {
                setDuration(Number(e.target.value));
              }
            }}
          />
        </Form.Group>
      </Container>
      <Row>
        <Col />
        <Col md={2}>
          <Button
            id="previousStep"
            onClick={previousStep}
            variant="primary"
            className="btn-block mr-1 mt-1 btn-lg"
          >
            Back
          </Button>
        </Col>
        <Col md={2}>
          <Button
            id="nextStep"
            variant="primary"
            disabled={nextDisabled}
            className="btn-block mr-1 mt-1 btn-lg"
            type="submit"
          >
            Next
          </Button>
        </Col>
        <Col />
      </Row>
    </Form>
  );
};

export default SimMapping;
