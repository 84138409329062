import React, { useContext, useState } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { WsContext } from '../../context/ws';

// two way clipboard sharing
const DeviceClipboard = ({ channel }: { channel: string }) => {
  const { sendWithCallback } = useContext(WsContext);
  const [value, setValue] = useState('');
  return (
    <>
      <div className="d-flex align-items-end bu-py-0_5">
        <div className="bu-mr-1">
          <div style={{ fontSize: '14px' }}>
            Send text to device:{' '}
          </div>
          <FormControl
            className="bu-mr-1"
            size="sm"
            value={value}
            onChange={(event: any) => setValue(event.target.value)}
            onKeyDown={
          (event: any) => (event.key === 'Enter'
            ? sendWithCallback('clipboard.paste', channel, () => {
            }, `{"text":"${value}"}`)
            : null)
        }
          />
        </div>
        <Button
          variant={!value ? 'secondary' : 'outline-primary'}
          size="sm"
          disabled={!value}
          onClick={() => {
            sendWithCallback('clipboard.paste', channel, () => {
            }, `{"text":"${value}"}`);
          }}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default DeviceClipboard;
