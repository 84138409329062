import axios, { AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';

// set default base URL, can be overwritten by options variable
const baseURL = process.env.REACT_APP_BE_URL;

const useAxios = (options?: AxiosRequestConfig) => {
  const instance = useMemo(
    () => axios.create({
      baseURL,
      ...options,
    }),
    [options, baseURL],
  );

  instance.interceptors.response.use((response) => response, (error) => {
    if (error?.response?.data?.message) {
      return Promise.reject(new Error(error?.response?.data?.message));
    }
    if (error?.response?.data?.error) {
      console.log(error?.response?.data?.error);
      return Promise.reject(new Error(error?.response?.data?.error));
    }

    if (error?.response?.data) {
      return Promise.reject(new Error(error?.response?.data));
    }

    return Promise.reject(error);
  });

  return instance;
};

export default useAxios;
