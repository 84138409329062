import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Card, Form, FormControl, FormGroup,
} from 'react-bootstrap';
import useForm from '../../../../hooks/useForm';
import useEditProbeDevice from '../../../../hooks/services/probe/useEditProbeDevice';

interface IModalProps {
  onHide: () => void;
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  probeName: string;
  deviceData: any;
  getProbes: any;
}

type FormErrors = {
  name?: string;
  serial?: string,
  type?: string,
  adboe?: boolean,
  version?: string,
  status?: string,
  exclusive?: string,
  rooted?: string,
  IP?: string,
};

const EditDeviceTable = ({
  onHide, setErrMessage, setMessage, probeName, deviceData, getProbes,
}: IModalProps) => {
  const [showError, setShowError] = useState(true);

  const [editProbeDevice, { error }] = useEditProbeDevice({
    onSuccess: () => {
      setMessage('Device has been updated!');
      getProbes();
      onHide();
    },
    onError: () => setErrMessage('Failed to update device'),
  });

  const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      device_id: deviceData.device_id,
      name: deviceData.name,
      serial: deviceData.serial,
      type: deviceData.type,
      adboe: deviceData.adboe,
      version: deviceData.version,
      // status: deviceData.status,
      IP: deviceData.IP,
      exclusive: deviceData.exclusive,
      rooted: deviceData.rooted,
    },
    onSubmit: () => {
      editProbeDevice({
        probeName,
        device: {
          device_id: deviceData.device_id.toString(),
          name: formValues.name,
          serial: formValues.serial,
          type: formValues.type,
          adboe: formValues.adboe,
          version: formValues.version,
          // status: formValues.status,
          IP: formValues.IP,
          exclusive: formValues.exclusive,
          rooted: formValues.rooted,
        },
      });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.name || values?.name === 0) {
        errors.name = 'Please fill in device name.';
      }
      if (!values?.serial || values?.serial === '') {
        errors.serial = 'Please fill in serial.';
      }
      if (!values?.type || values?.type === 0) {
        errors.type = 'Please fill in type.';
      }
      if (!values?.version || values?.version === '') {
        errors.version = 'Please fill in version.';
      }
      if ((values?.type === 'IP_Phone' || values?.type === 'Analogue_Modem') && (!values?.IP || values?.IP === '' || !ipv4Regex.test(values?.IP))) {
        errors.IP = 'Please fill in IP.';
      }
      return errors;
    },
  });

  // show error if it appears
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <>
      <div>
        <Card className="form-input">
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              {error?.message && showError && (
              <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                {error?.message}
              </Alert>
              )}
              <Form.Row>
                <FormGroup key="probe" className="ml-2">
                  <Form.Label htmlFor="probe">Probe</Form.Label>
                  <FormControl
                    autoFocus
                    id="probe"
                    name="probe"
                    type="text"
                    value={probeName}
                    disabled
                  />
                </FormGroup>
                <FormGroup key="name" className="ml-2">
                  <Form.Label htmlFor="name">Device name</Form.Label>
                  <FormControl
                    autoFocus
                    id="name"
                    isInvalid={submitted && Boolean(formErrors?.name)}
                    name="name"
                    disabled
                    type="text"
                    value={deviceData.name}
                  />
                </FormGroup>
              </Form.Row>
              <Form.Row>
                <FormGroup key="serial" className="ml-2">
                  <Form.Label htmlFor="serial">
                    Serial<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span>
                  </Form.Label>
                  <FormControl
                    id="serial"
                    isInvalid={submitted && Boolean(formErrors?.serial)}
                    name="serial"
                    onChange={handleChange('serial')}
                    required
                    type="text"
                    value={formValues?.serial}
                  />
                  {submitted && formErrors?.serial && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.serial}
                  </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup key="type" className="ml-2">
                  <Form.Label htmlFor="type">
                    Type<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={formValues.type}
                    id="type"
                    isInvalid={submitted && Boolean(formErrors?.type)}
                    name="type"
                    onChange={handleChange('type')}
                  >
                    <option value="" disabled hidden>type</option>
                    <option value="iOS">iOS</option>
                    <option value="Android">Android</option>
                    <option value="IoT">IoT</option>
                    <option value="IP_Phone">IP Phone</option>
                    <option value="LTE_Modem">LTE Modem</option>
                    <option value="Analogue_Modem">Analogue Modem</option>
                  </Form.Control>
                  {submitted && formErrors?.type && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.type}
                  </FormControl.Feedback>
                  )}
                </FormGroup>
              </Form.Row>
              {deviceData?.type === 'Android' && (
                <Form.Row>
                  <FormGroup key="adboe" className="ml-2">
                    <Form.Check
                      checked={formValues?.adboe}
                      value="adboe"
                      id="adboe"
                      key="adboe"
                      type="checkbox"
                      label="Connected via ADBoE"
                      inline
                      aria-label="item"
                      className="lg mr-0 ml-3"
                      onChange={handleChange('adboe')}
                    />
                  </FormGroup>
                </Form.Row>
              )}
              <Form.Row>
                <FormGroup key="version" className="ml-2">
                  <Form.Label htmlFor="version">
                    Version<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span>
                  </Form.Label>
                  <FormControl
                    id="version"
                    isInvalid={submitted && Boolean(formErrors?.version)}
                    name="version"
                    onChange={handleChange('version')}
                    required
                    type="text"
                    value={formValues?.version}
                  />
                  {submitted && formErrors?.version && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.version}
                  </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup key="IP" className="ml-2">
                  <Form.Label htmlFor="IP">
                    IP Address
                    {(formValues?.type === 'IP_Phone' || formValues?.type === 'Analogue_Modem')
                    && (<span style={{ fontSize: 'large', fontWeight: 'bold', color: 'red' }}>*</span>)}
                  </Form.Label>
                  <FormControl
                    id="IP"
                    isInvalid={submitted && Boolean(formErrors?.IP)}
                    name="IP"
                    onChange={handleChange('IP')}
                    type="text"
                    value={formValues?.IP}
                  />
                  {submitted && formErrors?.IP && (
                  <FormControl.Feedback type="invalid">
                    {formErrors?.IP}
                  </FormControl.Feedback>
                  )}
                </FormGroup>
              </Form.Row>
              <Form.Row>
                <FormGroup key="exclusive" className="ml-2">
                  <Form.Label htmlFor="exclusive">
                    Exclusive
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={formValues.exclusive}
                    id="exclusive"
                    name="exclusive"
                    onChange={handleChange('exclusive')}
                  >
                    <option value="" disabled hidden>status</option>
                    <option value="true">yes</option>
                    <option value="false">no</option>
                  </Form.Control>
                </FormGroup>
                <FormGroup key="rooted" className="ml-2">
                  <Form.Label htmlFor="rooted">
                    Rooted
                  </Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={formValues.rooted}
                    id="rooted"
                    name="rooted"
                    onChange={handleChange('rooted')}
                  >
                    <option value="" disabled hidden>status</option>
                    <option value="true">yes</option>
                    <option value="false">no</option>
                  </Form.Control>
                </FormGroup>
                <FormGroup key="name" className="ml-2">
                  <Form.Label htmlFor="name">Subscriber</Form.Label>
                  <FormControl
                    autoFocus
                    id="name"
                    isInvalid={submitted && Boolean(formErrors?.name)}
                    name="name"
                    disabled
                    type="text"
                    value={deviceData?.subscribers
                      ? deviceData?.subscribers[Object.keys(deviceData?.subscribers)[0]]?.msisdn
                      : ''}
                  />
                </FormGroup>
              </Form.Row>
              <div className="text-right">
                <Button
                  className="btn btn-primary"
                  type="submit"
                  disabled={(!formValues?.version || formValues?.version === '')
                      || (!formValues?.serial || formValues?.serial === '')
                      || (!formValues?.type || formValues?.type === 0)
                      || ((formValues?.type === 'IP_Phone' || formValues?.type === 'Analogue_Modem') && (!formValues?.IP || formValues?.IP === '' || !ipv4Regex.test(formValues?.IP)))}
                >Save
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default EditDeviceTable;
