import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetRepository(path:string, branch:string, id:number) {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_GIT });

  return useQuery(['repository', id, branch], () => axios.get(
    `/repository?path=${path}&branch=${branch}&id=${id}`,
  ).then(({ data }) => data)
    .catch((err: any) => console.log(err)), { staleTime: 1000 * 60 * 5 });
}
