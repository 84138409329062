import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { ViewContext } from '../context/view';
import { WsContext } from '../context/ws';

type Point = {
  x: number,
  y: number,
};

let seq = -1;
const cycle = 100;

// device screen with mouse and keyboard handling
const Screen = () => {
  const {
    image,
    screenInfo,
    channel,
  } = useContext(ViewContext);
  const { cmdWs } = useContext(WsContext);
  const screenEl = useRef<HTMLImageElement>(null);
  const [swiping, setSwiping] = useState(false);
  const [throttleTime, setThrottleTime] = useState(0);

  // ws message sequence number generator
  const nextSeq = (): number => {
    seq += 1;
    if (seq >= cycle) seq = 0;
    return seq;
  };

  // translate screen point browser -> device
  const getRealPoint = (p: Point): Point => {
    let refPoint: Point = { x: 0, y: 0 };
    let swap = false;
    switch (screenInfo?.orientation) {
      default:
      case 0:
        break;
      case 90:
        refPoint = { x: 0, y: 1 };
        swap = true;
        break;
      case 180:
        refPoint = { x: 1, y: 1 };
        break;
      case 270:
        refPoint = { x: 1, y: 0 };
        swap = true;
        break;
    }
    return (
      swap
        ? {
          y: Math.abs(refPoint.x - (p.x / screenInfo!.virtualHeight)),
          x: Math.abs(refPoint.y - (p.y / screenInfo!.virtualWidth)),
        } : {
          x: Math.abs(refPoint.x - (p.x / screenInfo!.virtualWidth)),
          y: Math.abs(refPoint.y - (p.y / screenInfo!.virtualHeight)),
        }
    );
  };

  // send msg via websocket
  const sendMsg = (msg: string, ws: WebSocket | undefined) => {
    if (ws && ws.OPEN) ws.send(msg);
    else console.log('unable to send msg to websocket');
  };

  // send msg via command websocket
  const sendCmdMsg = (msg: string) => {
    sendMsg(msg, cmdWs);
  };

  // send msg[] via command websocket
  const sendCmdArray = (msgs: string[]) => {
    msgs.forEach((item) => sendCmdMsg(item));
  };

  // handle swipe
  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement>) => {
    // send mouse move only when in swiping state
    if (swiping) {
      // send mouse move just a few times
      const time = Date.now();
      if (time - throttleTime > 40) {
        const { x, y } = getRealPoint({
          x: e.nativeEvent.offsetX,
          y: e.nativeEvent.offsetY,
        });
        const messageArray = [
          `42["input.touchMove","${channel}",{"seq":${nextSeq()},"contact":0,"x":${x},"y":${y},"pressure":0.5}]`,
          `42["input.touchCommit","${channel}",{"seq":${nextSeq()}}]`,
        ];
        sendCmdArray(messageArray);
        setThrottleTime(time);
      }
    }
  };

  // handle mouse click or start of swipe
  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
    // start click or swipe
    setSwiping(true);
    const { x, y } = getRealPoint({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY,
    });
    const messageArray = [`42["input.gestureStart","${channel}",{"seq":${nextSeq()}}]`,
      `42["input.touchDown","${channel}",{"seq":${nextSeq()},"contact":0,"x":${x},"y":${y},"pressure":0.5}]`,
      `42["input.touchCommit","${channel}",{"seq":${nextSeq()}}]`,
    ];
    sendCmdArray(messageArray);
  };

  // send termination of mouse action
  const endMouseAction = () => {
    if (swiping) setSwiping(false);
    const messageArray = [
      `42["input.touchUp","${channel}",{"seq":${nextSeq()},"contact":0}]`,
      `42["input.touchCommit","${channel}",{"seq":${nextSeq()}}]`,
      `42["input.gestureStop","${channel}",{"seq":${nextSeq()}}]`,
    ];
    sendCmdArray(messageArray);
  };

  // end click or swipe when mouse up
  const handleMouseUp = () => {
    endMouseAction();
  };

  // stop swiping/clicking, when mouse leaves img borders
  const handleMouseOut = () => {
    if (swiping) endMouseAction();
  };

  // send keyboard character
  const sendChar = (key: string) => {
    let esapedKey = key;
    // escape special keys
    if (key === '\\') esapedKey = '\\\\';
    if (key === '"') esapedKey = '\\"';
    sendCmdMsg(`42["input.type","${channel}",{"text":"${esapedKey}"}]`);
  };

  // send keyboard key
  const sendKey = (key: string) => {
    sendCmdArray([
      `42["input.keyDown","${channel}",{"key":"${key}"}]`,
      `42["input.keyUp","${channel}",{"key":"${key}"}]`,
    ]);
  };

  const handleKeyDown = (key: string, isShift: boolean, capsLock: boolean) => {
    switch (key) {
      case 'Shift':
      case 'Alt':
      case 'Control':
      case 'Meta':
        break;
      case 'Dead':
        // todo: try another keyboard layouts
        if (isShift) sendChar('ˇ');
        else sendChar('\'');
        break;
      case 'Escape':
        sendKey('escape');
        break;
      case 'Enter':
        sendKey('enter');
        break;
      case 'Delete':
        sendKey('forward_del');
        break;
      case 'ArrowRight':
        sendKey('dpad_right');
        break;
      case 'ArrowLeft':
        sendKey('dpad_left');
        break;
      case 'ArrowUp':
        sendKey('dpad_up');
        break;
      case 'ArrowDown':
        sendKey('dpad_down');
        break;
      case 'Tab':
        // todo: avoid mixing tab for device and tab for window
        sendKey('tab');
        break;
      case 'CapsLock':
        sendCmdMsg(`42["input.${capsLock ? 'keyDown' : 'keyUp'}","${channel}",{"key":"caps_lock"}]`);
        break;
      case 'Backspace':
        sendKey('del');
        break;
      default:
        sendChar(key);
        break;
    }
  };

  // focus screen img on mount
  useEffect(() => {
    if (screenEl.current) screenEl.current.focus();
  }, []);

  // this message is used in original STF web UI, but it looks it is not necessary
  // useEffect(() => {
  //   if (channel) {
  //     sendWithCallback('connect.start', channel, () => {});
  //   }
  // }, [channel]);

  // calc image w/h based on phone screen and orientation
  let height = 0;
  let width = 0;
  switch (screenInfo?.orientation) {
    case 90:
    case 270:
      width = screenInfo ? screenInfo.virtualHeight : 0;
      height = screenInfo ? screenInfo.virtualWidth : 0;
      break;
    case 0:
    default:
      height = screenInfo ? screenInfo.virtualHeight : 0;
      width = screenInfo ? screenInfo.virtualWidth : 0;
      break;
  }
  if (image) {
    return (
      // todo: find better element than img, maybe overlap more elements (canvas,img, div, button)?
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <img
        ref={screenEl}
        id="screen-image"
        style={{
          height,
          width,
          opacity: '1',
          imageRendering: 'auto',
        }}
        alt="device"
        src={image}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        draggable={false}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onKeyDown={(e) => handleKeyDown(e.key, e.shiftKey, e.getModifierState('CapsLock'))}
        onKeyUp={(e) => {
          // handle turning off CapsLock key
          if (e.key === 'CapsLock') handleKeyDown(e.key, e.shiftKey, e.getModifierState('CapsLock'));
        }}
        onMouseOut={handleMouseOut}
        // todo: maybe on blur can occur even when mouse is not clicked...?
        onBlur={handleMouseOut}
      />
    );
  }
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: 692,
      minWidth: 311,
      background: 'white' }}
    >
      <Spinner animation="border" role="status" style={{ margin: '0 auto' }} />
      <span style={{ margin: '10px auto' }}>Loading ...</span>
    </div>
  );
};

export default Screen;
