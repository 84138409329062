/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import CountDown from 'components/CountDown';
import moment from 'moment';
import { Container, Col, Row, Button } from 'react-bootstrap';
import getReservationStatus from 'utils/reservations';
import useGetUsers from 'hooks/services/user/useGetUsers';
import config from 'config';
import { WindowsManagerGenericContext, WindowState } from 'components/WindowManager/WindowManager';
import StfControl from './StfControl';

interface IDeviceReservationProps {
  probe: any,
  reservation: any,
  stfDevice: any,
}

const DeviceReservation = ({
  probe,
  reservation,
  stfDevice,
} : IDeviceReservationProps): JSX.Element => {
  const { data: users } = useGetUsers();
  const resStatus = getReservationStatus(
    moment(reservation.from).format(config.ui.dateTimeFormat),
    moment(reservation.until).format(config.ui.dateTimeFormat),
    config.ui.dateTimeFormat,
  );

  const {
    openWindow, getState, killWindow, focusWindow, setWindowState
  } = useContext(WindowsManagerGenericContext);

  const [probeStatus, setProbeStatus] = useState<WindowState>('CLOSED');

  const closeProbeWindow = () => {
    setWindowState(probe?.probe_alias, 'CLOSED');
    setProbeStatus('CLOSED');
    killWindow(probe.probe_alias);
    localStorage.removeItem(probe?.probe_alias);
  };

  useEffect(() => {
    setProbeStatus(getState(probe?.probe_alias));
  }, []);

  let resStatusColor = '#6B6B6B';
  if (resStatus === 'upcoming') resStatusColor = '#1e75cb';
  else if (resStatus === 'active') resStatusColor = 'green';

  useEffect(() => {
    const timer = setInterval(() => {
      const isWindowOpen = localStorage.getItem(probe?.probe_alias);
      if (!isWindowOpen && getState(probe?.probe_alias) !== 'OPEN') {
        closeProbeWindow();
      } else {
        setProbeStatus('OPEN');
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleOpenWindow = () => {
    openWindow({
      id: probe.probe_alias,
      title: probe.probe_alias,
      url: `${window.location.origin}/manual_testing/${probe.probe_alias}`,
      state: 'CLOSED',
      size: { width: 1070, height: 900 },
      window: null
    });
    localStorage.setItem(probe?.probe_alias, 'true');
  };

  const renderDevicesSwitch = () => {
    switch (probe?.devices[0]?.type) {
      case 'Android':
        return (stfDevice
          ? <StfControl device={stfDevice} probe={probe} reservation={reservation} />
          : null);
        break;
      case 'iOS':
        if (probeStatus !== 'OPEN') {
          return (
            <Row>
              <Button onClick={handleOpenWindow}>Use device</Button>
            </Row>
          );
        }
        return (
          <Row>
            <Button variant="danger" onClick={() => closeProbeWindow()}>Release Device</Button>
            <Button onClick={() => focusWindow(probe.probe_alias)}>Focus Device</Button>
          </Row>
        );
        return null;
        break;
      default:
        return null;
        break;
    }
  };

  return (
    <Row className="border rounded-bottom bu-px-1 bu-py-1 bu-mb-1">
      <Col md={1} style={{ position: 'relative', display: 'inline-block' }}>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '10%',
          transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
          textAlign: 'center',
          color: '#6B6B6B',
          fontSize: '0.8em',
        }}
        >
          {probe.devices[0]?.type?.replace('_', ' ')}
        </div>
      </Col>
      <Col>
        <Container>
          <Row style={{ fontWeight: 'bold' }}>
            {`${probe.location} - ${probe.probe_alias}`}
          </Row>
          <Row>
            {probe.device}
          </Row>
          <Row style={{ color: probe.status === 'online' && (probe?.devices && probe?.devices[0]?.status === 'online') ? 'green' : 'red' }}>
            {probe.status === 'online' && (probe?.devices && probe?.devices[0]?.status === 'online') ? 'online' : 'offline'}
          </Row>
        </Container>
      </Col>
      <Col>
        <Row style={{ color: '#6B6B6B', fontSize: '0.8em' }}>Reservation</Row>
        <Row>
          <div
            className="mr-2"
            style={{
              color: '#6B6B6B',
              fontSize: '0.8em',
              display: 'flex',
              alignItems: 'flex-end'
            }}
          >
            From:
          </div>
          <span>{moment(reservation.from).format(config.ui.dateTimeFormat)}</span>
        </Row>
        <Row>
          <span className="mr-2" style={{ color: '#6B6B6B', fontSize: '0.8em', display: 'flex', alignItems: 'flex-end' }}>Until:</span>
          <span>{moment(reservation.until).format(config.ui.dateTimeFormat)}</span>
        </Row>
        <Row>
          <span className="mr-2" style={{ color: '#6B6B6B', fontSize: '0.8em', display: 'flex', alignItems: 'flex-end' }}>By:</span>
          <a href={`mailto:${users?.find((user:any) => user.uid === reservation.user_id)?.mail}`}> {users?.find((user:any) => user.uid === reservation.user_id)?.cn}</a>
        </Row>
        {
          resStatus === 'active'
            ? <Row className="mt-3"><CountDown deadline={+moment(reservation.until)} /></Row>
            : null
        }
      </Col>
      <Col sm={1}>
        <Row style={{ color: '#6B6B6B', fontSize: '0.8em' }}>Status</Row>
        <Row style={{ color: resStatusColor }}>
          {resStatus}
        </Row>
      </Col>
      <Col sm={2}>
        {resStatus === 'active' && renderDevicesSwitch()}
        {!stfDevice && resStatus === 'active'
          ? <Row>{(probe?.devices && probe?.devices[0]?.type === 'Android' && (probe?.devices && (probe?.devices[0]?.status === 'offline' || probe?.devices[0]?.stf_status !== 'online'))) ? 'Device disconnected' : null} </Row>
          : null }
      </Col>
    </Row>
  );
};

export default DeviceReservation;
