import React, { useMemo } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ProbeTable from 'pages/Reservation/CreateReservationModal/ProbeTable';
import { useForm } from 'react-form';

const ProbeSelection = ({
  probes,
  reservations,
  selectedProbes,
  setSelectedProbes,
  previousStep,
  nextStep
}: any) => {
  const defaultValues = useMemo(() => [
    {
      probes: selectedProbes,
    }
  ], []);

  const {
    Form,
    meta: { isValid: fieldsAreValid }
  } = useForm({
    defaultValues,
    validate: (values: any) => {
      if (values.probes && values.probes.length === 0) {
        return 'Select at least one probe.';
      }
      return false;
    },
    onSubmit: (values: any) => {
      setSelectedProbes(values.probes);
      nextStep();
    }
  });

  return (
    <Form>
      <h2>
        Select Probes
      </h2>
      <Container className="pt-4">
        {probes && reservations
        && (
        <ProbeTable
          probes={probes}
          reservations={reservations}
          selectedProbes={selectedProbes}
          semiAutomated
        />
        )}
        <div style={{ color: 'red' }}>{!fieldsAreValid ? 'Select at least one probe.' : null}</div>
        <Row>
          <Col />
          <Col md={2}>
            <Button
              id="previousStep"
              onClick={previousStep}
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
              disabled
            >
              Back
            </Button>
          </Col>
          <Col md={2}>
            <Button
              id="nextStep"
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
              type="submit"
              disabled={!fieldsAreValid}
            >
              Next
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
    </Form>
  );
};

export default ProbeSelection;
