import React from 'react';

import InputMask from 'react-input-mask';

import { Col, Form, Row } from 'react-bootstrap';

import { useField } from 'react-form';
import moment from 'moment';
import validateDate, { ShowError } from '../utils';

const EditReservationFormFields = ({ probe, defaultValues }: any) => {
  const fromField = useField('from', {
    validate: (value) => {
      if (!value) {
        return 'This field is required';
      }
      return validateDate(value.toString());
    },
  });

  const {
    onChange: handleFromFieldChange,
    onBlur: handleFromFieldBlur,
  } = fromField?.getInputProps();

  const untilField = useField('until', {
    validate: (value) => {
      if (!value) {
        return 'This field is required';
      }
      return validateDate(value.toString());
    },
  });

  const {
    onChange: handleUntilFieldChange,
    onBlur: handleUntilFieldBlur,
  } = untilField?.getInputProps();

  // todo: disable input when editing reservation which started in the past
  const disabled = moment(defaultValues.from, 'DD/MM/YYYY HH:mm').isBefore(moment());

  return (
    <>
      <Form.Group>
        <Row>
          <Col md={6}>
            <Form.Label>From</Form.Label>
            {!disabled
              ? (
                <InputMask
                  mask="99/99/9999 99:99"
                  value={fromField?.value as any}
                  onChange={handleFromFieldChange}
                  onBlur={handleFromFieldBlur}
                >
                  {(inputProps: any) => (
                    <Form.Control {...inputProps} />
                  )}
                </InputMask>
              )
              : (
                <Form.Control type="text" id="lname" name="lname" disabled value={fromField?.value ? fromField.value.toString() : ''} />
              )}

            <Form.Text>DD/MM/YYYY hh:mm</Form.Text>
            <ShowError msg={fromField?.meta.error} />
          </Col>
          <Col md={6}>
            <Form.Label>Until</Form.Label>
            {/* todo check onBlur if this is ok, added by JH */}
            <InputMask
              mask="99/99/9999 99:99"
              value={untilField?.value as any}
              onChange={handleUntilFieldChange}
              onBlur={handleUntilFieldBlur}
            >
              {(inputProps: any) => <Form.Control {...inputProps} />}
            </InputMask>
            <Form.Text>DD/MM/YYYY hh:mm</Form.Text>
            <ShowError msg={untilField?.meta.error} />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <Form.Label className="mb-3">Probe</Form.Label>
        {probe?.probe_name}
        <Form.Text>{`${probe?.probe_alias} - ${probe?.platform} ${probe?.OS} - ${probe?.status ? probe?.status : ''}`}</Form.Text>
      </Form.Group>
    </>
  );
};

export default EditReservationFormFields;
