import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { Col, Form, Row } from 'react-bootstrap';
import { useField } from 'react-form';
import moment from 'moment';
import validateDate, { ShowError } from '../utils';
import ProbeTable from './ProbeTable';

const CreateReservationFormFields = ({ probes, reservations, selectedProbes }: any) => {
  const [timeFilteredReservations, setTimeFilteredReservations] = useState<any>(undefined);
  const fromField = useField('from', {
    validate: (value) => {
      if (!value) {
        return 'This field is required';
      }
      return validateDate(value.toString());
    },
  });

  const {
    onChange: handleFromFieldChange,
    onBlur: handleFromFieldBlur,
  } = fromField?.getInputProps();

  const untilField = useField('until', {
    validate: (value) => {
      if (!value) {
        return 'This field is required';
      }
      return validateDate(value.toString());
    },
  });

  const {
    onChange: handleUntilFieldChange,
    onBlur: handleUntilFieldBlur,
  } = untilField?.getInputProps();

  useEffect(() => {
    setTimeFilteredReservations(reservations?.filter((res:any) => moment(res.from).isBefore(moment(`${untilField.value}`, 'DD/MM/YYYY HH:mm'))
    && moment(res.until).isAfter(moment(`${fromField.value}`, 'DD/MM/YYYY HH:mm'))));
  }, [untilField, fromField]);

  return (
    <>
      <Form.Group>
        <Row>
          <Col md={6}>
            <Form.Label>From</Form.Label>
            <InputMask
              mask="99/99/9999 99:99"
              value={fromField?.value as any}
              onChange={handleFromFieldChange}
              onBlur={handleFromFieldBlur}
            >
              {(inputProps: any) => (
                <Form.Control {...inputProps} />
              )}
            </InputMask>
            <Form.Text>
              DD/MM/YYYY hh:mm
            </Form.Text>
            <ShowError msg={fromField?.meta.error} />
          </Col>
          <Col md={6}>
            <Form.Label>Until</Form.Label>
            <InputMask
              mask="99/99/9999 99:99"
              value={untilField?.value as any}
              onChange={handleUntilFieldChange}
              onBlur={handleUntilFieldBlur}
            >
              {(inputProps: any) => <Form.Control {...inputProps} />}
            </InputMask>
            <Form.Text>
              DD/MM/YYYY hh:mm
            </Form.Text>
            <ShowError msg={untilField?.meta.error} />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group>
        <ProbeTable
          probes={probes}
          reservations={timeFilteredReservations}
          selectedProbes={selectedProbes}
          semiAutomated={false}
        />
      </Form.Group>
    </>
  );
};

export default CreateReservationFormFields;
