import _ from 'lodash';

/* eslint-disable prefer-const */
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
// import { TagsInput } from 'react-tag-input-component';
import {
  Alert,
  Button,
  Col,
  Form,
  FormControl,
  Modal,
} from 'react-bootstrap';
import moment from 'moment';

import { Sim } from 'hooks/services/simManagement/interfaces';
import useGetProbeLocations from 'hooks/services/probe/location/useGetProbeLocations';

import AutosuggestTagInput from 'components/AutosuggestTagInput/AutosuggestTagInput';
import Table from 'components/Table';

import useAlerts from 'hooks/useAlerts';
import useForm from 'hooks/useForm';

import useAddSubscriber from 'hooks/services/simManagement/subscriber/useAddSubscriber';
import useGetProbes from 'hooks/services/probe/useGetProbes';
import useGetSubscriber from 'hooks/services/simManagement/subscriber/useGetSubscriber';
import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useEditSubscriber from 'hooks/services/simManagement/subscriber/useEditSubscriber';

type ConfirmModalProps = {
  text: string;
  title: string;
  onAccept: () => void;
  onHide: () => void;
  onReject: () => void;
  show: boolean;
  noButtonVariant?: string;
  yesButtonVariant?: string;
};

const ConfirmModal = ({
  text,
  title,
  onAccept: handleAccept,
  onHide: handleHide,
  noButtonVariant = 'secondary',
  yesButtonVariant = 'primary',
  onReject: handleReject,
  show,
}: ConfirmModalProps) => (
  <Modal
    centered
    show={show}
    onHide={() => handleHide()}
    id="sim-edit-modal"
    backdrop="static"
  >
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>{text}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button
        onClick={() => handleReject()}
        type="button"
        variant={noButtonVariant}
      >
        No
      </Button>
      <Button
        onClick={() => handleAccept()}
        type="button"
        variant={yesButtonVariant}
      >
        Yes
      </Button>
    </Modal.Footer>
  </Modal>
);

type AssignToProbeModalProps = {
  formValues: any,
  msisdn: string,
  onChange: (value: string | null, event?: any) => void;
  onHide: () => void;
  show: boolean;
  type: 'fixed_line' | 'sip';
};

const AssignToProbeModal = ({
  formValues,
  msisdn,
  onChange: handleChange,
  onHide: handleHide,
  show,
  type,
}: AssignToProbeModalProps) => {
  const getProbes = useGetProbes();

  const { data: locations } = useGetProbeLocations();

  const devices = _.chain(getProbes?.data)
    .map((probe) => {
      const probeDevices = _.map(probe?.devices, (device) => ({
        ...device,
        country_iso: probe.country_iso,
        probe_name: probe.probe_name,
        probe_alias: probe.probe_alias,
        assigned_number: _.get(_.chain(device.subscribers)
          .values()
          .first()
          .value(),
        'msisdn', ''),
      }));

      return probeDevices;
    })
    .flatten()
    .value();

  const locationsByCountryIso = _.keyBy(locations, 'country_iso');

  const tableData = _.chain(devices)
    .filter(['type', type === 'fixed_line' ? 'Analogue_Modem' : 'IP_Phone'])
    .map((device) => ({ location: _.get(locationsByCountryIso, `${device?.country_iso}.country_name`, ''), ...device }))
    .value();

  const tableRowActions = useMemo(() => ({
    content: '',
    items: [
      {
        content: 'Assign',
        key: 'edit',
        onClick: (probe: any) => handleChange(probe?.device_id, formValues),
        variant: 'outline-primary'
      },
    ],
  }), []);

  return (
    <Modal
      centered
      size="lg"
      show={show}
      onHide={() => handleHide()}
      id="sim-edit-modal"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign number to a probe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Row>
          <Col md="6">
            <Form.Group key="msisdn">
              <Form.Label htmlFor="name">Number</Form.Label>
              <FormControl
                disabled
                name="msisdn"
              // required
                type="text"
                value={msisdn}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Table
          actionsPosition="left"
          columns={[{
            accessor: 'probe_alias',
            disableFilters: true,
            Header: 'Name',
          }, {
            accessor: 'location',
            disableFilters: true,
            Header: 'Location'
          }, {
            accessor: 'assigned_number',
            disableFilters: true,
            Header: 'Assigned number'
          }, {
            accessor: 'name',
            disableFilters: true,
            Header: 'Device'
          }]}
          data={tableData}
          hideExportButton
          maxHeight="350px"
          rowActions={tableRowActions}
        />
      </Modal.Body>
    </Modal>
  );
};

type SubscriberFormProps = {
  initialValues: any;
  onCloseButtonClick: () => void;
  onSubmit: (formValues: any) => void;
  showAssignProbeModal: boolean;
  setShowAssignProbeModal: (value: boolean) => void,
  showAssignProbeConfirmModal: boolean;
  setShowAssignProbeConfirmModal: (value: boolean) => void,
  showReassignProbeConfirmModal: { data: any } | boolean;
  setShowReassignProbeConfirmModal: (value: { data: any } | boolean) => void,
  subscriberId: number | null;
  type: 'fixed_line' | 'sip';
};

type FormErrors = {
  position?: string;
  iccid?: string;
  msisdn?: string;
  imsi?: string;
  tariff?: string;
  wnw?: string;
  op_wnw?: string;
  psp?: string;
  wholesale_id?: string;
  itg_id?: string;
  secret?: string;
  name?: string;
  origin?: string;
  prepaid?: string;
  sim_type?: string;
  type?: string;
  modified?: string;
  network?: string;
};

const SubscriberForm = (props: SubscriberFormProps) => {
  const {
    initialValues,
    onCloseButtonClick: handleCloseButtonClick,
    onSubmit: handleFormSubmit,
    showAssignProbeModal,
    setShowAssignProbeModal,
    showAssignProbeConfirmModal,
    setShowAssignProbeConfirmModal,
    showReassignProbeConfirmModal,
    setShowReassignProbeConfirmModal,
    subscriberId,
    type
  } = props;

  const isNewSubscriber = !subscriberId;

  const { data: locations } = useGetProbeLocations();
  const locationsByCountryIso = _.keyBy(locations, 'country_iso');

  const { data: subscribers } = useGetSubscribers();

  const getProbes = useGetProbes();

  const devices = _.chain(getProbes?.data).map((probe) => {
    const probeDevices = _.map(probe?.devices, (device) => ({
      ...device,
      country_iso: probe.country_iso,
      probe_name: probe.probe_name,
      assigned_number: _.get(_.chain(device.subscribers)
        .values()
        .first()
        .value(),
      'msisdn', ''),
    }));

    return probeDevices;
  }).flatten()
    .value();

  const {
    errors: formErrors,
    handleChange,
    handleSubmit,
    setValue,
    submitted,
    touched,
    updateValues,
    values: formValues,
  } = useForm({
    initialValues,
    onSubmit: handleFormSubmit,
    validate: (values) => {
      const errors: FormErrors = {};

      if (!values?.msisdn || values?.msisdn === '') {
        errors.msisdn = 'Please fill in Number field.';
      }

      let regExp = /[a-zA-Z]/g;
      if (regExp.test(values?.msisdn)) {
        errors.msisdn = 'Number field cannot contain letters';
      }

      if (values) {
        if (!values?.lab && !values?.live) {
          errors.network = 'Please select at least one network type';
        }
      }

      return errors;
    },
  });

  console.log(formErrors);

  const device = _.find(devices, ['device_id', formValues?.device_id]);

  const getDeviceSubscriber = (device_id: string) => _.find(subscribers, ['assigned_to.device_id', device_id]);
  const deviceSubscriber = getDeviceSubscriber(formValues?.device_id);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col>
          <Form.Group key="msisdn" className="ml-2">
            <Form.Label htmlFor="name">Number*</Form.Label>
            <FormControl
              autoFocus
              id="msisdn"
              isInvalid={submitted && Boolean(formErrors?.msisdn)}
              name="msisdn"
              onChange={handleChange('msisdn')}
              // required
              type="text"
              value={formValues?.msisdn}
            />
            {submitted && Boolean(formErrors?.msisdn) && (
              <FormControl.Feedback type="invalid">
                {formErrors?.msisdn}
              </FormControl.Feedback>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group key="name">
            <Form.Label htmlFor="name">Name</Form.Label>
            <FormControl
              id="name"
              isInvalid={submitted && Boolean(formErrors?.name)}
              name="name"
              onChange={handleChange('name')}
              type="text"
              value={formValues?.name}
            />
            {submitted && Boolean(formErrors?.name) && (
              <FormControl.Feedback type="invalid">
                {formErrors?.name}
              </FormControl.Feedback>
            )}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col />
        <Col>
          <Form.Group key="tariff">
            <Form.Label htmlFor="tariff">Tariff</Form.Label>
            <FormControl
              id="tariff"
              isInvalid={submitted && Boolean(formErrors?.tariff)}
              name="tariff"
              onChange={handleChange('tariff')}
              type="text"
              value={formValues?.tariff}
            />
            {submitted && formErrors?.tariff && (
              <FormControl.Feedback type="invalid">
                {formErrors?.tariff}
              </FormControl.Feedback>
            )}
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <Form.Label htmlFor="network">Network</Form.Label>
          <Form.Row>
            <Form.Group key="live" style={{ marginTop: '10px', minWidth: '80px' }}>
              <Form.Check
                checked={formValues?.live}
                value="live"
                id="live"
                key="live"
                type="checkbox"
                label="Live"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={(() => {
                  if (formValues?.live === true) {
                    setValue('lab', true);
                  }
                  setValue('live', !formValues?.live);
                })}
              />
            </Form.Group>
          </Form.Row>
          {submitted && Boolean(formErrors?.network) && (
          <div className="invalid-feedback d-block">{formErrors?.network}</div>
          )}
        </Col>
        <Col>
          <Form.Group style={{ width: '100%' }}>
            <Form.Label>Live Tags</Form.Label>
            <AutosuggestTagInput
              tags={formValues?.tags}
              separators={['Enter', ' ', ',']}
              onChangeInput={(newTags: any) => setValue('tags', newTags)}
              suggestions={_.uniq(_.union(...subscribers.map((sub: any) => sub?.tags)))}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col xs="auto">
          <Form.Label htmlFor="network">&nbsp;</Form.Label>
          <Form.Row>
            <Form.Group key="lab" style={{ marginTop: '10px', minWidth: '80px' }}>
              <Form.Check
                checked={formValues?.lab}
                value="lab"
                id="lab"
                key="lab"
                type="checkbox"
                label="Lab"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={(() => {
                  if (formValues?.lab === true) {
                    setValue('live', true);
                  }
                  setValue('lab', !formValues?.lab);
                })}
              />
              {submitted && Boolean(formErrors?.network) && (
              <div className="invalid-feedback d-block">{formErrors?.network}</div>
              )}
            </Form.Group>
          </Form.Row>
          {submitted && Boolean(formErrors?.network) && (
          <div className="invalid-feedback d-block">{formErrors?.network}</div>
          )}
        </Col>
        <Col>
          <Form.Group style={{ width: '100%' }}>
            <Form.Label>Lab Tags</Form.Label>
            <AutosuggestTagInput
              disabled
              tags={[]}
              separators={['Enter', ' ', ',']}
              onChangeInput={() => null}
              suggestions={[]}
            />
          </Form.Group>
        </Col>
      </Form.Row>
      {!isNewSubscriber && (
        <>
          <hr />
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Assigned to probe</Form.Label>
                <FormControl
                  disabled
                  onChange={() => null}
                  type="text"
                  value={device?.probe_name ?? ''}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Location</Form.Label>
                <FormControl
                  disabled
                  onChange={() => null}
                  type="text"
                  value={_.get(locationsByCountryIso, `${device?.country_iso}.country_name`, '')}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Device name</Form.Label>
                <FormControl
                  disabled
                  onChange={() => null}
                  type="text"
                  value={device?.name ?? ''}
                />
              </Form.Group>
            </Col>
            <Col className="d-flex align-items-end" xs="auto">
              <Form.Group>
                {!device && <Button onClick={() => setShowAssignProbeModal(true)} type="button" variant="outline-primary" style={{ marginTop: 0, marginBottom: 0 }}>Assign</Button>}
                {device && <Button onClick={() => updateValues({ device_id: null })} type="button" variant="outline-primary" style={{ marginTop: 0, marginBottom: 0 }}>Unassign</Button>}
              </Form.Group>
            </Col>
          </Form.Row>
        </>
      )}
      {showAssignProbeModal && createPortal(<AssignToProbeModal
        formValues={formValues}
        msisdn={formValues?.msisdn}
        onChange={(value) => {
          const existingDeviceSubscriber = _.find(subscribers, ['assigned_to.device_id', value]);

          if (existingDeviceSubscriber) {
            setShowReassignProbeConfirmModal({
              data: {
                ...formValues,
                device_id: value
              }
            });
          } else if (isNewSubscriber) {
            handleFormSubmit({
              ...formValues,
              device_id: value
            });
          } else {
            updateValues({ device_id: value });
          }

          setShowAssignProbeModal(false);
        }}
        onHide={() => setShowAssignProbeModal(false)}
        show={showAssignProbeModal}
        type={type}
      />, document.body)}
      {showAssignProbeConfirmModal && createPortal(<ConfirmModal
        text={`Do you want to assign ${formValues?.msisdn} to a probe?`}
        title="Assign new number to a probe?"
        onAccept={() => setShowAssignProbeModal(true)}
        onHide={() => setShowAssignProbeConfirmModal(false)}
        onReject={() => handleFormSubmit(formValues)}
        noButtonVariant="outline-primary"
        yesButtonVariant="outline-primary"
        show={showAssignProbeConfirmModal}
      />, document.body)}
      {Boolean(showReassignProbeConfirmModal) && createPortal(<ConfirmModal
        text={`${getDeviceSubscriber(_.get(showReassignProbeConfirmModal, 'data.device_id'))?.assigned_to?.probe_name} already has an assigned number. Do you want to update the number to ${formValues?.msisdn}?`}
        title="Update assigned number?"
        onAccept={() => {
          if (typeof showReassignProbeConfirmModal === 'object' && 'data' in showReassignProbeConfirmModal) {
            handleFormSubmit(showReassignProbeConfirmModal?.data);
          }
        }}
        onHide={() => setShowReassignProbeConfirmModal(false)}
        onReject={() => setShowReassignProbeConfirmModal(false)}
        show={Boolean(showReassignProbeConfirmModal)}
      />, document.body)}
      <div className="text-right">
        <Button
          onClick={handleCloseButtonClick}
          variant="secondary"
        >
          Close
        </Button>
        {isNewSubscriber && (
          <Button
            disabled={!touched}
            onClick={(event: any) => {
              if (!_.isEmpty(formErrors)) {
                handleSubmit(event);
              } else {
                setShowAssignProbeConfirmModal(true);
              }
            }}
            type="button"
            variant="primary"
          >
            Add
          </Button>
        )}
        {!isNewSubscriber && deviceSubscriber?.id !== subscriberId && (
          <Button
            disabled={!touched}
            onClick={(event: any) => {
              if (deviceSubscriber) {
                setShowReassignProbeConfirmModal({
                  data: formValues
                });
              } else if (!_.isEmpty(formErrors)) {
                handleSubmit(event);
              } else {
                handleFormSubmit(formValues);
              }
            }}
            type="button"
            variant="primary"
          >
            Update
          </Button>
        )}
        {!isNewSubscriber && deviceSubscriber?.id === subscriberId && (
          <Button
            disabled={!touched}
            type="submit"
            variant="primary"
          >
            Update
          </Button>
        )}
      </div>
    </Form>
  );
};

type SubscriberModalProps = {
  onHide: () => void;
  show: boolean;
  subscriberId: null | number;
  type: 'fixed_line' | 'sip';
};

const SubscriberModal = (props: SubscriberModalProps) => {
  const {
    onHide: handleHide,
    show,
    subscriberId,
    type
  } = props;

  const { data: subscriber } = useGetSubscriber(subscriberId);

  const [showError, setShowError] = useState(true);
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const { showAlert } = useAlerts();

  const [showAssignProbeModal, setShowAssignProbeModal] = useState(false);
  const [showAssignProbeConfirmModal, setShowAssignProbeConfirmModal] = useState(false);
  const [
    showReassignProbeConfirmModal,
    setShowReassignProbeConfirmModal
  ] = useState<{ data: any } | boolean>(false);

  // success or error message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errMessage) {
      showAlert({
        id: `new-message_${errMessage}`,
        content: `${errMessage}`,
        variant: 'danger',
      });
      setErrMessage('');
    }
  }, [message, errMessage, showAlert]);

  const [addSubscriber, { error: addSubscriberError }] = useAddSubscriber({
    onSuccess: (_data, variables) => {
      setMessage(`${type === 'fixed_line' ? 'Fixed number' : 'SIP account'} ${variables?.sim?.msisdn} has been created!`);
      handleHide();
    },
    onError: (_data, variables) => {
      setErrMessage(`Failed to create ${type === 'fixed_line' ? 'fixed number' : 'SIP account'} ${variables?.sim?.iccid}`);
    },
  });

  const [editSubscriber, { error: editSubscriberError }] = useEditSubscriber({
    onSuccess: (_data, variables) => {
      setMessage(`${type === 'fixed_line' ? 'Fixed number' : 'SIP account'}  ${variables?.sim?.msisdn} has been updated!`);
      handleHide();
    },
    onError: (_data, variables) => {
      setErrMessage(`Failed to update ${type === 'fixed_line' ? 'fixed number' : 'SIP account'} ${variables?.sim?.iccid}`);
    },
  });

  const handleSubmit = (formValues: any) => {
    const date = new Date();
    const timeMod = moment(date).format('YYYY-MM-DD HH:mm:ss');

    const sim: Sim = {
      iccid: formValues.iccid,
      msisdn: (formValues.msisdn).replace('+', ''),
      imsi: formValues.imsi,
      tariff: formValues.tariff,
      wnw: formValues.wnw,
      op_wnw: formValues.op_wnw,
      psp: formValues.psp,
      wholesale_id: formValues.wholesale_id,
      itg_id: formValues.itg_id,
      secret: formValues.secret,
      name: formValues.name,
      origin: formValues.origin,
      prepaid: formValues.prepaid,
      type: formValues.type,
      sim_type: formValues.sim_type,
      position: formValues.position,
      modified: timeMod,
      lab: formValues.lab,
      live: formValues.live,
      tags: formValues.tags,
    };

    const assignedDevice = subscriber?.assigned_to?.device_id;
    const selectedDevice = formValues?.device_id;

    const hasAssignedDevice = !_.isNil(assignedDevice);
    const hasSelectedDevice = !_.isNil(selectedDevice);

    const isNewDevice = !hasAssignedDevice
      && hasSelectedDevice;

    const isDeviceUnassigned = hasAssignedDevice
      && !hasSelectedDevice;

    const isSameDevice = hasAssignedDevice
      && hasSelectedDevice
      && assignedDevice === selectedDevice;

    const isDeviceReassigned = hasAssignedDevice
      && hasSelectedDevice
      && assignedDevice !== selectedDevice;

    if (isSameDevice) {
      sim.assigned_to = subscriber?.assigned_to;
    }

    if (isNewDevice || isDeviceUnassigned || isDeviceReassigned) {
      sim.device_id = hasSelectedDevice ? selectedDevice.toString() : null;
    }

    if (subscriber?.id) {
      editSubscriber({
        id: subscriber.id,
        sim,
      });
    } else {
      setShowAssignProbeConfirmModal(false);
      setShowAssignProbeModal(false);
      addSubscriber({
        sim,
      });
    }
  };

  const initialValues = {
    iccid: subscriber?.iccid || '',
    msisdn: subscriber?.msisdn || '',
    imsi: subscriber?.imsi || '',
    tariff: subscriber?.tariff || '',
    wnw: subscriber?.wnw || '',
    op_wnw: subscriber?.op_wnw || '',
    psp: subscriber?.psp || '',
    wholesale_id: subscriber?.wholesale_id || '',
    itg_id: subscriber?.itg_id || '',
    name: subscriber?.name || '',
    origin: subscriber?.origin || '',
    prepaid: _.isNil(subscriber?.prepaid) ? false : subscriber?.prepaid,
    type: subscriber?.type || type,
    sim_type: subscriber?.sim_type,
    position: subscriber?.position || '',
    modified: subscriber?.modified || '',
    lab: subscriber?.lab || false,
    live: subscriber?.live || true,
    tags: subscriber?.tags || [],
    device_id: subscriber?.assigned_to?.device_id || null,
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleHide}
        id="sim-edit-modal"
        backdrop="static"
        style={{ display: showAssignProbeConfirmModal || showReassignProbeConfirmModal || showAssignProbeModal ? 'none' : 'block' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{subscriber?.id ? `Edit ${type === 'fixed_line' ? 'Fixed Number' : 'SIP Account'} ${subscriber.msisdn ?? ''}` : `Add ${type === 'fixed_line' ? 'Fixed Number' : 'SIP Account'}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addSubscriberError?.message && showError && (
            <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
              {addSubscriberError?.message}
            </Alert>
          )}
          {editSubscriberError?.message && showError && (
            <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
              {editSubscriberError?.message}
            </Alert>
          )}
          {(!subscriberId || (subscriberId && subscriber)) && (
            <SubscriberForm
              initialValues={initialValues}
              onCloseButtonClick={handleHide}
              onSubmit={handleSubmit}
              showAssignProbeModal={showAssignProbeModal}
              setShowAssignProbeModal={setShowAssignProbeModal}
              showAssignProbeConfirmModal={showAssignProbeConfirmModal}
              setShowAssignProbeConfirmModal={setShowAssignProbeConfirmModal}
              showReassignProbeConfirmModal={showReassignProbeConfirmModal}
              setShowReassignProbeConfirmModal={setShowReassignProbeConfirmModal}
              subscriberId={subscriberId}
              type={type}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriberModal;
