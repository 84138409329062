import React, { ChangeEvent, useState } from 'react';
import { Form, Container, Row, Col, Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Sim } from 'hooks/services/simManagement/interfaces';
import TaggedSubscribers from './TaggedSubscribers';

const TestParameters = ({
  previousStep,
  nextStep,
  subscriberTagsUnique,
  selectedOsix,
  setSelectedOsix,
  selectedNetwork,
  subscribers,
  setSelectedNetwork,
  selectedSubscribers,
  setSelectedSubscribers,
  selectedTcpDump,
  setSelectedTcpDump,
  selectedRecordScreen,
  setSelectedRecordScreen,
}: any) => {
  const [showSubscriberModal, setShowSubscriberModal] = useState(false);

  return (
    <Form>
      <strong>
        Select the test parameters for your campaign.
      </strong>
      <Row style={{ marginTop: '2rem', marginRight: '2rem', marginLeft: '2rem', marginBottom: '2rem' }}>
        <Col>
          <Row>
            Network under test:
          </Row>
          <Row>
            <Form.Check
              checked={selectedNetwork === 'Live'}
              value="selectedNetworkLive"
              id="selectedNetworkLive"
              key="selectedNetworkLive"
              type="radio"
              inline
              aria-label="item"
              className="lg mr-0 ml-3"
              onChange={() => setSelectedNetwork('Live')}
            />
            <Form.Label htmlFor="selectedNetworkLive" className="col-form-label mr-4">Live</Form.Label>
          </Row>
          <Row>
            <Form.Check
              checked={selectedNetwork === 'Lab'}
              value="selectedNetworkLab"
              id="selectedNetworkLab"
              key="selectedNetworkLab"
              type="radio"
              inline
              aria-label="item"
              className="lg mr-0 ml-3"
              onChange={() => setSelectedNetwork('Lab')}
            />
            <Form.Label htmlFor="selectedNetworkLab" className="col-form-label mr-4">Lab</Form.Label>
          </Row>
        </Col>
        <Col>
          <Row>
            Subscribers:
          </Row>
          <Row>
            <Col md={5} style={{ padding: 0 }}>
              <Form.Check
                checked={_.isEmpty(selectedSubscribers)}
                value="selectedSubscribersAll"
                id="selectedSubscribersAll"
                key="selectedSubscribersAll"
                type="radio"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={() => setSelectedSubscribers([])}
              />
              <Form.Label htmlFor="selectedSubscribersAll" className="col-form-label mr-4">All available</Form.Label>
            </Col>
            {_.isEmpty(selectedSubscribers) && (
              <Col md={2} style={{ padding: '0', margin: 'auto 0 auto -20px' }}>
                {`(${subscribers.filter((sub: Sim) => (sub.live === (selectedNetwork === 'Live') || sub.lab === (selectedNetwork === 'Lab')))?.length})`}
              </Col>
            )}
          </Row>
          <Row>
            <Col md={5} style={{ padding: 0 }}>
              <Form.Check
                checked={!_.isEmpty(selectedSubscribers)}
                value="selectedSubscribersTag"
                id="selectedSubscribersTag"
                key="selectedSubscribersTag"
                type="radio"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={() => setSelectedSubscribers([subscriberTagsUnique[0]])}
              />
              <Form.Label htmlFor="selectedSubscribersTag" className="col-form-label mr-4">By tag</Form.Label>
            </Col>
            {!_.isEmpty(selectedSubscribers) && (
              <Col md={2} style={{ padding: 0, margin: 'auto 0 auto -20px' }}>
                <span
                  role="button"
                  tabIndex={0}
                  className="link-text"
                  onClick={() => setShowSubscriberModal(true)}
                  onKeyPress={() => { }}
                >
                  {`(${subscribers.filter((sub:any) => (selectedSubscribers?.filter(
                    (subTag:any) => sub?.tags?.includes(subTag)
                  )?.length === selectedSubscribers?.length)
                  && (sub.lab === (selectedNetwork === 'Lab')
                  || sub.live === (selectedNetwork === 'Live')))?.length}/${subscribers.filter((sub: Sim) => (sub.live === (selectedNetwork === 'Live') || sub.lab === (selectedNetwork === 'Lab')))?.length})`}
                </span>
              </Col>
            )}
          </Row>
          {(!_.isEmpty(selectedSubscribers))
            && [...selectedSubscribers]?.map((selectedSub:any, index:any) => (
              <Row key={`${selectedSub}row`}>
                <Col md={7} sm={6} style={{ padding: '3px 1px' }}>
                  <Form.Control
                    as="select"
                    id="tags"
                    name="tags"
                    onChange={
                      (e: ChangeEvent<HTMLSelectElement>) => !_.includes(
                        selectedSubscribers, e.target.value
                      )
                      && setSelectedSubscribers(selectedSubscribers.map((item:any) => (
                        _.indexOf(selectedSubscribers, item) === index ? e.target.value : item)))
                    }
                  >
                    {[selectedSub, ...subscriberTagsUnique?.filter((sub:any) => !selectedSubscribers
                      ?.includes(sub))]?.map((tag: any) => (
                        <option key={`${tag}-${selectedSub}`} value={tag}>{tag}</option>
                    ))}
                  </Form.Control>
                </Col>
                <Col md={5} sm={6} style={{ padding: '2px 2px' }}>
                  {(index !== 0 || selectedSubscribers?.length > 1) && (
                    <button
                      type="button"
                      className="btn icon-button-tag"
                      onClick={() => setSelectedSubscribers(
                        [...selectedSubscribers.filter((sub:any) => selectedSub !== sub)]
                      )}
                    >
                      <FontAwesomeIcon
                        className="align-middle"
                        icon={faTrashAlt}
                      />
                    </button>
                  )}
                  {(!_.isEmpty(selectedSubscribers)
                && index === selectedSubscribers?.length - 1)
                && subscriberTagsUnique?.length !== selectedSubscribers?.length
                && (
                  <button
                    type="button"
                    className="btn icon-button-tag"
                    onClick={() => setSelectedSubscribers(
                      [...selectedSubscribers, subscriberTagsUnique?.filter(
                        (subTag:any) => !_.includes(selectedSubscribers, subTag)
                      )[0]]
                    )}
                  >
                    <FontAwesomeIcon
                      className="align-middle"
                      icon={faPlus}
                    />
                  </button>
                )}
                </Col>
              </Row>
            ))}
        </Col>
        <Col>
          <Row>
            Traces:
          </Row>
          <Row>
            <div key="osix" className="d-inline">
              <Form.Check
                checked={selectedOsix}
                value="osix"
                id="osix"
                key="osix"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={() => setSelectedOsix(!selectedOsix)}
              />
              <Form.Label htmlFor="osix" className="col-form-label mr-4">OSIX</Form.Label>
            </div>
          </Row>
          <Row>
            <div key="tcpdump" className="d-inline">
              <Form.Check
                checked={selectedTcpDump}
                value="tcpdump"
                id="tcpdump"
                key="tcpdump"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={() => setSelectedTcpDump(!selectedTcpDump)}
              />
              <Form.Label htmlFor="tcpdump" className="col-form-label mr-4">TCPdump</Form.Label>
            </div>
          </Row>
          <Row>
            <div key="recordscreen" className="d-inline">
              <Form.Check
                checked={selectedRecordScreen}
                value="recordscreen"
                id="recordscreen"
                key="recordscreen"
                inline
                aria-label="item"
                className="lg mr-0 ml-3"
                onChange={() => setSelectedRecordScreen(!selectedRecordScreen)}
              />
              <Form.Label htmlFor="recordscreen" className="col-form-label mr-4">Record mobile device screen</Form.Label>
            </div>
          </Row>
        </Col>
      </Row>
      <Container className="pt-4">
        <Row>
          <Col />
          <Col md={2}>
            <Button
              id="previousStep"
              onClick={previousStep}
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
            >
              Back
            </Button>
          </Col>
          <Col md={2}>
            <Button
              id="nextStep"
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
              type="submit"
              onClick={() => nextStep()}
            >
              Next
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
      <Modal
        show={showSubscriberModal}
        onHide={() => setShowSubscriberModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Subscribers
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TaggedSubscribers
            subscribers={
              subscribers?.filter((sub:any) => (
                selectedSubscribers?.filter((subTag:any) => sub?.tags?.includes(
                  subTag
                ))?.length === selectedSubscribers?.length
                && (sub.lab === (selectedNetwork === 'Lab')
                || sub.live === (selectedNetwork === 'Live'))
              ))
            }
          />
        </Modal.Body>
      </Modal>
    </Form>
  );
};

export default TestParameters;
