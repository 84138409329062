// import _ from 'lodash';

import React, { useEffect, useState } from 'react';

// import { Alert } from 'react-bootstrap';

import FetchApi from './FetchApi';
import useGetAttenuatorDetails from '../hooks/useGetAttenuatorDetails';

interface IChannels {
  attName: string;
  setSelectedAtt(name?: string): void;
  setErrMessage: (message: string) => void;
}

const Channels = ({ attName, setSelectedAtt, setErrMessage }: IChannels) => {
  const handleClose = () => setSelectedAtt();

  const {
    data: attDetails,
    refetch: getAttDetails,
  } = useGetAttenuatorDetails(attName);

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState(false);

  let checkAttDetails: number;

  useEffect(() => {
    if (attDetails) {
      checkAttDetails = Object.entries(attDetails.channels).length;
      if (checkAttDetails === 0) {
        // setErrMessage(`Error loading channel details for ${attName}`);
        setErrorLoading(true);
      }
    }
  }, [attDetails]);

  return (
    <div className="p-3" style={{ border: '1px dotted silver' }}>
      <div className="d-flex mb-2">
        <h3>{attName}</h3>
        <button
          type="button"
          className="btn btn-sm btn-secondary ml-auto"
          onClick={handleClose}
        >Exit
        </button>
      </div>
      <div className="d-flex flex-wrap mb-5">
        {attDetails?.channels
          .sort((a: any, b: any) => ((a.name > b.name) ? 1 : -1))
          .map((channel: any) => {
            const { max, min, name } = channel;
            return (
              <FetchApi
                key={name}
                name={name}
                max={max}
                min={min}
                attName={attName}
                getAttDetails={getAttDetails}
                setErrMessage={setErrMessage}
                setLoading={setLoading}
              />
            );
          })}
        {!errorLoading && loading && <div>Loading... </div>}
        {errorLoading && <div>There are no channels configured for selected attenuator</div>}
      </div>
    </div>
  );
};

export default Channels;
