import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeleteArrayRequestData = {
  arrayName: string;
};

export default function useDeleteArray(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ arrayName }: DeleteArrayRequestData) => axios.delete(
    `/sim-manager/api/arrays/${arrayName}`,
  ).then(({ data }) => data), options);
}
