import React, { useContext, useMemo, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import moment from 'moment';
import { Probe } from 'hooks/services/probe/interfaces';
import {
  Device, WindowState,
} from 'components/stf/context/interface';
import { WindowsManagerContext } from 'components/stf/context/windowsManager';
import DeviceWindowContent from 'components/stf/window/DeviceWindowContent';
import { ViewProvider } from 'components/stf/context/view';

const StfControl = (
  { device,
    reservation,
    probe,
  }: { device: Device, reservation: any | null, probe: Probe | null }
) => {
  const [winId] = useState(device.channel);
  const {
    openWindow, startDestroyWindow, getState, focusWindow,
  } = useContext(WindowsManagerContext);
  const {
    display: { url }, channel, model,
  } = device;

  const windowContent = useMemo(() => (
    <ViewProvider channel={channel} winId={winId}>
      <DeviceWindowContent
        probe={probe}
        device={device}
        winId={winId}
        deadline={+moment(reservation.until)}
        onTimeOut={() => startDestroyWindow(winId)}
      />
    </ViewProvider>
  ), [device, url, reservation.until]);

  const handleOpenWindow = () => {
    openWindow({
      id: winId,
      content: windowContent,
      title: probe?.probe_alias || model,
      state: 'CLOSED',
      size: { width: 200, height: 200 },
      externalWin: null,
    });
  };

  const renderSwitch = (state: WindowState) => {
    switch (state) {
      case undefined:
        if (probe?.devices && probe?.devices[0].stf_status === 'online' && probe?.devices[0]?.status === 'online') {
          return (
            <Button
              disabled={!device
              || !device.present
              || !device.ready
              || (probe?.devices && probe?.devices[0]?.stf_status !== 'online')}
              onClick={handleOpenWindow}
            >
              Use device
            </Button>
          );
        }
        return null;
      case 'OPEN':
        return (
          <>
            <Button className="bu-mr-1" variant="danger" onClick={() => startDestroyWindow(winId)}>
              Release device
            </Button>
            <Button onClick={() => focusWindow(winId)}>Focus device</Button>
          </>
        );
      default:
        return <Button disabled>Closing...</Button>;
    }
  };

  return (
    <>
      {/* Button for opening/closing separate window */}
      <Row>
        {
          renderSwitch(getState(winId))
        }
      </Row>
      <Row>
        {(!device
          || !device.present
          || !device.ready
          || (probe?.devices && probe?.devices[0]?.status === 'offline')
          || (probe?.devices && probe?.devices[0]?.stf_status !== 'online')) ? 'Device disconnected' : null}
      </Row>
    </>
  );
};
export default StfControl;
