import _ from 'lodash';
import CustomTreeItem from './CustomTreeItem';

export const makeTree:any = (input:any) => {
  const result:any = [];
  const level = { result };
  _.forEach(input, (item) => {
    item.path.split('/').filter((p:any) => p !== '').reduce((r:any, name:any, i:any, a:any) => {
      if (!r[name]) {
        // eslint-disable-next-line no-param-reassign
        r[name] = { result: [] };
        if (name === _.last(a)) {
          r.result.push({ name, tag: item.tag, parentPath: item.path.split(name)[0] });
        } else {
          r.result.push({ name, children: r[name].result, parentPath: item.path.split(name)[0] });
        }
      }
      return r[name];
    }, level);
  });
  return result;
};

export const renderTree = (tree: any) => {
  if (!tree) return null;
  return (tree.map((item:any) => {
    if (!item.children) {
      return (
        <CustomTreeItem
          key={item.name}
          nodeId={`${item.parentPath}${item.name}`}
          label={`${item.name} = ${item?.tag}`}
          disabled={!item?.tag}
        />
      );
    }
    return (
      <CustomTreeItem
        key={item?.name}
        nodeId={`${item.parentPath}${item.name}`}
        label={item?.name}
      >
        {renderTree(item.children)}
      </CustomTreeItem>
    );
  }));
};

export const getFolders = (dir: string[]) => {
  let result:string[] = [];
  dir?.forEach((item: any) => {
    let tempDir = '';
    const pathSplit = item?.path?.split('/');
    pathSplit?.forEach((path:string) => {
      if (_.indexOf(pathSplit, path) !== pathSplit.length - 1) {
        tempDir = tempDir.concat(tempDir === '' ? '' : '/', path);
        if (!result.includes(tempDir)) {
          result = [...result, tempDir];
        }
      }
    });
  });
  return result;
};
