import React, { useMemo } from 'react';

import Table from 'components/Table';

import useGetUsers from 'hooks/services/user/useGetUsers';
import useGetGroups from 'hooks/services/user/group/useGetGroups';

const UsersTable = () => {
  const { data: users } = useGetUsers();
  const { data: groups } = useGetGroups();

  // join users and groups to get group name inside user
  const data = useMemo(() => {
    if (groups && users) {
      return users.map((user: any) => {
        const gidNames: any[] = [];
        const gidNamesStr: string[] = [];
        user.gids.forEach((userGid: string) => {
          groups.every((element: any) => {
            if (element.gid === userGid) {
              gidNames.push({ gId: userGid, gName: element.groupname });
              gidNamesStr.push(`${element.groupname} (${userGid})`);
              return false;
            }
            return true;
          });
        });
        return { ...user, gidNames, gidNamesStr: gidNamesStr.join(', ') };
      });
    }

    return [];
  }, [groups, users]);

  const columns = useMemo(() => [
    {
      Header: 'Username',
      accessor: 'username',
      isSortable: true,
    },
    {
      Header: 'User ID',
      accessor: 'uid',
      isSortable: true,
    },
    {
      Header: 'Groups',
      accessor: 'gidNamesStr',
      style: { overflow: 'visible', whiteSpace: 'normal', wordWrap: 'break-word' },
      maxWidth: 350,
      width: 200,
      // todo: show formated cell content, but ensure possibility to export
      // (when using jsx inside cell, json2csv is not able to parse circular dep.
      // and there are exported many objects system attrs)
      // or change table eg. https://codesandbox.io/s/flattening-row-with-react-table-prkqb?file=/src/App.tsx:300-310
      // id: 'gids',
      // accessor: (items: any) => (
      //   items.gidNames.map((gid: any, index: number) => (
      //     <div key={gid.gId}>
      //       <span>{gid.gName} ({gid.gId}){index < items.gids.length - 1 ? ',' : ''}</span>
      //     </div>
      //   ))),
      isSortable: true,
    },
  ], []);

  return (
    <Table
      columns={columns}
      data={data ?? []}
      skipReset={false}
    />
  );
};

export default UsersTable;
