import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useAudioRecordingDownload(probeName: any, fileName: string) {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_ATTENUATORS });

  return useQuery(['download', probeName, fileName], () => axios.get(
    `/cms/api/probes/${probeName}/control/api/audio/recording/${fileName}`,
    {
      responseType: 'blob',
    }
  ).then(({ data }) => data));
}
