/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Container, Spinner } from 'react-bootstrap';
import useGetRepository from 'hooks/services/git/useGetRepository';
import _ from 'lodash';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { makeTree, renderTree, getFolders } from './Utils';
import './styles.scss';

type Party = {
  name: string;
  type: string;
  provider?: string;
  RAT?: string;
  location?: string;
  VoLTE?: string;
  VoWiFi?: string;
};

type DataType = {
  name: string;
  path: string;
  tag?: string;
  parties?: Party[];
  content?: string;
};

const TestCaseSelection = ({
  shouldResetForm,
  repository,
  branch,
  setSelectedTestCases,
  selectedTestCases,
  previousStep,
}: any) => {
  const {
    data: tcData,
    refetch: getTcData,
    isLoading
  } = useGetRepository(repository?.path, branch, repository?.id);
  const [testSet, setTestSet] = useState<DataType[] | undefined>(undefined);
  const [testSetTree, setTestSetTree] = useState<any[] | undefined>(undefined);

  const [renderTestSet, setRenderTestSet] = useState<DataType[] | undefined>(undefined);
  const [selectedLeft, setSelectedLeft] = useState<string[] | undefined>(undefined);

  const [renderSelectedTests, setRenderSelectedTests] = useState<DataType[] | undefined>(undefined);
  const [selectedRight, setSelectedRight] = useState<string[] | undefined>(undefined);
  const [expandedSelected, setExpandedSelected] = useState<string[]>([]);
  const [tags, setTags] = useState<string[] | undefined>(undefined);
  const [tagsValue, setTagsValue] = useState('');

  const [foundTestSet, setFoundTestSet] = useState<DataType[] | undefined>(undefined);
  // const [foundSelectedTests, setFoundSelectedTests] = useState<DataType[] | undefined>(undefined);

  const [testContent, setTestContent] = useState<string | undefined>(undefined);

  useEffect(() => {
    getTcData();
  }, [repository, branch]);

  useEffect(() => {
    setRenderTestSet(makeTree(foundTestSet));
  }, [foundTestSet]);

  useEffect(() => {
    setExpandedSelected(getFolders(selectedTestCases));
    console.log(expandedSelected);
  }, [selectedTestCases]);

  useEffect(() => {
    if (shouldResetForm) {
      setSelectedTestCases(undefined);
      setRenderSelectedTests(undefined);
    }
  }, [shouldResetForm]);

  useEffect(() => {
    if (tcData) {
      setTestSet(tcData);
      setTestSetTree(makeTree(tcData));
      // cleanup
      setRenderTestSet(undefined);
      setSelectedLeft(undefined);
      setSelectedRight(undefined);
      setTags(undefined);
      setTestContent(undefined);
    }
  }, [tcData, isLoading]);

  return (
    <>
      <Row className="mt-3">
        <strong style={{ marginLeft: '1px' }}>
          Create your test campaign from the available test cases or use your already known tags.
        </strong>
      </Row>
      <Row className="test-case-selection">
        <Col className="p-0">
          <span>Available test cases</span>
          <div className="test-case-selection-box">
            <Form.Group className="test-case-search" controlId="formSearchTestSet" hidden={!repository}>
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={(e:any) => {
                  const searchResult = testSet?.filter(
                    (item:any) => item.path.toLowerCase().includes(e.target.value.toLowerCase())
                    || item?.tag.toLowerCase().includes(e.target.value.toLowerCase())
                    || item?.content.toLowerCase().includes(e.target.value.toLowerCase())
                  );
                  e.target.style.color = _.isEmpty(searchResult) ? 'red' : 'black';
                  setFoundTestSet(e.target.value && !_.isEmpty(searchResult)
                    ? searchResult
                    : testSet);
                }}
              />
            </Form.Group>
            <div style={{ overflowY: 'scroll', height: '359px' }}>
              {!isLoading && !repository && (
                <div className="spinner-container" style={{ height: '100%' }}>
                  <span style={{ margin: 'auto 0 auto 0' }}>Please select a project to view test cases.</span>
                </div>
              )}
              {isLoading && repository && (
                <div className="spinner-container" style={{ height: '100%' }}>
                  <Spinner animation="border" className="spinner" role="status" style={{ marginTop: 'auto' }} />
                  <span style={{ marginBottom: 'auto' }}>Loading...</span>
                </div>
              )}
              {tcData ? (
                <TreeView
                  aria-label="file system navigator"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  multiSelect
                  onNodeSelect={(event: React.SyntheticEvent, nodeIds: string[]) => {
                    setSelectedLeft(_.isEmpty(nodeIds) ? undefined : nodeIds);
                    if (testSet && nodeIds.length > 0) {
                      const testName = _.last(nodeIds[nodeIds.length - 1].split('/'));
                      setTestContent(_.first(testSet
                        .filter((test:any) => test.name === testName))?.content);
                    }
                  }}
                >
                  {renderTestSet
                    ? renderTree(renderTestSet)
                    : testSetTree && renderTree(testSetTree)}
                </TreeView>
              )
                : ''}
            </div>
          </div>
        </Col>
        <Col md={1} className="test-case-selection-splitter">
          {/* Add to list */}
          <Button
            disabled={!selectedLeft}
            onClick={() => {
              if (foundTestSet && selectedLeft) {
                const filteredTc = foundTestSet.filter((item:any) => selectedLeft
                  .find((tc:any) => item.path.includes(tc)));
                setSelectedTestCases(selectedTestCases
                  ? [...selectedTestCases, ...filteredTc]
                  : filteredTc);
              } else if (testSet && selectedLeft) {
                const filteredTc = testSet.filter((item:any) => selectedLeft
                  .find((tc:any) => item.path.includes(tc)));
                setSelectedTestCases(selectedTestCases
                  ? [...selectedTestCases, ...filteredTc]
                  : filteredTc);
              }
            }}
          >
            {'>>>'}
          </Button>
          {/* Remove from list */}
          <Button
            onClick={() => {
              if (selectedRight) {
                const filteredTc = selectedTestCases.filter((item:any) => !selectedRight
                  .find((tc:any) => item.path.includes(tc)));
                setSelectedTestCases(filteredTc || []);
                setSelectedRight(undefined);
              }
            }}
            disabled={!selectedRight}
          >
            {'<<<'}
          </Button>
        </Col>
        <Col className="p-0">
          <span>My test set</span>
          <div className="test-case-selection-box">
            <Form.Group className="test-case-search" controlId="formSearchSelectedTests" hidden={_.isEmpty(selectedTestCases)}>
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={(e:any) => {
                  const result = selectedTestCases?.filter((item:any) => item.path.toLowerCase()
                    .includes(e.target.value.toLowerCase())
                    || item?.tag.toLowerCase().includes(e.target.value.toLowerCase())
                    || item?.content.toLowerCase().includes(e.target.value.toLowerCase()));
                  e.target.style.color = _.isEmpty(result) ? 'red' : 'black';
                  setRenderSelectedTests(e.target.value && !_.isEmpty(result)
                    ? makeTree(result)
                    : undefined);
                }}
              />
            </Form.Group>
            <div className="test-case-tree-view">
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
                expanded={expandedSelected}
                onNodeSelect={(event: React.SyntheticEvent, nodeIds: string[]) => {
                  if (testSet && nodeIds.length > 0) {
                    const testName = _.last(nodeIds[nodeIds.length - 1].split('/'));
                    setTestContent(
                      _.first(testSet.filter((test:any) => test.name === testName))?.content
                    );
                  } else {
                    setTestContent(undefined);
                  }
                  setSelectedRight(_.isEmpty(nodeIds) ? undefined : nodeIds);
                }}
                onNodeToggle={(event: React.SyntheticEvent, nodeIds: string[]) => {
                  setExpandedSelected(nodeIds);
                }}
              >
                {renderSelectedTests
                  ? renderTree(renderSelectedTests)
                  : selectedTestCases && renderTree(makeTree(selectedTestCases))}
              </TreeView>
            </div>
            <div>
              <Form.Group className="test-case-add m-0 p-0" controlId="formAddByTag">
                <Form.Control
                  type="text"
                  placeholder="Add comma separated tags"
                  value={tagsValue}
                  onChange={(e:any) => {
                    setTagsValue(e.target.value);
                    setTags(e.target.value.replaceAll(' ', '').split(',').filter((tag:any) => tag !== ''));
                  }}
                />
                <Button
                  disabled={_.isEmpty(tags)}
                  onClick={() => {
                    if (testSet && tags && !_.isEmpty(tags)) {
                      const filteredTc = testSet.filter((item:any) => tags.includes(item.tag));
                      setTagsValue('');
                      setSelectedTestCases(selectedTestCases
                        ? [...selectedTestCases, ...filteredTc]
                        : filteredTc);
                    }
                  }}
                >
                  Add
                </Button>
              </Form.Group>
            </div>
          </div>
        </Col>
      </Row>
      <Container className="pt-4">
        <Row>
          <Col />
          <Col md={2}>
            <Button
              id="previousStep"
              onClick={previousStep}
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
            >
              Back
            </Button>
          </Col>
          <Col md={2}>
            <Button
              id="nextStep"
              variant="primary"
              className="btn-block mr-1 mt-1 btn-lg"
              type="submit"
              disabled={typeof selectedTestCases === 'undefined' || selectedTestCases?.length === 0}
            >
              Next
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
      {testContent && (
        <Row className="test-case-details">
          <div className="test-case-details-label">
            Test case details
          </div>
          <div className="test-case-content">
            {testContent?.split('\n')?.map((line: string, i: number) => (
              <div key={`${+i}-line-row`}>
                {line}
                <br />
              </div>
            ))}
          </div>
        </Row>
      )}
    </>
  );
};

export default TestCaseSelection;
