import React from 'react';
import Table from 'components/Table';
import useUserTableSettings from 'hooks/useUserTableSettings';
import { Sim } from 'hooks/services/simManagement/interfaces';
import SimColumns from 'pages/Administration/SimColumns';

const hiddenColumns = [
  'position',
  'modified',
  'sim_type',
  'type',
  'prepaid',
  'origin',
  'name',
  'secret',
  'itg_id',
  'wholesale_id',
  'psp',
  'op_wnw',
  'wnw',
  'tariff',
  'imsi',
  'msisdn',
];

type TaggedSubscribersProps = {
  subscribers: Sim[];
};

const TaggedSubscribers = ({
  subscribers
}: TaggedSubscribersProps) => {
  const userTableSettings = useUserTableSettings('tagged-subscribers', { hiddenColumns });

  const columns = [
    ...SimColumns,
    {
      Header: 'Tags',
      accessor: 'tags',
      isSortable: false,
      Cell: ({ value }: any) => (
        <>
          {value.join(', ')}
        </>
      )
    },
    {
      Header: 'Network',
      accessor: 'network',
      Cell: ({ value }: any) => (
        <>
          {value && value?.filter((val:any) => (['lab', 'live'].includes(val))).join(', ')}
        </>
      )
    },
  ];

  const extendedSims = subscribers
    ? subscribers.map((sim:Sim) => ({
      ...sim,
      network: [sim?.lab && 'lab', sim?.live && 'live']
    }))
    : null;

  const tableData = extendedSims;

  return (
    <>
      {userTableSettings?.isFetched && (
      <Table
        actionsPosition="left"
        columns={columns}
        data={tableData ?? []}
        disablePagination
        hideRefreshButton
        initialState={userTableSettings?.initialState}
        onStateChange={userTableSettings.updateTableSettings}
        showSettingsMenu
        skipReset={false}
        stickyActions
        stickyHeader
      />
      )}
    </>
  );
};

export default TaggedSubscribers;
