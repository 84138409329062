import React, { useMemo, useState } from 'react';
import moment from 'moment';

import { useForm } from 'react-form';

import {
  Button, Modal,
} from 'react-bootstrap';

import useCreateReservation from 'hooks/services/reservation/useCreateReservation';

import CreateReservationFormFields from './CreateReservationFormFields';

const round = (
  date: any,
  duration: any,
) => moment(Math.ceil((+date) / (+duration)) * (+duration));

const CreateReservationModal = ({
  from, onHide, onSuccess, probes, reservations, selectedProbes, show, until,
}: any) => {
  const [createReservation, { isLoading, error }] = useCreateReservation({ onSuccess });
  const [probeSelectionError, setProbeSelectionError] = useState(true);

  const defaultValues = useMemo(() => {
    // align now time to nearest 15min raster in future
    const fromTime = from ? moment(from) : round(moment(), moment.duration(15, 'minutes'));

    return ({
      from: from ? moment(from).format('DD/MM/YYYY HH:mm') : moment().format('DD/MM/YYYY HH:mm'),
      until: until ? moment(until).format('DD/MM/YYYY HH:mm') : fromTime.add(2, 'hour').format('DD/MM/YYYY HH:mm'),
      probes: selectedProbes,
    });
  }, []);

  const {
    Form,
    meta: { error: formError, isValid: fieldsAreValid }
  } = useForm({
    defaultValues,
    validate: (values: any) => {
      setProbeSelectionError(values.probes && values.probes.length === 0);
      if (moment(values?.until, 'DD/MM/YYYY HH:mm').isBefore(moment(values?.from, 'DD/MM/YYYY HH:mm'))) {
        return 'The end time is before start time.';
      }
      return false;
    },
    onSubmit: (values: any) => {
      if (!isLoading) {
        values.probes.forEach((probe: any) => {
          // avoid creating reservation with "from time" in past,
          // in the worst case add 5sec to ensure backend will create it
          const fromFuture = moment(values?.from, 'DD/MM/YYYY HH:mm').isBefore(moment()) ? moment().add(5, 'second') : moment(values?.from, 'DD/MM/YYYY HH:mm');
          createReservation({
            probe_name: probe,
            reservation: {
              from: fromFuture.toISOString(),
              until: moment(values?.until, 'DD/MM/YYYY HH:mm').toISOString(),
            },
          });
        });
      }
    },
  });

  return (
    <Modal show={show} onHide={onHide} backdrop="static" size="lg">
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>New reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bu-pb-1">
            <strong>Please select the time frame and probe for your new reservation.</strong>
          </div>
          <CreateReservationFormFields
            single
            probes={probes}
            reservations={reservations}
            selectedProbes={selectedProbes}
          />
        </Modal.Body>
        <Modal.Footer>
          <div>
            <div style={{ color: 'red' }}>{formError || null}</div>
            <div style={{ color: 'red' }}>{error ? error.message : null}</div>
          </div>
          <Button disabled={isLoading || !fieldsAreValid || probeSelectionError} variant="primary" type="submit">
            Reserve
          </Button>
          <Button disabled={isLoading} variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};
export default CreateReservationModal;
