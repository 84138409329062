import React, { useEffect } from 'react';
import Channel from './Channel';
import useGetChannelValues from '../hooks/useGetChannelValues';
import useGetChannelDetails from '../hooks/useGetChannelDetails';

interface IChannels {
  attName: string;
  setErrMessage: (message: string) => void;
  max: number;
  min: number;
  name: string;
  getAttDetails: any;
  setLoading: (message: boolean) => void;
}

const FetchApi = ({ attName,
  setErrMessage,
  min,
  max,
  name,
  getAttDetails,
  setLoading, }: IChannels) => {
  const { data: channelValues, refetch: getChannelValues } = useGetChannelValues(attName);
  const { data: channelDetails } = useGetChannelDetails(attName, name);

  useEffect(() => {
    if (channelValues) {
      if (Object.entries(channelValues).length === 0) {
        setErrMessage('Error loading channel values');
      }
    }
  }, [channelValues]);

  useEffect(() => {
    if (channelValues && channelDetails) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [channelValues, channelDetails]);

  return (
    <>
      {channelValues && channelDetails && (
        <Channel
          key={name}
          name={name}
          max={max}
          min={min}
          rf_max={channelDetails.unit.rf_max}
          rf_min={channelDetails.unit.rf_min}
          level={channelValues[name]}
          attName={attName}
          getAttDetails={getAttDetails}
          getChannelValues={getChannelValues}
        />
      )}
    </>
  );
};

export default FetchApi;
