import React from 'react';

type PageTitleProps = {
  children: React.ReactNode;
  style?: any;
};

const PageTitle = ({ children, style }: PageTitleProps) => <h1 className="display-4" style={style}>{children}</h1>;

export default PageTitle;
