import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router-dom';

export const AutoLogout = () => {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const rootHref = history.createHref({ pathname: '/' });
  const keycloakLogoutRedirectUri = `${window?.location?.origin}${rootHref}`;

  useEffect(() => {
    const handleTokenExpiration = () => {
      keycloak.logout({ redirectUri: keycloakLogoutRedirectUri });
    };

    keycloak.onTokenExpired = handleTokenExpiration;

    return () => {
      keycloak.onTokenExpired = () => {};
    };
  }, [keycloak]);

  return null;
};

export default AutoLogout;
