import React, { ChangeEvent, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import { Spinner } from 'react-bootstrap';
import useEditChannelValue from '../hooks/useEditChannelValue';
import useEditChannelFade from '../hooks/useEditChannelFade';
import './Attenuator.css';

interface IChannel {
  max: number;
  min: number;
  rf_max: number;
  rf_min: number;
  name: string;
  level: number;
  attName: string;
  getAttDetails: any;
  getChannelValues: any;
}

const UPDATE_CHANNEL_INTERVAL = 2000;

export default function InputSlider({
  name,
  rf_max,
  rf_min,
  min,
  max,
  level,
  attName,
  getAttDetails,
  getChannelValues,
}: IChannel) {
  const marks = [{ value: rf_min, label: rf_min }, { value: rf_max, label: rf_max }];
  const [value, setValue] = useState<number | number[]>(level);
  const [fade, setFade] = useState(false);
  const [loading, setLoading] = useState(false);

  const [editChannelValue] = useEditChannelValue(
    {
      onSuccess: () => {
        getAttDetails();
        getChannelValues();
      },
    },
  );

  useEffect(() => {
    setValue(level);
    if ((level === min || level === max) && fade) { setFade(false); }
  }, [level]);

  useEffect(() => {
    if (level === value && !fade) { setLoading(false); }
  }, [value, level, fade]);

  useEffect(() => {
    const interval = setInterval(() => {
      getChannelValues();
    }, UPDATE_CHANNEL_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const [editChannelFade] = useEditChannelFade();

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    setValue(newValue);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
    editChannelValue({ attName, name, level: Number(event.target.value) });
  };

  const handleSliderCommittedChange = (event: any, newValue: number | number[]) => {
    setValue(newValue);
    console.log(`handleSliderCommittedChange value: ${value}`);
    editChannelValue({ attName, name, level: value });
  };

  const handleBlur = () => {
    if (value < min) {
      setValue(min);
    } else if (value > max) {
      setValue(max);
    }
  };

  const handleSetOff = () => {
    setValue(max);
    editChannelValue({ attName, name, level: 'off' });
  };
  const handleSetOn = () => {
    setValue(min);
    editChannelValue({ attName, name, level: 'on' });
  };
  const handleFadeOut = () => {
    setValue(max);
    editChannelFade({ attName, name, value: 'off' });
  };
  const handleFadeIn = () => {
    setValue(min);
    editChannelFade({ attName, name, value: 'on' });
  };

  return (
    <div className="attenuator-channel">
      <button
        type="button"
        className="btn btn-outline-danger mr-0 px-2"
        onClick={handleSetOff}
        disabled={level === max}
      >
        OFF
      </button>
      <button
        type="button"
        className="btn btn-secondary ml-3 px-2"
        onClick={handleFadeOut}
        disabled={level === max}
      >
        Fade OUT
      </button>
      <Grid container spacing={2} alignItems="center" className="my-5">
        <Grid item>
          <h3 id="input-slider" className="px-4">
            {name}
          </h3>
          <Input
            className="db-value-input"
            value={value}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: { rf_min } || 0,
              max: { rf_max } || 100,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
          <br />
          <span className="pl-4">dB</span>
        </Grid>
        <div className="channel-slider" style={{ display: 'flex' }}>
          <Slider
            orientation="vertical"
            onChange={handleSliderChange}
            onChangeCommitted={handleSliderCommittedChange}
            value={value}
            aria-label="attenuator slider"
            min={rf_min || 0}
            max={rf_max || 100}
            marks={marks}
          />
        </div>
        {(loading) && <Spinner animation="border" variant="primary" size="sm" />}
      </Grid>
      <div className="mt-0">
        <button
          type="button"
          className="btn btn-outline-success mr-0 px-2"
          onClick={handleSetOn}
          disabled={level === min}
        >
          ON
        </button>
        <button
          type="button"
          className="btn btn-secondary ml-3 px-2"
          onClick={handleFadeIn}
          disabled={level === min}
        >
          Fade IN
        </button>
      </div>
    </div>
  );
}
