import React from 'react';

import PageTitle from 'components/PageTitle';
import PoolTable from './PoolTable';

const Pools = () => (
  <>
    <PageTitle>Pools</PageTitle>
    <div className="border rounded-bottom bu-px-3 bu-py-2">
      <PoolTable />
    </div>
  </>
);

export default Pools;
