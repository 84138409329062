import _ from 'lodash';
import { useRef, useEffect } from 'react';

import useGetUserSettings from './services/user/settings/useGetUserSettings';
import useUpdateUserSettings from './services/user/settings/useUpdateUserSettings';

const updateSetting = (userSettings: any, settingId: any, value: any) => {
  if (!userSettings) {
    return {
      [settingId]: [value]
    };
  }

  const newUserSettings = { ...userSettings };

  const oldTableSettings = newUserSettings[settingId] || [];

  if (!_.isEqual(oldTableSettings, value)) {
    newUserSettings[settingId] = value;
  }

  return newUserSettings;
};

export default function useUpdateSettings(settingId: any, value?: any) {
  const latestUserSettingsRef = useRef();

  const getUserSettings = useGetUserSettings();
  const { data: userSettings, isFetched } = getUserSettings;
  const [updateUserSettings] = useUpdateUserSettings();

  useEffect(() => {
    if (userSettings) {
      latestUserSettingsRef.current = userSettings;
    }
  }, [userSettings]);

  const initialState = userSettings?.[settingId] || value;

  const updateTableSettings = (state: any) => {
    const newUserSettings = updateSetting(userSettings, settingId, state);
    console.log(newUserSettings);

    if (!_.isEqual(userSettings, newUserSettings)
      && !_.isEqual(latestUserSettingsRef.current, newUserSettings)) {
      updateUserSettings(newUserSettings);

      latestUserSettingsRef.current = newUserSettings;
    }
  };

  return {
    initialState,
    isFetched,
    updateTableSettings
  };
}
