import { useQuery } from 'react-query';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetProjects(topic:string) {
  const axios = useAxiosWithToken({ baseURL: process.env.REACT_APP_GIT });

  return useQuery(['projects', topic], () => axios.get(
    `/projects/${topic}`,
  ).then(({ data }) => data),
  { staleTime: 1000 * 60 * 5 });
}
