import { useKeycloak } from '@react-keycloak/web';
import { KeycloakTokenParsed } from 'keycloak-js';
import { useState, useEffect } from 'react';

// extended token info as provided from Dragonflies Keycloak,
// plus administrator resolution
interface ITokenParsed extends KeycloakTokenParsed {
  email?: string;
  name?: string;
  groups?: string[];
  gidNumbers?: number[];
  family_name?: string;
  given_name?: string;
  preferred_username?: string;
  uidNumber?: string;

  isAdmin: boolean;
  roles: string[];
}

// available roles:
// const availableroles = [
//   'tessaprod-roles-admin',
//   'tessaprod-roles-attenuator',
//   'tessaprod-roles-automation',
//   'tessaprod-roles-manual',
//   'tessaprod-roles-simpool-editor',
//   'tessadev-roles-admin',
//   'tessadev-roles-attenuator',
//   'tessadev-roles-automation',
//   'tessadev-roles-manual',
//   'tessadev-roles-simpool-editor',
// ];

// hook for getting user information provided by keycloak's token
const useUser = () => {
  // use default keycloak hook
  const { keycloak } = useKeycloak();
  const [user, setUser] = useState<ITokenParsed>({ isAdmin: false, roles: [] });

  // parse info from token
  useEffect(() => {
    let isAdmin = false;
    let tokenData = null;
    const roles: string[] = [];
    if (keycloak && keycloak.authenticated) {
      tokenData = keycloak.tokenParsed as ITokenParsed;
      // console.log(`keycloak token: ${JSON.stringify(tokenData)}`);

      // groups are not used anymore, isAdmin is detected based on roles
      // // detect admin user, based on groups
      // if (tokenData && Array.isArray(tokenData.groups)
      //   // Keycloak groups changed. tessadev-admin-user is not relevant any more
      //   && tokenData.groups.includes('tessadev-admin-api')) isAdmin = true;

      // detect roles
      // "resource_access": {
      //   "tessaprod": {
      //     "roles": [
      //       "tessaprod-roles-admin"
      //     ]
      //   }
      // },
      if (tokenData && tokenData.resource_access) {
        const key = Object.keys(tokenData.resource_access)
          .filter((item:string) => item === keycloak.clientId)[0];
        if (tokenData.resource_access[key]?.roles
          && Array.isArray(tokenData.resource_access[key].roles)) {
          tokenData.resource_access[key].roles.forEach((r) => {
            const role = r.split(/^tessa\w+-roles-/)[1];
            if (role) roles.push(role);
            // detect admin based on roles
            if (role.toString() === 'admin') isAdmin = true;
          });
        }
      }
    }

    // test roles:
    // roles.push('manual');
    // roles.push('attenuator');
    // roles.push('automation');
    // roles.push('simpool-editor');
    // roles.push('admin');

    setUser({ ...tokenData, isAdmin, roles });
  }, [keycloak.authenticated, keycloak.tokenParsed]);

  return user;
};

export default useUser;
