import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type StatusData = {
  name: string;
  enabled: boolean;
};

type UpdateArrayStatusRequestData = {
  status: StatusData
};

export default function useUpdateArrayStatus(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ status }: UpdateArrayStatusRequestData) => axios.put(
    `/sim-manager/api/arrays/${status.name}/status`,
    status,
  ).then(({ data }) => data), options);
}
