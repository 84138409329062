import React, { useContext, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome } from '@fortawesome/free-brands-svg-icons';
import { faPlane, faCog, faBroadcastTower, faEraser, faSync, faTools, faInfo, faRecordVinyl, faStopCircle, faPlay, faFileAudio } from '@fortawesome/free-solid-svg-icons';

import { Col, Row } from 'react-bootstrap';

import ModalConfirm from 'components/confirmModal/ModalConfirm';

import { Probe } from 'hooks/services/probe/interfaces';
import { useApiPolling, SimMapping } from 'hooks/apiPolling/apiPollingContext';
import useAudioRecordingStart from 'hooks/services/audioRecording/useAudioRecordingStart';
import useAudioRecordingStop from 'hooks/services/audioRecording/useAudioRecordingStop';
import useAudioRecordingDownload from 'hooks/services/audioRecording/useAudioRecordingDownload';
import usePlaySample from 'hooks/services/audioRecording/usePlaySample';

import ModalPortalAudio from '../../../ModalPortalAudio';

import { WsContext } from '../../context/ws';
import { WindowsManagerContext } from '../../context/windowsManager';
import { Device } from '../../context/interface';

const ProbeInfo = ({
  device,
  probe,
  winId,
} : {
  device: Device | undefined,
  probe: Probe | null,
  winId: string,
}) => {
  const [fileName, setFileName] = useState('');
  const [tempFileName, setTempFileName] = useState('');
  const [rerender, setRerender] = useState(false);
  const { sendWithCallback } = useContext(WsContext);
  const channel = device?.channel || '';
  const { mappings } = useApiPolling();
  const [mapping, setMapping] = useState<SimMapping | null>(null);
  const [confirmModal, setConfirmModal] = useState<any>(undefined);
  const [recordingStarted, setRecordingStarted] = useState<boolean>(false);
  const [recordingStopped, setRecordingStopped] = useState<boolean>(false);
  const [isDisabledPlaySample, setIsDisabledPlaySample] = useState<number>(0);
  const [timer, setTimer] = useState(60);
  const [showAudioRecordModal, setShowAudioRecordModal] = useState(false);
  const { data: download, refetch: getDownload
  } = useAudioRecordingDownload(probe?.probe_name, fileName);

  const {
    getDoc,
  } = useContext(WindowsManagerContext);

  useEffect(() => {
    if (mappings && device && probe) {
      setMapping(mappings.find(
        (value: SimMapping) => value.channel.path.includes(probe.probe_name)
      ) || null);
    }
  }, [mappings, device]);

  // compose message
  const getCmdMsg = (cmd: string): string => `{"command":"${cmd}", "timeout":10000}`;

  useEffect(() => {
    const timerInt:any = timer > 0 && recordingStarted && setInterval(() => {
      setTimer((time) => time - 1);
    }, 1000);
    return () => {
      clearInterval(timerInt);
    };
  }, [recordingStarted]);

  useEffect(() => {
    if (timer === 0) {
      setRecordingStarted(false);
      setRecordingStopped(true);
    }
  }, [timer]);

  const disableButton = () => {
    setIsDisabledPlaySample(Date.now());
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const time = Date.now();
      if (time > isDisabledPlaySample + (15000)) {
        setIsDisabledPlaySample(0);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [isDisabledPlaySample]);

  const [audioRecordingStart] = useAudioRecordingStart();

  const handleRecordingStarted = (probeName:any) => {
    const file = `audio_${new Date().toJSON().slice(0, 16)}.wav`;
    audioRecordingStart({ probeName, parameters: { filename: file, audiodevice: 'plughw:CARD=Device,DEV=0', stream: true, args: { '-d': 60 } } });
    setTempFileName(file);
    setRecordingStopped(false);
  };

  const [audioRecordingStop] = useAudioRecordingStop();

  const handleRecordingStopped = (probeName:any) => {
    audioRecordingStop({ probeName, parameters: {} });
    setRecordingStopped(true);
  };

  const [playSample] = usePlaySample();

  const handlePlaySample = (probeName:any) => {
    playSample({ probeName, parameters: { filename: 'channel_check_speech_m.wav', audiodevice: 'plughw:CARD=Device,DEV=0' } });
  };

  const handleDownload = () => {
    setFileName(tempFileName);
    setRerender(!rerender);
    getDownload();
    const url = window.URL.createObjectURL(new Blob([download], { type: 'audio/wav' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.href = '';
  };

  const fakeDownload = () => {
    setFileName(tempFileName);
    setRerender(!rerender);
    const url = window.URL.createObjectURL(new Blob([download], { type: 'audio/wav' }));
    window.URL.revokeObjectURL(url);
    setRerender(!rerender);
    setShowAudioRecordModal(true);
  };

  return (
    <>
      <Row className="bu-py-0_5 ">
        <Col>
          <span className="font-weight-bold">Actions:</span>
        </Col>
      </Row>
      <Row className="bu-mb-1" style={{ fontSize: '14px', textAlign: 'center' }}>
        <Col style={{ display: 'flex' }}>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => setConfirmModal({
                title: 'Restart Device',
                onOk: () => {
                  sendWithCallback('device.reboot', channel, () => null);
                  setConfirmModal(undefined);
                },
                body: <>Do you want to restart the device now?</>
              })}
            >
              <FontAwesomeIcon
                icon={faSync}
              />
            </button>
            <span>Restart<br />device</span>
          </div>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => setConfirmModal({
                title: 'Clean Device',
                onOk: () => {
                  sendWithCallback('shell.command', channel, () => null, getCmdMsg('pm clear com.android.chrome & rm -rR /sdcard/Download/*'));
                  setConfirmModal(undefined);
                },
                body:
                  (
                    <>
                      Do you want to clean the device now?
                      <br />Cache and download folders will be cleaned.
                    </>
                  )
              })}
            >
              <FontAwesomeIcon
                icon={faEraser}
              />
            </button>
            <span>Clean<br />device</span>
          </div>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => sendWithCallback('shell.command', channel, () => null, getCmdMsg('am start -a android.settings.SETTINGS'))}
            >
              <FontAwesomeIcon
                icon={faCog}
              />
            </button>
            <span>Settings</span>
          </div>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              disabled={Number(device?.version) <= 9 || !mapping?.mapped}
              className="btn icon-button"
              onClick={() => sendWithCallback('shell.command', channel, () => null, getCmdMsg(Number(device?.version) >= 13
                ? 'am start -n com.samsung.android.app.telephonyui/.netsettings.ui.NetSettingsActivity'
                : 'am start -S -a android.settings.NETWORK_OPERATOR_SETTINGS'))}
            >
              <FontAwesomeIcon
                icon={faBroadcastTower}
              />
            </button>
            <span>Mobile<br />networks</span>
          </div>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => {
                setRecordingStarted(!recordingStarted);
                if (recordingStarted) {
                  setRecordingStarted(false);
                  handleRecordingStopped(probe?.probe_name);
                  setTimer(60);
                } else {
                  handleRecordingStarted(probe?.probe_name);
                }
              }}
            >
              <FontAwesomeIcon
                icon={recordingStarted ? faStopCircle : faRecordVinyl}
              />
              <span style={{ fontSize: '14px' }}>{recordingStarted && ` 00:${timer < 10 ? `0${timer}` : timer}`}</span>
            </button>
            <span>{recordingStarted ? 'Stop' : 'Start'}<br />audio recording</span>
          </div>
          {(timer < 0 || recordingStopped) && (
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => {
                setRerender(!rerender);
                getDownload();
                fakeDownload();
                setRerender(!rerender);
              }}
            >
              <FontAwesomeIcon
                icon={faPlay}
              />
            </button>
            <span>Play<br />audio recording</span>
          </div>
          )}
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              disabled={isDisabledPlaySample !== 0}
              onClick={() => {
                handlePlaySample(probe?.probe_name);
                disableButton();
              }}
            >
              <FontAwesomeIcon
                icon={faFileAudio}
              />
            </button>
            {(isDisabledPlaySample !== 0) && (
              <span>Playing sample</span>) }
            {(isDisabledPlaySample === 0) && (
              <span>Play sample<br />on device</span>) }
          </div>
        </Col>
      </Row>
      <Row className="bu-pb-2 border-bottom" style={{ fontSize: '14px', textAlign: 'center' }}>
        <Col style={{ display: 'flex' }}>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              disabled={Number(device?.version) <= 9}
              className="btn icon-button"
              onClick={() => sendWithCallback('shell.command', channel, () => null, getCmdMsg('am start -S -a android.settings.AIRPLANE_MODE_SETTINGS'))}
            >
              <FontAwesomeIcon
                icon={faPlane}
              />
            </button>
            <span>Flight<br />mode</span>
          </div>
          <div style={{ margin: '4px' }} className="icon-button-container">
            <button
              type="button"
              className="btn icon-button"
              onClick={() => sendWithCallback('shell.command', channel, () => null, getCmdMsg('am start --user 0 -a android.intent.action.DIAL && input text *#0011#'))}
            >
              <FontAwesomeIcon
                icon={faTools}
              />
            </button>
            <span>Service<br />mode</span>
          </div>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => sendWithCallback('browser.open', channel, () => null, '{"url":"", "browser":"com.android.chrome/com.google.android.apps.chrome.Main"}')}
            >
              <FontAwesomeIcon
                icon={faChrome}
              />
            </button>
            <span>Chrome</span>
          </div>
          <div style={{ margin: '4px' }}>
            <button
              type="button"
              className="btn icon-button"
              onClick={() => sendWithCallback('shell.command', channel, () => null, getCmdMsg('am start -a android.settings.DEVICE_INFO_SETTINGS'))}
            >
              <FontAwesomeIcon
                icon={faInfo}
              />
            </button>
            <span>About<br />Phone</span>
          </div>

        </Col>
      </Row>
      <ModalPortalAudio
        htmlDoc={getDoc(winId)}
        htmlBackgroundElement={getDoc(winId)?.getElementById('portal')}
        show={showAudioRecordModal}
        onDownload={() => handleDownload()}
        onClose={() => {
          setShowAudioRecordModal(false);
          setRecordingStarted(false);
          setTimer(60);
        }}
        title="Play audio recording"
        download={download}
      />
      <ModalConfirm
        htmlDoc={getDoc(winId)}
        htmlBackgroundElement={getDoc(winId)?.getElementById('portal')}
        title={confirmModal?.title}
        show={confirmModal}
        onClose={() => setConfirmModal(undefined)}
        onCancel={() => setConfirmModal(undefined)}
        onOk={() => confirmModal.onOk()}
        body={confirmModal?.body}
      />
    </>
  );
};

export default ProbeInfo;
