import { useEffect, useState } from 'react';
import useGetActl from 'hooks/services/git/useGetActl';
import { Container, Row, Form, Col, InputGroup } from 'react-bootstrap';
import clsx from 'clsx';
import Step, { IStep } from './Step';

const ActlHelpPage = () => {
  const [checks, setChecks] = useState<boolean[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const {
    data,
    isLoading,
    error,
  } = useGetActl();
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (filteredData && !isLoading) {
      const arr = data.map(() => true);
      // android, yealink, modem, attenuator,  [file names = categories]
      setChecks([true, true, true, true, ...arr]);
    }
  }, [filteredData]);

  const changeCheck = (index: number) => {
    setChecks((prev) => {
      const newItems = [...prev];
      newItems[index] = !newItems[index];
      return newItems;
    });
  };

  const changeAllChecks = () => {
    setChecks((prev) => {
      const b = !prev[0];
      const newItems = prev.map(() => b);
      console.log(newItems);
      return newItems;
    });
  };

  const checkDevice = (devices: string[]) => {
    let output = false;
    devices.forEach((device) => {
      switch (device.toLowerCase()) {
        case 'android':
          if (checks[0]) output = true;
          break;
        case 'yealink':
          if (checks[1]) output = true;
          break;
        case 'modem':
          if (checks[2]) output = true;
          break;
        case 'attenuator':
          if (checks[3]) output = true;
          break;
        default:
          break;
      }
    });
    return output;
  };

  const handleSearchChange = (event: any) => {
    const value = event?.target?.value || undefined;
    console.log(`setting global filter = ${value}`);
    setGlobalFilter(value);
  };

  useEffect(() => {
    if (data) {
      setFilteredData(!globalFilter || globalFilter === '' ? data : data.map((file: any) => {
        const steps = file.steps.filter((step: any) => (
          JSON.stringify(step).toLowerCase().includes(globalFilter.toLowerCase())
        ));
        return { ...file, steps };
      }));
    }
  }, [globalFilter, data]);

  // todo: count steps
  // const getStepsCount = (files: any) => {
  //   let count = 0;
  //   files.forEach((file: any) => {
  //     count += file.steps.length;
  //   });
  //   return count;
  // };

  return (
    <Container fluid className="ml-5 mr-5">
      <h1>ACTL help page</h1>
      {isLoading ? <Row>Loading...</Row> : null}
      {error ? <Row>Error loading data.</Row> : null}
      <Row>
        <Col lg={4} md="auto" xs={12}>
          <span className="sr-only">
            <Form.Label htmlFor="table-search">Search</Form.Label>
          </span>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Search</InputGroup.Text>
            </InputGroup.Prepend>
            {/* todo: implement search */}
            <Form.Control
              onChange={handleSearchChange}
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        Devices
      </Row>
      <Row>
        <Col>
          <Form.Check
            style={{ width: '105px' }}
            inline
            label="Android"
            type="checkbox"
            checked={checks[0]}
            onChange={() => changeCheck(0)}
          />

          <Form.Check
            style={{ width: '105px' }}
            inline
            label="Yealink"
            type="checkbox"
            checked={checks[1]}
            onChange={() => changeCheck(1)}
          />

          <Form.Check
            style={{ width: '105px' }}
            inline
            label="Modem"
            type="checkbox"
            checked={checks[2]}
            onChange={() => changeCheck(2)}
          />

          <Form.Check
            style={{ width: '105px' }}
            inline
            label="Attenuator"
            type="checkbox"
            checked={checks[3]}
            onChange={() => changeCheck(3)}
          />
        </Col>
        <Col sm={6} />
      </Row>
      <Row>
        Category
      </Row>
      <Row>
        <Col>
          <>
            {filteredData ? filteredData.map((file: any, index: number) => {
              if (file) {
                return (

                  <Form.Check
                    key={file.fileName}
                    checked={checks[index + 4]}
                    style={{ width: '220px' }}
                    inline
                    label={file.fileName}
                    type="checkbox"
                    onChange={() => changeCheck(index + 4)}
                  />

                );
              }
              return null;
            }) : null}

          </>
        </Col>
        <Col className="align-self-end">
          <button
            className={clsx('text-button')}
            key="show"
            onClick={
              () => changeAllChecks()
            }
            type="button"
          >
            <u>all/none</u>
          </button>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col>Steps</Col>
        {/* todo: show filtered and all steps (intersection of two filters) */}
        {/* <Col>{`${getStepsCount(filteredData)}/${getStepsCount(data)}`}</Col>` */}
      </Row>
      <Row className="overflow-auto" style={{ height: '50%' }}>{filteredData ? filteredData.filter((item: any, index: number) => checks[index + 4]).map((file: any) => {
        if (file) {
          return (file.steps.filter((item: any) => checkDevice(item.devices)).map((step: IStep) => (
            <Step step={step} category={file.fileName} key={step.name} />))
          );
        }
        return null;
      }) : null}

      </Row>
    </Container>
  );
};

export default ActlHelpPage;

// https://gitlab01.its-telekom.eu/avengers/documber
// https://gitlab01.its-telekom.eu/avengers/actl
