export const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'title',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'startDate',
  itemTimeEndKey: 'endDate',
  groupLabelKey: 'title',
};

export const headerLabelFormats = {
  yearShort: 'YY',
  yearLong: 'YYYY',
  monthShort: 'MM.YY',
  monthMedium: 'MM.YYYY',
  monthMediumLong: 'MM.YYYY',
  monthLong: 'MM.YYYY',
  dayShort: 'D',
  dayLong: 'DD',
  hourShort: 'HH',
  hourMedium: 'HH:00',
  hourMediumLong: 'L, HH:00',
  hourLong: 'dddd, LL, HH:00',
  time: 'LLL',
};

export const subHeaderLabelFormats = {
  yearShort: 'YY',
  yearLong: 'YYYY',
  monthShort: 'MM',
  monthMedium: 'MM',
  monthLong: 'MM',
  dayShort: 'D',
  dayMedium: 'DD',
  dayMediumLong: 'DD',
  dayLong: 'DD.MM.YYYY',
  hourShort: 'HH',
  hourLong: 'HH:00',
  minuteShort: 'mm',
  minuteLong: 'HH:mm',
};

export const timeSteps = {
  second: 1,
  minute: 15,
  hour: 1,
  day: 1,
  month: 1,
  year: 1,
};

export default {
  keys,
  headerLabelFormats,
  subHeaderLabelFormats,
  timeSteps,
};
