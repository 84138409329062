import _ from 'lodash';
import { useMutation, useQueryCache } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';
import { Probe } from './interfaces';

type EditProbeRequestData = {
  probeName: string;
  probe: Probe;
};

export default function useEditProbe(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  const queryCache = useQueryCache();

  return useMutation(({ probe, probeName }: EditProbeRequestData) => axios.put(
    `/cms/api/probes/${probeName}`,
    probe,
  ).then(({ data }) => data), {
    ...options,
    onSuccess: (data, context) => {
      if (_.isFunction(options?.onSuccess)) {
        options?.onSuccess(data, context);
      }
      queryCache.invalidateQueries('probes');
    }
  });
}
