import { KeyboardEvent } from 'react';
import { CmdRequestDataType } from './hooks/useSetCommand';
import { ProbeDataType } from './types';

/**
 * @desc checkbox selected data helper func
 * @param arr
 * @param searchValue
 */
export const isSelected = (arr: any[], searchValue: any) => (
  arr?.includes(searchValue)
);

/**
 * @desc check if used keyboard key is the 'Enter'
 * @param e
 * @return boolean
 */
export const isEnter = (e: KeyboardEvent): boolean => e.key === 'Enter';

/**
 * @desc split array to chunks (=> selected test cases (tags) into test rounds)
 * @param arr
 * @param chunkSize
 */
export const chunk = (arr: any[], chunkSize: number = 1): string[][] => {
  const R = [];
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
};

/**
 * @desc prepare command for backend in json format
 * @param tags
 * @param probeData
 * @param selectedYealink
 * @param selectedModem
 * @param uuid
 * @param osix
 * @param tcpdump
 * @param recordscreen
 * @param currentUser
 */
export const prepareCommand = (
  tags: string[],
  probeData: ProbeDataType[],
  selectedYealink: string[],
  selectedModem: string[],
  uuid: string,
  osix: boolean,
  tcpdump: boolean,
  recordscreen: boolean,
  currentUser: string | undefined,
  user_id: string | undefined,
): CmdRequestDataType => {
  const prepareProbeData = (probeName: string, iccid: string) => ({
    probe_alias: probeName,
    desired_mapping: {
      iccid
    }
  });

  const prepareYealinkData = (name: string) => ({
    probe_alias: name,
  });

  const prepareModemData = (name: string) => ({
    probe_alias: name,
  });

  const allDevices = probeData
    .map((probe) => prepareProbeData(probe.probeName, probe.simData[1]));
  console.log(`allDevices ${JSON.stringify(allDevices)}`);

  const yealinkDevices = selectedYealink
    .map((yealink) => prepareYealinkData(yealink));
  console.log(`yealinkDevices ${JSON.stringify(yealinkDevices)}`);

  const modemDevices = selectedModem
    .map((modem) => prepareModemData(modem));
  console.log(`modemDevices ${JSON.stringify(modemDevices)}`);

  const cmdTags = tags.join(' or ');
  return {
    image_tag: 'avengers/htvat:latest',
    cucumber_args: `-t ${cmdTags} -f ReportPortal::Cucumber::Formatter`,
    reservation_duration: (tags.length * 15),
    test_identifier: 'htvatprod',
    user: `${currentUser}`,
    user_id: `${user_id}`,
    env_vars: `rp_uuid=${uuid};rp_launch=Tessa_test;OSIX=${osix};TCPDUMP=${tcpdump};RECORD_SCREEN=${tcpdump}`,
    filters: [...allDevices, ...yealinkDevices, ...modemDevices],
  };
};

/**
 * @filter unique values from array
 * @example [2,3,4,7,3].filter(onlyUnique)
 * @param value
 * @param index
 * @param self
 */
export const onlyUnique = (value: any, index: string | number, self: any) => (
  self.indexOf(value) === index
);

export const validateTags = (value: any) => (
  (/^(@[a-zA-Z0-9\s]+,\s*)*(@[a-zA-Z0-9\s]+){1}$/).test(value)
);
