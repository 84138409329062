import AdministrationLayout from 'components/layout/AdministrationLayout';
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link, Route, NavLink } from 'react-router-dom';
import AccountReq from './AccountReq';
// import PwdMng from './PwdMng';
import ServerStatusInfo from './ServerStatusInfo';
import SupportReq from './SupportReq';

const menuItems = [
  { title: 'Support Request', link: '/help/support', page: SupportReq },
  { title: 'Account Request', link: '/help/support/account_request', page: AccountReq },
  // password management is now done directly in Keycloak under https://auth.its-telekom.eu/auth/realms/wwtc/account/
  // { title: 'Password Management', link: '/help/support/pwd_mng', page: PwdMng },
  { title: 'Server Status Info', link: '/help/support/server_status_info', page: ServerStatusInfo },
];

const Landing = () => (
  <AdministrationLayout
    sidebar={(
      <ListGroup className="bu-mt-1_5" variant="flush">
        {menuItems.map((menuItem) => (
          <ListGroup.Item
            action
            as={NavLink}
            to={menuItem.link}
            key={menuItem.title}
            id={menuItem.title.toLowerCase().replace(' ', '-')}
            exact
          >
            {menuItem.title}
          </ListGroup.Item>
        ))}
      </ListGroup>
    )}
  >
    <ol className="breadcrumb">
      <li className="breadcrumb-item"><Link to="/help">Help</Link></li>
      <li className="breadcrumb-item"><Link to="/help/support">Support</Link></li>
      {menuItems.map((menuItem) => (
        <Route
          key={menuItem.title}
          path={menuItem.link}
          exact
          render={() => <li className="breadcrumb-item">{menuItem.title}</li>}
        />
      ))}
    </ol>
    {menuItems.map((menuItem) => (
      <Route
        key={menuItem.title}
        path={menuItem.link}
        exact
        component={menuItem.page}
      />
    ))}
  </AdministrationLayout>
);

export default Landing;
