import { useQuery } from 'react-query';

import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

export default function useGetProbes(format = 'long') {
  const axios = useAxiosWithToken();

  // get probes with devices, if format !== 'long', then without devices
  return useQuery(['probes', format], () => axios.get(
    `/cms/api/probes?format=${format}`,
  ).then(({ data }) => data.slice().map((item:any) => {
    // translate from "defective" to "maintenance"
    const status = item.status === 'defective' ? 'maintenance' : item.status;
    return ({
      ...item,
      status,
    });
  }).sort((a:any, b:any) => {
    if (a.probe_name < b.probe_name) return -1;
    if (a.probe_name > b.probe_name) return 1;
    return 0;
  })), { staleTime: 1000 * 60 * 5 });
}
