import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import moment from 'moment';
import useConfirm from 'hooks/useConfirm';
import ResourcesModal from './ResourcesModal';

import useDeleteQueuedStatus from '../../hooks/services/scheduler/useDeleteQueuedStatus';
import useRemoveQueuedStatus from '../../hooks/services/scheduler/useRemoveQueuedStatus';

interface IOverviewProps {
  queuedStatuses: any,
  getQueuedStatuses: any,
  users: any,
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
}

const QueuedJobs = ({
  queuedStatuses,
  getQueuedStatuses,
  users,
  setMessage,
  setErrMessage
}: IOverviewProps) => {
  const [showResourcesModal, setShowResourcesModal] = useState(false);
  const [jobUuid, setJobUuid] = useState<string>('');

  const confirm = useConfirm();

  const [deleteQueuedStatus] = useDeleteQueuedStatus(
    {
      onSuccess: () => {
        setMessage('Queued Job Aborted');
        getQueuedStatuses();
      },
      onError: () => setErrMessage('Failed to abort queued job'),
    },
  );

  const handleDeleteQueuedStatus = (id: any) => {
    confirm({
      body: 'Do you want to abort job?',
      onOk: () => deleteQueuedStatus({ id }),
      title: 'Abort job',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  const [removeQueuedStatus] = useRemoveQueuedStatus(
    {
      onSuccess: () => {
        setMessage('Job retried');
        getQueuedStatuses();
      },
      onError: () => setErrMessage('Failed to retry job'),
    },
  );

  const handleRemoveQueuedStatus = (id: any) => {
    confirm({
      body: 'Do you want to retry queued job?',
      onOk: () => removeQueuedStatus({ id }),
      title: 'Retry queued job',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  useEffect(() => {
    getQueuedStatuses();
  }, []);

  return (
    <>
      {queuedStatuses?.length < 1
        ? <span>Currently there are no jobs for your user</span> : (
          queuedStatuses?.map((job: any) => (
            <Row className="border rounded p-3">
              <Col>
                <Row>
                  <strong>Details:</strong>
                </Row>
                <Row>
                  Approval ID:   {job.approval_id}
                </Row>
                <Row>
                  Created by:   {users?.filter(
                  (item:any) => item.uid.toString() === job.arguments[0].user_id
                )[0].username}
                </Row>
                <Row>
                  Schedule ID:   {job.arguments[0].schedule_id || 'N/A'}
                </Row>
                <Row>
                  Campaign Name:   {job.arguments[0]
                  .schedule_id && job.arguments[0]
                  .schedule_name ? job.arguments[0].schedule_name : 'N/A'}
                </Row>
                {job.jail_status && (
                <>
                  <Row>
                    Times jailed:   {job.jail_status.times_jailed}
                  </Row>
                  <Row>
                    Jailed at first:   {moment(job.jail_status.jailed_at_first).utc(true).format('YYYY-MM-DD H:mm:ss')}
                  </Row>
                </>
                )}
              </Col>
              <Col>
                <Row>
                  <strong>Show:</strong>
                </Row>
                <Row>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                      setShowResourcesModal(true);
                      setJobUuid(job.approval_id);
                    }}
                  >
                    Resources
                  </button>
                  <button type="button" className="btn btn-outline-primary btn-sm" disabled>Logs</button>
                </Row>
                <Row>
                  <button type="button" className="btn btn-outline-primary btn-sm" disabled>Devices</button>
                </Row>
              </Col>
              <Col>
                <Row>
                  <strong>Job Execution Status:</strong>
                </Row>
                {job.jail_status && (
                  typeof job.jail_status.reason === 'string' ? (
                    <p>{job.jail_status.reason}</p>
                  ) : (
                    <>
                      {job.jail_status.reason.map((item: string[]) => (
                        <Row>{item[0]}</Row>
                      ))}
                    </>
                  )
                )}
                {!job.jail_status && <Row style={{ marginTop: '1rem' }}>Awaiting approval</Row>}
                <Row>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    style={{ marginTop: '1rem' }}
                    onClick={() => {
                      handleRemoveQueuedStatus(job.approval_id);
                    }}
                  >
                    Retry
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary btn-sm"
                    style={{ marginTop: '1rem' }}
                    onClick={() => {
                      handleDeleteQueuedStatus(job.approval_id);
                    }}
                  >
                    Abort
                  </button>
                </Row>
              </Col>
            </Row>
          )))}
      {showResourcesModal
                && (
                  <ResourcesModal
                    onHide={() => setShowResourcesModal(false)}
                    show={showResourcesModal}
                    queuedStatuses={queuedStatuses}
                    statuses={null}
                    jobUuid={jobUuid}
                  />
                )}
    </>
  );
};

export default QueuedJobs;
