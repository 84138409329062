import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button, Card, Col, Form, FormControl, FormGroup, Modal,
} from 'react-bootstrap';
import useForm from '../../../../../hooks/useForm';
import { ISimArray } from '../../../../../context/backendData/interfaces';
import useDeleteArray from '../../../../../hooks/services/simManagement/array/useDeleteArray';
import useAddArray from '../../../../../hooks/services/simManagement/array/useAddArray';

interface IModalProps {
  show: boolean
  onHide: () => void;
  arrayData: ISimArray;
  getSimArrays: any;
  setMessage: (msg: string) => void;
  setErrMessage: (msg: string) => void;
}

type FormErrors = {
  name?: string;
};

const EditSimArrayModal = ({
  onHide, show, arrayData, getSimArrays, setErrMessage, setMessage,
}: IModalProps) => {
  const [showError, setShowError] = useState(true);

  // UPDATE ARRAY - it means in fact to delete it and create a new one
  const [deleteArray] = useDeleteArray({
    onError: () => setErrMessage('Failed to update SIM array'),
  });
  const [updateArray, { error }] = useAddArray({
    onSuccess: () => {
      setMessage('SIM array has been updated!');
      getSimArrays();
      onHide();
    },
    onError: () => setErrMessage('Failed to update SIM array'),
  });

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      name: arrayData.name,
      internalIPAddress: arrayData.internalIPAddress,
      internalIPPort: arrayData.internalIPPort,
      externalIPAddress: arrayData.externalIPAddress,
      externalIPPort: arrayData.externalIPPort,
      maxNumberOfBoards: arrayData.maxNumberOfBoards,
    },
    onSubmit: () => {
      // todo remove this stupid condition when possible to switch
      // eslint-disable-next-line no-constant-condition
      if (1 < 2) {
        setErrMessage('sorry, we cannot update arrays for now');
      } else {
        deleteArray({ arrayName: arrayData.name })
          .then(() => updateArray({
            array: {
              name: formValues.name,
              internalIPAddress: formValues.internalIPAddress,
              internalIPPort: formValues.internalIPPort,
              externalIPAddress: formValues.externalIPAddress,
              externalIPPort: formValues.externalIPPort,
              maxNumberOfBoards: formValues.maxNumberOfBoards,
            },
          }));
      }
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.name || values?.name === '') {
        errors.name = 'Please fill in the name.';
      }
      return errors;
    },
  });

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="xl"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header>
                Edit SIM array
                <button type="button" className="close" onClick={onHide} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  {error?.message && showError && (
                    <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                      {error?.message}
                    </Alert>
                  )}
                  <Form.Row>
                    <Form.Group as={Col} md={3}>
                      <Form.Label htmlFor="name">Name</Form.Label>
                      <FormControl
                        autoFocus
                        id="name"
                        isInvalid={submitted && Boolean(formErrors?.name)}
                        name="name"
                        onChange={handleChange('name')}
                        required
                        type="text"
                        value={formValues?.name}
                      />
                      {submitted && Boolean(formErrors?.name) && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.name}
                        </FormControl.Feedback>
                      )}
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="internalIPAddress">
                      <Form.Label htmlFor="internalIPAddress">
                        internal IP Address
                      </Form.Label>
                      <FormControl
                        id="internalIPAddress"
                        disabled
                        name="internalIPAddress"
                        required
                        type="text"
                        value={formValues?.internalIPAddress}
                      />
                      {submitted && formErrors?.internalIPAddress && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.internalIPAddress}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup key="internalIPPort" className="ml-2">
                      <Form.Label htmlFor="internalIPPort">
                        internal IP Port
                      </Form.Label>
                      <FormControl
                        id="internalIPPort"
                        disabled
                        name="internalIPPort"
                        required
                        type="text"
                        value={formValues?.internalIPPort}
                      />
                      {submitted && formErrors?.internalIPPort && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.internalIPPort}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="externalIPAddress">
                      <Form.Label htmlFor="externalIPAddress">
                        external IP Address
                      </Form.Label>
                      <FormControl
                        id="externalIPAddress"
                        disabled
                        name="externalIPAddress"
                        required
                        type="text"
                        value={formValues?.externalIPAddress}
                      />
                      {submitted && formErrors?.externalIPAddress && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.externalIPAddress}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup key="externalIPPort" className="ml-2">
                      <Form.Label htmlFor="externalIPPort">
                        external IP Port
                      </Form.Label>
                      <FormControl
                        id="externalIPPort"
                        name="externalIPPort"
                        disabled
                        required
                        type="text"
                        value={formValues?.externalIPPort}
                      />
                      {submitted && formErrors?.externalIPPort && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.externalIPPort}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <Form.Row>
                    <FormGroup key="maxNumberOfBoards">
                      <Form.Label htmlFor="maxNumberOfBoards">
                        max number of boards
                      </Form.Label>
                      <FormControl
                        id="maxNumberOfBoards"
                        disabled
                        // isInvalid={submitted && Boolean(formErrors?.maxNumberOfBoards)}
                        name="maxNumberOfBoards"
                        // onChange={handleChange('maxNumberOfBoards')}
                        required
                        type="text"
                        value={formValues?.maxNumberOfBoards}
                      />
                      {submitted && formErrors?.maxNumberOfBoards && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.maxNumberOfBoards}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <div className="text-right">
                    <Button className="btn btn-secondary" style={{ color: 'black' }} onClick={onHide}>Close</Button>
                    <Button className="btn btn-primary" type="submit">Save</Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default EditSimArrayModal;
