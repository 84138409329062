import _ from 'lodash';
import React, {
  useCallback, useState, useRef, ReactNode,
} from 'react';
import PropTypes from 'prop-types';

import scrollToTop from 'utils/scrollToTop';

type Alert = {
  id: string;
  content: ReactNode,
  heading?: ReactNode,
  variant?: 'danger' | 'info' | 'success' | 'warning';
  persist?: boolean;
  persistOnce?: boolean;
};

type AlertsContextValue = {
  alerts: Alert[];
  dismissAlert: (id?: string) => void;
  dismissNonPersistAlerts: () => void;
  dismissAllAlerts: () => void;
  showAlert: (alert?: Alert) => void;
};

const initialValue: AlertsContextValue = {
  alerts: [],
  dismissAlert: () => {
    Error('dismissAlert used outside AlertsProvider!');
  },
  dismissNonPersistAlerts: () => {
    Error('dismissNonPersistAlerts used outside AlertsProvider!');
  },
  dismissAllAlerts: () => {
    Error('dismissAllAlerts used outside AlertsProvider!');
  },
  showAlert: () => {
    Error('showAlert used outside AlertsProvider!');
  },
};

const AlertsContext = React.createContext(initialValue);

const AlertsProvider: React.FC = ({ children }) => {
  const timeouts: any = useRef({});
  const [alerts, setAlerts] = useState(initialValue.alerts);

  const dismissAlert = useCallback((id) => {
    setAlerts((state) => _.reject(state, ['id', id]));
  }, []);

  const showAlert = useCallback((alert) => {
    setTimeout(() => {
      scrollToTop();
    }, 50);
    setAlerts((state) => {
      const existingAlertIndex = _.findIndex(state, ['id', alert?.id]);

      if (existingAlertIndex > -1) {
        const newState = [...state];

        newState[existingAlertIndex] = alert;

        return newState;
      }
      return [...state, alert];
    });

    if (!(alert?.persist || alert?.persistOnce)) {
      clearTimeout(timeouts.current[alert.id]);
      timeouts.current[alert.id] = setTimeout(() => {
        dismissAlert(alert?.id);
        clearTimeout(timeouts.current[alert.id]);
      }, 10000);
    }
  }, [dismissAlert]);

  const dismissNonPersistAlerts = useCallback(() => {
    setAlerts((state) => _.chain(state)
      .reject(({ persist, persistOnce }) => (!(persist || persistOnce)))
      .map(({ persistOnce, ...alert }) => alert)
      .value());
  }, []);

  const dismissAllAlerts = useCallback(() => {
    setAlerts([]);
  }, []);

  const value = {
    alerts,
    dismissAlert,
    dismissNonPersistAlerts,
    dismissAllAlerts,
    showAlert,
  };

  return (
    <AlertsContext.Provider
      value={value}
    >
      {children}
    </AlertsContext.Provider>
  );
};

AlertsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  AlertsContext,
};

export default AlertsProvider;
