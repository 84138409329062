import React, { useEffect, useState } from 'react';
import {
  Alert, Button, Card, Form, FormControl, FormGroup, Modal,
} from 'react-bootstrap';
import useForm from '../../../../hooks/useForm';
import useAddProbeLocation from '../../../../hooks/services/probe/location/useAddProbeLocation';

interface ILocation {
  country_iso: string;
  country_name: string;
}

interface IModalProps {
  show: boolean
  onHide: () => void;
  setErrMessage: (message: string) => void;
  setMessage: (message: string) => void;
  getLocations: any;
}

type FormErrors = {
  country_iso?: string;
  country_name?: string;
};

const AddLocationModal = ({
  onHide, show, setErrMessage, setMessage, getLocations,
}: IModalProps) => {
  const [showError, setShowError] = useState(true);

  const [addLocation, { error }] = useAddProbeLocation(
    {
      onSuccess: () => {
        setMessage('Added new location');
        getLocations();
        onHide();
      },
      onError: () => setErrMessage('unable to add client to location'),
    },
  );

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      locationName: '',
    },
    onSubmit: (location: ILocation) => {
      addLocation(location);
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.country_name || values?.country_name === '') {
        errors.country_name = 'Please fill in location name.';
      }
      if (!values?.country_iso || values?.country_iso === '') {
        errors.country_iso = 'Please fill in location ISO.';
      }
      return errors;
    },
  });

  // show error if error changes
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={onHide}
          centered
          size="xl"
          backdrop="static"
        >
          <Modal.Body>
            <Card className="form-input">
              <Card.Header>
                Add new location
                <button type="button" className="close" onClick={onHide} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={handleSubmit}>
                  {error?.message && showError && (
                    <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
                      {error?.message}
                    </Alert>
                  )}
                  <Form.Row>
                    <FormGroup key="country_name">
                      <Form.Label htmlFor="country_name">
                        Country name
                      </Form.Label>
                      <FormControl
                        id="country_name"
                        isInvalid={submitted && Boolean(formErrors?.country_name)}
                        name="country_name"
                        onChange={handleChange('country_name')}
                        required
                        type="text"
                        value={formValues?.country_name}
                      />
                      {submitted && formErrors?.country_name && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.country_name}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                    <FormGroup key="country_iso">
                      <Form.Label htmlFor="country_iso">
                        Country ISO
                      </Form.Label>
                      <FormControl
                        id="country_iso"
                        isInvalid={submitted && Boolean(formErrors?.country_iso)}
                        name="country_iso"
                        onChange={handleChange('country_iso')}
                        required
                        type="text"
                        value={formValues?.country_iso}
                      />
                      {submitted && formErrors?.country_iso && (
                        <FormControl.Feedback type="invalid">
                          {formErrors?.country_iso}
                        </FormControl.Feedback>
                      )}
                    </FormGroup>
                  </Form.Row>
                  <div className="text-right">
                    <Button className="btn btn-secondary" style={{ color: 'black' }} onClick={onHide}>Close</Button>
                    <Button className="btn btn-primary" type="submit">Save</Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddLocationModal;
