import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import clsx from 'clsx';
import { WsContext } from '../context/ws';

type Key = {
  command: string;
  label: string;
  icon: string;
};

const basicKeys = [
  {
    command: 'menu',
    label: 'Menu',
    icon: 'icon-list-view',
  },
  {
    command: 'home',
    label: 'Home',
    icon: 'icon-home',
  },
  {
    command: 'app_switch',
    label: 'App Switch',
    icon: 'icon-tile-view',
  },
  {
    command: 'back',
    label: 'Back',
    icon: 'icon-reply',
  },
];

// phone hadrware & additional buttons/keys
const BasicButtons = ({ channel }: { channel: string }) => {
  const { cmdWs } = useContext(WsContext);

  const sendMsg = (msg: string) => {
    if (cmdWs && cmdWs.OPEN) {
      cmdWs.send(msg);
    }
  };

  const sendKeyDown = (key: string) => {
    sendMsg(`42["input.keyDown","${channel}",{"key":"${key}"}]`);
  };

  const sendKeyUp = (key: string) => {
    sendMsg(`42["input.keyUp","${channel}",{"key":"${key}"}]`);
  };

  const getIcon = (icon: string) => (
    <i className={clsx('icon', icon)} />
  );

  const createPhoneButton = (key: Key) => (
    <Button
      className="customTooltip custom-basic-button"
      key={key.label}
      onMouseDown={() => sendKeyDown(key.command)}
      onMouseUp={() => sendKeyUp(key.command)}
      style={{ width: '25%', height: '2.5rem' }}
    >
      {getIcon(key.icon)}
    </Button>
  );

  return (
    <div className="basic-buttons">
      {basicKeys.map((key) => createPhoneButton(key))}
    </div>
  );
};
export default BasicButtons;
