import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type PauseScheduledJobData = {
  scheduleId: number;
};

export default function usePauseScheduledJob(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ scheduleId }: PauseScheduledJobData) => axios.put(
    `/scheduler/api/schedule/${scheduleId}/pause`,
  ).then(({ data }) => data), options);
}
