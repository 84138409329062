import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import ReactDOM from 'react-dom';
import { WindowState } from '../context/interface';

interface Props {
  // window name showed in title (if supported by browser)
  title: string;
  // callback for closing window
  closeWindow: (winId: string) => void;
  onWindowResize: (winId: string, width: number, height: number) => void;
  state: WindowState;
  setWin: (winId: string, extWin: Window) => void;
  winId: string;
}

const Popout: FunctionComponent<Props> = (
  {
    title, closeWindow, children, onWindowResize, setWin, winId,
  }: React.PropsWithChildren<Props>,
) => {
  const [containerElem, setContainerElement] = useState<HTMLDivElement | null>();
  const [externalWindow, setExternalWindow] = useState<Window | null>();

  // copy all CSS styles from source Document to target Document
  const copyStyles = (sourceDoc: Document, targetDoc: Document) => {
    Array.from(sourceDoc.styleSheets).forEach((styleSheet: CSSStyleSheet) => {
      try {
        if (styleSheet.cssRules) {
          const newStyleEl = sourceDoc.createElement('style');

          if (!styleSheet.cssRules.item(0)?.cssText.startsWith('html { background: url("data:')) {
            Array.from(styleSheet.cssRules).forEach((cssRule) => {
              let css = cssRule.cssText;
              css = css.replaceAll('url("/', `url("${window.location.origin}/`);
              newStyleEl.appendChild(sourceDoc.createTextNode(css));
            });
          }
          targetDoc.head.appendChild(newStyleEl);
        } else {
          const newLinkEl = sourceDoc.createElement('link');
          console.log(`linked css: ${styleSheet.href}`);
          if (styleSheet.href) {
            newLinkEl.rel = 'stylesheet';
            newLinkEl.href = styleSheet.href;
            targetDoc.head.appendChild(newLinkEl);
          }
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  // register resize event when externalWindow exists
  useEffect(() => {
    if (externalWindow) {
      externalWindow.addEventListener('resize', () => {
        // handle window resize - call parent
        onWindowResize(winId, externalWindow.innerWidth, externalWindow.innerHeight);
      });
    }
  }, [externalWindow, onWindowResize, winId]);

  // open a new window on component mount
  useEffect(() => {
    // init size of new window
    const width = 1100;
    const height = 830;
    const y = window.top.outerHeight / 2 + window.top.screenY - (height / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - (width / 2);
    // new window features
    const features = `height=${height}, width=${width}, top=${y}, left=${x}, toolbar=no, menubar=no, resizable=no, location=0, titlebar=0, status=0, menubar=0`;
    const win = window.open('', '_blank', features);

    let el = null;

    if (win) {
      copyStyles(document, win.document);
      // set window title
      win.document.title = title;
      // containerElement = win.document.createElement('div');
      el = document.createElement('div');
      el.id = 'portal';
      // win.document.body.firstChild?.remove();
      win.document.body.appendChild(el);
      // modal
      const modal = document.createElement('div');
      modal.id = 'modal';
      win.document.body.appendChild(modal);
      // call parent before unload
      win.addEventListener('beforeunload', () => closeWindow(winId));
      // remember win
      setExternalWindow(win);
      // register window in windows manager
      setWin(winId, win);
      setContainerElement(el);
    }
  }, [closeWindow, setWin, title, winId]);

  return (
    // render component's children into the popout window
    containerElem ? ReactDOM.createPortal(children, containerElem) : null
  );
};
export default Popout;
