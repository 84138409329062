import _ from 'lodash';
import React, {
  FunctionComponent, useEffect, useState, useRef, useCallback, createContext,
} from 'react';
import uuid from 'uuid';
import { useKeycloak } from '@react-keycloak/web';
import useGetStfDevice from 'hooks/services/stf/useGetStfDevices';
import useGetStfUser from 'hooks/services/stf/useGetStfUser';
import {
  Device, IWsContext,
} from './interface';

interface WsProps {
}

// websocket packet types
enum PacketTypes {
  unsupported = -1,
  open = 0, // non-ws
  close = 1, // non-ws
  ping = 2,
  pong = 3,
  message = 4,
  upgrade = 5,
  noop = 6,
}

type Packet = {
  type: PacketTypes,
  data?: any,
  secondDigit?: number,
};

type OpenMsg = {
  sid: string, upgrades: any[], pingInterval: number, pingTimeout: number
};

type Subscriber = {
  id: string,
  callback: (msg: string) => any,
};

type Msg = {
  body?: any,
  data: any,
  progress?: number,
  seq: number,
  source: string,
  success?: boolean,
};

type MsgReceive = {
  type: string,
  id: string,
  msg: Msg,
};

type MsgSend = {
  id: string,
  msg: string,
};

const WsContext = createContext({} as IWsContext);

const WsProvider: FunctionComponent<WsProps> = (
  {
    children,
  }: React.PropsWithChildren<WsProps>,
) => {
  const [devices, setDevices] = useState<Device[]>([]);
  const { data: restDevices } = useGetStfDevice();
  const {
    data: user,
  } = useGetStfUser();

  const { keycloak } = useKeycloak();

  const [cmdWs, setcmdWs] = useState<WebSocket | undefined>();
  const pingIntervalTimer = useRef<number | undefined>();
  const pingTimeoutTimer = useRef<number | undefined>();

  const [pingInterval, setPingInterval] = useState(25000);
  const [pingTimeout, setPingTimeout] = useState(5000);

  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [sendQue, setSendQue] = useState<MsgSend[]>([]);
  const [rcvQue, setRcvQue] = useState<MsgReceive[]>([]);

  const reconnectTime = 5000;

  const sendWithCallback = (
    msg: string, channel: string, callback: (msg: any) => void, data?: string,
  ): string => {
    const sessionId = uuid.v4();
    setSubscribers((prevState) => [...prevState, { id: sessionId, callback }]);
    // todo: escape data
    const dataToSend = data || null;

    setSendQue((prevState) => [...prevState, {
      id: sessionId,
      msg: `42["${msg}","${channel}","${sessionId}",${dataToSend}]`,
    }]);
    return sessionId;
  };

  useEffect(() => {
    if (subscribers && subscribers.length > 0 && rcvQue && rcvQue.length > 0) {
      let idsToRemove: string[] = [];
      // todo: on tx.done merge all messages to one
      rcvQue.filter((n:MsgReceive, i:number) => rcvQue.indexOf(n) === i).forEach((item) => {
        if (item.type !== 'tx.progress') {
          subscribers.forEach((sub) => {
            if (sub.id === item.id) {
              idsToRemove = [...idsToRemove, sub.id];
              const message = rcvQue.filter((msg:MsgReceive) => msg.id === sub.id)
                .map(((val:MsgReceive) => (val.msg?.data ? val.msg?.data : '')));
              sub.callback(JSON.stringify(message));
            }
          });
        }
      });

      // if idsToRemove is empty don't update state with same data as it will create infinite loop
      if (!_.isEmpty(idsToRemove)) {
        setSubscribers((prevState) => prevState.filter((item) => !idsToRemove.includes(item.id)));
        setRcvQue((prevState) => prevState.filter((item) => !idsToRemove.includes(item.id)));
      }
    }
  }, [subscribers, rcvQue]);

  useEffect(() => {
    // todo: is it safe? what happens when new item is queued?
    const que = [...sendQue];
    if (que && que.length > 0) {
      if (cmdWs) {
        setSendQue([]);
        que.map((item) => cmdWs.send(item.msg));
      }
    }
  }, [sendQue, cmdWs]);

  const connect = useCallback(() => {
    if (user) {
      // setcmdWs(new WebSocket(`ws://${ip}/stfWs/socket.io/?uip=${user && encodeURIComponent(user.ip)}&EIO=3&transport=websocket`));
      const pat = /^https?:\/\//i;
      const wssProcotol = window.location.origin.indexOf('https') < 0 ? 'ws' : 'wss';
      // /^https?:\/\/|^\/\//i
      // full URL
      let url = '';
      if (process.env.REACT_APP_STF_WS && pat.test(process.env.REACT_APP_STF_WS)) {
        url = process.env.REACT_APP_STF_WS;
      } else { // relative URL
        url = `${wssProcotol}://${window.location.host}${process.env.REACT_APP_STF_WS}`;
      }
      setcmdWs(new WebSocket(`${url}/socket.io/?uip=${user && encodeURIComponent(user.ip)}&EIO=3&transport=websocket`));
    }
  }, [user]);

  useEffect(() => {
    if (restDevices && user) {
      setDevices(restDevices.devices);
      connect();
    }
  }, [restDevices, user]);

  const decodePacket = (
    packetData: string,
  ): Packet => {
    try {
      let data: string = packetData;
      // get packet type
      const packetType: PacketTypes = Number(data.charAt(0));
      data = data.slice(1);
      // get second digit if any (what the hell the second digit can mean??)
      let secondDigit = 0;
      if (packetType === PacketTypes.message) {
        secondDigit = Number(data.charAt(0));
        data = data.slice(1);
      }
      // assume that the number has only two digits...
      // if data follows
      if (secondDigit > 0) {
        return { type: packetType, secondDigit, data: JSON.parse(data) };
      }
      return { type: packetType, secondDigit };
    } catch (e: any) {
      console.log('unable to parse incoming packet:', packetData, 'error: ', e.message);
    }
    return { type: PacketTypes.noop, secondDigit: 0 };
  };

  const sendPacket = useCallback((packet: Packet) => {
    if (cmdWs && cmdWs.readyState) {
      cmdWs.send(`${packet.type}`);
    }
  }, [cmdWs]);
  // send a ping packet
  const ping = useCallback(() => {
    sendPacket({ type: PacketTypes.ping });
  }, [sendPacket]);

  // set ping timeout
  const onHeartbeat = useCallback((timeout: number) => {
    clearTimeout(pingTimeoutTimer.current!);
    pingTimeoutTimer.current = window.setTimeout(() => {
      if (cmdWs && WebSocket.CLOSED === cmdWs.readyState) return;
      console.log('ws ping response timeout');
      if (cmdWs) cmdWs.close();
    }, timeout || (pingInterval + pingTimeout));
  }, [pingInterval, pingTimeout, cmdWs]);

  // pings server periodically and expects response
  const setPing = useCallback(() => {
    clearTimeout(pingTimeoutTimer.current);
    clearInterval(pingIntervalTimer.current!);
    pingIntervalTimer.current = window.setInterval(() => {
      ping();
      onHeartbeat(pingTimeout);
    }, pingInterval);
  }, [pingTimeout, pingInterval, onHeartbeat, ping]);

  useEffect(() => {
    if (cmdWs && cmdWs.OPEN) setPing();
  }, [cmdWs, pingTimeout, pingInterval, setPing]);

  const handleUpdateDevices = useCallback((elem: any) => {
    // todo: handle on the fly device addition
    setDevices((oldDevices: Device[]) => {
      const tmpDevices = oldDevices.map((dev: Device) => (
        dev.serial === elem.serial ? {
          ...dev, ...elem,
        } : dev
      ));
      return (tmpDevices);
    });
  }, [setDevices]);

  useEffect(() => {
    if (cmdWs) {
      cmdWs.onopen = () => {
        const msg = `42["tessa.token","${keycloak?.token}"]`;
        cmdWs.send(msg);
      };
      cmdWs.onmessage = (msg) => {
        // console.log('msg in:', msg.data);
        const packet = decodePacket(msg.data);
        switch (packet.type) {
          case PacketTypes.pong:
            clearTimeout(pingTimeoutTimer.current!);
            break;
          case PacketTypes.open:
            if (packet.data) {
              const openMsg: OpenMsg = packet.data;
              if (openMsg) {
                setPingInterval(openMsg.pingInterval);
                setPingTimeout(openMsg.pingTimeout);
              }
            }
            break;
          case PacketTypes.message:
            if (packet.data) {
              const msgType = packet.data[0];
              if (typeof msgType === 'string') {
                switch (msgType) {
                  case 'socket.ip':
                    break;
                  case 'device.change':
                    handleUpdateDevices(packet.data[1].data);
                    break;
                  case 'device.add':
                    console.log('add');
                    handleUpdateDevices(packet.data[1].data);
                    break;
                  case 'device.remove':
                    console.log('removed');
                    // set ready to false to indicate device removal
                    handleUpdateDevices({
                      ...packet.data[1].data,
                      ready: false,
                    });
                    break;
                  case 'device.log':
                    break;
                  case 'tx.done':
                    cmdWs.send(`42["tx.cleanup","${packet.data[1]}"]`);
                    if (rcvQue.filter((rcv:MsgReceive) => rcv.id === packet.data[1]).length) {
                      setRcvQue((prevState) => prevState.map((item:MsgReceive) => {
                        if (item.id === packet.data[1]) {
                          return ({
                            type: packet.data[0],
                            id: packet.data[1],
                            msg: item.msg,
                          });
                        }
                        return item;
                      }));
                    } else {
                      setRcvQue((prevState) => [...prevState, {
                        type: packet.data[0],
                        id: packet.data[1],
                        msg: packet.data[2],
                      }]);
                    }
                    break;
                  case 'tx.progress':
                    if (rcvQue.filter((rcv:MsgReceive) => rcv.id === packet.data[1]).length) {
                      setRcvQue((prevState) => [...prevState
                        .filter((item:MsgReceive) => item.id === packet.data[1]),
                      ...prevState.filter((item:MsgReceive) => [{
                        ...item,
                        msg: {
                          ...item.msg,
                          data: `${item?.msg?.data} ${packet.data[2]?.msg?.data}`,
                        },
                      }])
                      ]);
                    } else {
                      setRcvQue((prevState) => [...prevState, {
                        type: packet.data[0],
                        id: packet.data[1],
                        msg: packet.data[2],
                      }]);
                    }
                    break;
                  case 'tx.cancel':
                    break;
                  default:
                    break;
                }
              }
            }
            break;
          default:
            break;
        }
      };
      cmdWs.onclose = (e) => {
        console.log(`Socket is closed. Reconnect will be attempted in ${reconnectTime} ms.`, e.reason);
        // clear timers
        clearInterval(pingIntervalTimer.current!);
        clearTimeout(pingTimeoutTimer.current!);

        setTimeout(() => {
          connect();
        }, reconnectTime);
      };
      cmdWs.onerror = (err: Event) => {
        console.error('Socket encountered error: ', err, 'Closing socket');
        if (cmdWs) cmdWs.close();
      };
    }
  }, [cmdWs, connect, handleUpdateDevices]);

  return (
    <WsContext.Provider value={{ cmdWs, sendWithCallback, devices }}>
      {children}
    </WsContext.Provider>
  );
};
const RestConsumer = WsContext.Consumer;
export { WsProvider, RestConsumer, WsContext };
