// Interfaces
enum ElementType {
  text = 0,
  string,
  number,
  specialType,
  unknown,
}

export interface IStepElement {
  content: string | null;
  type: ElementType;
  typeKey: string;
  selectedValueIndex: number | null;
  isActive: boolean;
  key: string;
}

export interface ITag {
  id: string;
  text: string;
  editable?: boolean;
}

// TODO temporary out of use
export interface IStepDefiniton {
  // id: number;
  text: string;
}

export interface IStepDefinitionList {
  name: string;
  data: string[];
}

export interface IStep {
  id: string;
  key: string;
  elements: IStepElement[];
  isSelected: boolean;
}

export interface IScenario {
  id: number;
  order: number;
  name: string;
  tags: ITag[];
  steps: IStep[];
  isSelected: boolean;
}

export interface IFeatureFile {
  id: number;
  order: number;
  name: string;
  description: string;
  tags: ITag[];
  scenarios: IScenario[];
  isSelected: boolean;
}

export interface ISelected {
  ffId: number,
  scId: number,
  stepId: string,
}

export interface IGherkinContext {
  tags: ITag[];
  availableSteps: IStepDefinitionList[];
  displayedSteps: IStepDefinitionList[];
  featureFiles: IFeatureFile[];
  error: string;
  selected: ISelected;
  clearErrors(): void;
  addAvailableTag(tag: ITag): void;
  removeAvailableTag(tagId: number): void;
  updateAvailableTag(tag: ITag): void;
  addAvailableStep(step: IStep): void;
  removeAvailableStep(stepId: number): void;
  updateAvailableStep(step: IStep): void;
  createStep(ffId: number, scId: number, step: IStep): void;
  updateStepList(ffId: number, scId: number, steps: IStep[]): void;
  deleteStep(ffId: number, scId: number, stepId: string): void;
  createScenario(ffId: number, scenario: IScenario): void;
  updateScenario(ffId: number, scenario: IScenario): void;
  deleteScenario(ffId: number, scId: number): void;
  addScenarioTag(ffId: number, scId: number, tag:ITag): void;
  deleteScenarioTag(ffId: number, scId: number, tagId: string): void;
  createFeatureFile(ff: IFeatureFile): void;
  updateFeatureFile(ff: IFeatureFile): void;
  deleteFeatureFile(ffId: number): void;
  addFeatureFileTag(ffId: number, tag:ITag): void;
  deleteFeatureFileTag(ffId: number, tagId: string): void;
  updateDisplayedSteps(steps: IStepDefinitionList[]): void;
  setFeatureFileSelected(ffId: number): void;
  setScenarioSelected(ffId: number, scId: number): void;
  setStepSelected(ffId: number, scId: number, stepId: string): void;
}

export default ElementType;
