import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type CreateMappingRequestData = {
  probeName: string;
  iccid: number;
};

export default function useCreateMapping(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ probeName, iccid }: CreateMappingRequestData) => axios.put(
    `/sim-manager/api/mappings/probe/${probeName}`,
    { probe_name: probeName, iccid }
  ).then(({ data }) => data), options);
}
