import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import uuid from 'uuid';

import { keys } from '../Timeline/timelineKeys';

import TablePagination from '../Table/TablePagination';
import TableSort from '../Table/TableSort';

import useCustomTable from '../Table/useCustomTable';
import Timeline from '../Timeline';

import TimelineActions from './TimelineActions';
import useTimeline from './useTimeline';

import './timelineWithTable.scss';

const TimelineWithTable = (
  {
    NewButtonProps,
    TableProps,
    groups,
    horizontalLineClassNamesForGroup,
    ...props
  }: any,
) => {
  const {
    columns,
    customGlobalFilter,
    customPageSize,
    disableActions,
    hideNewButton,
    itemsPerPage,
    onSelectedRowsChange,
    sidebarWidth: sidebarWidthProp,
  } = TableProps;

  const {
    // table
    headerGroups,
    prepareRow,
    // pagination
    rows,
    page,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    pageOptions,
    pageCount,
    selectedFlatRows,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    // global filtering (searching)
    setGlobalFilter,
  } = useCustomTable({ data: groups || [], ...TableProps });

  const [usingCustomPageSize, setUsingCustomPageSize] = useState(customPageSize !== undefined);

  const {
    animateScroll, fullscreen, setFullscreen, setVisibleTime, zoom, visibleTime,
  } = useTimeline();

  useEffect(() => {
    if (_.isFunction(onSelectedRowsChange)) {
      onSelectedRowsChange(_.map(selectedFlatRows, 'original'));
    }
  }, [selectedFlatRows]);

  const groupRenderer = useCallback(({ group: row }: any) => {
    prepareRow(row);
    return (
      <div role="table">
        <div role="rowgroup">
          <div {...row.getRowProps({ className: 'reservations-timeline-sidebar-title-container' })}>
            {row.cells.map((cell: any) => (
              <div
                {...cell.getCellProps({
                  className: clsx('reservations-timeline-sidebar-title-column text-truncate', {
                    selection: cell?.column?.id === '__selection',
                  }),
                })}
              >
                {cell.render('Cell', { editable: true })}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }, [selectedFlatRows]);

  const titleRender = headerGroups.map((headerGroup) => (
    <div key={uuid.v4()} role="table">
      <div {...headerGroup.getHeaderGroupProps({ className: 'reservations-timeline-sidebar-title-container header' })}>
        {headerGroup.headers.map((column) => (
          <div
            {...column.getHeaderProps({
              className: clsx('reservations-timeline-sidebar-title-column text-truncate', {
                selection: column?.id === '__selection',
              }),
            })}
          >
            <div className="d-flex align-items-center flex-nowrap">
              {/* Add a sort direction indicator */}
              {column.canSort ? (
                <TableSort
                  isSorted={column?.isSorted}
                  isSortedDesc={column?.isSortedDesc}
                  {...column.getSortByToggleProps()}
                >
                  <span
                    className="text-nowrap"
                    style={{ fontWeight: 500 }}
                  >
                    {column.render('Header')}
                  </span>
                </TableSort>
              ) : (
                <span
                  className="text-nowrap"
                  style={{ fontWeight: 500 }}
                >
                  {column.render('Header')}
                </span>
              )}
              {column.canFilter && !column.disableFilters ? column.render('Filter') : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  ));

  if (customGlobalFilter && globalFilter !== customGlobalFilter) {
    setGlobalFilter(customGlobalFilter);
  }

  if (usingCustomPageSize && customPageSize) {
    setPageSize(customPageSize);
    setUsingCustomPageSize(false);
  }

  const [firstTime, setFirstTime] = useState(true);

  const onTimeChange = (
    visibleTimeStart: number, visibleTimeEnd: number, updateScrollCanvas: any,
  ) => {
    if (firstTime) {
      // something is setting wrong start/end time for calendar but just after init,
      // can't find from where change comes, so I handle it here and spip first time change
      setFirstTime(false);
    } else {
      setVisibleTime([
        visibleTimeStart,
        visibleTimeEnd,
      ]);
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  };

  // workaround for Timeline groups not rerendering when row is selected
  const sidebarWidth = useMemo(() => {
    const defaultSidebarWidht = sidebarWidthProp ?? 620;

    if (selectedFlatRows) {
      return defaultSidebarWidht + (selectedFlatRows.length * 0.00001);
    }

    return defaultSidebarWidht;
  }, [selectedFlatRows, sidebarWidthProp]);

  return (
    <div className={clsx({ fullscreen })}>
      {!disableActions && (
        <TimelineActions
          data={rows}
          // ExportButtonProps={ExportButtonProps}
          columns={columns}
          gotoPage={gotoPage}
          // hideExportButton={hideExportButton}
          hideNewButton={hideNewButton}
          // hideRefreshButton={hideRefreshButton}
          // hideSearchInput={hideSearchInput}
          NewButtonProps={NewButtonProps}
          // RefreshButtonProps={RefreshButtonProps}
          // SearchInputProps={SearchInputProps}
          setGlobalFilter={setGlobalFilter}
          animateScroll={animateScroll}
          fullscreen={fullscreen}
          setFullscreen={setFullscreen}
          setVisibleTime={setVisibleTime}
          zoom={zoom}
        />
      )}
      <Timeline
        groups={page.map((row: any) => ({ ...row, groupId: row?.original?.probe_name }))}
        horizontalLineClassNamesForGroup={horizontalLineClassNamesForGroup}
        groupRenderer={groupRenderer}
        keys={{
          ...keys,
          groupIdKey: 'groupId',
        }}
        onTimeChange={onTimeChange}
        title={titleRender}
        visibleTimeStart={visibleTime[0]}
        visibleTimeEnd={visibleTime[1]}
        sidebarWidth={sidebarWidth}
        {...props}
      />
      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        pageIndex={pageIndex}
        rows={rows.length}
        setPageSize={setPageSize}
        pageSize={pageSize}
        customPageSize={customPageSize}
        pageSizesArray={itemsPerPage}
      />
    </div>
  );
};

export default TimelineWithTable;
