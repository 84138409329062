import moment from 'moment';

const getReservationStatus = (from: any, until: any, timeFormat: string) => {
  // moment(reservation.from).format(config.ui.dateTimeFormat)
  const momentFrom = moment(from, timeFormat);
  const momentUntil = moment(until, timeFormat);
  const momentNow = moment();

  if (momentFrom.isAfter(momentNow) && momentUntil.isAfter(momentNow)) {
    return 'upcoming';
  } if (momentUntil.isAfter(momentNow)) {
    return 'active';
  }
  return 'expired';
};

export default getReservationStatus;
