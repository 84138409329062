import { Container, Row, Col } from 'react-bootstrap';
import * as React from 'react';

const Imprint = () => (
  <div className="pt-4">
    <Container>
      <Row>
        <Col md={12}>
          <h1 className="display-4">
            Imprint
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <strong>
            Address:
          </strong>
          <p>
            Deutsche Telekom AG
            <br />
            Friedrich-Ebert-Allee 140
            <br />
            53113 Bonn, Germany
          </p>
          <strong>
            Trade Register:
          </strong>
          <p>
            District Court of Bonn HRB 6794,
            <br />
            Registered Office Bonn
            <br />
            VAT ID No. DE 123475223
          </p>
          <strong>
            Contact:
          </strong>
          <p>
            <a href="mailto:test.automation@telekom.de">test.automation@telekom.de</a>
          </p>
          <strong>
            Authorized representatives:
          </strong>
          <p>
            Timotheus Höttges
            <br />
            Adel Al-Saleh
            <br />
            Birgit Bohle
            <br />
            Srinivasan Gopalan
            <br />
            Dr. Christian P. Illek
            <br />
            Thorsten Langheim
            <br />
            Dominique Leroy
            <br />
            Claudia Nemat
          </p>
          <strong>
            Regulatory authority:
          </strong>
          <p>
            Federal Network Agency for Electricity, Gas, Telecommunications, Post and Railway
            <br />
            Tulpenfeld 4, 53113 Bonn, Germany
            <br />
          </p>
          <strong>Responsible:</strong>
          <p>
            Deutsche Telekom AG
            <br />
            Marin Komadina
            <br />
            TDI Tribe Lead - Automation Delivery
            <br />
            Radnicka cesta 21
            <br />
            10 000 Zagreb, Croatia
            <br />
            HR
          </p>
          <strong>
            Editorial staff:
          </strong>
          <p>
            Argiris Stamateas
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Imprint;
