import clsx from 'clsx';

import { useDrag, useDrop } from 'react-dnd';

import TableSort from './TableSort';

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): any => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  );
  return [...columnOrder];
};

const TableHeader = ({ column, columnOrder, setColumnOrder, stickyActions }: any) => {
  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: any) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder
      );
      setColumnOrder(newColumnOrder);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  });

  return (
    <th
      className={clsx({
        'table-sticky-th-left': column?.id === '__actions' && stickyActions,
        'table-sticky-th-right': column?.id === '__selection' && stickyActions,
      })}
      ref={dropRef}
      style={{ opacity: isDragging ? 0.5 : 1, width: column?.id === '__actions' ? '1px' : undefined }}
      {...column.getHeaderProps()}
    >
      <div ref={previewRef} className="d-flex align-items-center flex-nowrap">
        {/* Add a sort direction indicator */}
        {column.canSort ? (
          <TableSort
            isSorted={column?.isSorted}
            isSortedDesc={column?.isSortedDesc}
            {...column.getSortByToggleProps()}
          >
            <span className="text-nowrap" style={{ fontWeight: 500 }}>
              <div className="text-button" ref={dragRef}>
                {column.render('Header')}
              </div>
            </span>
          </TableSort>
        ) : (
          <span className="text-nowrap" style={{ fontWeight: 500 }}>
            <div className="text-button" ref={dragRef}>
              {column.render('Header')}
            </div>
          </span>
        )}
        {column.canFilter && !column.disableFilters ? column.render('Filter') : null}
      </div>
    </th>
  );
};

export default TableHeader;
