import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type DeleteQueuedStatusData = {
  id: number;
};

export default function useDeleteQueuedStatus(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ id }: DeleteQueuedStatusData) => axios.delete(
    `/scheduler/api/queue/${id}`,
  ).then(({ data }) => data), options);
}
