import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import useConfirm from 'hooks/useConfirm';
import useUserTableSettings from 'hooks/useUserTableSettings';

import Table from 'components/Table';

import { IGroup } from 'context/backendData/interfaces';

import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';
import useDeleteSubscriber from 'hooks/services/simManagement/subscriber/useDeleteSubscriber';

import { Sim } from 'hooks/services/simManagement/interfaces';
import EditSimModal from '../SimCardsTable/EditSimModal';

const hiddenColumns = [
  'msisdn',
  'imsi',
  'tariff',
  'wnw',
  'op_wnw',
  'psp',
  'wholesale_id',
  'itg_id',
  'secret',
  'name',
  'origin',
  'prepaid',
  'type',
  'sim_type',
  'modified',
  'position'
];

const OfflineSimCardsTable = (props: any) => {
  const { tableColumns, setMessage, setErrMessage } = props;
  const { data: simCards, refetch: getSimCards } = useGetSubscribers();
  const [selectedSims, setSelectedSims] = useState<any>();

  const userTableSettings = useUserTableSettings('offline-sim-cards', { hiddenColumns });

  const [editSimShowModal, setEditSimShowModal] = useState(false);
  const [selectedSim, setSelectedSim] = useState<IGroup | undefined>();

  const columns = useMemo(() => tableColumns, [tableColumns]);

  const [deleteSim] = useDeleteSubscriber(
    {
      onSuccess: () => {
        setMessage('SIMs have been deleted');
        getSimCards();
        setSelectedSims('');
      },
      onError: () => setErrMessage('unable to delete SIM(s)'),
    },
  );

  // add confirm modal
  const confirm = useConfirm();
  // delete selected sims
  const handleDelete = async (id: any) => {
    confirm({
      body: 'Do you really want to delete selected SIMs?',
      onOk: () => deleteSim({ id }),
      title: 'Delete SIMs',
    });
  };

  const handleSelectedRowsChange = useCallback((rows: any) => {
    setSelectedSims((prevState: any) => {
      const newSelectedSims = _.map(rows, 'id');

      if (!_.isEqual(prevState, newSelectedSims)) {
        return newSelectedSims;
      }

      return prevState;
    });
  }, []);

  const openModal = (value: any) => {
    console.log('group data: ', value);
    setSelectedSim(value);
    setEditSimShowModal(true);
  };

  const tableRowActions = useMemo(() => ({
    content: 'Actions',
    items: [
      {
        content: 'Edit',
        key: 'edit',
        onClick: (row: any) => openModal(row),
        variant: 'outline-primary'
      },
    ],
  }), []);

  const tableData = simCards?.filter(
    (simCard: Sim) => !simCard.position && (simCard.type === 'mappable' || simCard.type === 'static')
  ).map((sim: Sim) => ({
    ...sim,
    network: [sim?.lab && 'lab', sim?.live && 'live']
  }));

  console.log(`SelectedSims ${selectedSims}`);

  return (
    <>
      {userTableSettings?.isFetched && (
        <Table
          actionsPosition="left"
          columns={columns}
          disablePagination
          rowActions={tableRowActions}
          data={tableData ?? []}
          DeleteButtonProps={
            {
              id: 'delete-sim-cards',
              content: 'Delete',
              disabled: _.toSafeInteger(selectedSims?.length) < 1,
              onClick: handleDelete,
            }
          }
          initialState={userTableSettings?.initialState}
          selectable
          showSettingsMenu
          stickyActions
          stickyHeader
          onStateChange={userTableSettings.updateTableSettings}
          onSelectedRowsChange={handleSelectedRowsChange}
          skipReset={false}
        />
      )}
      {editSimShowModal
        && (
          <EditSimModal
            onHide={() => setEditSimShowModal(false)}
            show={editSimShowModal}
            simData={selectedSim}
            getSimCards={getSimCards}
          />
        )}
    </>
  );
};

export default OfflineSimCardsTable;
