import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import parser from 'cron-parser';
import cronstrue from 'cronstrue';
import moment from 'moment';
import useConfirm from 'hooks/useConfirm';
import useDeleteScheduledJob from '../../hooks/services/scheduler/useDeleteScheduledJob';
import usePauseScheduledJob from '../../hooks/services/scheduler/usePauseScheduledJob';
import useUnpauseScheduledJob from '../../hooks/services/scheduler/useUnpauseScheduledJob';

import TestTagModal from './TestTagModal';

interface IOverviewProps {
  scheduledJobs: any,
  getScheduledJobs: any;
  setMessage: (message: string) => void;
  setErrMessage: (message: string) => void;
  users: any;
}

const ScheduledCampaigns = ({
  scheduledJobs,
  getScheduledJobs,
  setMessage,
  setErrMessage,
  users,
}: IOverviewProps) => {
  const [showTestTagModal, setShowTestTagModal] = useState(false);
  const [schedulingId, setSchedulingId] = useState<string>('');
  const [campaignName, setCampaignName] = useState<string>('');

  const options = {
    currentDate: Date.now(),
    iterator: true,
    utc: true,
  };

  const nextRunCron = (cron:any) => {
    const interval = parser.parseExpression(cron, options);
    const intervalObj: any = interval.next();
    const originalDate = intervalObj.value.toDate();
    const year = originalDate.getFullYear();
    const month = (`0${originalDate.getMonth() + 1}`).slice(-2); // Add leading zero if necessary
    const day = (`0${originalDate.getDate()}`).slice(-2); // Add leading zero if necessary
    const hours = (`0${originalDate.getHours()}`).slice(-2); // Add leading zero if necessary
    const minutes = (`0${originalDate.getMinutes()}`).slice(-2); // Add leading zero if necessary
    const seconds = (`0${originalDate.getSeconds()}`).slice(-2); // Add leading zero if necessary
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const nextRunAt = (time:any) => {
    const timeUTC = moment(time.replace(' UTC', '')).format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T').concat('Z');
    const originalDate = new Date(timeUTC);
    const year = originalDate.getFullYear();
    const month = (`0${originalDate.getMonth() + 1}`).slice(-2); // Add leading zero if necessary
    const day = (`0${originalDate.getDate()}`).slice(-2); // Add leading zero if necessary
    const hours = (`0${originalDate.getHours()}`).slice(-2); // Add leading zero if necessary
    const minutes = (`0${originalDate.getMinutes()}`).slice(-2); // Add leading zero if necessary
    const seconds = (`0${originalDate.getSeconds()}`).slice(-2); // Add leading zero if necessary
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const confirm = useConfirm();

  const [pauseCampaign] = usePauseScheduledJob(
    {
      onSuccess: () => {
        setMessage('Campaign paused');
        getScheduledJobs();
      },
      onError: () => setErrMessage('Failed to pause campaign'),
    },
  );

  const handlePauseCampaign = (scheduleId: any) => {
    confirm({
      body: 'Do you want to pause the campaign? Note: Currently running jobs will continue to run and terminate as scheduled.',
      onOk: () => pauseCampaign({ scheduleId }),
      title: 'Pause Campaign',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  const [unpauseCampaign] = useUnpauseScheduledJob(
    {
      onSuccess: () => {
        setMessage('Campaign resumed');
        getScheduledJobs();
      },
      onError: () => setErrMessage('Failed to resume campaign'),
    },
  );

  const handleUnpauseCampaign = (scheduleId: any) => {
    confirm({
      body: 'Do you want to resume the campaign?',
      onOk: () => unpauseCampaign({ scheduleId }),
      title: 'Resume Campaign',
    });
  };

  const [deleteCampaign] = useDeleteScheduledJob(
    {
      onSuccess: () => {
        setMessage('Campaign deleted');
        getScheduledJobs();
      },
      onError: () => setErrMessage('Failed to delete campaign'),
    },
  );

  const handleDeleteCampaign = (scheduleId: any) => {
    confirm({
      body: 'Do you want to delete the campaign? Note: Currently running jobs will continue to run and terminate as scheduled.',
      onOk: () => deleteCampaign({ scheduleId }),
      title: 'Delete Campaign',
      cancelPhrase: 'No',
      okPhrase: 'Yes',
    });
  };

  const scheduledJobsKeyArray = Object.keys(scheduledJobs);

  const sortedKeys = Object.keys(scheduledJobs).sort((keyA, keyB) => {
    const jobA = scheduledJobs[keyA][1];
    const jobB = scheduledJobs[keyB][1];

    // Check if the jobs have the "paused" property
    const pausedA = jobA.paused || false; // Default to false if "paused" is missing
    const pausedB = jobB.paused || false;

    // Compare based on the presence of the "paused" property
    if (pausedA && !pausedB) return 1; // jobA is paused but jobB isn't
    if (!pausedA && pausedB) return -1; // jobB is paused but jobA isn't

    // Check if the jobs have the "last_run" property
    const hasLastRunA = 'last_run' in jobA;
    const hasLastRunB = 'last_run' in jobB;

    // Compare based on the presence of the "last_run" property
    if (!hasLastRunA && hasLastRunB) return -1; // jobA doesn't have "last_run" but jobB does
    if (hasLastRunA && !hasLastRunB) return 1; // jobB doesn't have "last_run" but jobA does

    // Compare based on the "last_run" property (if available)
    if (hasLastRunA && hasLastRunB) {
      const lastRunA = new Date(jobA.last_run);
      const lastRunB = new Date(jobB.last_run);

      if (lastRunA > lastRunB) return -1; // jobA has later "last_run" than jobB
      if (lastRunA < lastRunB) return 1; // jobB has later "last_run" than jobA
    }

    // If both have the same "paused" status, "last_run" status, or no "last_run" property,
    // compare based on keys
    return keyA.localeCompare(keyB);
  });

  useEffect(() => {
    getScheduledJobs();
  }, []);

  return (
    <>
      {sortedKeys.length < 1 ? <span>No scheduled campaigns</span> : (
        sortedKeys.map((key: any) => (
          <Row className="test-case-details">
            <div className="job-label">
              {scheduledJobs[key][1].name}
            </div>
            <div className="test-case-content border rounded p-3 mb-2">
              <Row>
                <Col>
                  <Row>
                    <strong>Status:</strong>
                  </Row>
                  <Row>
                    {
                scheduledJobs[key][1].paused
                  ? ('Paused') : ('Waiting for next run')
                }
                  </Row>
                </Col>
                <Col xs={7}>
                  <Row>
                    <strong>Scheduling:</strong>
                  </Row>
                  <Row>
                    <Col>
                      <Row>Repetition (UTC):</Row>
                      <Row>{scheduledJobs[key][1].type === 'cron' ? cronstrue.toString(scheduledJobs[key][1].value) : 'Once'}</Row>
                    </Col>
                    <Col>
                      <Row>Next run at:</Row>
                      <Row>{scheduledJobs[key][1].type === 'cron'
                        ? moment(nextRunCron(scheduledJobs[key][1].value)).utc(true).format('YYYY-MM-DD HH:mm:ss')
                        : moment(nextRunAt(scheduledJobs[key][1].value)).utc(true).format('YYYY-MM-DD HH:mm:ss')}
                      </Row>
                    </Col>
                    <Col>
                      <Row>Last Run:</Row>
                      <Row>{scheduledJobs[key][1].last_run ? moment(scheduledJobs[key][1].last_run.replace(' UTC', '').replace(' ', 'T').concat('Z')).utc(true).format('YYYY-MM-DD HH:mm:ss') : '---'}</Row>
                    </Col>
                    <Col>
                      <Row>Ceated by</Row>
                      <Row>{users.filter(
                        (item:any) => item.uid.toString() === scheduledJobs[key][1].args.user_id
                      )[0].username}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col className="ml-5">
                  <Row>
                    <strong>Show:</strong>
                  </Row>
                  <Row>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        setShowTestTagModal(true);
                        setSchedulingId(scheduledJobs[key][1].args.schedule_id);
                        setCampaignName(scheduledJobs[key][1].name);
                      }}
                    >
                      Test Set
                    </button>
                  </Row>
                </Col>
              </Row>
              <Row>
                {
                scheduledJobs[key][1].paused
                  ? (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        handleUnpauseCampaign(scheduledJobs[key][1].args.schedule_id);
                      }}
                    >
                      Resume
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      disabled={scheduledJobs[key][1].type === 'at'}
                      onClick={() => {
                        handlePauseCampaign(scheduledJobs[key][1].args.schedule_id);
                      }}
                    >
                      Pause
                    </button>
                  )
              }
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={() => {
                    handleDeleteCampaign(scheduledJobs[key][1].args.schedule_id);
                  }}
                >
                  Delete
                </button>
              </Row>
            </div>
          </Row>
        )))}
      {showTestTagModal
      && (
        <TestTagModal
          onHide={() => setShowTestTagModal(false)}
          show={showTestTagModal}
          campaignName={campaignName}
          scheduledJobs={scheduledJobs}
          scheduledJobsKeyArray={scheduledJobsKeyArray}
          schedulingId={schedulingId}
        />
      )}
    </>
  );
};

export default ScheduledCampaigns;
