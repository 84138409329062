import React from 'react';
import { Container } from 'react-bootstrap';
import { Probe } from 'hooks/services/probe/interfaces';
import DeviceSim from './Sim';
import Buttons from './Buttons';
import {
  Device,
} from '../stf/context/interface';

type InfoProps = {
  device: Device | undefined;
  deadline: number | null;
  onTimeOut?: () => any;
  probe: Probe | null;
  getStatus: () => void;
  deviceStatus: string;
  showSimAlert: boolean;
};

const Info = ({
  device,
  deadline,
  onTimeOut,
  probe,
  getStatus,
  deviceStatus,
  showSimAlert,
} : InfoProps) => (
  <Container style={{ minWidth: '600px' }}>
    <Buttons
      deadline={deadline}
      onTimeOut={onTimeOut}
      probe={probe}
    />
    <DeviceSim
      device={device}
      probe={probe}
      getStatus={getStatus}
      deviceStatus={deviceStatus}
      showSimAlert={showSimAlert}
    />
  </Container>
);

export default Info;
