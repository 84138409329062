import _ from 'lodash';
import {
  FormEvent, MouseEvent, useCallback, useMemo, useState,
} from 'react';

type IUseFormEvents = FormEvent<HTMLFormElement> | MouseEvent<HTMLButtonElement>;

type IUseFormProps = {
  change?: (newValues: any, values: any) => any;
  initialValues?: any;
  onSubmit?: (values: any) => void;
  submitWithErros?: boolean;
  validate?: (values: any) => any;
};

type IUseFormInputProps = {
  name: string;
};

type IUseFormStateValues = any;

type IUseFormState = {
  submitted: boolean;
  touched: boolean;
  values?: IUseFormStateValues;
};

const initialStates: IUseFormState = {
  submitted: false,
  touched: false,
};

const nameAndProps = (nameOrProps: string | IUseFormInputProps) => {
  const isArrayOrString = _.isArray(nameOrProps) || _.isString(nameOrProps);
  const name = isArrayOrString ? nameOrProps : _.get(nameOrProps, 'name');
  const props = !isArrayOrString ? nameOrProps : {};

  return [name, props];
};

const useForm = ({
  change,
  initialValues,
  onSubmit,
  submitWithErros,
  validate,
} : IUseFormProps = {
  change: undefined,
  initialValues: {},
  onSubmit: undefined,
  submitWithErros: false,
  validate: undefined,
}) => {
  const [formState, setFormState] = useState({
    ...initialStates,
    values: initialValues || {},
  });

  const errors = useMemo(
    () => (_.isFunction(validate) ? validate(formState?.values) : {}),
    [validate, formState],
  );

  const invalid = !_.isEmpty(errors);

  const mergeNewFormValue = useCallback(
    (value = {}, values = {}) => {
      const newValues = _.merge(_.cloneDeep(values), value);

      return _.isFunction(change) ? change(newValues, values) : newValues;
    },
    [change],
  );

  const handleBlur = useCallback(
    () => {
      if (!formState?.touched) {
        setFormState((state) => ({
          ...state,
          touched: true,
        }));
      }
    },
    [formState],
  );

  const handleChange = useCallback(
    (name: string, valuePath?: string) => (eventOrValue: IUseFormEvents | any = '') => {
      const targetValuePath = eventOrValue?.target?.type === 'checkbox'
        ? 'target.checked'
        : 'target.value';

      const value = valuePath
        ? _.get(eventOrValue, valuePath, eventOrValue)
        : _.get(eventOrValue, targetValuePath, eventOrValue);

      const newFormValue = _.set({}, name, value);

      const newFormValues = mergeNewFormValue(
        newFormValue,
        _.get(formState, 'values'),
      );

      setFormState((state) => ({
        ...(state || {}),
        touched: true,
        submitted: false,
        values: newFormValues,
      }));
    },
    [formState, mergeNewFormValue],
  );

  const handleSubmit = (event: IUseFormEvents) => {
    event.preventDefault();

    setFormState((state) => ({
      ...state,
      submitted: true,
    }));

    if (_.isFunction(onSubmit) && (submitWithErros || !invalid)) {
      onSubmit(_.get(formState, 'values'));
    }
  };

  const handleReset = () => {
    setFormState({
      ...initialStates,
      values: initialValues,
    });
  };

  const inputProps = (nameOrProps: string | IUseFormInputProps, controlled: boolean) => {
    const [name, props] = nameAndProps(nameOrProps);

    const value = _.get(formState?.values, name, '');
    const error = _.get(errors, name, '');

    const onBlur = handleBlur;
    const onChange = handleChange(name);

    const defaultProps = {
      name,
      onChange,
      onBlur,
      ...props,
    };

    const propsWithValue = {
      ...defaultProps,
      inValid: formState?.submitted && Boolean(error),
      feedback: formState?.submitted && error,
      value,
    };

    return controlled ? propsWithValue : defaultProps;
  };

  const reset = useCallback(() => {
    setFormState({
      ...initialStates,
      values: initialValues,
    });
  }, [initialValues]);

  const resetValues = useCallback(() => {
    setFormState((state) => ({
      ...state,
      submitted: false,
      values: initialValues,
    }));
  }, [initialValues]);

  const setValue = useCallback((path, value, touched = true) => {
    setFormState((state) => ({
      ...(state || {}),
      submitted: false,
      touched: touched ?? state?.touched,
      values: _.set(_.cloneDeep(state?.values), path, value),
    }));
  }, []);

  const updateValues = useCallback(
    (valuesOrFn, touched = true) => {
      setFormState((state) => ({
        ...(state || {}),
        submitted: false,
        touched: touched ?? state?.touched,
        values: _.isFunction(valuesOrFn)
          ? mergeNewFormValue(valuesOrFn(state?.values), state?.values)
          : mergeNewFormValue(valuesOrFn, state?.values),
      }));
    },
    [mergeNewFormValue],
  );

  const setValues = useCallback((valuesOrFn, touched = true) => {
    setFormState((state) => ({
      ...(state || {}),
      submitted: false,
      touched: touched ?? state?.touched,
      values: _.isFunction(valuesOrFn)
        ? valuesOrFn(state?.values)
        : valuesOrFn,
    }));
  }, []);

  const setSubmitted = (submitted: boolean) => setFormState((state) => ({ ...state, submitted }));

  return {
    errors,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    invalid,
    inputProps,
    reset,
    resetValues,
    setValue,
    setValues,
    setSubmitted,
    updateValues,
    ...formState,
  };
};

export default useForm;
