import React, { useEffect, useState } from 'react';

import {
  Alert,
  Button, Card, Col, Form, FormControl, Modal,
} from 'react-bootstrap';
import useGetSubscribers from '../../../../../hooks/services/simManagement/subscriber/useGetSubscribers';
import EditPoolTable from './EditPoolTable';
import useAssignSimPool from '../../../../../hooks/services/simManagement/pool/useAssignSimPool';
import useUnassignSimPool from '../../../../../hooks/services/simManagement/pool/useUnassignSimPool';
import useAlerts from '../../../../../hooks/useAlerts';
import useForm from '../../../../../hooks/useForm';
import useUpdatePool from '../../../../../hooks/services/simManagement/pool/useUpdatePool';

const isSelected = (arr: string[] | undefined, searchValue: any) => (
  arr?.includes(searchValue)
);

const EditPoolModal = (props : any) => {
  const {
    onHide,
    show,
    selectedPool,
    getPools,
  } = props;

  type FormErrors = {
    name?: string;
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(true);
  const [message, setMessage] = useState('');
  const { showAlert } = useAlerts();

  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errorMessage) {
      showAlert({
        id: `new-message_${errorMessage}`,
        content: `${errorMessage}`,
        variant: 'danger',
      });
      setErrorMessage('');
    }
  }, [message, errorMessage, showAlert]);

  const { data: sims } = useGetSubscribers();
  const [selectedSims, setSelectedSims] = useState(selectedPool?.sims);

  useEffect(() => {}, [sims, selectedSims]);

  const [addSimPool] = useAssignSimPool({
    onSuccess: () => {
      setMessage('Pool updated!');
      getPools();
      onHide();
    },
    onError: (error) => {
      setErrorMessage(`Failed to update pool:  ${error}`);
    }
  });

  const [removeSimPool] = useUnassignSimPool({
    onSuccess: () => {
      setMessage('Pool updated!');
      getPools();
      onHide();
    },
    onError: (error) => {
      setErrorMessage(`Failed to update pool:  ${error}`);
    }
  });

  const handleUpdatePool = () => {
    selectedSims.forEach((sim : any) => {
      if (!isSelected(selectedPool?.sims, sim)) {
        addSimPool({
          name: selectedPool?.name,
          iccid: sim,
        });
      }
    });
    selectedPool?.sims.forEach((sim : any) => {
      if (!isSelected(selectedSims, sim)) {
        removeSimPool({
          name: selectedPool?.name,
          iccid: sim,
        });
      }
    });
  };

  const [updatePool, { error }] = useUpdatePool({
    onSuccess: () => {
      setMessage(`pool ${selectedPool?.name} has been renamed!`);
      getPools();
      onHide();
    },
    onError: () => setErrorMessage(`Failed to rename pool ${selectedPool?.name}`),
  });

  const {
    errors: formErrors, handleChange, handleSubmit, submitted, values: formValues,
  } = useForm({
    initialValues: {
      name: selectedPool?.name,
    },
    onSubmit: () => {
      updatePool({
        pool: selectedPool?.name,
        updatedPool: {
          name: formValues.name,
        }
      });
    },
    validate: (values) => {
      const errors: FormErrors = {};
      if (!values?.name || values?.name === '') {
        errors.name = 'Please fill in the name.';
      }
      return errors;
    },
  });

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      id="group-edit-modal"
      backdrop="static"
    >
      <Card>
        <Card.Header>
          {`Edit Pool ${selectedPool.name}` }
          <button type="button" className="close" onClick={onHide} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </Card.Header>
        <Form onSubmit={handleSubmit}>
          {error?.message && showError && (
          <Alert variant="warning" onClose={() => setShowError(false)} dismissible>
            {error?.message}
          </Alert>
          )}
          <Card.Body>
            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label htmlFor="name">Name</Form.Label>
                <FormControl
                  autoFocus
                  id="name"
                  isInvalid={submitted && Boolean(formErrors?.name)}
                  name="name"
                  onChange={handleChange('name')}
                  required
                  type="text"
                  value={formValues?.name}
                />
                {submitted && Boolean(formErrors?.name) && (
                <FormControl.Feedback type="invalid">
                  {formErrors?.name}
                </FormControl.Feedback>
                )}
              </Form.Group>
            </Form.Row>
            <EditPoolTable
              selectedSims={selectedSims}
              setSelectedSims={setSelectedSims}
              isSelected={isSelected}
            />
          </Card.Body>
          <Card.Footer style={{ width: '100%', display: 'flex' }}>
            <div className="ml-auto">
              <Button
                variant="light"
                onClick={onHide}
              >
                Close
              </Button>
              <Button type="submit" onClick={handleUpdatePool}>
                Update pool
              </Button>
            </div>
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditPoolModal;
