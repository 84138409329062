import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from '../../axios/useAxiosWithToken';

type EditChannelValueData = {
  probe: string;
  channel: string;
  value: number | number[] | string;
};

export default function useEditProbeChannelFade(options?: MutationConfig<any, any>) {
  const axios = useAxiosWithToken({ ...options, baseURL: process.env.REACT_APP_ATTENUATORS });

  return useMutation(({ probe, channel, value }: EditChannelValueData) => axios.patch(
    `/attenuator/api/probes/${probe}/channels/${channel}/fade/${value}`
  ).then(({ data }) => data), options);
}
