import React from 'react';

interface IAttProps {
  name: string;
  setSelectedAtt: React.Dispatch<string>
}

const Attenuator = ({ name, setSelectedAtt }: IAttProps) => (
  <button
    type="button"
    id={name}
    className="btn btn-block btn-lg btn-light"
    onClick={() => setSelectedAtt(name)}
  >
    {name}
  </button>
);

export default Attenuator;
