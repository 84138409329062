import { useMutation } from 'react-query';
import { MutationConfig } from 'react-query/types/core/types';
import useAxiosWithToken from 'hooks/axios/useAxiosWithToken';

type ReservationData = {
  from?: string;
  until?: string;
};

type CreateReservationRequestData = {
  probe_name: string;
  reservation: ReservationData
};

export default function useCreateReservation(options: MutationConfig<any, any>) {
  const axios = useAxiosWithToken();

  return useMutation(({ probe_name, reservation }: CreateReservationRequestData) => axios.post(
    `/reservations/api/reservations/byProbeName/${probe_name}`,
    reservation,
  ).then(({ data }) => data), options);
}
