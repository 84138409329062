import _ from 'lodash';
import React, {
  useEffect, useState,
} from 'react';
import {
  Button,
  Modal,
  Tabs,
  Tab,
  Card,
} from 'react-bootstrap';

import useGetProbes from '../../../../../hooks/services/probe/useGetProbes';
import useGetBoards from '../../../../../hooks/services/simManagement/board/useGetBoards';
import useGetSubscribers from '../../../../../hooks/services/simManagement/subscriber/useGetSubscribers';
import useUpdateClaims from '../../../../../hooks/services/user/claim/useUpdateClaims';
import useAlerts from '../../../../../hooks/useAlerts';
import EditSimCardsTable from './EditSimCardsTable';
import EditBoardsTable from './EditBoardsTable';
import EditProbesTable from './EditProbesTable';

// e.g. array groupData.sims, iccid
const isSelected = (arr: string[] | undefined, searchValue: any) => (
  _.includes(arr, searchValue)
);

export interface IEditGroupModalProps {
  show: boolean;
  onHide: () => void;
  groupData: any;
  getClaims: any;
}
const EditGroupModal = (props: IEditGroupModalProps) => {
  const {
    show, onHide, groupData, getClaims,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const { showAlert } = useAlerts();

  // success message rendering
  useEffect(() => {
    if (message) {
      showAlert({
        id: `new-message_${message}`,
        content: `${message}`,
        variant: 'success',
      });
      setMessage('');
    }
    if (errorMessage) {
      showAlert({
        id: `new-message_${errorMessage}`,
        content: `${errorMessage}`,
        variant: 'danger',
      });
      setErrorMessage('');
    }
  }, [message, errorMessage, showAlert]);

  const { data: probes } = useGetProbes();
  const { data: boards } = useGetBoards();
  const { data: sims } = useGetSubscribers();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortedBoards = _.groupBy(boards, (board) => (
    board?.board_path?.split('.')[0]
  ));

  const [selectedProbes, setSelectedProbes] = useState(groupData?.probes);
  const [selectedBoards, setSelectedBoards] = useState(groupData?.boards);
  const [selectedSims, setSelectedSims] = useState(groupData?.sims);

  useEffect(() => {}, [boards, probes, sims,
    selectedBoards, selectedProbes, selectedSims]);

  const [updateClaims] = useUpdateClaims({
    onSuccess: () => {
      setMessage(`group ${groupData.gid} has been updated!`);
      getClaims();
      onHide();
    },
    onError: () => setErrorMessage(`Failed to update group ${groupData.gid}`),
  });

  const handleUpdateGroup = () => updateClaims({
    groupId: groupData.gid,
    claims: {
      sims: selectedSims,
      boards: selectedBoards,
      pools: groupData.pools || [],
      probes: selectedProbes,
    },
  });

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      id="group-edit-modal"
      backdrop="static"
    >
      <Card>
        <Card.Header>
          {`Edit Group ${groupData.gid}` }
          <button type="button" className="close" onClick={onHide} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey="probes"
            id="group-edit-tabs"
            className="mb-3 border-bottom"
          >
            <Tab className="modalTab" eventKey="probes" title="PROBES">
              <EditProbesTable
                probes={probes}
                isSelected={isSelected}
                selectedProbes={selectedProbes}
                setSelectedProbes={setSelectedProbes}
              />
            </Tab>
            <Tab className="modalTab" eventKey="boards" title="BOARDS">
              <EditBoardsTable
                sortedBoards={sortedBoards}
                selectedBoards={selectedBoards}
                setSelectedBoards={setSelectedBoards}
                isSelected={isSelected}
              />
            </Tab>
            <Tab className="modalTab" eventKey="sims" title="SIMS">
              <EditSimCardsTable
                groupData={groupData}
                selectedSims={selectedSims}
                setSelectedSims={setSelectedSims}
                isSelected={isSelected}
              />
            </Tab>
          </Tabs>
        </Card.Body>
        <Card.Footer style={{ width: '100%', display: 'flex' }}>
          <div className="ml-auto">
            <Button
              variant="light"
              onClick={onHide}
            >
              Close
            </Button>
            <Button onClick={handleUpdateGroup}>
              Update group
            </Button>
          </div>
        </Card.Footer>
      </Card>
    </Modal>
  );
};

export default EditGroupModal;
