import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';

import Table from 'components/Table';

import useUserTableSettings from 'hooks/useUserTableSettings';

import useGetSubscribers from 'hooks/services/simManagement/subscriber/useGetSubscribers';

import tableColumns from '../../../SimColumns';

const hiddenColumns = [
  'msisdn',
  'imsi',
  'tariff',
  'wnw',
  'op_wnw',
  'psp',
  'wholesale_id',
  'itg_id',
  'secret',
  'name',
  'origin',
  'prepaid',
  'type',
  'sim_type',
  'modified',
  'position'
];

const EditPoolTable = (props : any) => {
  const { selectedSims, setSelectedSims, isSelected } = props;
  const { data: sims } = useGetSubscribers();

  const userTableSettings = useUserTableSettings('edit-pool', { hiddenColumns });

  const handleChangeSims = (e: any) => {
    const { value } = e.target;
    if (isSelected(selectedSims, value)) {
      setSelectedSims(selectedSims?.filter((val: any) => val !== value));
    } else {
      setSelectedSims([...selectedSims || [], value]);
    }
  };

  const columns = useMemo(() => [
    {
      Header: 'Selected',
      accessor: 'checked',
      disableFilters: true,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      Cell: (props: any) => {
        const { cell: { row } } = props;
        const { iccid, checked } = row.original;
        return (
          <>
            <Form.Check
              defaultChecked={checked}
              defaultValue={iccid}
              id={`groups-sims.${iccid}`}
              key={`groups-sims.${iccid}`}
              inline
              aria-label="Select"
              onClick={handleChangeSims}
            />
          </>
        );
      },
    },
    ...tableColumns.map((item) => ({ ...item, disableFilters: true })),
  ], [tableColumns]);

  const tableData = sims?.filter((sim: any) => sim.position !== null)
    .map((simCard: any) => ({ checked: isSelected(selectedSims, simCard.iccid), ...simCard }));

  return (
    <>
      <Table
        columns={columns}
        data={tableData ?? []}
        hideExportButton
        initialState={userTableSettings?.initialState}
        onStateChange={userTableSettings.updateTableSettings}
        skipReset={false}
      />
    </>
  );
};

export default EditPoolTable;
