import React from 'react';

import { Col, Pagination, Row } from 'react-bootstrap';

import { IPaginationProps } from './interfaces';

const TablePagination = (props: IPaginationProps) => {
  const {
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    // pageOptions,
    pageCount,
    pageIndex,
    pageSize,
    pageSizesArray,
    rows,
    setPageSize,
  } = props;

  if (rows < 10) {
    return null;
  }

  const pageSizes = pageSizesArray || [10, 20, 50, 100];

  return (
    <Row className="bu-mt-1">
      <Col xs="auto">
        <div className="d-flex align-items-baseline">
          <Pagination>
            {pageSizes.map((value, index) => {
              if (rows >= pageSizes[index - 1] || index === 0) {
                return (
                  <Pagination.Item
                    active={pageSize === value}
                    disabled={rows <= 10}
                    onClick={() => setPageSize(value)}
                    key={value}
                  >
                    {value}
                  </Pagination.Item>
                );
              }
              return null;
            })}
          </Pagination>
          <span className="bu-ml-1">per page</span>
        </div>
      </Col>
      <Col />
      <Col xs="auto">
        <Pagination>
          {pageCount > 4 && (
            <Pagination.Item disabled={!canPreviousPage} onClick={() => gotoPage(0)}>
              First
            </Pagination.Item>
          )}
          <Pagination.Item
            disabled={!canPreviousPage}
            onClick={() => previousPage()}
          >
            Previous
          </Pagination.Item>
          {Array(pageCount)
            .fill(0)
            .map(
              (item, index) => index > pageIndex - 5
                && index < pageIndex + 3 && (
                  <Pagination.Item
                    active={pageIndex === index}
                    key={`pagination-item-${index + 1}`}
                    onClick={() => gotoPage(index)}
                  >
                    {index + 1}
                  </Pagination.Item>
              ),
            )}
          <Pagination.Item
            disabled={!canNextPage}
            onClick={() => nextPage()}
          >
            Next
          </Pagination.Item>
          {pageCount > 4 && (
            <Pagination.Item
              disabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
            >
              Last
            </Pagination.Item>
          )}
        </Pagination>
      </Col>
    </Row>
  );
};

export default TablePagination;
